import {
    Box, Button, Divider, Flex, Text, Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer, Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useToast,
} from "@chakra-ui/react";

import { useNavigate } from "react-router-dom";
import { myContext } from "../../context/Context";
import { useContext, useEffect, useRef, useState } from "react";
import NavbarNew from "../../Navbar/NavbarNew";



function Deposit() {



    let result = JSON.parse(sessionStorage.getItem('key'));
    let navigate = useNavigate()
    const btnRef = useRef()
    let [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    let toast=useToast()


    let [net, setNetEquity] = useState(0)
    let pl = JSON.parse(localStorage.getItem("totalNetProfit"))
  

    // let { totalbalance, fetchBalance } = useContext(myContext)

    let margin = JSON.parse(localStorage.getItem("margin"))
    let res = JSON.parse(localStorage.getItem("account_type"))

    let [neteq, setEq] = useState(0)
    let [bonus,setBonus]=useState();
    let { totalbalance } = res
   

    


    let fetchData = async () => {
        try {
            let response = await fetch(`https://trading-jz57.onrender.com/register/${result.type.AcNumber}`)
            let res = await response.json();
           
            setEq(res.neteq+ parseFloat(pl))
            setBonus(res.bonus);
         

        } catch (error) {
            toast({
                description: `something went wrong`,
                status: `error`,
                duration: 5000,
                isClosable: true,
                position: "top-center",
            })
        }
    }
    useEffect(() => {
        fetchData();
       
    }, [])





    let fetchRequestDeposit = async () => {
        try {
            let response = await fetch(`https://trading-jz57.onrender.com/file/${result.type.AcNumber}`)
            let res = await response.json();
            setData(res.reverse());
               
        }
        catch (err) {
            toast({
                description: `something went wrong`,
                status: `error`,
                duration: 5000,
                isClosable: true,
                position: "top-center",
            })
        }

    }


    useEffect(() => {
        fetchRequestDeposit();
    }, [])

    const userPerPage = 5;
    const lastIndex = currentPage * userPerPage;
    const firstIndex = lastIndex - userPerPage;
    const user = data.slice(firstIndex, lastIndex);
    const totalPages = Math.ceil(data.length / userPerPage);

    return (
        <>
            <Box>
                <NavbarNew />
            </Box>
            <Box >

                <Flex gap={"30px"}>
                    <Box w={"20%"} display={{ base: "none", sm: "none", md: "none", lg: "none", xl: "block" }} p={"20px"} h={"100vh"} color={"white"} bg={"black"} >
                        <Box mt={"30px"}>
                            <Text textAlign={"center"} fontSize='2xl'>Account Summary</Text>
                        </Box>
                        <Box mt={"30px"}>
                            <Flex direction={"column"} gap={"20px"}>
                                <Box>
                                    <Flex justifyContent={"space-between"}>
                                        <Text fontWeight={"bold"}> Package</Text>
                                        <Text fontWeight={"500"}>{res.account_type} </Text>
                                    </Flex>
                                </Box>
                                <Box>
                                    <Flex justifyContent={"space-between"}>
                                        <Text fontWeight={"bold"}> Balance</Text>
                                        <Text fontWeight={"500"}> $ {totalbalance !== undefined ? totalbalance.toFixed(2) : "0.00"}</Text>
                                    </Flex>
                                </Box>
                                <Box>
                                    <Flex justifyContent={"space-between"}>
                                        <Text fontWeight={"bold"}> Open P/L</Text>
                                        <Text fontWeight={"500"}> $ {pl !== null ? pl.toFixed(2) : "0.00"}</Text>
                                    </Flex>
                                </Box>
                                <Box>
                                    <Flex justifyContent={"space-between"}>
                                        <Text fontWeight={"bold"}> Net Equity</Text>
                                        <Text fontWeight={"500"}> $ {neteq !== undefined ? neteq.toFixed(2) : "0.00"}</Text>
                                    </Flex>
                                </Box>
                                <Box>
                                    <Flex justifyContent={"space-between"}>
                                        <Text fontWeight={"bold"}> Bonus</Text>
                                        <Text fontWeight={"500"}> $ {bonus !== undefined ? bonus.toFixed(2) : "0.00"}</Text>
                                    </Flex>
                                </Box>
                                <Box>
                                    <Flex justifyContent={"space-between"}>
                                        <Text fontWeight={"bold"}> Margin</Text>
                                        <Text fontWeight={"500"}> $ {margin !== undefined ? margin.toFixed(2) : "0.00"}</Text>
                                    </Flex>
                                </Box>
                            </Flex>
                        </Box>

                    </Box>
                    <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "100%", xl: "80%" }} p={"20px"} boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px" >
                        <Box mt={"30px"}>
                            <Flex justifyContent={"space-between"} flexDirection={{ base: "column", sm: "row", md: "row", lg: "row", xl: "row" }}>
                                <Text fontSize={"xl"}>Deposit List</Text>
                                <Button bg={"rgb(255,218,69)"} mt={{ base: "20px", sm: "0px", md: "0px", lg: "0px", xl: "0px" }} color={"black"} onClick={() => { navigate("/deposit") }}>ADD DEPOSIT</Button>
                            </Flex>
                        </Box>
                        <Divider mt={"10px"}></Divider>
                        <Box mt={"20px"}>
                            <TableContainer>
                                <Table variant='simple' >

                                    <Thead>
                                        <Tr>
                                            <Th color={"white"}>Date</Th>
                                            <Th color={"white"}>Deposit Type</Th>
                                            <Th color={"white"}>Amount</Th>
                                            <Th color={"white"}>Status</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {
                                            user.length > 0 ? user.map((ele, idx) => {
                                                return <Tr key={idx}>
                                                    <Td>{new Intl.DateTimeFormat('en-GB', {
                                                        timeZone: 'Europe/London',
                                                        day: 'numeric',
                                                        month: 'short',
                                                        year: 'numeric',
                                                        hour: 'numeric',
                                                        minute: 'numeric',
                                                        second: 'numeric',
                                                        hour12: false,
                                                    }).format(new Date(ele.created_at))
                                                        .replace(/ /, '-')
                                                        .replace(/,/g, '')
                                                    }</Td>
                                                    <Td>{ele.code}</Td>
                                                    <Td >{ele.amount}</Td>
                                                    <Td >{ele.type_at}</Td>

                                                </Tr>
                                            }) : <Tr>
                                                <Td textAlign={"center"} colSpan={5}>No deposit history</Td>
                                            </Tr>
                                        }


                                    </Tbody>
                                    <Tfoot>
                                    </Tfoot>
                                </Table>
                            </TableContainer>
                            {user.length > 0 ? (
                                <Flex alignItems="center" justifyContent="right" gap="10px" mt="10px">
                                    <Text fontWeight="bold">
                                        {currentPage} of {totalPages}
                                    </Text>
                                    <Button
                                        bg={"rgb(255,218,69)"} color={"black"}
                                        _hover={{ cursor: "pointer" }}
                                        isDisabled={currentPage == 1}
                                        onClick={() => setCurrentPage(currentPage - 1)}
                                    >
                                        {"<"}
                                    </Button>
                                    <Button
                                        bg={"rgb(255,218,69)"} color={"black"}
                                        _hover={{ cursor: "pointer" }}
                                        isDisabled={currentPage == totalPages}
                                        onClick={() => setCurrentPage(currentPage + 1)}
                                    >
                                        {">"}
                                    </Button>
                                </Flex>
                            ) : null}
                        </Box>
                    </Box>
                </Flex>
            </Box>
        </>
    )
}

export default Deposit;