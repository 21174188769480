import { useToast } from "@chakra-ui/react";
import React, { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";

export const myContext = createContext();

const AuthContext = ({ children }) => {
  const user = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
    street_add: "",
    zip_code: "",
    city: "",
    state: "",
    country: "",
    dob: "",
    phone: "",
    account_type: "",
    leverage: "",
    remarks:""
  };

  const [userDetail, setUserDetails] = useState(user);
  let [page,setPage]=useState(1)
  
  let navigate = useNavigate();
  let [totalbalance, setBalanceTotal] = useState(0)
  let [pl, setpl] = useState();
  let toast = useToast();
  let [load, setLoad] = useState(true);

  let result = JSON.parse(sessionStorage.getItem("key"))
//console.log(page)
  const storeuserDetails = (data) => {
    setUserDetails({ ...userDetail, ...data });
  };
  // console.log(userDetail);

  let fetchBalance = async () => {
    try {
      let response = await fetch(`https://trading-jz57.onrender.com/register/${result.type.AcNumber}`)
      let res = await response.json();
      console.log(res.totalbalance);
      setBalanceTotal(res.totalbalance)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchData = async () => {
   
  };


  let logout = () => {
    sessionStorage.clear();
  }

  return (
    <div>
      <myContext.Provider value={{ storeuserDetails, userDetail, fetchData,  totalbalance, fetchBalance, setpl, pl, logout, load, setLoad ,setPage,page}}>
        {children}
      </myContext.Provider>
    </div>
  );
};

export default AuthContext;
