import {
  Box,
  Flex,
  FormControl,
  Input,
  InputRightElement,
  Text,
  InputGroup,
  Button,
  useToast,
  ButtonSpinner,
  Image,
} from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addUser } from "../redux/action";
import { myContext } from "../context/Context";
import NavbarNew from "../Navbar/NavbarNew";
import { Spinner } from '@chakra-ui/react'
import back from "../../src/images/back1.jpg"

export function Register1() {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const navigate = useNavigate();
  const { storeuserDetails, userDetail, setPage, page } = useContext(myContext)
  let [data, setData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: ""
  })
  let dispatch = useDispatch()
  let [next, setNext] = useState(true)
  let toast = useToast()
  useEffect(() => {
    setData({
      first_name: userDetail.first_name || "",
      last_name: userDetail.last_name || "",
      email: userDetail.email || "",
      password: userDetail.password || "",
      confirm_password: userDetail.confirm_password || "",
    });
  }, [userDetail]);
  let storeData = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });

  }

  const nextPage = async (e) => {
    e.preventDefault();
    if(data.password===data.confirm_password){
    setNext(false)
    try {
      let response = await fetch(`https://trading-jz57.onrender.com/check/email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ email: data.email })

      })
      if (response.ok) {
        let res = await response.json();
        console.log(res);
        toast({
          description: `${res.msg}`,
          status: `success`,
          duration: 5000,
          isClosable: true,
          position: "top-center",
        })
        setNext(true)
      } else {
        setPage(page + 1)
        storeuserDetails(data);
        setNext(true)
      }
    } catch (error) {
      toast({
        description: `Error in fetching`,
        status: `error`,
        duration: 5000,
        isClosable: true,
        position: "top-center",
      })
      setNext(false)
    }
  }else{
    toast({
      title: 'Password and confirm password are diffrent ',
      // description: `Check Close deal`,
      status: 'error',
      duration: 9000,
      isClosable: true,
      position: "bottom-left"
  })
  }

  };


  const { first_name, last_name, email, password, confirm_password } = data
  return (
    <>
      <Box>
        <Box>
          <NavbarNew />
        </Box>
        <Box>
          <Flex>
            <Box width={{base:"100%",sm:"100%",md:"100%",lg:"50%",xl:"50%"}} p={{base:"20px",sm:"20px",md:"20px",lg:"120px",xl:"120px"}}
            >
              <Flex
                display="flex"
                flexDirection={{ base: "column", md: "row" }}
                
              >
                <Box width={"100%"}>
                  <Flex gap="10px">
                    <Text fontSize={{ base: "xl", md: "2xl" }}>CREATE</Text>
                    <Text fontSize={{ base: "xl", md: "2xl" }} color="rgb(255,218,69)">
                      GLOABFX PROFILE
                    </Text>
                  </Flex>
                  <form onSubmit={nextPage}>
                    <Input
                      mt={"20px"}
                      type="text"
                      name="first_name"
                      _placeholder={{ fontSize: "14px" }}
                      placeholder="Enter First Name"
                      onChange={storeData}
                      value={first_name}
                      isRequired
                    />
                    <Input
                      mt={"20px"}
                      type="text"
                      name="last_name"
                      _placeholder={{ fontSize: "14px" }}
                      placeholder="Enter Last Name"
                      onChange={storeData}
                      value={last_name}
                      isRequired
                    />
                    <Input
                      mt={"20px"}
                      type="text"
                      name="email"
                      _placeholder={{ fontSize: "14px" }}
                      placeholder="Enter Email Address"
                      onChange={storeData}
                      value={email}
                      isRequired
                    />
                    <InputGroup size="md">
                      <Input
                        name="password"
                        mt={"20px"}
                        pr="4.5rem"
                        type={show ? "text" : "password"}
                        fontSize="14px"
                        _placeholder={{ fontSize: "14px" }}
                        placeholder="Enter Password"
                        onChange={storeData}
                        value={password}
                        isRequired
                      />
                      <InputRightElement width="4.5rem" mt={"20px"}>
                        <Button h="1.75rem" size="sm" onClick={handleClick}>
                          {show ? "Hide" : "Show"}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                    <Input
                      mt={"20px"}
                      type="text"
                      name="confirm_password"
                      value={data.confirm_password}
                      _placeholder={{ fontSize: "14px" }}
                      placeholder="Enter Confirm Password"
                      onChange={storeData}
                      isRequired
                    />
                    {
                      next ? <Button
                        w="100%"
                        mt="20px"
                        // leftIcon={<ArrowForwardIcon />}
                        bg="rgb(255,218,69)"
                        variant="solid"
                        color="black"
                        type="submit"
                        cursor={"pointer"}
                      >
                        Continue
                      </Button> : <Button
                        w="100%"
                        mt="20px"
                        // leftIcon={<ArrowForwardIcon />}
                        bg="rgb(255,218,69)"
                        variant="solid"
                        color="black"
                        type="submit"
                        cursor={"pointer"}
                      > <Spinner /> </Button>
                    }

                  </form>
                </Box>
              </Flex>
            </Box>
            <Box w={"50%"} display={{base:"none",sm:"none",md:"none",lg:"block",xl:"block"}}>
            <Image src={back} style={{ width: '100%', height: '100vh', objectFit: 'cover' }} />
            </Box>
      </Flex>
    </Box >
    </Box>
    </>
  );
}


