import { Box, Button, Flex, Input, Text, Textarea } from "@chakra-ui/react";
import Navbar from "../../Navbar/Navbar";
import NavbarNew from "../../Navbar/NavbarNew";
import NavbarHome from "../../Navbar/NavbarHome";
import Footer from "../../Navbar/Footer";



function Policy() {
    return (
        <>
            <Box>
                <NavbarHome />
            </Box>
            <Box padding={{base:'10px',sm:"10px",md:"10px",xl:"30px 45px",lg:"30px 45px"}} >
                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"50px"}>Our Trading Policy</Text>
            </Box>
            <Box paddingLeft={{base:'10px',sm:"10px",md:"10px",xl:"45px",lg:"45px"}}>
                <Flex gap={"20px"} flexDirection={{base:"column",sm:"column",md:"column",lg:"row",xl:"row"}}>
                    <Box w={{base:'100%',sm:"100%",md:"100%",xl:"70%",lg:"70%"}}>
                       
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>Disclaimer for forex</Text>
                            </Box>
                            <Box>
                            <Text fontSize={"15px"} mt={"30px"}>
                            By moving forward to access the materials through this gateway, you represent that you do not reside in one of the restricted countries and that you will not send any information from this website to anyone or to publications that are widely distributed in those countries.
                            </Text>
                            <Text fontSize={"15px"} mt={"30px"}>
                            This website is not meant for or accessible by those who are in the prohibited countries due to legal or regulatory limitations that are in effect there. We apologise for any inconvenience this may have caused.
                            </Text>
                             
                            </Box>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>Important Information Note</Text>
                            </Box>
                            <Box>
                            <Text fontSize={"15px"} mt={"30px"}>
                            The materials on this website are not intended for or directed at anyone situated in the prohibited countries and are provided for your convenience only.
                            </Text>
                            
                            </Box>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>Please carefully read this message</Text>
                            </Box>
                            <Box>
                            <Text fontSize={"15px"} mt={"30px"}>
                            It is applicable to everyone who visits this website. The disclaimer listed below may be changed or revised at any time. Every time you visit the website, you should read it completely.
                            </Text>
                            
                            </Box>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>Persons from Foreign Countries</Text>
                            </Box>
                            <Box>
                            <Text fontSize={"15px"} mt={"30px"}>
                            In certain places, it might not be legal to see the items you are trying to access. Only particular groups of people may be permitted to view such things in other jurisdictions. Anyone who wants to access these materials and/or conduct business through this website must first ensure that there are no local laws restricting or prohibiting them from doing so. By using this website, you represent and warrant that you are authorised to access the information therein and to engage in financial transactions through GloabFx under the laws and regulations of your local jurisdiction.


                            </Text>
                            <Text fontSize={"15px"} mt={"30px"}>
                            Please leave this website if you are not authorised to access the content or conduct business through it, or if you are unsure of your authorization to do so.
                            </Text>
                            
                            </Box>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>Source of Access</Text>
                            </Box>
                            <Box>
                            <Text fontSize={"15px"} mt={"30px"}>
                            In any case, GloabFx will not open an account with anyone who resides in one of the restricted nations or in another country where we are aware that access to our website or trading through it is against the law.
                            </Text>
                            
                            </Box>

                        </Box>
                    </Box>

                    <Box w={{base:"100%",sm:"100%",md:"100%",lg:"30%",xl:"30%"}} p={{base:'5px',sm:"5px",md:"5px",lg:"30px",xl:"30px"}}>
                        <Input placeholder="Your Name" />
                        <Input mt={"20px"} placeholder="Your Email" />
                        <Input mt={"20px"} placeholder="Phone Number" />
                        <Textarea mt={"20px"} placeholder="Your Messages"/>
                        <Button mt={"20px"}  bg={"rgb(255,218,69)"} w={"100%"}>SEND MAIL</Button>
                    </Box>
                </Flex>
            </Box>
            <Box>
                <Footer/>
            </Box>
        </>
    )
}
export default Policy;