import { Box, Button, Divider, Flex, Input, Select, Spinner, Text, useToast } from "@chakra-ui/react";
import { useState } from "react";
import AccountSummary from "../ModalPages/AccountSummary";
import NavbarNew from "../Navbar/NavbarNew";
import { useNavigate } from "react-router-dom";



function AddnewBank() {
    let [bank, setBankDetails] = useState({
        nick_name: "",
        full_name: "",
        bank_name: "",
        ifsc_code: "",
        account_number: "",
        branch_name: "",
        city: "",
        country: "",
        currency: ""
    })
    let [load, setLoad] = useState(true);

    let result = JSON.parse(sessionStorage.getItem("key"))
    let navigate = useNavigate()
    let toast=useToast();


    let saveBankDetails = (e) => {
        setBankDetails({ ...bank, [e.target.name]: e.target.value })
    }

    let saveDetails = async (e) => {
        e.preventDefault();
        setLoad(false)
        try {
            let response = await fetch(`https://trading-jz57.onrender.com/bank/${result.type.AcNumber}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(bank)

            })
            if (response.ok) {
                let res = await response.json();
                console.log(res);
                toast({
                    description: `Added Bank successfully`,
                    status: `success`,
                    duration: 5000,
                    isClosable: true,
                    position: "top-center",
                });
                setLoad(true);
                navigate("/withdraw")
            }else{
                toast({
                    description: `Unable to save try again`,
                    status: `error`,
                    duration: 5000,
                    isClosable: true,
                    position: "top-center",
                });
               setLoad(true);
            }

        } catch (error) {
            console.log(error)
            toast({
                description: `Error in server`,
                status: `error`,
                duration: 5000,
                isClosable: true,
                position: "top-center",
            });
            setLoad(true)
        }

    }
    return (
        <>
            <Box >
                <NavbarNew />
                <Box mt={"20px"} p={"30px"}>
                    <Box >
                        <Flex justifyContent={"space-between"}>
                            <Text fontSize={"xl"}>New Bank Account</Text>
                            <Button bg={"rgb(255,218,69)"} color={"black"} onClick={() => navigate("/withdraw")}>Back</Button>
                        </Flex>
                    </Box>
                    <Divider mt={"5px"}
                    ></Divider>
                    <form onSubmit={saveDetails}>
                        <Box mt={"20px"}>
                            <Flex justifyContent={"space-between"} flexDirection={{ base: "column", sm: "column", md: "row", lg: "row", xl: "row" }}>
                                <Box w={{ base: "100%", sm: "100%", md: "45%", lg: "45%", xl: "45%" }}>
                                    <Text>
                                        Nick Name
                                    </Text>
                                    <Input
                                        type="text"
                                        name="nick_name"
                                        value={bank.nick_name}
                                        onChange={saveBankDetails}
                                        placeholder="User nick name to identify your account" isRequired />
                                </Box>
                                <Box w={{ base: "100%", sm: "100%", md: "45%", lg: "45%", xl: "45%" }} mt={{ base: "20px", sm: "20px", md: "0px", lg: "0px", xl: "0px" }}>
                                    <Text>
                                        Your Name
                                    </Text>
                                    <Input
                                        name="full_name"
                                        type="text"
                                        value={bank.full_name}
                                        onChange={saveBankDetails}
                                        placeholder="Enter Beneficiary Acccount Name" isRequired />
                                </Box>
                            </Flex>
                        </Box>
                        <Box mt={"20px"}>
                            <Flex justifyContent={"space-between"} flexDirection={{ base: "column", sm: "column", md: "row", lg: "row", xl: "row" }}>
                                <Box w={{ base: "100%", sm: "100%", md: "45%", lg: "45%", xl: "45%" }}>
                                    <Text>
                                        Beneficiary Bank Name
                                    </Text>
                                    <Input
                                        type="text"
                                        name="bank_name"
                                        value={bank.bank_name}
                                        onChange={saveBankDetails}
                                        placeholder="Enter Bank Name"
                                        isRequired
                                    />
                                </Box>
                                <Box w={{ base: "100%", sm: "100%", md: "45%", lg: "45%", xl: "45%" }} mt={{ base: "20px", sm: "20px", md: "0px", lg: "0px", xl: "0px" }}>
                                    <Text>
                                        Beneficiary Code
                                    </Text>
                                    <Input
                                        type="text"
                                        name="ifsc_code"
                                        value={bank.ifsc_code}
                                        onChange={saveBankDetails}
                                        placeholder="Enter IFSC Code"
                                        isRequired
                                    />
                                </Box>
                            </Flex>
                        </Box>
                        <Box mt={"20px"}>
                            <Flex justifyContent={"space-between"} flexDirection={{ base: "column", sm: "column", md: "row", lg: "row", xl: "row" }} >
                                <Box w={{ base: "100%", sm: "100%", md: "45%", lg: "45%", xl: "45%" }}>
                                    <Text>
                                        Beneficiary Acccount Number
                                    </Text>
                                    <Input
                                        type="number"
                                        name="account_number"
                                        value={bank.account_number}
                                        onChange={saveBankDetails}
                                        placeholder="Enter Acccount Number"
                                        isRequired
                                    />
                                </Box>
                                <Box w={{ base: "100%", sm: "100%", md: "45%", lg: "45%", xl: "45%" }} mt={{ base: "20px", sm: "20px", md: "0px", lg: "0px", xl: "0px" }}>
                                    <Text>
                                        Branch Name
                                    </Text>
                                    <Input
                                        type="text"
                                        name="branch_name"
                                        value={bank.branch_name}
                                        onChange={saveBankDetails}
                                        placeholder="Enter Branch Name"
                                        isRequired
                                    />
                                </Box>
                            </Flex>
                        </Box>
                        <Box mt={"20px"}>
                            <Flex justifyContent={"space-between"} flexDirection={{ base: "column", sm: "column", md: "row", lg: "row", xl: "row" }}>
                                <Box w={{ base: "100%", sm: "100%", md: "45%", lg: "45%", xl: "45%" }} >
                                    <Text>
                                        City
                                    </Text>
                                    <Input
                                        type="text"
                                        name="city"
                                        value={bank.city}
                                        onChange={saveBankDetails}
                                        placeholder="Enter City"
                                        isRequired
                                    />
                                </Box>
                                <Box w={{ base: "100%", sm: "100%", md: "45%", lg: "45%", xl: "45%" }} mt={{ base: "20px", sm: "20px", md: "0px", lg: "0px", xl: "0px" }}>
                                    <Text>
                                        Country
                                    </Text>
                                    <Input
                                        type="text"
                                        name="country"
                                        value={bank.country}
                                        onChange={saveBankDetails}
                                        placeholder="Enter Country" isRequired />
                                </Box>
                            </Flex>
                        </Box>
                        <Box mt={"20px"} >
                            <Flex justifyContent={"space-between"} flexDirection={{ base: "column", sm: "column", md: "row", lg: "row", xl: "row" }}>
                                <Box w={{ base: "100%", sm: "100%", md: "45%", lg: "45%", xl: "45%" }}>
                                    <Text>Select Currency</Text>
                                    <Select  name="currency" value={bank.currency}
                                        onChange={saveBankDetails} 
                                        style={{ backgroundColor: "rgb(55,67,78)" }}
                                        isRequired
                                    > 
                                    <option value='' style={{ backgroundColor: "rgb(55,67,78)" }}>Select Currency</option>
                                        <option value='dollar' style={{ backgroundColor: "rgb(55,67,78)" }}>US DOLLAR</option>

                                    </Select>
                                </Box>
                                <Box w={{ base: "100%", sm: "100%", md: "45%", lg: "45%", xl: "45%" }}>

                                </Box>
                            </Flex>
                        </Box>
                        <Box mt={"20px"} alignContent={"right"}>
                            {
                                load?<Button type="Submit" color={"black"} bg={"rgb(255,218,69)"}>Save</Button>:
                                <Button type="Submit" color={"black"} bg={"rgb(255,218,69)"}><Spinner/></Button>
                            }
                        </Box>
                    </form>
                </Box>
            </Box>
        </>
    )
}

export default AddnewBank;