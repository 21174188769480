import { Box, Button, Center, Divider, Image, Input, Spinner, Text, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../images/logo.png"


function ForgetPasswordChange(){
    let [load,setLoad]=useState(true)

    let email=JSON.parse(localStorage.getItem("email"));

    let [newPassword,setPassword]=useState()
    let toast=useToast();
    let navigate=useNavigate();
   

    let changePasswordData=async(e)=>{
        setLoad(false)
        e.preventDefault();
        try {
            let response=await fetch(`https://trading-jz57.onrender.com/changepassword`,{
            method:"POST",
            headers:{
                "COntent-Type":"application/json"
            },
            body:JSON.stringify({email:email,newPassword:newPassword})
         })
         if(response.ok){
            let res=await response.json();
            
            toast({
                description: `Password updated successfully`,
                status: `success`,
                duration: 5000,
                isClosable: true,
                position: "top-center",
            })
            navigate("/login");
            setLoad(true)
            
         }else{
            toast({
                description: `unable to update`,
                status: `error`,
                duration: 5000,
                isClosable: true,
                position: "top-center",
            })
            setLoad(true)
         }
         
        } catch (error) {
           
            toast({
                description: `something went wrong`,
                status: `error`,
                duration: 5000,
                isClosable: true,
                position: "top-center",
            })
            setLoad(true);
        }
         
    }


    return(
        <>
        <Box>
        <Center>
         <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "80%", xl: "80%" }}  mt={"20px"} p={"40px"} boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px;">
            <Box 
            //  border={"1px solid red"}
            >
                <Center>
                <Image w={{ base: "100%", sm: "100%", md: "100%", lg: "200px", xl: "200px" }} src={logo}/>
                </Center>
            </Box>
                        <Text mt={"20px"} fontSize={{ base: "md", sm: "md", md: "md", lg: "2xl", xl: "2xl" }} color={"rgb(255,218,69)"}>
                            Change Password
                        </Text>
                        <Divider mt={"3px"}></Divider>
                        <form onSubmit={changePasswordData}>
                           
                            <Box mt={"20px"}>
                                <Text>
                                    Enter New Password
                                </Text>
                                <Input type="text" name="newPassword" value={newPassword} onChange={(e)=>{setPassword(e.target.value)}}/>
                            </Box>
                            <Box mt={"20px"}>
                                {
                                    load ? <Button type="submit" color={"black"} bg={"rgb(255,218,69)"}>CHANGE PASSWORD</Button> : <Button type="submit" color={"black"} bg={"rgb(255,218,69)"}><Spinner /></Button>
                                }

                            </Box>
                        </form>
                    </Box>
                    </Center>
                    </Box>
        </>
    )
}

export default ForgetPasswordChange;