import { Box, Button, Flex, Input, Text, Textarea } from "@chakra-ui/react";
import Navbar from "../../Navbar/Navbar";
import NavbarNew from "../../Navbar/NavbarNew";
import NavbarHome from "../../Navbar/NavbarHome";
import Footer from "../../Navbar/Footer";



function AccountV() {
    return (
        <>
            <Box>
                <NavbarHome />
            </Box>
            <Box padding={{base:'10px',sm:"10px",md:"10px",xl:"30px 45px",lg:"30px 45px"}} >
                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"50px"}>Account Verification</Text>
            </Box>
            <Box paddingLeft={{base:'10px',sm:"10px",md:"10px",xl:"45px",lg:"45px"}}>
                <Flex gap={"20px"} flexDirection={{base:"column",sm:"column",md:"column",lg:"row",xl:"row"}}>
                    <Box w={{base:'100%',sm:"100%",md:"100%",xl:"70%",lg:"70%"}}>
                        <Box>
                            <Text fontSize={"15px"}>
                            Please be aware that an unverified account not only has less options provided to you by GloabFx but also runs the risk of being shut down. Yes, even though account verification is not currently required, it is always preferable to safeguard it by submitting all required supporting papers.
                            </Text>
                            <Text fontSize={"15px"} mt={"30px"}>
                            It is a rather easy process. All you have to do is mail the address and identity documentation to GloabFx after scanning them. The list of acceptable address and identity proofs is listed in the annexure that was discussed earlier. The list is in accordance with the directives issued by the Indian government.
                            </Text>
                            <Text fontSize={"15px"} mt={"30px"}>
                            After reviewing the documents, we will let you know whether we agree or disagree (as appropriate). The average time needed to perform the treatment is 72 hours. If additional documentation is required, we will let you know and take appropriate action.
                            </Text>
                            <Text fontSize={"15px"} mt={"30px"}>
                            The first and second levels of verification are present. The prerequisites for each level are different, and in order to achieve the second level, you must first pass the first level. Read about the intricacies of our Verify Account process to learn more.
                            </Text>
                            
                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>Things to consider</Text>
                            </Box>
                            <Box>
                            <Text fontSize={"15px"} mt={"30px"}>
                            The following should always be considered while sending verification documents:
                            </Text>
                            <Box p={"20px"}>
                                <Text fontSize={"15px"}><li>Please submit a coloured scanned copy to us for validation. Do not forget to scan the document on both sides.</li></Text>
                                <Text fontSize={"15px"}><li>The document cannot have any additions, scratch-outs, alterations, or erasures.</li></Text>
                                <Text fontSize={"15px"}><li>The quality of the photos should be excellent.</li></Text>
                                <Text fontSize={"15px"}><li>The details in the various documents should be same.</li></Text>
                                <Text fontSize={"15px"}><li>After at least six months of applying for account verification, validity should expire.</li></Text>
                                <Text fontSize={"15px"}><li>Please only send files in the approved formats (see the annexure). There should be a 2MB file size limit.</li></Text>
                                <Text fontSize={"15px"}><li>These are a few general guidelines. Before sending account verification documents, please read the protocol.</li></Text>
                            </Box>
                            </Box>

                        </Box>
                        
                    </Box>

                    <Box w={{base:"100%",sm:"100%",md:"100%",lg:"30%",xl:"30%"}} p={{base:'5px',sm:"5px",md:"5px",lg:"30px",xl:"30px"}}>
                        <Input placeholder="Your Name" />
                        <Input mt={"20px"} placeholder="Your Email" />
                        <Input mt={"20px"} placeholder="Phone Number" />
                        <Textarea mt={"20px"} placeholder="Your Messages"/>
                        <Button mt={"20px"}  bg={"rgb(255,218,69)"} w={"100%"}>SEND MAIL</Button>
                    </Box>
                </Flex>
            </Box>
            <Box>
                <Footer/>
            </Box>
        </>
    )
}
export default AccountV;