import { Box, Flex, Image, Text, Grid, GridItem, Badge } from "@chakra-ui/react";
import ParticlesPert from "./ParticlesPert";
import pc from "../images/pc_main.png";
import deposit from "../images/deposit.png";
import profit from "../images/profit.png";
import withdraw from "../images/withdraw.png"
import slide from "../images/slide_2.jpg"
import award5 from "../images/award_5.png"
import award6 from "../images/award_6.png"
import award8 from "../images/award_8.png"
import award10 from "../images/award_10.png"
import award13 from "../images/award_13.png"
import award14 from "../images/award_14.png"
import payment from "../images/payment.png"
import security from "../images/security.png"
import world from "../images/world.png"
import team from "../images/team.png"
import report from "../images/report.png"
import platform from "../images/platform.png"
import support from "../images/support.png"
import exchange from "../images/exchange.png"
import allen from "../images/allen.jpg"
import brown from "../images/brown.jpeg"
import melisa from "../images/melisa.jpeg"
import glenda from "../images/glenda.jpeg"
import carly from "../images/carly.jpg"
import { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import NavbarNew from "../Navbar/NavbarNew";
import { useNavigate } from "react-router-dom";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import NavbarHome from "../Navbar/NavbarHome";
import Footer from "../Navbar/Footer";


const images = [
    {
        src: `${allen}`,
        title: ' ALLEN BREWER',
        description: 'Am Allen from North Carolina, Currently living in Arizona with my Family, i came across Elitecryptotrade24 while browsing through facebook, I accessed the site and contact them via whatsapp and i started investing with $5000 and am making $51,560.00 Weekly.',
        rating: 4.8,
    },
    {
        src: `${brown}`,
        title: 'Brown',
        description: "Hello everyone Investing is not the problem but getting the returns and profits is where most of us have problems. I've been long in Elitecryptotrade24, so I know how it feels like to lose your money. I have lost my hard earned money on fake sites and managers but thanks to Elitecryptotrade24 for helping me recover all. From investing to payout it's been automatic. If you're willing to earn Join today and invest and recover all your lost is legit and certified.",
        rating: 4.5,
    },
    {
        src: `${melisa}`,
        title: 'MELISSA DORORTHY',
        description: 'Truly the corona outbreak have really affected me a lot financially, but because of the help of Elitecryptotrade24 in through this forex investment am still financially stable',
        rating: 4.2,
    },
    {
        src: `${glenda}`,
        title: 'GLENDA KARINA',
        description: "I have become debt free since I started my trade with Elitecryptotrade24 and I have earn over $10,000 and more still coming all thank this trading platform i have made so much profits I made a withdraw of $25000 I'm so much excited at this moment.",
        rating: 4.2,
    },
    {
        src: `${carly}`,
        title: 'CARLY JONES',
        description: 'I want to say a big thank you to Elitecryptotrade24, Just got my profit of $7500 in my Bank account. This is indeed a trust worthy platform to invest',
        rating: 4.2,
    },
    // Add more image objects as needed
];

function MainPage() {
    let navigate = useNavigate()
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const handleNext = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const handlePrev = () => {
        setCurrentImageIndex(
            (prevIndex) => (prevIndex - 1 + images.length) % images.length
        );
    };

    const autoSlideInterval = 5000; // Adjust the interval as needed
    let autoSlideTimer;

    const startAutoSlide = () => {
        autoSlideTimer = setInterval(() => {
            handleNext();
        }, autoSlideInterval);
    };

    const stopAutoSlide = () => {
        clearInterval(autoSlideTimer);
    };

    // Start auto-slide on component mount
    useEffect(() => {
        startAutoSlide();

        // Clear the interval on component unmount
        return () => {
            stopAutoSlide();
        };
    }, []);


    return (
        <>

            {/* ParticlesPert component applied as background */}
            <Box w="100%" position="relative">
                <Box zIndex={100} position={"sticky"}>
                    <NavbarHome />
                </Box>
                <ParticlesPert />

                <Flex gap="20px" flexDirection={{ base: "column", sm: "column", md: "column", lg: "row", xl: "row" }} justifyContent={"center"}>
                    <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "50%", xl: "50%" }} zIndex={10} margin="auto" p="20px">
                        <Box w="100%">
                            <Text fontSize={{ base: "3xl", sm: "3xl", md: "3xl", lg: "6xl", xl: "6xl" }} textAlign={{ base: "center", sm: "center", md: "center", lg: "right", xl: "right" }}>
                                <Text display="inline">PROFITABLE</Text>{" "}
                                <br />
                                <Text display="inline">INVESTMENTS</Text>{" "}
                                <br />
                                <Text display="inline">IN BITCOIN</Text>
                            </Text>
                        </Box>
                        <Box mt="20px" textAlign="center" p="20px">
                            <Flex
                                justifyContent={{ base: "center", sm: "center", md: "center", lg: "flex-end", xl: "flex-end" }}
                                gap="20px" margin="auto">
                                <Box
                                    border="1px solid yellow"
                                    borderRadius="10px"
                                    p="10px"
                                    textAlign="center"
                                >
                                    <Text fontWeight="bold" fontSize="xl">
                                        710+
                                    </Text>
                                    <Text p="10px">Traders Online</Text>
                                </Box>
                                <Box
                                    border="1px solid yellow"
                                    borderRadius="10px"
                                    p="10px"
                                    textAlign="center"
                                >
                                    <Text fontWeight="bold" fontSize="xl">
                                        156,789+
                                    </Text>
                                    <Text p="10px">Total Registered</Text>
                                </Box>
                                <Box
                                    border="1px solid yellow"
                                    borderRadius="10px"
                                    p="10px"
                                    textAlign="center"
                                >
                                    <Text fontWeight="bold" fontSize="xl">
                                        888,909+
                                    </Text>
                                    <Text p="10px">Deals Today</Text>
                                </Box>
                            </Flex>
                        </Box>
                    </Box>
                    <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "50%", xl: "50%" }} zIndex={10}>
                        <Image src={pc} />
                    </Box>
                </Flex>
            </Box>


            <Box mx="auto" p="4" zIndex={10}>
                <Text fontSize={{ base: "lg", sm: "lg", md: "lg", lg: "4xl", xl: "4xl" }} fontWeight={"bold"} textAlign="center" mt={{ base: 10, sm: 10, md: 10, lg: 10, xl: 10 }}>
                    HOW GLOABFX WORKS?
                </Text>
                <Box mt={"30px"} >
                    <Grid templateColumns={{ base: "repeat(1, 1fr)", sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)", lg: "repeat(2, 1fr)", xl: "repeat(2, 1fr)" }} gap={6}>
                        <GridItem w={"80%"} m={"auto"} textAlign={"center"}>
                            <Image display={"block"} margin={"auto"} src={deposit} />
                            <Text mt={"20px"} fontSize={"xl"}>DEPOSIT</Text>
                            <Text mt={"10px"}>Send bitcoins to the personal trading wallet, which generated by the platform</Text>
                        </GridItem>
                        <GridItem w={"80%"} m={"auto"} textAlign={"center"} >
                            <Image display={"block"} margin={"auto"} src={profit} />
                            <Text mt={"20px"} fontSize={"xl"}>GET PROFIT</Text>
                            <Text mt={"10px"}>Earn huge return on investment. With our professional team of traders, you are guaranteed of your earnings</Text>
                        </GridItem>
                        <GridItem w={"80%"} m={"auto"} textAlign={"center"} >
                            <Image display={"block"} margin={"auto"} src={withdraw} />
                            <Text mt={"20px"} fontSize={"xl"}>WITHDRAW</Text>
                            <Text mt={"10px"}>Our withdrawals are all processed instantly after they are requested. We provide multiple withdrawal methods</Text>
                        </GridItem>

                    </Grid>
                </Box>
            </Box>

            <Box mt={"20px"} backgroundImage={slide} p={"30px"} >
                <Text fontSize={{ base: "lg", sm: "lg", md: "lg", lg: "4xl", xl: "4xl" }} fontWeight={"bold"} textAlign="center" mt={10} mb={"30px"}>OUR AWARD PLATFORM</Text>

                <Box margin={"auto"} w={"100%"}>
                    <Box margin={"auto"} w={"80%"} pb={"30px"}>
                        <Grid templateColumns={{ base: "repeat(1,1fr)", sm: "repeat(1,1fr)", md: "repeat(1,1fr)", lg: "repeat(3,1fr)", xl: "repeat(3,1fr)" }} gap={"30px"} margin={"auto"}>
                            <GridItem  >
                                <Image src={award13} w={"15%"} h={{ base: "", sm: "", md: "", lg: "40%", xl: "40%" }} />
                                <Text mt={"20px"} fontSize={"xl"}>CENTURY INTERNATIONAL QUALITY GOLD ERA AWARD</Text>
                                <Text mt={"10px"}>The prestigious award was given to Elitecryptotrade24 in recognition of our outstanding commitment to Quality and Excellence, particularly in the realm of Customer Satisfaction.</Text>
                            </GridItem>
                            <GridItem  >
                                <Image src={award10} w={"15%"} h={{ base: "", sm: "", md: "", lg: "40%", xl: "40%" }} />
                                <Text mt={"20px"} fontSize={"xl"}>MOST INNOVATIVE BINARY OPTION PLATFORM</Text>
                                <Text mt={"10px"}>As Steve Jobs once said, innovation distinguishes between leaders and followers. Our innovative approach makes our product shine—and the evidence is in this beautiful accolade.</Text>
                            </GridItem>
                            <GridItem  >
                                <Image src={award5} w={"15%"} h={{ base: "", sm: "", md: "", lg: "40%", xl: "40%" }} />
                                <Text mt={"20px"} fontSize={"xl"}>MOST RELIABLE BINARY OPTIONS BROKER</Text>
                                <Text mt={"10px"}>Our first priority is the security of our clients' funds. This was recognized by the experts at MasterForex-V, who awarded Elitecryptotrade24 the title of Most Trusted Binary Options Broker.</Text>
                            </GridItem>
                            <GridItem  >
                                <Image w={"15%"} src={award14} h={{ base: "", sm: "", md: "", lg: "40%", xl: "40%" }} />
                                <Text mt={"20px"} fontSize={"xl"}>THE INTELLIGENT TRADING APP FOR BINARY OPTIONS</Text>
                                <Text mt={"10px"}>The Mobile Star Awards is the largest annual mobile innovations and software awards program in the world. In 2016, the organization honored the Elitecryptotrade24 trading app as the best in its category, praising its efficiency and impeccable design.
                                </Text>
                            </GridItem>
                            <GridItem  >
                                <Image w={"15%"} src={award6} h={{ base: "", sm: "", md: "", lg: "40%", xl: "40%" }} />
                                <Text mt={"20px"} fontSize={"xl"}>WORLD'S LEADING BINARY OPTIONS BROKER</Text>
                                <Text mt={"10px"}>At the same MasterForex-V Elitecryptotrade24 was awarded for being the World's Leading Binary Options Broker. The perfection in our service and product was recognized by the experts of the conference in 2014.</Text>
                            </GridItem>
                            <GridItem  >
                                <Image w={"15%"} src={award8} h={{ base: "", sm: "", md: "", lg: "40%", xl: "40%" }} />
                                <Text mt={"20px"} fontSize={"xl"}>FASTEST GROWING BINARY OPTION BRAND</Text>
                                <Text mt={"10px"}>Global Brands Magazine, Britain’s reputable brand observer, awarded Elitecryptotrade24 along with a number of outstanding European brands — an achievement worth working for.</Text>
                            </GridItem>

                        </Grid>
                    </Box>
                </Box>

            </Box>
            <Text fontSize={{ base: "xl", sm: "xl", md: "xl", lg: "4xl", xl: "4xl" }} fontWeight={"bold"} textAlign="center" mt={10} mb={"30px"}>OUR PLATFORM ADVANTAGES</Text>
            <Box w={"80%"} margin={"auto"}>
                <Grid templateColumns={{ base: "repeat(1,1fr)", sm: "repeat(1,1fr)", md: "repeat(1,1fr)", lg: "repeat(3,1fr)", xl: "repeat(3,1fr)" }} gap={"60px"}>
                    <GridItem>
                        <Flex alignItems={"center"} gap={"10px"}>
                            <Image w={"15%"} src={payment} />
                            <Box>
                                <Text fontSize={"xl"}>
                                    PAYMENT OPTIONS</Text>
                                <Text mt={"5px"}>
                                    We provide various withdrawal methods.</Text>
                            </Box>
                        </Flex>
                    </GridItem>
                    <GridItem>
                        <Flex alignItems={"center"} gap={"10px"}>
                            <Image w={"15%"} src={security} />
                            <Box>
                                <Text fontSize={"xl"}>

                                    STRONG SECURITY</Text>
                                <Text mt={"5px"}>
                                    With advanced security systems, we keep your account always protected.</Text>
                            </Box>
                        </Flex>
                    </GridItem>
                    <GridItem>
                        <Flex alignItems={"center"} gap={"10px"}>
                            <Image w={"15%"} src={world} />
                            <Box>
                                <Text fontSize={"xl"}>
                                    WORLD COVERAGE</Text>
                                <Text mt={"5px"}>
                                    Our platform is used by bitcoin investors worldwide</Text>
                            </Box>
                        </Flex>
                    </GridItem>
                    <GridItem>
                        <Flex alignItems={"center"} gap={"10px"}>
                            <Image w={"15%"} src={team} />
                            <Box>
                                <Text fontSize={"xl"}>
                                    EXPERIENCED TEAM</Text>
                                <Text mt={"5px"}>
                                    Our experienced team helps us build the best product and deliver first class service to our clients.
                                </Text>
                            </Box>
                        </Flex>
                    </GridItem>
                    <GridItem>
                        <Flex alignItems={"center"} gap={"10px"}>
                            <Image w={"15%"} src={report} />
                            <Box>
                                <Text fontSize={"xl"}>
                                    ADVANCED REPORTING</Text>
                                <Text mt={"5px"}>
                                    We provide reports for all investments done using our platform.</Text>
                            </Box>
                        </Flex>
                    </GridItem>
                    <GridItem>
                        <Flex alignItems={"center"} gap={"10px"}>
                            <Image w={"15%"} src={platform} />
                            <Box>
                                <Text fontSize={"xl"}>

                                    CROSS-PLATFORM TRADING</Text>
                                <Text mt={"5px"}>
                                    Our platform can be accessed from various devices such as Phones, Tablets & Pc.</Text>
                            </Box>
                        </Flex>
                    </GridItem>
                    <GridItem>
                        <Flex alignItems={"center"} gap={"10px"}>
                            <Image w={"15%"} src={support} />
                            <Box>
                                <Text fontSize={"xl"}>

                                    EXPERT SUPORT</Text>
                                <Text mt={"5px"}>
                                    Our 24/7 support allows us to keep in touch with customers in all time zones and regions.
                                </Text>
                            </Box>
                        </Flex>
                    </GridItem>
                    <GridItem>
                        <Flex alignItems={"center"} gap={"10px"}>
                            <Image w={"15%"} src={exchange} />
                            <Box>
                                <Text fontSize={"xl"}>
                                    INSTANT EXCHANGE</Text>
                                <Text mt={"5px"}>
                                    Change your world today and make yourself a great tomorrow, invest with the little money you have and make a great profit at the end.</Text>
                            </Box>
                        </Flex>
                    </GridItem>
                </Grid>
            </Box>
            <Text fontSize={{ base: "xl", sm: "xl", md: "xl", lg: "4xl", xl: "4xl" }} fontWeight={"bold"} textAlign="center" mt={10} mb={"30px"}>WHAT INVESTORS SAY</Text>
            <Box w={"80%"} margin={"auto"} textAlign={"center"} display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center">
                <Image key={currentImageIndex}
                    src={images[currentImageIndex].src}
                    alt={`Image ${currentImageIndex + 1}`}
                    style={{

                        borderRadius: "50%",
                        width: '100px',

                    }}>



                </Image>

                <Box mt={4}>
                    <Text fontSize="lg" color={"white"} fontWeight="bold">
                        {images[currentImageIndex].title}
                    </Text>
                    <Text color={"white"} mt={2}>
                        {images[currentImageIndex].description}
                    </Text>
                    <Box mt={2}>
                        <Badge colorScheme="green">Rating: {images[currentImageIndex].rating}</Badge>
                    </Box>
                </Box>

                <Box mt={4}>
                    <button onClick={handlePrev} ><ChevronLeftIcon /></button>
                    <button onClick={handleNext} ><ChevronRightIcon /></button>
                </Box>
            </Box>
            {/* <Box w={"100%"} mt={"100px"} pb={"25px"}>
                 <Box w={"80%"} margin={"auto"}>
                 <Flex gap={"50px"} flexDirection={{base:"column",sm:"column",md:"row",lg:"row",xl:"row"}}>
                    <Box w={"100%"}>
                     <Text fontSize={"xl"} fontWeight={"bold"}>ABOUT</Text>
                     <Text mt={"20px"}>Gloabfx is a company formed by a team of PROFESSIONAL TRADERS with EXPERTISE in one of the biggest financial markets of today. Our focus is to provide our affiliates with daily and constant profits in these markets.</Text>
                    </Box>
                    <Box w={"100%"}>
                         <Text fontSize={"xl"} fontWeight={"bold"}>QUICK LINKS</Text>
                         <Text mt={"20px"} color={"rgb(255,218,69)"} onClick={()=>navigate("/")} cursor={"pointer"}>HOME</Text>
                         <Text mt={"10px"} color={"rgb(255,218,69)"} onClick={()=>navigate("/login")} cursor={"pointer"}>LOGIN</Text>
                         <Text mt={"10px"} color={"rgb(255,218,69)"} onClick={()=>navigate("/register")} cursor={"pointer"}>REGISTER</Text>
                    </Box>
                    <Box w={"100%"}>
                        <Text fontSize={"xl"} fontWeight={"bold"}>RISK WARNING</Text>
                        <Text mt={"20px"}>Gloabfx is one of the leading platforms in the United States offering binary options, Forex and spreads. Regulated by the CFTC and based in London.</Text>
                    </Box>
                    <Box w={"100%"}>
                        <Text fontSize={"xl"} fontWeight={"bold"}>CONTACT US</Text>
                        <Box mt={"20px"}>
                            <Flex>
                                <Text>ADDRESS: </Text> &nbsp;
                                <Text  color={"rgb(255,218,69)"}>Histon, Cambridge CB25 5AD, United Kingdom</Text>
                            </Flex>
                        </Box>
                        <Box w={"100%"}>
                            <Flex>
                                <Text>EMAIL:</Text>&nbsp;
                               
                                <Text color={"rgb(255,218,69)"}>support@gloabfx.com</Text>
                                
                            </Flex>
                        </Box>
                    </Box>
                 </Flex>
                 </Box>
            </Box> */}
            <Box>
                <Footer />
            </Box>
        </>
    );
}

export default MainPage;
