import { Box, Flex, Table, TableContainer, Tbody, Td, Th, Thead, Tr, Text, Divider, Center, Button } from "@chakra-ui/react"
import { useEffect, useState } from "react";
import NavbarNew from "../../Navbar/NavbarNew";
import Navbar from "../../Navbar/Navbar";



function ClosedDealDemoPage() {
    let result = JSON.parse(sessionStorage.getItem("demo")) || ""

    let [dataclose, setDataclose] = useState([])
    let [balance, setBalance] = useState();
    let [bonus, setBonus] = useState(0);
    let [totalbalance, setBalanceTotal] = useState()
    const [currentPage, setCurrentPage] = useState(1);

// console.log(result.token);

    let fetchBalance = async () => {
        try {
            let response = await fetch(`https://trading-jz57.onrender.com/registerdemo/user/${result.type.AcNumber}`)
            let res = await response.json();
            // console.log(res);
            setBalance(res.totalBalance)
            setBonus(res.totalBonus)
            setBalanceTotal(res.totalPriceBonus)

        } catch (error) {
            console.log(error)
        }
    }

    let fetchDataclosedAllDeal = async () => {
        try {
            let response = await fetch(`https://trading-jz57.onrender.com/closedemo/${result.type.AcNumber}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${result.token}`,
                },
            })
            let res = await response.json();
            setDataclose(res.reverse());
        
        } catch (error) {
            console.log(error);
        }
    }

   

    useEffect(() => {
        fetchDataclosedAllDeal();
        fetchBalance();
    }, [])

    const userPerPage = 10;
    const lastIndex = currentPage * userPerPage;
    const firstIndex = lastIndex - userPerPage;
    const user = dataclose.slice(firstIndex, lastIndex);
    const totalPages = Math.ceil(dataclose.length / userPerPage);


    return (
        <>
            <Box overflowX={'auto'} >
                <Navbar/>
                <Box p={"5px"}>
                    <Box mt={"20px"} boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px">
                        <Center><Text fontSize='2xl'>Closed Deals</Text></Center>
                        <Divider mt={"10px"} />
                        <TableContainer mt={"10px"}>
                            <Table variant='simple' colorScheme='teal'>
                                {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
                                <Thead bg={"rgb(231,236,241)"}
                                >
                                    <Tr textAlign={"center"}>
                                        <Th>

                                            <Flex direction={"column"} gap={"10px"}>
                                                <Box textAlign={"center"}>Order# </Box>
                                                <Box textAlign={"center"}>Instrument</Box>
                                            </Flex>
                                        </Th>
                                        <Th>
                                            <Flex direction={"column"} gap={"10px"}>
                                                <Box textAlign={"center"}>Type  </Box>
                                                <Box textAlign={"center"}>Lot Size</Box>
                                            </Flex>
                                        </Th>
                                        <Th>
                                            <Flex direction={"column"} gap={"10px"}>
                                                <Box textAlign={"center"}>Take Profit</Box>
                                                <Box textAlign={"center"}>Stop Loss</Box>
                                            </Flex>
                                        </Th>
                                        <Th textAlign={"center"}>Open Time</Th>
                                        <Th textAlign={"center"}>Open Rate</Th>
                                        <Th textAlign={"center"}>Close Rate</Th>
                                        <Th textAlign={"center"}>Order Profit</Th>
                                        <Th textAlign={"center"}>Close Time</Th>
                                        <Th>

                                        </Th>

                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {
                                        user.map((ele, idx) => {


                                            return <Tr textAlign={"center"} key={idx} >
                                                <Td>
                                                    <Flex direction={"column"} gap={"10px"}>
                                                        <Box textAlign={"center"}>{ele.order_id}</Box>
                                                        <Box textAlign={"center"}>{ele.title && ele.title.length === 6 ? (
                                                            `${ele.title.slice(0, 3)}/${ele.title.slice(3)}`
                                                        ) : (
                                                            ele.title
                                                        )}</Box>
                                                    </Flex>
                                                </Td>
                                                <Td textAlign={"center"}>
                                                    <Flex direction={"column"} gap={"10px"}>
                                                        <Box textAlign={"center"}>{ele.order_type}</Box>
                                                        <Box textAlign={"center"}>{ele.lotsize}</Box>
                                                    </Flex>
                                                </Td>
                                                <Td textAlign={"center"}>
                                                    <Flex direction={"column"} gap={"10px"}>
                                                        <Box textAlign={"center"}>{ele.takeprofit}</Box>
                                                        <Box textAlign={"center"}>{ele.stoploss}</Box>
                                                    </Flex> </Td>
                                                <Td textAlign={"center"}>{new Intl.DateTimeFormat('en-GB', {
                                                    timeZone: 'Europe/London',
                                                    day: 'numeric',
                                                    month: 'short',
                                                    year: 'numeric',
                                                    hour: 'numeric',
                                                    minute: 'numeric',
                                                    second: 'numeric',
                                                    hour12: false,
                                                }).format(new Date(ele.created_at))
                                                    .replace(/ /, '-')
                                                    .replace(/,/g, '')
                                                }</Td>
                                                <Td textAlign={"center"}>{ele.bidorask}
                                                </Td>
                                                <Td textAlign={"center"}>{ele.close_rate}


                                                </Td>
                                                <Td textAlign={"center"}>
                                                    {ele.order_profit}
                                                </Td>
                                                <Td textAlign={"center"}>
                                                {new Intl.DateTimeFormat('en-GB', {
                                                        timeZone: 'Europe/London',
                                                        day: 'numeric',
                                                        month: 'short',
                                                        year: 'numeric',
                                                        hour: 'numeric',
                                                        minute: 'numeric',
                                                        second: 'numeric',
                                                        hour12: false,
                                                    }).format(new Date(ele.closed_at))
                                                        .replace(/ /, '-')
                                                        .replace(/,/g, '')
                                                    }
                                                </Td>
                                                <Td>{ele.manual_auto}</Td>
                                            </Tr>
                                        })
                                    }


                                </Tbody>

                            </Table>
                        </TableContainer>
                        {user.length > 0 ? (
                            <Flex alignItems="center" justifyContent="right" gap="10px" mt="10px">
                                <Text fontWeight="bold">
                                    {currentPage} of {totalPages}
                                </Text>
                                <Button
                                    bg={"rgb(255,218,69)"} color={"black"}
                                    _hover={{ cursor: "pointer" }}
                                    isDisabled={currentPage == 1}
                                    onClick={() => setCurrentPage(currentPage - 1)}
                                >
                                    {"<"}
                                </Button>
                                <Button
                                    bg={"rgb(255,218,69)"} color={"black"}
                                    _hover={{ cursor: "pointer" }}
                                    isDisabled={currentPage == totalPages}
                                    onClick={() => setCurrentPage(currentPage + 1)}
                                >
                                    {">"}
                                </Button>
                            </Flex>
                        ) : null}
                    </Box>
                </Box>
            </Box>
        </>
    )
}
export default ClosedDealDemoPage