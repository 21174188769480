import { Box, Button, Divider, Flex, Image, Table, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr } from "@chakra-ui/react";
import LeftNav from "./LeftNav";
import { useEffect, useState } from "react";



function Depositreq() {
    let [data, setData] = useState([])
    const [currentPage, setCurrentPage] = useState(1);



    let fetchBankWithdraw = async () => {
        try {
            let response = await fetch(`https://trading-jz57.onrender.com/file/admin`)
            let res = await response.json();
            console.log(res);
            setData(res.reverse());
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        fetchBankWithdraw();
    }, [])

    let ApproveRequest = async (order_id, approve) => {
        console.log(approve);
        try {
            let response = await fetch(`https://trading-jz57.onrender.com/file/putfile/${order_id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ type_at: approve })
            })
            if (response.ok) {
                let res = await response.json();
                console.log(res);
                window.location.reload();
            }
        } catch (error) {
            console.log(error);
        }
    }


    const userPerPage = 5;
    const lastIndex = currentPage * userPerPage;
    const firstIndex = lastIndex - userPerPage;
    const user = data.slice(firstIndex, lastIndex);
    const totalPages = Math.ceil(data.length / userPerPage);
    
    return (
        <>
            <Box>
            <Flex gap={"20px"} flexDirection={{base:"column",sm:"column",md:"column",lg:"row",xl:"row"}}>
                    <Box w={{base:"100%",sm:"100%",md:"100%",lg:"20%",xl:'20%'}}>
                        <LeftNav />
                    </Box>
                    <Box w={{base:"100%",sm:"100%",md:"100%",lg:"80%",xl:'80%'}}>

                        <Box w={{base:"100%",sm:"100%",md:"100%",lg:"80%",xl:'80%'}} p={"20px"}>
                            <Text fontSize={"2xl"}>All Deposit</Text>
                            <Divider></Divider>
                            <TableContainer mt={"10px"}>
                                <Table variant='simple'>

                                    <Thead>
                                        <Tr>

                                            <Th color={"white"}>User Account </Th>
                                            <Th color={"white"}>Proof</Th>
                                            <Th color={"white"}>Amount</Th>
                                            <Th color={"white"}>Code</Th>
                                            <Th color={"white"}>Status</Th>
                                            <Th color={"white"}>Action</Th>
                                            <Th color={"white"}>Action</Th>


                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {
                                            user.length > 0 ? user.map((ele, idx) => {
                                                return <Tr key={idx}>
                                                    <Td>{ele.AcNumber}</Td>
                                                    <Td>
                                                        <a href={ele.Image} target="_blank" rel="noopener noreferrer">

                                                            <Image w={"120px"} h={"30px"} src={ele.Image}></Image>
                                                        </a>
                                                    </Td>


                                                    <Td>{ele.amount}</Td>

                                                    <Td>{ele.code}</Td>
                                                    <Td>{ele.type_at}</Td>
                                                    <Td>
                                                        <Button
                                                            color={"white"}
                                                            bg={"rgb(10,179,156)"}
                                                            _hover={{cursor:"pointer",color:"black",bg:"white"}}
                                                            onClick={() => ApproveRequest(ele._id, "approved")}
                                                        >
                                                            Approve
                                                        </Button>
                                                    </Td>
                                                    <Td>
                                                        <Button
                                                            color={"white"}
                                                            bg={"red"}
                                                            _hover={{cursor:"pointer",color:"black",bg:"white"}}
                                                            onClick={() => ApproveRequest(ele._id, "rejected")}
                                                        >
                                                            Reject
                                                        </Button>
                                                    </Td>

                                                </Tr>
                                            }) : <Tr>
                                                <Td colSpan={7} textAlign={"center"}>No Deposit Request</Td>

                                            </Tr>
                                        }


                                    </Tbody>
                                    <Tfoot>

                                    </Tfoot>
                                </Table>
                            </TableContainer>
                            {user.length > 0 ? (
                                <Flex alignItems="center" justifyContent="right" gap="10px" mt="10px">
                                    <Text fontWeight="bold">
                                        {currentPage} of {totalPages}
                                    </Text>
                                    <Button
                                        bg={"rgb(255,218,69)"} _hover={{ color: "black", bg: "white", cursor: "pointer" }}
                                        color="black"
                                        isDisabled={currentPage == 1}
                                        onClick={() => setCurrentPage(currentPage - 1)}
                                    >
                                        {"<"}
                                    </Button>
                                    <Button
                                        bg={"rgb(255,218,69)"} _hover={{ color: "black", bg: "white", cursor: "pointer" }}
                                        color="black"
                                        
                                        isDisabled={currentPage == totalPages}
                                        onClick={() => setCurrentPage(currentPage + 1)}
                                    >
                                        {">"}
                                    </Button>
                                </Flex>
                            ) : null}
                        </Box>
                    </Box>
                </Flex>
            </Box>
        </>
    )
}

export default Depositreq;