import { Box, Button, Flex, Input, Text, Textarea } from "@chakra-ui/react";
import Navbar from "../../Navbar/Navbar";
import NavbarNew from "../../Navbar/NavbarNew";
import NavbarHome from "../../Navbar/NavbarHome";
import Footer from "../../Navbar/Footer";



function ContactUs() {
    return (
        <>
            <Box>
                <NavbarHome />
            </Box>
            <Box padding={{base:'10px',sm:"10px",md:"10px",xl:"30px 45px",lg:"30px 45px"}} >
                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"50px"}>Contact Us</Text>
            </Box>
            <Box paddingLeft={{base:'10px',sm:"10px",md:"10px",xl:"45px",lg:"45px"}}>
                <Flex gap={"20px"} flexDirection={{base:"column",sm:"column",md:"column",lg:"row",xl:"row"}}>
                    <Box w={{base:'100%',sm:"100%",md:"100%",xl:"70%",lg:"70%"}}>
                       
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"24px"}>Contact Info:</Text>
                            </Box>
                            <Box>
                            <Text fontSize={"15px"} mt={"30px"}>
                            We are always ready to help you. There are many ways to contact us. You may drop us a line, give us a call or send an email, choose what suits you most.
                            </Text>
                            
                            </Box>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"24px"}>Head Office: </Text>
                            </Box>
                            <Box p={"20px"}>
                            <Text fontSize={"15px"} >
                            <li> <span style={{fontWeight:"bold"}}>Address:</span> Histon, Cambridge CB25 5AD, United Kingdom</li>
                            </Text>
                            <Text fontSize={"15px"} >
                            <li> <span style={{fontWeight:"bold"}}>Contact No: </span> +44 1223 931373</li>
                            </Text>
                            <Text fontSize={"15px"} >
                            <li> <span style={{fontWeight:"bold"}}>E-mail:</span>  info@GloabFx.com</li>
                            </Text>
                            </Box>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"24px"}>Registered Address: </Text>
                            </Box>
                            <Box p={"20px"}>
                            <Text fontSize={"15px"} >
                            <li> <span style={{fontWeight:"bold"}}>Address:</span> Histon, Cambridge CB25 5AD, United Kingdom</li>
                            </Text>
                            
                            <Text fontSize={"15px"} >
                            <li> <span style={{fontWeight:"bold"}}>E-mail:</span>support@Gloabfx.com</li>
                            </Text>
                            </Box>

                        </Box>
                    </Box>

                    <Box w={{base:"100%",sm:"100%",md:"100%",lg:"30%",xl:"30%"}} p={{base:'5px',sm:"5px",md:"5px",lg:"30px",xl:"30px"}}>
                        <Input placeholder="Your Name" />
                        <Input mt={"20px"} placeholder="Your Email" />
                        <Input mt={"20px"} placeholder="Phone Number" />
                        <Textarea mt={"20px"} placeholder="Your Messages"/>
                        <Button mt={"20px"}  bg={"rgb(255,218,69)"} w={"100%"}>SEND MAIL</Button>
                    </Box>
                </Flex>
            </Box>
            <Box>
                <Footer/>
            </Box>
        </>
    )
}
export default ContactUs;