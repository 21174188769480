import React from "react";
import AdminUser from "./Admin/AdminUser";
import Home from "./Home";
import Login from "./Login";
import MainPage from "./MainPage";

function MainHome() {
    const login = sessionStorage.getItem("key");

    if (login) {
        const user = JSON.parse(login);

        if (user.type === "user") {
            // User is logged in as a regular user, render the Home component
            return <Home />;
        }
        if(user.type==="Admin"){
            return <AdminUser/>
        } 
    }

    // User is not logged in or the type is not specified, render the Login component
    return <MainPage />;
}

export default MainHome;
