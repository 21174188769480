import { Box, Button, Flex, Input, Text, Textarea } from "@chakra-ui/react";
import Navbar from "../../Navbar/Navbar";
import NavbarNew from "../../Navbar/NavbarNew";
import NavbarHome from "../../Navbar/NavbarHome";
import Footer from "../../Navbar/Footer";



function CostTrading() {
    return (
        <>
            <Box>
                <NavbarHome />
            </Box>
            <Box padding={{ base: '10px', sm: "10px", md: "10px", xl: "30px 45px", lg: "30px 45px" }} >
                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"50px"}>Costs and Trading</Text>
            </Box>
            <Box paddingLeft={{ base: '10px', sm: "10px", md: "10px", xl: "45px", lg: "45px" }}>
                <Flex gap={"20px"} flexDirection={{ base: "column", sm: "column", md: "column", lg: "row", xl: "row" }}>
                    <Box w={{ base: '100%', sm: "100%", md: "100%", xl: "70%", lg: "70%" }}>
                        <Box>
                            <Text fontSize={"15px"}>
                                Several main pairs’ interbank spreads, together with spreads on other products that are highly competitive
                                There are no extra costs. No additional fees
                            </Text>
                            <Box p={"20px"}>
                                <Text fontSize={"15px"} >
                                    <li>Modern technologies to guarantee effective balancing protection.</li>

                                </Text>
                                <Text fontSize={"15px"} >
                                    <li>Ability to hedge</li>

                                </Text>
                                <Text fontSize={"15px"} >
                                    <li>Deal execution in one click</li>

                                </Text>
                                <Text fontSize={"15px"} >
                                    <li>A market order in advance</li>

                                </Text>
                                <Text fontSize={"15px"} mt={"10px"}>
                                    <li>Levels of stop-loss assurance</li>

                                </Text>
                                <Text fontSize={"15px"} >
                                    <li>Utilise all available margins to make the best use of your trading capital. Without margin calls.</li>

                                </Text>
                                <Text fontSize={"15px"} >
                                    <li>Immediate payouts of future profits</li>

                                </Text>
                                <Text fontSize={"15px"} >
                                    <li>Leverage that the user can choose, up to 400:1</li>

                                </Text>

                            </Box>

                        </Box>
                       

                    </Box>

                    <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "30%", xl: "30%" }} p={{ base: '5px', sm: "5px", md: "5px", lg: "30px", xl: "30px" }}>
                        <Input placeholder="Your Name" />
                        <Input mt={"20px"} placeholder="Your Email" />
                        <Input mt={"20px"} placeholder="Phone Number" />
                        <Textarea mt={"20px"} placeholder="Your Messages" />
                        <Button mt={"20px"} bg={"rgb(255,218,69)"} w={"100%"}>SEND MAIL</Button>
                    </Box>
                </Flex>
            </Box>
            <Box>
                <Footer/>
            </Box>
        </>
    )
}
export default CostTrading;