import { Routes, Route } from "react-router-dom"
import Login from "../Components/Login";
import { Register1 } from "../Components/Register1";
import { Register2 } from "../Components/Register2";
import Register3 from "../Components/Register3";
import Home from "../Components/Home"
import EmailVerify from "../Components/EmailVerify";
import RegistrationThank from "../Components/ThankYou/RegistrationThank";
import ClosedDealPage from "../Components/ClosedDeal/ClosedDealPage";
import AdminHome from "../Components/Admin/AdminHome";
import AdminLogin from "../Components/Admin/AdminLogin";
import AdminUser from "../Components/Admin/AdminUser";
import DepositMoney from "../Components/Admin/DepositMoney";
import Edit from "../ModalPages/Edit";
import BankDetails from "../Bank/BankDetails";
import AddnewBank from "../Bank/AddnewBank";
import WithdrawRequest from "../Components/Withdraw/WithdrawRequest";
import WithdrawMoney from "../Components/Withdraw/WithdrawMoney";
import Deposit from "../Components/Withdraw/Deposit";
import NavbarNew from "../Navbar/NavbarNew";
import PrivateRouter from "./PrivateRouter";
import AddDeposit from "../Components/Withdraw/AddDeposit";
import RegisterHome from "../Components/ThankYou/RegisterHome";
import LiveChart from "../Components/Withdraw/LiveChart";
import Profile from "../Navbar/Profile";
import ChangePassword from "../Components/ClosedDeal/ChangePassword";
import MainHome from "../Components/MainHome";
import ForgetPasswordChange from "../Components/ThankYou/ForgetPasswordChange";
import ForgetPass from "../Components/ThankYou/ForgetPass";
import DocumentVerification from "../ModalPages/DocumentVerification";
import Depositreq from "../Components/Admin/Depositreq";
import WithdrawReq from "../Components/Admin/WithdrawReq";
import Verify from "../Components/Admin/Verify";
import MainPage from "../Components/MainPage";
import Demo from "../Components/Demo";
import ProfileDemo from "../Navbar/ProflieDemo";
import ClosedDealDemoPage from "../Components/ClosedDeal/ClosedDealDemoPage";
import AdminDepositDemo from "../Components/Admin/AdminDepositDemo";
import AccountC from "../Components/HomePageSmall/AccountC";
import AccountV from "../Components/HomePageSmall/AccountV";
import BenfitTrad from "../Components/HomePageSmall/BenfitTrad";
import Commodities from "../Components/HomePageSmall/Commodities";
import ContactUs from "../Components/HomePageSmall/ContactUs";
import CostTrading from "../Components/HomePageSmall/CostTrading";
import CryptoCurr from "../Components/HomePageSmall/CryptoCurr";
import CurrencyTrading from "../Components/HomePageSmall/CurrencyTrading";
import DailyM from "../Components/HomePageSmall/DailyM";
import Education from "../Components/HomePageSmall/Education";
import FAQs from "../Components/HomePageSmall/FAQs";
import FinCal from "../Components/HomePageSmall/FinCal";
import Forex from "../Components/HomePageSmall/Forex";
import GloabFxHelp from "../Components/HomePageSmall/GloabFxHelp";
import Goal from "../Components/HomePageSmall/Goal";
import Indices from "../Components/HomePageSmall/Indices";
import OpenLive from "../Components/HomePageSmall/OpenLive";
import Platform from "../Components/HomePageSmall/Platform";
import Policy from "../Components/HomePageSmall/Policy";
import RealChart from "../Components/HomePageSmall/RealChart";
import Risk from "../Components/HomePageSmall/Risk";
import Signal from "../Components/HomePageSmall/Signal";
import Stocks from "../Components/HomePageSmall/Stocks";
import Terms from "../Components/HomePageSmall/Terms";
import TradingList from "../Components/HomePageSmall/TradingList";
import DepositHist from "../Components/Admin/DepositHist";
import WithOtherreq from "../Components/Admin/WithOtherreq";
import ChangePasswordAdmin from "../Components/Admin/ChangePasswordAdmin";



function AllRoutes() {
    return (
        <Routes>
            <Route path="/" element={<MainHome />} />
            <Route path="/login" element={<Login />} />
            <Route path="/home" element={<PrivateRouter allowed={["user", "Admin"]}><Home /></PrivateRouter>} />
            <Route path="/users/:id/verify/:token" element={<EmailVerify />} />
            <Route path="/thankyou" element={<RegistrationThank />} />
            <Route path="/closeDeal" element={<PrivateRouter allowed={["user", "Admin"]}><ClosedDealPage /></PrivateRouter>} />

            {/* <Route path="/depositmoney" element={<PrivateRouter><DepositMoney /></PrivateRouter>} /> */}
            <Route path="/bank" element={<PrivateRouter allowed={["user", "Admin"]}><AddnewBank /></PrivateRouter>} />
            <Route path="/bankdetails" element={<PrivateRouter allowed={["user", "Admin"]}><BankDetails /></PrivateRouter>} />
            <Route path="/requestwith" element={<PrivateRouter allowed={["user", "Admin"]}><WithdrawRequest /></PrivateRouter>} />
            <Route path="/withdraw" element={<PrivateRouter allowed={["user", "Admin"]}><WithdrawMoney /></PrivateRouter>} />
            <Route path="/depositpage" element={<PrivateRouter allowed={["user", "Admin"]}><Deposit /></PrivateRouter>} />
            <Route path="/navbar" element={<NavbarNew />} />
            <Route path="/deposit" element={<PrivateRouter allowed={["user", "Admin"]}><AddDeposit /></PrivateRouter>} />
            <Route path="/register" element={<RegisterHome />} />
            <Route path="/livechart" element={<PrivateRouter allowed={["user", "Admin"]}><LiveChart /></PrivateRouter>} />
            <Route path="/profile" element={<PrivateRouter allowed={["user", "Admin"]}><Profile /></PrivateRouter>} />
            <Route path="/changepass" element={<PrivateRouter allowed={["user", "Admin"]}><ChangePassword /></PrivateRouter>} />
            <Route path="/forget" element={<ForgetPass />} />
            <Route path="/forgetchange" element={<ForgetPasswordChange />} />
            <Route path="/document" element={<PrivateRouter allowed={["user", "Admin"]}><DocumentVerification /></PrivateRouter>} />
            <Route path="/mainpagehome" element={<MainPage />}/>
            <Route path="/demo" element={<Demo/>}/>
            <Route path="/profiledemo" element={<ProfileDemo/>}/>
            <Route path="/closedemo" element={<ClosedDealDemoPage/>}/>
            <Route path="/admindepositdemo" element={<AdminDepositDemo/>}/>


            <Route path="/adminuser" element={<PrivateRouter allowed={["Admin"]}><AdminUser /></PrivateRouter>} />
            <Route path="/admindeposit" element={<PrivateRouter allowed={["Admin"]}><DepositMoney /></PrivateRouter>} />
            <Route path="/admindepositreq" element={<PrivateRouter allowed={["Admin"]}><Depositreq /></PrivateRouter>} />
            <Route path="/operaadminpersonal" element={<AdminLogin />} />
            <Route path="/adminwithdraw" element={<PrivateRouter allowed={["Admin"]}><WithdrawReq /></PrivateRouter>} />
            <Route path="/verifyall" element={<PrivateRouter allowed={["Admin"]}><Verify /></PrivateRouter>} />
            <Route path="/deposithistory" element={<PrivateRouter allowed={["Admin"]}><DepositHist /></PrivateRouter>} />
            <Route path="/otherwithdraw" element={<PrivateRouter allowed={["Admin"]}><WithOtherreq /></PrivateRouter>} />
            <Route path="/changepassword" element={<PrivateRouter allowed={["Admin"]}><ChangePasswordAdmin /></PrivateRouter>} />
            <Route path="/accountc" element={<AccountC />}/>
            <Route path="/accountv" element={<AccountV />}/>
            <Route path="/accountc" element={<AccountC />}/>
            <Route path="/benfit" element={<BenfitTrad />}/>
            <Route path="/commodities" element={<Commodities />}/>
            <Route path="/contactus" element={<ContactUs />}/>
            <Route path="/costtrading" element={<CostTrading />}/>
            <Route path="/cryptocurr" element={<CryptoCurr />}/>
            <Route path="/currencytrad" element={<CurrencyTrading />}/>
            <Route path="/dailym" element={<DailyM />}/>
            <Route path="/education" element={<Education />}/>
            <Route path="/faq" element={<FAQs />}/>
            <Route path="/fincal" element={<FinCal />}/>
            <Route path="/forex" element={<Forex />}/>
            <Route path="/help" element={<GloabFxHelp />}/>
            <Route path="/goal" element={<Goal />}/>
            <Route path="/indices" element={<Indices />}/>
            <Route path="/openlive" element={<OpenLive />}/>
            <Route path="/platform" element={<Platform />}/>
            <Route path="/policy" element={<Policy />}/>
            <Route path="/realchart" element={<RealChart />}/>
            <Route path="/risk" element={<Risk />}/>
            <Route path="/signal" element={<Signal />}/>
            <Route path="/stock" element={<Stocks />}/>
            <Route path="/terms" element={<Terms />}/>
            <Route path="/trading" element={<TradingList />}/>
        </Routes>
    )
}

export default AllRoutes;