import { Box, Button, Flex, Input, Text, Textarea } from "@chakra-ui/react";
import Navbar from "../../Navbar/Navbar";
import NavbarNew from "../../Navbar/NavbarNew";
import NavbarHome from "../../Navbar/NavbarHome";
import Footer from "../../Navbar/Footer";



function BenfitTrad() {
    return (
        <>
            <Box>
                <NavbarHome />
            </Box>
            <Box padding={{base:'10px',sm:"10px",md:"10px",xl:"30px 45px",lg:"30px 45px"}} >
                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"50px"}>Benefits of Trading</Text>
            </Box>
            <Box paddingLeft={{base:'10px',sm:"10px",md:"10px",xl:"45px",lg:"45px"}}>
                <Flex gap={"20px"} flexDirection={{base:"column",sm:"column",md:"column",lg:"row",xl:"row"}}>
                    <Box w={{base:'100%',sm:"100%",md:"100%",xl:"70%",lg:"70%"}}>
                        <Box>
                            <Text fontSize={"15px"}>
                            Gloabfx is an innovative trading platform capable of trading a wide range of financial resources, including spot trading in commodities and foreign currency (Forex). By offering wide trading capability and flexibility, Gloabfx offers the full package for traders, resulting in a solid modern trading platform. As the industry leader and subject to secure regulation, Gloabfx is required to offer a specific calibre of service. We have a strong commitment to maintaining the highest standards of professionalism and business ethics.
                            </Text>
                            <Text fontSize={"15px"} mt={"30px"}>
                            Real-time pricing and a remarkable amount of liquidity are provided to our customers through the winning combination of technology innovation and industry knowledge known as Gloabfx. This combination makes sure that all of the financial markets always provide our traders with the best trading environment.
                            </Text>
                           
                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>Why do traders choose Gloabfx?</Text>
                            </Box>
                            <Box p={"20px"}>
                            <Text fontSize={"15px"} mt={"10px"}>
                            <li>Instant access to world markets for commodities and forex.</li>
                    
                            </Text>
                            <Text fontSize={"15px"} >
                            <li>Using global resources, one click can provide worldwide liquidity.</li>
                    
                            </Text>
                            <Text fontSize={"15px"} >
                            <li>With offices around the world, we can provide outstanding customer assistance in your language.</li>
                    
                            </Text>
                            <Text fontSize={"15px"} >
                            <li>For skilled traders with larger accounts, a wide variety of interbank market access tools.</li>
                    
                            </Text>
                            
                            </Box>

                        </Box>
                      
                    </Box>

                    <Box w={{base:"100%",sm:"100%",md:"100%",lg:"30%",xl:"30%"}} p={{base:'5px',sm:"5px",md:"5px",lg:"30px",xl:"30px"}}>
                        <Input placeholder="Your Name" />
                        <Input mt={"20px"} placeholder="Your Email" />
                        <Input mt={"20px"} placeholder="Phone Number" />
                        <Textarea mt={"20px"} placeholder="Your Messages"/>
                        <Button mt={"20px"}  bg={"rgb(255,218,69)"} w={"100%"}>SEND MAIL</Button>
                    </Box>
                </Flex>
            </Box>
            <Box>
                <Footer/>
            </Box>
        </>
    )
}
export default BenfitTrad;