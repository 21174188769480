import { Box, Button, ButtonSpinner, Checkbox, Flex, FormControl, FormErrorMessage, FormHelperText, Image, Input, Select, Spinner, Text, useToast } from "@chakra-ui/react"
import { ArrowBackIcon, CheckIcon } from "@chakra-ui/icons";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux"
import { addUser } from "../redux/action";
import { useNavigate } from "react-router-dom";
import { myContext } from "../context/Context";
import NavbarNew from "../Navbar/NavbarNew";
import back from "../../src/images/back1.jpg"

function Register3() {
    const [data, setData] = useState({
        account_type: "",
        leverage: "",
        remarks:""
    });
    let toast = useToast();  // Corrected usage

let { load, setLoad } = useContext(myContext);
const [error, setError] = useState("");
const [msg, setMsg] = useState("");




    let dispatch = useDispatch()
    const { storeuserDetails, userDetail, setPage, page } = useContext(myContext)
    // useEffect(() => {
    //     if (userDetail) {
    //       // Call fetchData when data is stored

    //       fetchData();



    //     }
    //   }, [userDetail]);

    useEffect(() => {

        setData({
            account_type: userDetail.account_type || "",
            leverage: userDetail.leverage || "",
            remarks:userDetail.remarks|| ""
        });

    }, [userDetail]);
    let navigate = useNavigate()


    const storeData = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
        storeuserDetails({ ...userDetail, [e.target.name]: e.target.value });
    };


    const nextPage = async (e) => {
        e.preventDefault();
        console.log(userDetail)
        setLoad(false);
        try {
            const response = await fetch(`https://trading-jz57.onrender.com/register`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(userDetail),
            });

            if (response.ok) {
                const result = await response.json();
                setMsg(result.msg);
                toast({
                    description: `Email sent successfully`,
                    status: `success`,
                    duration: 5000,
                    isClosable: true,
                    position: "top-center",
                });
                navigate("/thankyou");

            } else {
                // Log the error details to the console for debugging
                toast({
                    description: `Unable  to save`,
                    status: `success`,
                    duration: 5000,
                    isClosable: true,
                    position: "top-center",
                });
               
            }

            setLoad(true);
        } catch (error) {
            // Log the error details to the console for debugging
            console.log("An error occurred during registration:", error);
            setLoad(true);
            // setError("An error occurred during registration. Please try again.");
            toast({
                description: `An error occurred during registration. Please try again.`,
                status: `error`,
                duration: 5000,
                isClosable: true,
                position: "top-center",
            });
        }
    };

    return (
        <Box>
            <Box>
                <NavbarNew />
            </Box>
            <Box >
                

                <Flex gap={{ base: "10px", md: "20px" }} flexDirection={{ base: "column", md: "row" }}>

                    <Box width={{base:"100%",sm:"100%",md:"100%",lg:"50%",xl:"50%"}} p={{base:"20px",sm:"20px",md:"20px",lg:"120px",xl:"120px"}}>
                        <form onSubmit={nextPage} >
                            <Text mt={{ base: "10px", md: "20px" }} color="white">Now you are all set to start a forex account with us, and it's just a few clicks away.</Text>
                            <Select mt={{ base: "10px", md: "20px" }} name="account_type" style={{ backgroundColor: "rgb(55,67,78)", color: "white" }} value={data.account_type} onChange={storeData} isRequired>
                                <option value="" style={{ backgroundColor: "rgb(55,67,78)" }}>Select Account Type</option>
                                <option value="mini" style={{ backgroundColor: "rgb(55,67,78)" }}>Mini</option>
                                <option value="standard" style={{ backgroundColor: "rgb(55,67,78)" }}>Standard</option>
                                <option value="premium" style={{ backgroundColor: "rgb(55,67,78)" }}>Premium</option>
                            </Select>

                            <Select mt={{ base: "10px", md: "20px" }} name="leverage" placeholder="Select Leverage" value={data.leverage} onChange={storeData} bg={"rgb(55,67,78)"} isRequired>
                            {/* <option value="" style={{ backgroundColor: "rgb(55,67,78)" }}>Select Leverage</option> */}
                                <option value="1:500" style={{ backgroundColor: "rgb(55,67,78)" }}>1:500</option>
                            </Select>
                            <Input mt={"20px"}
                                    type="text"
                                    name="remarks"
                                    _placeholder={{ fontSize: "14px" }}
                                    placeholder="Remarks"
                                    value={data.remarks}
                                    onChange={storeData}
                                    

                                />
                            {/* {error && <div style={{ width: '370px', padding: "15px", margin: "10px 0", fontSize: "14px", backgroundColor: '#f34646', color: 'white', borderRadius: '5px', textAlign: 'center' }}>{error}</div>} */}
                            {/* {msg && <div style={{ width: '370px', padding: "15px", margin: "5px 0", fontSize: "14px", backgroundColor: 'rgb(132,252,60)', color: 'white', borderRadius: '5px', textAlign: 'center' }}>{msg}</div>} */}
                            <Flex mt={{ base: "20px", md: "30px" }} justifyContent="space-between">
                                <Button bg="rgb(255,218,69)" color="black" cursor={"pointer"} onClick={() => setPage(page - 1)}><ArrowBackIcon />Back</Button>
                                {
                                    load ? <Button type="submit" bg="rgb(255,218,69)" cursor={"pointer"} color="black"><CheckIcon />Submit</Button> : <Button
                                        size={{ base: "md", md: "lg" }}
                                        mt={{ base: "20px", md: "30px" }}
                                        // leftIcon={<ArrowForwardIcon />}
                                        bg="rgb(255,218,69)"
                                        variant="solid"
                                        color="black"
                                        cursor={"pointer"}
                                        type="submit"
                                    > <Spinner />  </Button>
                                }
                            </Flex>
                        </form>
                    </Box>

                    <Box width={"50%" } display={{base:"none",sm:"none",md:"none",lg:"block",xl:"block"}}>
                    <Image src={back} style={{ width: '100%', height: '100vh', objectFit: 'cover' }} />
                    </Box>
                </Flex>
            </Box>
        </Box>
    )
}

export default Register3;
