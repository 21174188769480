import { Box, Button, Flex, Input, Text, Textarea } from "@chakra-ui/react";
import Navbar from "../../Navbar/Navbar";
import NavbarNew from "../../Navbar/NavbarNew";
import NavbarHome from "../../Navbar/NavbarHome";
import Footer from "../../Navbar/Footer";



function GloabFxHelp() {
    return (
        <>
            <Box>
                <NavbarHome />
            </Box>
            <Box padding={{ base: '10px', sm: "10px", md: "10px", xl: "30px 45px", lg: "30px 45px" }} >
                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"50px"}>GloabFx Help</Text>
            </Box>
            <Box paddingLeft={{ base: '10px', sm: "10px", md: "10px", xl: "45px", lg: "45px" }}>
                <Flex gap={"20px"} flexDirection={{ base: "column", sm: "column", md: "column", lg: "row", xl: "row" }}>
                    <Box w={{ base: '100%', sm: "100%", md: "100%", xl: "70%", lg: "70%" }}>
                        <Box>

                            <Text fontSize={"15px"} mt={"30px"}>

                                GloabFx gives investors access to the biggest financial market in the world, enabling them to purchase and sell currencies around the clock. We also provide leverage, which enables traders to manage substantial holdings with little capital. Trading platforms with capabilities like charting tools and technical indicators are offered to traders. Additionally, we offer customer support and instructional materials, including online classes and trade tips. We aid traders in locating the most advantageous exchange rates, carrying out transactions quickly, and leveraging their gains.
                            </Text>

                        </Box>
                       

                    </Box>

                    <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "30%", xl: "30%" }} p={{ base: '5px', sm: "5px", md: "5px", lg: "30px", xl: "30px" }}>
                        <Input placeholder="Your Name" />
                        <Input mt={"20px"} placeholder="Your Email" />
                        <Input mt={"20px"} placeholder="Phone Number" />
                        <Textarea mt={"20px"} placeholder="Your Messages" />
                        <Button mt={"20px"} bg={"rgb(255,218,69)"} w={"100%"}>SEND MAIL</Button>
                    </Box>
                </Flex>
            </Box>
            <Box>
                <Footer/>
            </Box>
        </>
    )
}
export default GloabFxHelp;