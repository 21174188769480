import { Box, Button, Divider, Flex, Input, Select, Spinner, Text, Textarea, useToast } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { myContext } from "../../context/Context";
import NavbarNew from "../../Navbar/NavbarNew";
import { useNavigate } from "react-router-dom";



function WithdrawRequest() {

    let [data, setData] = useState([]);
    let [balance, setBalance] = useState(0);
    let [account_number, setAccount_number] = useState()
    let [saveWithdraw, setSaveWithdraw] = useState({
        withdraw_money: "",
        remarks: ""
    })
    let result = JSON.parse(sessionStorage.getItem("key"));
    let [load, setLoad] = useState(true);
    // let [neteq, setNetEquity] = useState(0)
    let pl = JSON.parse(localStorage.getItem("totalNetProfit"))


    let { totalbalance, fetchBalance } = useContext(myContext)

    let margin = JSON.parse(localStorage.getItem("margin"))
    let res = JSON.parse(localStorage.getItem("account_type"))
    let navigate = useNavigate();
    let toast = useToast();
    let [bonus, setBonus] = useState();
    let[saveWithdrawAll,setSaveWithdrawAll]=useState()


    let [neteq, setEq] = useState(0)
    let [withdraw1, setWithdraw1] = useState({
        Alloption: "",
        AccountId: "",
    })

    let fetchData1 = async () => {
        try {
            let response = await fetch(`https://trading-jz57.onrender.com/register/${result.type.AcNumber}`)
            let res = await response.json();
            // setData(res)

            setBalance(res.totalbalance);
            // setEq(res.neteq)
            setEq(res.neteq + parseFloat(pl))
            setBonus(res.bonus)


        } catch (error) {
            toast({
                description: `something went wrong`,
                status: `error`,
                duration: 5000,
                isClosable: true,
                position: "top-center",
            })
        }
    }



    useEffect(() => {
        fetchData1();
    }, [])

    // useEffect(() => {
    //     fetchBalance()
    //     setNetEquity((totalbalance) + parseFloat(pl))

    // }, [totalbalance])

    const fetchData = async () => {
        let response = await fetch(`https://trading-jz57.onrender.com/bank/${result.type.AcNumber}`)
        let res = await response.json();
        setData(res);

    }

    let handleSelectChange = (e) => {
        setAccount_number(e.target.value);
    }

    const SaveBankDetails = (e) => {
        setSaveWithdraw({ ...saveWithdraw, [e.target.name]: e.target.value })
        setSaveWithdrawAll({...saveWithdraw,...withdraw1})
    }
    console.log(neteq)
    let fetchWithdraw = async (e) => {
        e.preventDefault();
        setLoad(false);

        if (neteq <= 0) {
            toast({
                description: `Insufficient balance in your account`,
                status: `warning`,
                duration: 9000,
                isClosable: true,
                position: "top-center",
            })
            setLoad(true)
        } else if (neteq <= saveWithdraw.withdraw_money) {
            toast({
                description: `Enter amount should be less than Net Equity`,
                status: `warning`,
                duration: 5000,
                isClosable: true,
                position: "top-center",
            })
            setLoad(true)
        } else if (saveWithdraw.withdraw_money == "0") {
            toast({
                description: `Amount to be greater than zero`,
                status: `warning`,
                duration: 5000,
                isClosable: true,
                position: "top-center",
            })
            setLoad(true)
        }

        else {
            if(withdraw1.Alloption==="Bank"){
                try {
                    let response = await fetch(`https://trading-jz57.onrender.com/requestmoney/${result.type.AcNumber}/${account_number}`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(saveWithdrawAll)
                    })
                    if (response.ok) {
                        let res = await response.json();
    
                        navigate("/withdraw")
                        toast({
                            description: `Request added successfully`,
                            status: `success`,
                            duration: 5000,
                            isClosable: true,
                            position: "top-center",
                        })
                        setLoad(true)
                    } else {
                        toast({
                            description: `Unable to add`,
                            status: `success`,
                            duration: 5000,
                            isClosable: true,
                            position: "top-center",
                        })
                        setLoad(true)
                    }
    
                } catch (error) {
    
                    setLoad(false)
                    toast({
                        description: `unable to fetch`,
                        status: `success`,
                        duration: 5000,
                        isClosable: true,
                        position: "top-center",
                    })
                    setLoad(true)
                }
            }else{
                try {
                    let response = await fetch(`https://trading-jz57.onrender.com/otherwithdraw/${result.type.AcNumber}`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(saveWithdrawAll)
                    })
                    if (response.ok) {
                        let res = await response.json();
                        console.log(res)
                        navigate("/withdraw")
                        toast({
                            description: `Request added successfully`,
                            status: `success`,
                            duration: 5000,
                            isClosable: true,
                            position: "top-center",
                        })
                        setLoad(true)
                    } else {
                        toast({
                            description: `Unable to add`,
                            status: `success`,
                            duration: 5000,
                            isClosable: true,
                            position: "top-center",
                        })
                        setLoad(true)
                    }
    
                } catch (error) {
    
                    setLoad(false)
                    toast({
                        description: `unable to fetch`,
                        status: `success`,
                        duration: 5000,
                        isClosable: true,
                        position: "top-center",
                    })
                    setLoad(true)
                }
            }
        }
    }


    useEffect(() => {
        fetchData()
    }, []);


    let handleWithdrawChange = (e) => {
        setWithdraw1({ ...withdraw1, [e.target.name]: e.target.value })
        setSaveWithdrawAll({...withdraw1,...saveWithdraw})
    }
   
    console.log(withdraw1);
    console.log(saveWithdrawAll);

    return (
        <>
            <Box >
                <NavbarNew />
                <Flex gap={"30px"} >
                    <Box w={"20%"} p={"20px"} h={"100vh"} display={{ base: "none", sm: "none", md: "none", lg: "none", xl: "block" }} color={"white"} bg={"black"} >
                        <Box mt={"30px"}>
                            <Text textAlign={"center"} fontSize='2xl'>Account Summary</Text>
                        </Box>
                        <Box mt={"30px"}>
                            <Flex direction={"column"} gap={"20px"}>
                                <Box>
                                    <Flex justifyContent={"space-between"}>
                                        <Text fontWeight={"bold"}> Package</Text>
                                        <Text fontWeight={"500"}>{res.account_type} </Text>
                                    </Flex>
                                </Box>
                                <Box>
                                    <Flex justifyContent={"space-between"}>
                                        <Text fontWeight={"bold"}> Balance</Text>
                                        <Text fontWeight={"500"}> $ {balance !== undefined ? balance.toFixed(2) : "0.00"}</Text>
                                    </Flex>
                                </Box>
                                <Box>
                                    <Flex justifyContent={"space-between"}>
                                        <Text fontWeight={"bold"}> Open P/L</Text>
                                        <Text fontWeight={"500"}> $ {pl !== null ? pl.toFixed(2) : "0.00"}</Text>
                                    </Flex>
                                </Box>
                                <Box>
                                    <Flex justifyContent={"space-between"}>
                                        <Text fontWeight={"bold"}> Net Equity</Text>
                                        <Text fontWeight={"500"}> $ {neteq !== undefined ? neteq.toFixed(2) : "0.00"}</Text>
                                    </Flex>
                                </Box>
                                <Box>
                                    <Flex justifyContent={"space-between"}>
                                        <Text fontWeight={"bold"}> Bonus</Text>
                                        <Text fontWeight={"500"}> $ {bonus !== undefined ? bonus.toFixed(2) : "0.00"}</Text>
                                    </Flex>
                                </Box>
                                <Box>
                                    <Flex justifyContent={"space-between"}>
                                        <Text fontWeight={"bold"}> Margin</Text>
                                        <Text fontWeight={"500"}> $ {margin}</Text>
                                    </Flex>
                                </Box>
                            </Flex>
                        </Box>

                    </Box>
                    <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "100%", xl: "80%" }} borderRadius={"10px"} boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px" p={"30px"}>
                        <Text fontSize={"xl"}>New Withdrawal Request</Text>
                        <Divider mt={"10px"}></Divider>

                        <form onSubmit={fetchWithdraw}>
                            <Box mt={"20px"}>
                                <Box mb={"10px"}>
                                    <Select style={{ backgroundColor: "rgb(55,67,78)", color: "white" }} name="Alloption" value={withdraw1.Alloption} onChange={handleWithdrawChange}>
                                        <option style={{ backgroundColor: "rgb(55,67,78)" }} value={""}>Select Option</option>
                                        <option style={{ backgroundColor: "rgb(55,67,78)" }} value={"Bank"}>BANK</option>
                                        <option style={{ backgroundColor: "rgb(55,67,78)" }} value={"USDT"}>USDT</option>

                                        <option style={{ backgroundColor: "rgb(55,67,78)" }} value={"Bitcoin"}>BITCOIN</option>
                                        <option style={{ backgroundColor: "rgb(55,67,78)" }} value={"Paypal"}>PAYPAL</option>
                                    </Select>
                                </Box>
                                <Flex justifyContent={"space-between"} flexDirection={{ base: "column", sm: "column", md: "column", lg: "row", xl: "row" }}>
                                    <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "100%", xl: "45%" }} mt="30px">

                                        {
                                            withdraw1.Alloption === "Bank" ? (
                                                <Box>
                                                    <Text>Bank Account</Text>
                                                    <Select placeholder="Select Bank"
                                                        onChange={handleSelectChange}
                                                        value={account_number}
                                                        isRequired
                                                        style={{ backgroundColor: "rgb(55,67,78)", color: "white" }}
                                                    >
                                                        {
                                                            data.map((ele) => (
                                                                <option key={ele.account_number} style={{ backgroundColor: "rgb(55,67,78)", color: "white" }} value={ele.account_number}>
                                                                    {ele.nick_name} ({ele.account_number})
                                                                </option>
                                                            ))
                                                        }
                                                    </Select>
                                                </Box>
                                            ) : withdraw1.Alloption === "USDT" ? (
                                                <Box>
                                                    <Text >ID for USDT</Text>
                                                    <Input type="text" name="AccountId" value={withdraw1.AccountId} onChange={handleWithdrawChange}/>
                                                </Box>
                                            ) : withdraw1.Alloption === "Bitcoin" ? (
                                                <Box>
                                                    <Text >ID for Bitcoin</Text>
                                                    <Input type="text" name="AccountId" value={withdraw1.AccountId} onChange={handleWithdrawChange}/>
                                                </Box>
                                            ) : withdraw1.Alloption === "Paypal" ? (
                                                <Box>
                                                    <Text >ID for PayPal</Text>
                                                    <Input type="text"  name="AccountId" value={withdraw1.AccountId} onChange={handleWithdrawChange}/>
                                                </Box>
                                            ) : null
                                        }

                                    </Box>
                                    <Box mt="30px" w={{ base: "100%", sm: "100%", md: "100%", lg: "100%", xl: "45%" }}>
                                        <Text>Withdrawal Amount</Text>
                                        <Input
                                            type="Number"
                                            name="withdraw_money"
                                            onChange={SaveBankDetails}
                                            value={saveWithdraw.withdraw_money}
                                            isRequired
                                        />
                                    </Box>
                                </Flex>
                            </Box>
                            <Box mt="30px">
                                <Text>Remarks</Text>
                                <Textarea
                                    type="text"
                                    name="remarks"
                                    onChange={SaveBankDetails}
                                    value={saveWithdraw.remarks}
                                    isRequired
                                />
                            </Box>
                            <Box mt={"30px"}>
                                {
                                    load ? <Button type="submit" color={"black"} bg={"rgb(255,218,69)"} _hover={{ color: "black", bg: "white", cursor: "pointer" }}>REQUEST</Button> : <Button type="submit" bg={"red"} color={"white"}><Spinner /></Button>
                                }

                            </Box>
                        </form>
                    </Box>
                </Flex>
            </Box>
        </>
    )
}

export default WithdrawRequest;