import { ADD_USER } from "./Actiontype";



const initialState = {
    data: []
};

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ADD_USER: {
            return { ...state, todos: [...state.data, payload] }
        }
        default:{
            return state
        }
    }
}


export { reducer };