import { Box, Button, Flex, Input, Text, Textarea } from "@chakra-ui/react";
import Navbar from "../../Navbar/Navbar";
import NavbarNew from "../../Navbar/NavbarNew";
import NavbarHome from "../../Navbar/NavbarHome";
import Footer from "../../Navbar/Footer";



function CurrencyTrading() {
    return (
        <>
            <Box>
                <NavbarHome />
            </Box>
            <Box padding={{base:'10px',sm:"10px",md:"10px",xl:"30px 45px",lg:"30px 45px"}} >
                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"50px"}>Currency Trading</Text>
            </Box>
            <Box paddingLeft={{base:'10px',sm:"10px",md:"10px",xl:"45px",lg:"45px"}}>
                <Flex gap={"20px"} flexDirection={{base:"column",sm:"column",md:"column",lg:"row",xl:"row"}}>
                    <Box w={{base:'100%',sm:"100%",md:"100%",xl:"70%",lg:"70%"}}>
                        <Box>
                            <Text fontSize={"15px"}>
                                The term “trading” is not new, and practically everyone is familiar with the methods, strategies, and techniques needed to succeed at it. Occasionally, even if we are aware of the truth, we become impatient when trading. The market is a place where a lot of individuals are trying to make more money, but the true traders—or perhaps we should say, the professionals—are the ones who genuinely make a sizable profit. They are aware of the best times to enter and quit a market after realising a profit.
                            </Text>
                            <Text fontSize={"15px"} mt={"30px"}>
                                Let us talk about the “currency market” a little bit further. This market’s operation is primarily tied to the stock market; hence, one may wonder: It is easy; simply consider how you would assess the nation’s strength. Sure, when its money is worth more than the others’ in the same vein, how would you assess the worth of money? You are correct! A nation’s GDP will rise if it is performing well, and this will have a direct impact on the value of its currencies. In addition, political considerations are another element that has a direct impact on the currency market.
                            </Text>
                            <Text fontSize={"15px"} mt={"30px"}>
                                The Forex market is a marketplace where individuals can purchase and sell currencies with any country by creating a pair. Although there are many other currencies available for trading, a select few are the most widely used.
                            </Text>
                            <Text fontSize={"15px"} mt={"30px"}>
                                The US Dollar (USD), British Pound (GBP), Canadian Dollar (CAD), Swiss Franc (CHF), Euro (EUR), Australian Dollar (AUD), New Zealand Dollar (NZD), and Japanese Yen (JPY) are the eight most traded currencies on the market.
                            </Text>
                            <Text fontSize={"15px"} mt={"30px"}>
                                This market operates around the clock and refers to the three trading sessions: the European, Asian, and United States sessions. On the other hand, it operates five days a week, with Friday evenings off. Additionally, there is potential for overlapping sessions where the volume of trade is nearly doubled due to different time zones. Because of the large trading volume at this moment, people are making more money.
                            </Text>
                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>Highlights of Meaning</Text>
                            </Box>
                            <Box>
                                <Text fontSize={"15px"} mt={"30px"}>
                                    The forex market, sometimes referred to as FX, is a large global exchange market for currencies.
                                </Text>
                                <Text fontSize={"15px"} mt={"30px"}>
                                    The Forex market is well-known for its vast trade, finance, and commerce, as well as its liquid assets.
                                </Text><Text fontSize={"15px"} mt={"30px"}>
                                    To facilitate trade, currencies are paired with each other. For example, the symbol USD/EUR indicates that the US dollar is trading lower than the European dollar.


                                </Text><Text fontSize={"15px"} mt={"30px"}>
                                    Similar to other markets, the forex market supports swapping of currencies, futures and options trading, and spot and derivative markets.
                                </Text><Text fontSize={"15px"} mt={"30px"}>
                                    In essence, traders use this market for a variety of purposes, including geopolitical event speculation and hedging against foreign currencies.
                                </Text><Text fontSize={"15px"} mt={"30px"}>
                                    We are able to trade currencies on the forex market. This is referred to as the continuous market since it provides us with constant services and trading opportunities. In the past, banks and other institutional players acting on behalf of their clients controlled the foreign exchange market. These days, it has shifted to a retail focus as more traders and investors begin to invest in it.
                                </Text><Text fontSize={"15px"} mt={"30px"}>
                                    The interesting thing about the forex market is that it does not actually look like a building where people trade; instead, trading is possible through trading terminals, robust computer networks, etc. Banks that participate in this market include investment banks, which can be used for investment purposes; commercial banks, which can be used for commercial purposes; and retail investors.
                                </Text><Text fontSize={"15px"} mt={"30px"}>
                                    Users are constantly looking for a broker that can properly advise them on every deal, allowing them to make more money with less risk. In actuality, the broker will never be at fault if you lose patience and make a bad deal after receiving the right advice. The person you are searching for is the same as GloabFx.
                                </Text><Text fontSize={"15px"} mt={"30px"}>
                                    If you are seeking the top broker in the forex market, GloabFx is the best option. Although we provide a demo account option for currency trading, there are no additional costs associated with our services. Both novices and experts can use demo accounts to verify their online methods before venturing into the live market.
                                </Text>
                                <Text fontSize={"15px"} mt={"30px"}>
                                    For more understanding, let us talk about the many kinds of forex markets before moving on to their characteristics and advantages.
                                </Text>

                            </Box>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"50px"}>Types of Markets in Forex</Text>
                            </Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>Spot Market</Text>
                            </Box>
                            <Box>
                                <Text fontSize={"15px"} mt={"30px"}>
                                    The largest trade in the forex market is the spot market, which includes the forward and future markets. The emergence of electronic trading and the spread of trading platforms have increased the number of transactions on the spot market. In the past, when trading was not conducted electronically, there was a lower volume of spot market transactions.
                                </Text>
                                <Text fontSize={"15px"} mt={"30px"}>
                                    Additionally, GloabFx offers you the chance to trade on the spot market with the right assistance.
                                </Text>

                            </Box>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>Future and forward markets</Text>
                            </Box>
                            <Box>
                                <Text fontSize={"15px"} mt={"30px"}>
                                    Forward refers to a future contract that two people or businesses agree upon, whereby they commit to purchasing currency at a later time. We will predetermine the costs at which we will purchase in order to uphold the future commitment.
                                </Text>
                                <Text fontSize={"15px"} mt={"30px"}>
                                    You may increase your earnings with the support of GloabFx’s team of skilled professionals that are well-versed in both the forward and future markets.
                                </Text>

                            </Box>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"50px"} >Benefits and Applications of Forex Market</Text>
                            </Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>Hedging</Text>
                            </Box>
                            <Box>
                                <Text fontSize={"15px"} mt={"30px"}>
                                    Many businesses operate abroad and use foreign currencies for trade, but have you ever considered the possibility that currency values could fluctuate? If so, taking out a loan or selling goods abroad could result in significant losses. Purchasing or selling products and services outside of your home market carries the largest risk.
                                </Text>
                                <Text fontSize={"15px"} mt={"30px"}>
                                    A team at GloabFx will provide you with customer service help so you can use hedging to offset your losses.
                                </Text>

                            </Box>


                        </Box>
                        <Box>

                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>Using forex for speculative purposes</Text>
                            </Box>
                            <Box>
                                <Text fontSize={"15px"} mt={"30px"}>
                                    The forex market is not very volatile, but there are still some fluctuations due to factors like interest rates, transaction volume, market strength, and the supply and demand chain for currencies, among other things. It indicates that one currency in a pair is strengthening as the other weakens.
                                    GloabFx offers high-quality services and assists you in speculating to prevent losses.
                                </Text>
                                <Text fontSize={"15px"} mt={"30px"}>
                                    Additionally, GloabFx offers a wide selection of indicators and functional trading software that will improve your trading experience. To encourage new traders to trade, we are offering account bonuses. We also promise not to charge more for deposits or withdrawals.
                                </Text>
                                <Text fontSize={"15px"} mt={"30px"}>
                                    Before engaging in any real trading, we urge beginners to test out our sample services, as we can guarantee that they will be beneficial to them. The true goal of any trader when they first enter the market is to make a good profit; therefore, open your account with account bonuses and under the supervision of our specialists. GloabFx is pleased to assist you. Simply click here or log in to our account opening page to open an account.
                                </Text>

                            </Box>

                        </Box>
                    </Box>

                    <Box w={{base:"100%",sm:"100%",md:"100%",lg:"30%",xl:"30%"}} p={{base:'5px',sm:"5px",md:"5px",lg:"30px",xl:"30px"}}>
                        <Input placeholder="Your Name" />
                        <Input mt={"20px"} placeholder="Your Email" />
                        <Input mt={"20px"} placeholder="Phone Number" />
                        <Textarea mt={"20px"} placeholder="Your Messages" />
                        <Button mt={"20px"} bg={"rgb(255,218,69)"} w={"100%"}>SEND MAIL</Button>
                    </Box>
                </Flex>
            </Box>
            <Box>
                <Footer/>
            </Box>
        </>
    )
}
export default CurrencyTrading;