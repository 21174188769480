

import { Box, Flex, FormControl, Input, InputRightElement, Text, InputGroup, Button, Image, useToast, ButtonSpinner, Spinner } from "@chakra-ui/react"
import { useContext, useEffect, useState } from "react";
import { ArrowForwardIcon } from "@chakra-ui/icons"
import service from "../images/service_icon_4.png"
import service1 from "../images/service_icon_2.png"
import service2 from "../images/service_icon_3.png"
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addData, addUser } from "../redux/action";
import { myContext } from "../context/Context";
import NavbarNew from "../Navbar/NavbarNew";
import back from "../../src/images/back1.jpg"


export function Register2() {
    const [show, setShow] = useState(false);
    const handleClick = () => setShow(!show);
    let navigate = useNavigate();
    let [data, setData] = useState({
        street_add: "",
        zip_code: "",
        city: "",
        state: "",
        country: "",
        dob: "",
        phone: ""
    })

    const { storeuserDetails, userDetail, setPage, page } = useContext(myContext)
    let dispatch = useDispatch()
    let [next, setNext] = useState(true)
    let toast = useToast();
    useEffect(() => {
        setData({
            street_add: userDetail.street_add || "",
            zip_code: userDetail.zip_code || "",
            state: userDetail.state || "",
            city: userDetail.city || "",
            country: userDetail.country || "",
            dob: userDetail.dob || "",
            phone: userDetail.phone || "",
        });
    }, [userDetail]);
    let storeData = (e) => {
        const { name, value } = e.target;

        if (name === "phone" && value.length > 10) {
            return;
        }
        setData({ ...data, [name]: value });

    }

    let nextPage = async (e) => {
        e.preventDefault();
        setNext(false)
        try {
            let response = await fetch(`https://trading-jz57.onrender.com/check/phone`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ phone: data.phone })

            })
            if (response.ok) {
                let res = await response.json();
                console.log(res);
                toast({
                    description: `${res.msg}`,
                    status: `success`,
                    duration: 5000,
                    isClosable: true,
                    position: "top-center",
                })
                setNext(true)
            } else {
                setPage(page + 1)

                storeuserDetails(data);
                setNext(true)
            }
        } catch (error) {
            toast({
                description: `Error in fetching`,
                status: `error`,
                duration: 5000,
                isClosable: true,
                position: "top-center",
            })
            setNext(false)
        }
    }


    const { street_add, zip_code, city, state, country, dob, phone } = data


    return (
        <>
            <Box>
                <Box>
                    <NavbarNew />
                </Box>
                <Box

                    width={"100%"}
                   

                >
                    <Flex
                        display="flex"
                        flexDirection={{ base: "column", md: "row" }}
                        gap={{ base: "20px", md: "30px" }}
                    >
                        <Box width={{base:"100%",sm:"100%",md:"100%",lg:"50%",xl:"50%"}} p={{base:"20px",sm:"20px",md:"20px",lg:"120px",xl:"120px"}}>
                            <Flex gap="10px" >
                                <Text fontSize='2xl'>RESIDENTIAL</Text>
                                <Text fontSize='2xl' color="rgb(255,218,69)">
                                    ADDRESS
                                </Text>
                            </Flex>
                            <form onSubmit={nextPage}>
                                <Input mt={"20px"}
                                    type="text"
                                    name="street_add"
                                    _placeholder={{ fontSize: "14px" }}
                                    placeholder="Enter Street Address"
                                    value={data.street_add}
                                    onChange={storeData}
                                    isRequired

                                />
                                <Input mt={"20px"}
                                    type="number"
                                    name="zip_code"
                                    _placeholder={{ fontSize: "14px" }}
                                    placeholder="Enter Postal/Zip Code"
                                    value={data.zip_code}
                                    onChange={storeData}
                                    isRequired

                                />
                                <Input mt={"20px"}
                                    type="text"
                                    name="city"
                                    _placeholder={{ fontSize: "14px" }}
                                    placeholder="Enter City/Town"
                                    value={data.city}
                                    onChange={storeData}
                                    isRequired

                                />
                                <Input mt={"20px"}
                                    type="text"
                                    name="state"
                                    _placeholder={{ fontSize: "14px" }}
                                    placeholder="Enter State/Province"
                                    value={data.state}
                                    onChange={storeData}
                                    isRequired

                                />

                                <Input mt={"20px"}
                                    type="text"
                                    name="country"
                                    _placeholder={{ fontSize: "14px" }}
                                    placeholder="Enter Country"
                                    value={data.country}
                                    onChange={storeData}
                                    isRequired

                                />

                                <Flex gap="10px" mt={"20px"}>
                                    <Text fontSize='2xl'>PERSONAL</Text>
                                    <Text fontSize='2xl' color="rgb(255,218,69)">INFORMATION</Text>
                                </Flex>
                                <Text mt={"20px"}>Date of birth</Text>
                                <Input mt={"10px"}
                                    type="date"
                                    name="dob"
                                    _placeholder={{ fontSize: "14px" }}
                                    defaultValue='Enter your Date of Birth'
                                    value={data.dob}
                                    onChange={storeData}
                                    isRequired

                                />
                                <Text mt={"20px"}>Mobile Number (Enter 10 Digit No)</Text>
                                <Input mt={"10px"}
                                    type="number"
                                    name="phone"
                                    maxLength={10}
                                    _placeholder={{ fontSize: "14px" }}
                                    placeholder="Enter Phone"
                                    value={data.phone}
                                    onChange={storeData}
                                    isRequired

                                />


                                <Flex
                                    flexDirection={{ base: "column", md: "row" }}
                                    gap={{ base: "10px", md: "20px" }}
                                >
                                    {
                                        next ? <Button
                                            size={{ base: "md", md: "lg" }}
                                            mt={{ base: "20px", md: "30px" }}
                                            // leftIcon={<ArrowForwardIcon />}
                                            bg="rgb(255,218,69)"
                                            variant="solid"
                                            color="black"
                                            type="submit"
                                            cursor={"pointer"}
                                        >
                                            Continue
                                        </Button> : <Button
                                            size={{ base: "md", md: "lg" }}
                                            mt={{ base: "20px", md: "30px" }}
                                            // leftIcon={<ArrowForwardIcon />}
                                            bg="rgb(255,218,69)"
                                            variant="solid"
                                            color="black"
                                            type="submit"
                                            cursor={"pointer"}
                                        > <Spinner />  </Button>
                                    }
                                    <Button
                                        mt={{ base: "20px", md: "30px" }}
                                        color="black"
                                        bg={"rgb(255,218,69)"}
                                        cursor={"pointer"}
                                        size={{ base: "md", md: "lg" }}
                                        onClick={() => setPage(page - 1)}
                                    >
                                        Back
                                    </Button>
                                </Flex>

                            </form>
                        </Box>
                        <Box width={"50%"} display={{base:"none",sm:"none",md:"none",lg:"block",xl:"block"}}>

                            <Image src={back} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                        </Box>
                    </Flex>
                </Box>
            </Box>
        </>
    )
}