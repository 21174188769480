import { AspectRatio, Box, Button, ButtonGroup, Center, Checkbox, Divider, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerOverlay, Flex, Grid, GridItem, Image, Input, InputGroup, InputRightElement, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverFooter, PopoverHeader, PopoverTrigger, Portal, Select, Text, useDisclosure, useToast } from "@chakra-ui/react";
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon

} from '@chakra-ui/react'
import CountUp from 'react-countup';
import { AddIcon, ArrowDownIcon, ArrowUpIcon, ChevronDownIcon, ChevronLeftIcon, DragHandleIcon, HamburgerIcon } from "@chakra-ui/icons"
import logo from "../images/logo.png";
import { useContext, useEffect, useRef, useState } from "react";
import { Resizable } from "react-resizable";
import React from "react"
import Edit from "../ModalPages/Edit";
import AccountSummary from "../ModalPages/AccountSummary";
import TakeProfit from "../ModalPages/TakeProfit";
import StopLoss from "../ModalPages/StopLoss";
import { Link, useNavigate } from "react-router-dom";
import { myContext } from "../context/Context";
import ClosedDealPage from "./ClosedDeal/ClosedDealPage";
import { color } from "framer-motion";
import Loading from "./Withdraw/Loading";
import { throttle } from "lodash";


function Home() {
    const [data, setData] = useState([]);
    const [highLowValues, setDataHighLow] = useState([])
    const [previousData, setPreviousData] = useState([]);
    const [previousData1, setPreviousData1] = useState([]);
    const [lot, setLotsize] = useState(0.01);
    let [data1, setData1] = useState({})
    const [selectedSide, setSelectedSide] = useState(null);
    let [title, setTitle] = useState()
    let [datachange, setDataChange] = useState([]);
    let [dollar1price, setDollarPrice] = useState();
    let [bidincinput, setinputbid] = useState();
    let [askincinput, setinputask] = useState();
    let [inputfixedvaluebid1, setInputfixedvaluebid1] = useState();
    let [inputfixedvaluebid2, setInputfixedvaluebid2] = useState();
    let [inputfixedvalueask1, setInputfixedvalueask1] = useState();
    let [inputfixedvalueask2, setInputfixedvalueask2] = useState();

    let [inputfixedvaluebid11, setInputfixedvaluebid11] = useState();
    let [inputfixedvaluebid21, setInputfixedvaluebid21] = useState();
    let [inputfixedvalueask11, setInputfixedvalueask11] = useState();
    let [inputfixedvalueask21, setInputfixedvalueask21] = useState();

    const [autoIncrement, setAutoIncrement] = useState(true);
    const [autoIncrement1, setAutoIncrement1] = useState(true);
    let [dollorPriceask1, setDollaraskPrice1] = useState();
    let [dollorPriceask2, setDollaraskPrice2] = useState();
    let [dollorPricebid1, setDollarbidPrice1] = useState();
    let [dollorPricebid2, setDollarbidPrice2] = useState();

    let [dollorPriceask11, setDollaraskPrice11] = useState();
    let [dollorPriceask21, setDollaraskPrice21] = useState();
    let [dollorPricebid11, setDollarbidPrice11] = useState();
    let [dollorPricebid21, setDollarbidPrice21] = useState();
    let [increaseDollarAc, setIncreaseDollar] = useState();
    let [incdependRate, setIncreaseDependRate] = useState();
    const [selectedTab, setSelectedTab] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [addStopLoss, setAddStopLoss] = useState(false);
    const [addTakeProfit, setAddTakeProfit] = useState(false);

    const [addStopLoss1, setAddStopLoss1] = useState(false);
    const [addTakeProfit1, setAddTakeProfit1] = useState(false);
    const [commidity, setCommidities] = useState([])

    let { pl, setpl, logout } = useContext(myContext)
    let [setpagecurr, setPageNext] = useState("currency")
    let [changecurr, setChangecurr] = useState()
    let [changecom, setChangecom] = useState()



    let result = JSON.parse(sessionStorage.getItem("key"))

    let navigate = useNavigate()
    let toast = useToast();


    let logOut = () => {

        logout();
        navigate("/login")
        toast({
            description: `Logged Out`,
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-center",
        })
        window.location.reload(true)

    }


    let handlechangeCurrency = (value) => {
        setPageNext(value)
    }


    //this is for opening modal
    let gotopage = (side, ele, currencyPair) => {
        setLoading(false)
        setSelectedSide(side);
        setData1(ele)
        setTitle(currencyPair)

        // setTitle(currencyPair)
        setAutoIncrement(true)
        onOpen();

    }


    //this is for title and to get instrumnet 
    let changeTitle = (e) => {
        setAddStopLoss(false)
        setAddTakeProfit(false)
        setTitle(e.target.value)
        setAutoIncrement(true)
    }


    //below fetch is for modal input fetch of currency
    const appId = "8158fea4816e49299cb864b1f5737ea7"; // Replace with your actual app ID
    // const appId = "MGPC4oFdXWEWFVhYWhaX"
    // const currencyPairs = "EURUSD,EURJPY,EURGBP,EURCHF,EURAUD,EURCAD,EURNZD,USDJPY,USDCHF,USDCAD,GBPJPY,GBPAUD,GBPCAD,GBPNZD,GBPUSD,CADJPY,CADJPY,NZDCAD,NZDUSD,AUDUSD,AUDJPY,XAGUSD,XAUUSD";
    const commidities = "XAUUSD,XAGUSD"
    let newincask;
    let newinbid;



    // Empty dependency array to ensure this runs only once during component mount


    let setPagechange = (e) => {
        setPageNext(e.target.value);
    }

    const [initialPrice, setInitialPrice] = useState(
        title === "CADJPY" || title === "GBPJPY" ||
            title === "EURUSD" || title === "GBPUSD" ||
            title === "AUDUSD" || title === "USDCHF" ||
            title === "USDCAD" || title === "NZDUSD" ||
            title === "EURGBP" || title === "EURCHF" ||
            title === "EURAUD" || title === "EURCAD" ||
            title === "EURNZD" || title === "GBPAUD" ||
            title === "GBPCAD" || title === "GBPNZD" ||
            title === "NZDCAD" || title === "EURJPY" || title === "XAGUSD" ||
            title === "AUDJPY" || title === "USDJPY" ? 2.17 : 35.00

    );

    const updateInitialPrice = (newPrice) => {
        setInitialPrice((prevPrice) => {
            if (newPrice !== prevPrice) {
                return newPrice;
            }
            return prevPrice;
        });
    };
    let changeCurrComm = 0;
    let [loading, setLoading] = useState(true);









    const fetchData = () => {
        const currencyPairs = [
            { base: "EUR", symbols: "USD" },
            { base: "EUR", symbols: "JPY" },
            { base: "EUR", symbols: "GBP" },
            { base: "EUR", symbols: "CHF" },
            { base: "EUR", symbols: "AUD" },
            { base: "EUR", symbols: "CAD" },
            { base: "EUR", symbols: "NZD" },
            { base: "USD", symbols: "JPY" },
            { base: "USD", symbols: "CHF" },
            { base: "USD", symbols: "CAD" },
            { base: "GBP", symbols: "JPY" },
            { base: "GBP", symbols: "AUD" },
            { base: "GBP", symbols: "CAD" },
            { base: "GBP", symbols: "NZD" },
            { base: "GBP", symbols: "USD" },
            { base: "CAD", symbols: "JPY" },
            { base: "NZD", symbols: "CAD" },
            { base: "NZD", symbols: "USD" },
            { base: "AUD", symbols: "USD" },
            { base: "AUD", symbols: "JPY" },
            { base: "XAG", symbols: "USD" },
            { base: "XAU", symbols: "USD" }
        ];

        currencyPairs.forEach(({ base, symbols }) => {
            fetch(`https://openexchangerates.org/api/latest.json?app_id=8158fea4816e49299cb864b1f5737ea7&show_bid_ask=1&base=${base}&symbols=${symbols}`)
                .then((res) => res.json())
                .then((res) => {
                    const responseData = res.rates || {};
                    const currencyPair = base + symbols;
                    const currentBid = responseData[symbols]?.bid;
                    const currentAsk = responseData[symbols]?.ask;

                    if (currentBid && currentAsk) {
                        setData((prevData) => {
                            const updatedData = { ...prevData };

                            if (updatedData[currencyPair]) {
                                const previousBid = updatedData[currencyPair].bid;
                                const previousAsk = updatedData[currencyPair].ask;

                                const bidColor = currentBid > previousBid ? 'green' : currentBid < previousBid ? 'red' : 'normal';
                                const askColor = currentAsk > previousAsk ? 'green' : currentAsk < previousAsk ? 'red' : 'normal';

                                updatedData[currencyPair] = {
                                    bid: currentBid,
                                    ask: currentAsk,
                                    bidColor: bidColor,
                                    askColor: askColor
                                };
                            } else {
                                updatedData[currencyPair] = {
                                    bid: currentBid,
                                    ask: currentAsk,
                                    bidColor: 'normal',
                                    askColor: 'normal'
                                };
                            }

                            return updatedData;
                        });
                    } else {
                        console.log(`No data received from API for ${currencyPair}`);
                    }
                })
                .catch((error) => {
                    console.log("Error fetching data:", error);
                    setAutoIncrement(false);
                    setLoading(true);
                });
        });
    };


















    let fetchCommodities = async () => {
        // Assuming data is the complete dataset

        const filteredCommodities = Object.entries(data)
            .filter(([currencyPair, dataArray]) => {
                // const currencyPair = dataArray.base_currency + dataArray.quote_currency;
                return currencyPair === 'XAUUSD' || currencyPair === 'XAGUSD';
            }).map(([currencyPair, dataArray]) => {
                return {

                    currencyPair: currencyPair,
                    bid: dataArray.bid,
                    ask: dataArray.ask,
                    bidColor: dataArray.bidColor,
                    askColor: dataArray.askColor
                    // Add other properties as needed
                };
            });

        setCommidities(filteredCommodities);

    }





    let fetchPrice = () => {
        // setLoading(false)
        if (title === "XAUUSD") {
            updateInitialPrice(35.00);
        }
        if (title === "CADJPY" || title === "GBPJPY" ||
            title === "EURUSD" || title === "GBPUSD" ||
            title === "AUDUSD" || title === "USDCHF" ||
            title === "USDCAD" || title === "NZDUSD" ||
            title === "EURGBP" || title === "EURCHF" ||
            title === "EURAUD" || title === "EURCAD" ||
            title === "EURNZD" || title === "GBPAUD" ||
            title === "GBPCAD" || title === "GBPNZD" ||
            title === "AUDCAD" || title === "NZDCAD" ||
            title === "EURJPY" || title === "XAGUSD" ||
            title === "USDJPY" || title === "AUDJPY") {
            updateInitialPrice(2.17);
        }


        {
            Object.entries(data).map(([currencyPair, dataArray], idx) => {

                const currentBid = dataArray.bid;
                const currentAsk = dataArray.ask;


                if (currencyPair === title) {
                    setinputbid(currentBid)
                    setinputask(currentAsk)

                    // change = parseFloat(change);
                    // setChange(((parseFloat(currentAsk) - parseFloat(currentBid)) * 1000).toFixed(2))

                    if (autoIncrement === true) {
                        console.log(autoIncrement)
                        newincask = (parseFloat(currentAsk)).toFixed(5)
                        setInputfixedvalueask1((newincask));
                        setInputfixedvalueask2((newincask));
                        newinbid = (parseFloat(currentBid)).toFixed(5)
                        setInputfixedvaluebid1(newinbid);
                        setInputfixedvaluebid2(newinbid);
                        setDollaraskPrice1(0);
                        setDollarbidPrice1(0);
                        setDollaraskPrice2(0);
                        setDollarbidPrice2(0);
                    }


                }
                if (title === "XAUUSD") {
                    updateInitialPrice(35.00);
                    setChangecurr(8.00)
                }
                if (title === "CADJPY" || title === "GBPJPY" ||
                    title === "EURUSD" || title === "GBPUSD" ||
                    title === "AUDUSD" || title === "USDCHF" ||
                    title === "USDCAD" || title === "NZDUSD" ||
                    title === "EURGBP" || title === "EURCHF" ||
                    title === "EURAUD" || title === "EURCAD" ||
                    title === "EURNZD" || title === "GBPAUD" ||
                    title === "GBPCAD" || title === "GBPNZD" ||
                    title === "AUDCAD" || title === "NZDCAD" ||
                    title === "EURJPY" || title === "XAGUSD" ||
                    title === "USDJPY" || title === "AUDJPY") {
                    updateInitialPrice(2.17);
                    setChangecurr(0.40)
                }
                if (title === "CADJPY" || title === "GBPJPY" ||
                    title === "EURUSD" || title === "GBPUSD" ||
                    title === "AUDUSD" || title === "USDCHF" ||
                    title === "USDCAD" || title === "NZDUSD" ||
                    title === "EURGBP" || title === "EURCHF" ||
                    title === "EURAUD" || title === "EURCAD" ||
                    title === "EURNZD" || title === "GBPAUD" ||
                    title === "GBPCAD" || title === "GBPNZD" ||
                    title === "AUDCAD" || title === "NZDCAD" ||
                    title === "EURJPY" || title === "XAGUSD" ||
                    title === "USDJPY") {
                    setChangecurr(0.40);
                }
                if (title === "EURUSD" ||
                    title === "GBPUSD" ||
                    title === "AUDUSD" ||
                    title === "USDCHF" ||
                    title === "USDCAD" ||
                    title === "NZDUSD" ||
                    title === "EURGBP" ||
                    title === "EURCHF" || title === "EURAUD" || title === "EURCAD" || title === "EURNZD" || title === "GBPAUD" || title === "GBPCAD" || title === "GBPNZD" || title === "AUDCAD" || title === "NZDCAD") {
                    setIncreaseDependRate("0.0001")
                }
                if (title === "USDJPY") {
                    setIncreaseDependRate("0.01")
                }
                if (title === "EURJPY" || title === "XAUUSD" || title === "XAGUSD") {
                    setIncreaseDependRate("0.01")
                }

                if (title === "GBPJPY") {
                    setIncreaseDependRate("0.01")
                }

                if (title === "AUDJPY") {
                    setIncreaseDependRate("0.01")
                }
                if (title === "CADJPY") {
                    setIncreaseDependRate("0.01")
                }


            })
            setLoading(true);
        }






    };
    // Empty dependency array to ensure this runs only once during component mount

    //this is for color chnaging in our main page
    const getChangeColor = (current, previous) => {
        if (current > previous) return 'rgb(34,176,73)';
        if (current < previous) return 'red';
        return 'normal';
    };





    let [disbaledme, setDisabledme] = useState(false);
    let [disbaledme1, setDisabledme1] = useState(false);


    let [disbaledme11, setDisabledme11] = useState(false);
    let [disbaledme21, setDisabledme21] = useState(false);


    const handleClick = (tab) => {
        setSelectedTab(tab === selectedTab ? null : tab);
    };


    //this is for lotsize,pip and price
    const initialLotSize = 0.01;
    const initialPip = 0.10;

    const [lotSize, setLotSize] = useState(initialLotSize);
    const [pip, setPip] = useState(initialPip);

    const [price, setPrice] = useState(initialPrice);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);


    let storeLotsize = (e) => {
        let newLotSize = Number(e.target.value);

        // Handle cases when the entered value is not a valid number
        // if (isNaN(newLotSize)) {
        //     // Handle invalid input
        //     return;
        // }

        const allowedLotSizes = [0.01, 0.02, 0.03, 0.04, 0.05, 0.06, 0.07, 0.08, 0.09, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 2, 3, 4, 5];
        const roundedValue = allowedLotSizes.reduce((prev, curr) => {
            return Math.abs(curr - newLotSize) < Math.abs(prev - newLotSize) ? curr : prev;
        });

        // Update the input field value with the rounded value
        e.target.value = roundedValue;

        // Handle cases when the entered value is not a valid number
        setLotSize(roundedValue);

        if (title === "XAUUSD") {
            setInitialPrice(35.00);

            // Handle cases when the entered value is not a valid number
            const newPip = Math.min(initialPip * roundedValue * 100, 50);
            setPip(newPip);

            // Calculate price and ensure it's above 2170
            let newPr = Math.max(initialPrice * roundedValue * 100, 35.00);
            setPrice(newPr.toFixed(2));

            const newPi = Math.max(initialPip * roundedValue * 100, 0.10);
            setPip(newPi);
        } else {
            setInitialPrice(2.17);

            // Handle cases when the entered value is not a valid number
            const newPip = Math.min(initialPip * roundedValue * 100, 50);
            setPip(newPip);

            // Calculate price and ensure it's above 2170
            let newPr = Math.max(initialPrice * roundedValue * 100, 2.17);
            setPrice(newPr.toFixed(2));

            const newPi = Math.max(initialPip * roundedValue * 100, 0.10);
            setPip(newPi);
        }
    };




    // let storeLotsize = (e) => {
    //     let newLotSize = Number(e.target.value);

    //     // Handle cases when the entered value is not a valid number
    //     if (isNaN(newLotSize)) {
    //         // Handle invalid input
    //         return;
    //     }

    //     const allowedLotSizes = [0.01, 0.02, 0.03, 0.04, 0.05, 0.06, 0.07, 0.08, 0.09, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 2, 3, 4, 5];
    //     const roundedValue = allowedLotSizes.reduce((prev, curr) => {
    //         return Math.abs(curr - newLotSize) < Math.abs(prev - newLotSize) ? curr : prev;
    //     });

    //     // Update the input field value with the rounded value
    //     e.target.value = roundedValue;

    //     // Handle logic based on title
    //     if (title === "XAUUSD") {
    //         setInitialPrice(35.00);
    //     } else {
    //         setInitialPrice(2.17);
    //     }

    //     // Handle cases when the entered value is not a valid number
    //     setLotSize(roundedValue);
    //     const newPip = Math.min(initialPip * roundedValue * 100, 50);
    //     setPip(newPip);

    //     // Calculate price and ensure it's above 35.00 for XAUUSD and 2.17 for others
    //     let newPr = Math.max(initialPrice * roundedValue * 100, title === "XAUUSD" ? 35.00 : 2.17);
    //     setPrice(newPr.toFixed(2));

    //     const newPi = Math.max(initialPip * roundedValue * 100, 0.10);
    //     setPip(newPi);
    // };

    const handleIncreaseLotSize = () => {

        if (lotSize < 0.1) {
            setLotSize((prevLotSize) => Number((prevLotSize + 0.01).toFixed(2)));
            setPip((prevPip) => prevPip + 0.10);
            setPrice((parseFloat(initialPrice) * (lotSize + 0.01) * 100).toFixed(2));
            // setPrice((initialPrice * (lotSize + 0.01) * 100).toFixed(2));
        } else if (lotSize < 1) {
            setLotSize((prevLotSize) => Number((prevLotSize + 0.1).toFixed(1)));
            setPip((prevPip) => prevPip + 1.0);
            setPrice((parseFloat(initialPrice) * (lotSize + 0.1) * 100).toFixed(2));
            // setPrice((initialPrice * (lotSize + 0.1) * 100).toFixed(2));
        } else if (lotSize < 5) {
            setLotSize((prevLotSize) => Number((prevLotSize + 1).toFixed(0)));
            setPip((prevPip) => prevPip + 10);
            setPrice((parseFloat(initialPrice) * (lotSize + 1) * 100).toFixed(2));
            // setPrice((initialPrice * (lotSize + 1) * 100).toFixed(2));
        }

        if (lotSize === 5) {
            setIsButtonDisabled(true);
        }

    }


    const handleDecreaseLotSize = () => {

        // initialPrice = 2.17

        // 17500
        if (lotSize > 0.01) {
            if (lotSize <= 0.1) {
                setLotSize((prevLotSize) => Number((prevLotSize - 0.01).toFixed(2)));
                setPip((prevPip) => Math.max(prevPip - 0.10, 0));
                setPrice((parseFloat(initialPrice) * (lotSize - 0.01) * 100).toFixed(2));
            } else if (lotSize <= 1) {
                setLotSize((prevLotSize) => Number((prevLotSize - 0.1).toFixed(1)));
                setPip((prevPip) => Math.max(prevPip - 1.0, 0));
                setPrice((parseFloat(initialPrice) * (lotSize - 0.1) * 100).toFixed(2));
            } else if (lotSize <= 5) {
                setLotSize((prevLotSize) => Number((prevLotSize - 1).toFixed(0)));
                setPip((prevPip) => Math.max(prevPip - 10, 0));
                setPrice((parseFloat(initialPrice) * (lotSize - 1) * 100).toFixed(2));
            }

            if (isButtonDisabled && lotSize <= 5) {
                setIsButtonDisabled(false);
            }
        }
    };






    let chnageaddlossprofit = () => {
        setAutoIncrement(true)
        setAddStopLoss(false)
        setAddTakeProfit(false)
        setPrice(initialPrice)
        setLotSize(0.01);
        setTitle("")
        setinputbid("")
        setinputask("")
    }





    //this is to chnage the below and above in modal
    let [above1ask, setAbove1ask] = useState("Below")
    let [above2ask, setAbove2ask] = useState("Above")
    // Update onClick handlers to set the selected data
    const handleBuyClick = () => {
        if (selectedSide === "Buy") {
            setLoading(false)
            setAbove1ask("Below")
            setAbove2ask("Above")
            setSelectedSide("Sell");
            setDisabledme(false)
        } else {
            setLoading(false)
            setAbove1ask("Below")
            setAbove2ask("Above")
            setSelectedSide("Buy");
            setDisabledme(false)

        }
    };

    const handleSellClick = () => {

        if (selectedSide === "Sell") {
            setLoading(false)
            setAbove1ask("Below")
            setAbove2ask("Above")
            setSelectedSide("Buy");
            setDisabledme(false)
        } else {
            setLoading(false)
            setAbove1ask("Below")
            setAbove2ask("Above")
            setSelectedSide("Sell");
            setDisabledme(false)
        }

    };


    //this is for the checkbox to not allow the value when modal is opened and user has not selected the checkbox


    let onchnageloss = () => {
        if (addStopLoss === false) {
            setAutoIncrement(false)
            setAddStopLoss(true)

        } else {
            setAddStopLoss(false)
            setAutoIncrement(true)
        }
    }

    let setaddprofit = () => {
        if (addTakeProfit === false) {
            setAutoIncrement(false)
            setAddTakeProfit(true)
        } else {
            setAddTakeProfit(false)
            setAutoIncrement(true)
        }
    }

    let enterNewAskprofit = (e) => {
        const inputValue = e.target.value;
        setAutoIncrement(false);
        // Only update state if the input is a valid number or empty

        if (inputValue === "" || !isNaN(parseFloat(inputValue)) && (!autoIncrement && selectedSide === "Buy" && above2ask === "Above")) {
            setInputfixedvaluebid2(inputValue);
        }
        if (!autoIncrement && selectedSide === "Sell" && above1ask === "Below" && inputValue === "" || !isNaN(parseFloat(inputValue))) {
            setDisabledme(false)

            setInputfixedvalueask2(inputValue);
        }

        if (selectedSide === "Buy" && above2ask === "Above" && inputValue === "" || !isNaN(parseFloat(inputValue))) {
            setDisabledme1(false)

            setInputfixedvaluebid2(inputValue);
        }

        if (!autoIncrement && inputfixedvalueask2 < bidincinput && selectedSide === "Sell" && above1ask === "Below" && inputValue === "" || !isNaN(parseFloat(inputValue))) {
            setDisabledme1(false)

            setInputfixedvalueask2(inputValue);
        }
        if (inputfixedvalueask2 > bidincinput && selectedSide === "Sell" && above1ask === "Below" && inputfixedvalueask2 !== null && !autoIncrement) {
            toast({
                description: `Invalid Value,Enter below value`,
                status: `warning`,
                duration: 5000,
                isClosable: true,
                position: "top-center",
            });
        }
        if (inputfixedvaluebid2 < askincinput && selectedSide === "Buy" && above2ask === "Above" && inputfixedvaluebid2 !== null && !autoIncrement) {
            toast({
                description: `Invalid Value,Enter above value`,
                status: `warning`,
                duration: 5000,
                isClosable: true,
                position: "top-center",
            });
        }


    }

    let decinputask = () => {
        setAutoIncrement(false)
        if (autoIncrement === false) {
            if (selectedSide === "Buy" && above2ask === "Above") {
                const newAskValue = (parseFloat(inputfixedvalueask2) - parseFloat(incdependRate)).toFixed(5);
                setInputfixedvalueask2(newAskValue);
            }



            if (selectedSide === "Sell" && above1ask === "Below") {
                setDisabledme(false)
                const newAskValue = (parseFloat(inputfixedvaluebid2) - parseFloat(incdependRate)).toFixed(5);
                setInputfixedvaluebid2(newAskValue);
            }
        }
    }




    let incinputask = () => {
        setAutoIncrement(false);
        if (autoIncrement === false) {

            if (selectedSide === "Buy" && above2ask === "Above") {
                setDisabledme1(false)
                const newAskValue = (parseFloat(inputfixedvalueask2) + parseFloat(incdependRate)).toFixed(5);
                setInputfixedvalueask2(newAskValue);
            }



            if (inputfixedvaluebid2 === bidincinput && selectedSide === "Sell" && above2ask === "Below") {
                setDisabledme(true)
            }
            if (inputfixedvaluebid2 < bidincinput && selectedSide === "Sell" && above1ask === "Below") {
                setDisabledme1(false)
                const newAskValue = Math.max((parseFloat(inputfixedvaluebid2) + parseFloat(incdependRate)).toFixed(5))
                setInputfixedvaluebid2(newAskValue);
            }
        }
    }

    let dollarPriceinput = (e) => {
        setAutoIncrement(false)
        const newDollarPrice = (e.target.value);

        if (selectedSide === "Buy" && above2ask === "Above") {
            if (!isNaN(parseInt(newDollarPrice)) || newDollarPrice != 0) {
                setDollarbidPrice2(newDollarPrice);

                // Calculate and update inputfixedvalueask based on the newDollarPrice
                const newAskValue = (parseFloat(inputfixedvalueask2) + newDollarPrice * parseFloat(incdependRate)).toFixed(5);
                setInputfixedvalueask2(newAskValue);
            } else if (newDollarPrice === 0 || newDollarPrice === "") {
                setInputfixedvalueask2(askincinput);
                setAutoIncrement(true)
            }
        }

        if (selectedSide === "Sell" && above1ask === "Below") {
            if (!isNaN(parseInt(newDollarPrice)) || newDollarPrice != 0) {
                setDollaraskPrice2(newDollarPrice);

                // Calculate and update inputfixedvalueask based on the newDollarPrice
                const newAskValue = (parseFloat(inputfixedvaluebid2) - newDollarPrice * parseFloat(incdependRate)).toFixed(5);
                setInputfixedvaluebid2(newAskValue);
            } else if (newDollarPrice === 0 || newDollarPrice === "") {
                setInputfixedvaluebid2(bidincinput)
                setAutoIncrement(true)
            }
        }

    }
    let [bal, setbal] = useState();
    let [eq, setEq] = useState(0);
    let [margin, setMargin] = useState(0);
    let [freeMargin, setFreeMargin] = useState(0);
    let [exploser, setNetexploser] = useState()
    let [neteq, setneteq] = useState(0)

    let fetchtotalBal = async () => {
        try {
            let response = await fetch(`https://trading-jz57.onrender.com/register/${result.type.AcNumber}`)
            let res = await response.json();
            setbal(res.totalbalance)
            setEq(res.neteq + parseFloat(totalNetProfit))
            setneteq(res.neteq)
            setNetexploser(res.neteq * 500)
        } catch (error) {
            console.log(error)
        }
    }
    let dealPrice = async () => {
        try {
            let response = await fetch(`https://trading-jz57.onrender.com/makeadeal/${result.type.AcNumber}/dealprice`)
            let res = await response.json();
            setMargin(res.total_price)
            localStorage.setItem("margin", JSON.stringify(res.total_price))
        } catch (error) {
            console.log(error)
        }
    }




    const closeAllDeals = () => {
        // Logic to close all deals goes here
        variousdeal.forEach((elem) => {
            closedDealsucess(elem);
        });
    };


    let handleaddDollar = () => {
        setAutoIncrement(false)
        if (selectedSide === "Buy" && above2ask === "Above") {
            setDollarbidPrice2((prevDollarPrice) => Number(prevDollarPrice + 1));

            // Calculate and update inputfixedvalueask based on the increased dollaraskPrice
            const newAskValue = (parseFloat(inputfixedvalueask2) + parseFloat(incdependRate)).toFixed(5);
            setInputfixedvalueask2(newAskValue);
        }


        if (selectedSide === "Sell" && above1ask === "Below") {
            setDollaraskPrice2((prevDollarPrice) => Number(prevDollarPrice + 1));

            // Calculate and update inputfixedvalueask based on the increased dollaraskPrice
            const newAskValue = (parseFloat(inputfixedvaluebid2) - parseFloat(incdependRate)).toFixed(5);
            setInputfixedvaluebid2(newAskValue);
        }
    }



    let handleSubDollar = () => {
        if (selectedSide === "Buy" && above2ask === "Above") {
            setDollarbidPrice2((prevDollarPrice) => Number(prevDollarPrice - 1));

            // Calculate and update inputfixedvalueask based on the increased dollaraskPrice
            const newAskValue = (parseFloat(inputfixedvalueask2) - parseFloat(incdependRate)).toFixed(5);
            setInputfixedvalueask2(newAskValue);
        }

        if (selectedSide === "Sell" && above1ask === "Below") {
            setDollaraskPrice2((prevDollarPrice) => Number(prevDollarPrice - 1));

            // Calculate and update inputfixedvalueask based on the increased dollaraskPrice
            const newAskValue = (parseFloat(inputfixedvaluebid2) + parseFloat(incdependRate)).toFixed(5);
            setInputfixedvaluebid2(newAskValue);
        }
    }



    let enterNewRateloss = (e) => {
        const inputValue = e.target.value;
        setAutoIncrement(false);
        console.log("hi", inputfixedvalueask1, askincinput)

        // Only update state if the input is a valid number or empty
        if (!autoIncrement && selectedSide === "Buy" && above1ask === "Below" && inputValue === "" || !isNaN(parseFloat(inputValue))) {
            setDisabledme(false)
            // const newAskValue = (parseFloat(inputfixedvaluebid1) - 0.0001).toFixed(5);
            setInputfixedvaluebid1(inputValue);
        }
        if (!autoIncrement && selectedSide === "Sell" && above2ask === "Above" && inputValue === "" || !isNaN(parseFloat(inputValue))) {
            setDisabledme1(false)
            // const inputValue = (parseFloat(inputfixedvalueask1) - 0.0001).toFixed(5);
            setInputfixedvalueask1(inputValue);
        }

        if (!autoIncrement && inputfixedvaluebid1 < askincinput && selectedSide === "Buy" && above1ask === "Above" && inputValue === "" || !isNaN(parseFloat(inputValue))) {
            setDisabledme(false)
            // const inputValue = (parseFloat(inputfixedvaluebid1) + 0.0001).toFixed(5);
            setInputfixedvaluebid1(inputValue);
        }
        if (!autoIncrement && inputfixedvaluebid1 < askincinput && selectedSide === "Buy" && above1ask === "Below" && inputValue === "" || !isNaN(parseFloat(inputValue))) {
            setDisabledme(false)
            // const inputValue = (parseFloat(inputfixedvaluebid1) + 0.0001).toFixed(5);
            setInputfixedvaluebid1(inputValue);
        }
        if (!autoIncrement && selectedSide === "Sell" && above2ask === "Above" && inputValue === "" || !isNaN(parseFloat(inputValue))) {
            setDisabledme1(false)
            // const inputValue = (parseFloat(inputfixedvalueask1) + 0.0001).toFixed(5);
            setInputfixedvalueask1(inputValue);
        }
        if (inputfixedvalueask1 > askincinput && selectedSide === "Buy" && above1ask === "Below" && inputfixedvalueask1 !== null && !autoIncrement) {
            toast({
                description: `Invalid Value, Enter below value`,
                status: `warning`,
                duration: 5000,
                isClosable: true,
                position: "top-center",
            });
        }
        if (inputfixedvaluebid1 < bidincinput && selectedSide === "Sell" && above2ask === "Above" && inputfixedvaluebid1 !== null && !autoIncrement) {
            toast({
                description: `Invalid Value, Enter above value`,
                status: `warning`,
                duration: 5000,
                isClosable: true,
                position: "top-center",
            });
        }

    }
    let Decbidvalue = () => {
        setAutoIncrement(false);
        if (autoIncrement === false) {
            if (selectedSide === "Buy" && above1ask === "Below") {
                setDisabledme(false)
                const newAskValue = (parseFloat(inputfixedvalueask1) - parseFloat(incdependRate)).toFixed(5);
                setInputfixedvalueask1(newAskValue);
            }

            if (selectedSide === "Sell" && above2ask === "Above") {
                setDisabledme1(false)
                const newAskValue = (parseFloat(inputfixedvaluebid1) - parseFloat(incdependRate)).toFixed(5);
                setInputfixedvaluebid1(newAskValue);
            }
        }
    }


    let Incbidvalue = () => {
        setAutoIncrement(false);
        if (autoIncrement === false) {
            if (inputfixedvaluebid1 === askincinput && selectedSide === "Buy" && above1ask === "Below") {
                setDisabledme(true)
            }
            if (inputfixedvalueask1 < askincinput && selectedSide === "Buy" && above1ask === "Above") {
                setDisabledme(false)
                const newAskValue = (parseFloat(inputfixedvalueask1) + parseFloat(incdependRate)).toFixed(5);
                setInputfixedvalueask1(newAskValue);
            }
            if (inputfixedvalueask1 < askincinput && selectedSide === "Buy" && above1ask === "Below") {
                setDisabledme(false)
                const newAskValue = (parseFloat(inputfixedvalueask1) + parseFloat(incdependRate)).toFixed(5);
                setInputfixedvalueask1(newAskValue);
            }
            if (selectedSide === "Sell" && above2ask === "Above") {
                setDisabledme1(false)
                const newAskValue = (parseFloat(inputfixedvaluebid1) + parseFloat(incdependRate)).toFixed(5);
                if (newAskValue > bal || newAskValue == bal) {
                    toast({
                        title: 'Add stoploss less than your balance',
                        // description: `Check Close deal`,
                        status: 'error',
                        duration: 8000,
                        isClosable: true,
                        position: "bottom-left"
                    })
                }
                setInputfixedvaluebid1(newAskValue);
            }
        }

    }

    const handleaddDollar1 = () => {
        if (selectedSide === "Buy" && above1ask === "Below") {
            setDollarbidPrice1((prevDollarPrice) => Number(prevDollarPrice) + 1);

            // Calculate and update inputfixedvalueask based on the increased dollarbidPrice
            const newAskValue = (parseFloat(inputfixedvalueask1) - parseFloat(incdependRate)).toFixed(5);
            setInputfixedvalueask1(newAskValue);
        }
        if (selectedSide === "Sell" && above2ask === "Above") {
            setDollaraskPrice1((prevDollarPrice) => Number(prevDollarPrice) + 1);

            // Calculate and update inputfixedvaluebid based on the increased dollaraskPrice
            const newBidValue = (parseFloat(inputfixedvaluebid1) + parseFloat(incdependRate)).toFixed(5);
            setInputfixedvaluebid1(newBidValue);
        }
    };


    const Inputdollar = (e) => {
        setAutoIncrement(false);
        const newDollarPrice = e.target.value;

        if (selectedSide === "Buy" && above1ask === "Below") {
            if (!isNaN(parseInt(newDollarPrice)) || newDollarPrice != 0) {
                setDollarbidPrice1(newDollarPrice);

                // Calculate and update inputfixedvalueask based on the newDollarPrice
                const newAskValue = (parseFloat(inputfixedvalueask1) - newDollarPrice * parseFloat(incdependRate)).toFixed(5);
                setInputfixedvalueask1(newAskValue);
            } else if (newDollarPrice === 0 || newDollarPrice === "") {
                setInputfixedvalueask1(askincinput);
                setAutoIncrement(true);
            }
        }
        if (selectedSide === "Sell" && above2ask === "Above") {
            if (!isNaN(parseInt(newDollarPrice)) || newDollarPrice != 0) {
                setDollaraskPrice1(newDollarPrice);

                // Calculate and update inputfixedvalueask based on the newDollarPrice
                const newAskValue = (parseFloat(inputfixedvaluebid1) + newDollarPrice * parseFloat(incdependRate)).toFixed(5);
                setInputfixedvaluebid1(newAskValue);
            } else if (newDollarPrice === 0 || newDollarPrice === "") {
                setInputfixedvaluebid1(bidincinput);
                setAutoIncrement(true);
            }
        }
    }


    let handleSubDollar1 = () => {
        if (selectedSide === "Buy" && above1ask === "Below") {
            setDollarbidPrice1((prevDollarPrice) => Number(prevDollarPrice - 1));

            // Calculate and update inputfixedvalueask based on the increased dollaraskPrice
            const newAskValue = (parseFloat(inputfixedvalueask1) + parseFloat(incdependRate)).toFixed(5);
            setInputfixedvalueask1(newAskValue);
        }
        if (selectedSide === "Sell" && above2ask === "Above") {
            setDollaraskPrice1((prevDollarPrice) => Number(prevDollarPrice - 1));

            // Calculate and update inputfixedvalueask based on the increased dollaraskPrice
            const newAskValue = (parseFloat(inputfixedvaluebid1) - parseFloat(incdependRate)).toFixed(5);
            setInputfixedvaluebid1(newAskValue);
        }
    }






    let [dealprice, setPricedeal] = useState()
    let fetchdeal = async () => {
        // if(stoploss==bal||stoploss>bal){

        // }
        let obj = {
            dealer_id: result.type.AcNumber,
            order_type: selectedSide,
            title: title,
            lotsize: lotSize,
            price: price,
            pip: pip,
            changecurr: parseFloat(changecurr),
            bidorask: selectedSide === "Buy" ? askincinput : bidincinput,
        };

        if (addStopLoss) {
            if (obj.title === "XAUUSD" ||
                obj.title === "USDJPY" ||
                obj.title === "EURJPY" ||
                obj.title === "GBPJPY" ||
                obj.title === "AUDJPY" ||
                obj.title === "CADJPY") {
                obj.stoploss = selectedSide === "Buy" ? Number(inputfixedvalueask1).toFixed(3) : Number(inputfixedvaluebid1).toFixed(3);
            } else {
                obj.stoploss = selectedSide === "Buy" ? inputfixedvalueask1 : inputfixedvaluebid1;
            }
        } else {
            obj.stoploss = ""; // or set it to null or any other value based on your requirement
        }

        // Check if addTakeProfit is true before adding takeprofit to obj
        if (addTakeProfit) {
            if (obj.title === "XAUUSD" ||
                obj.title === "USDJPY" ||
                obj.title === "EURJPY" ||
                obj.title === "GBPJPY" ||
                obj.title === "AUDJPY" ||
                obj.title === "CADJPY") {
                obj.takeprofit = selectedSide === "Buy" ? Number(inputfixedvalueask2).toFixed(3) : Number(inputfixedvaluebid2).toFixed(3);
            } else {
                obj.takeprofit = selectedSide === "Buy" ? inputfixedvalueask2 : inputfixedvaluebid2
            }
        } else {
            obj.takeprofit = ""; // or set it to null or any other value based on your requirement
        }
        if (obj.stoploss >= bal) {
            toast({
                title: 'Stop Loss cannot be greater than Balance',
                // description: `Check Close deal`,
                status: 'error',
                duration: 2000,
                isClosable: true,
                position: "bottom-left"
            })
        } else {
            try {
                let response = await fetch(`https://trading-jz57.onrender.com/makeadeal`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(obj)
                })
                if (response.ok) {
                    let res = await response.json()
                    setPricedeal(res.deal.price)
                    openModal4();
                }
            } catch (error) {
                console.log(error)
            }
        }
    }

    const [variousdeal, setVariousDeal] = useState([])
    let fetchVaroiusdeal = async () => {
        try {
            let response = await fetch(`https://trading-jz57.onrender.com/makeadeal/${result.type.AcNumber}`)
            let res = await response.json();
            setVariousDeal(res.reverse());

        } catch (error) {
            console.log(error);
        }
    }






    const checkClosedDeals = () => {
        variousdeal.forEach((elem) => {
            closedDealsucess(elem);
        });
    };

    // useEffect(()=>{

    // },[variousdeal])


    let totalFirstNonNullValue = 0;
    let [totalpl, setTotalpl] = useState(0)
    let onclickClosedDeal = (elem) => {
        let { title, order_type, order_id, bidorask, takeprofit, stoploss, lotsize, price, changecurr } = elem

        const relevantValue = Object.entries(data).map(([currencyPair, ele], idx) => {
            // const ele = dataArray && dataArray.length > 0 ? dataArray[0] : {};
            const baseCurrency = currencyPair.substring(0, 3);
            const quoteCurrency = currencyPair.substring(3, 6);
            const formattedCurrencyPair = `${baseCurrency}/${quoteCurrency}`;

            if (title === "XAU/USD") {
                if (title === formattedCurrencyPair) {

                    // {console.log(ele.bid)}
                    if (lotsize === "0.01") {
                        return order_type === "Buy" ? ((parseFloat(ele.bid - bidorask)) - parseFloat(8)).toFixed(2) : ((parseFloat(bidorask - ele.ask)) - parseFloat(8)).toFixed(2);
                    } else if (lotsize === "0.02") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(16)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(16)).toFixed(2);
                    } else if (lotsize === "0.03") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(24)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(24)).toFixed(2);
                    }
                    else if (lotsize === "0.04") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(32)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(32)).toFixed(2);
                    }
                    else if (lotsize === "0.05") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(40)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(40)).toFixed(2);
                    }
                    else if (lotsize === "0.06") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(48)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(48)).toFixed(2);
                    }
                    else if (lotsize === "0.07") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(56)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(56)).toFixed(2);
                    }
                    else if (lotsize === "0.08") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(64)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(64)).toFixed(2);
                    }
                    else if (lotsize === "0.09") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(72)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(72)).toFixed(2);
                    }
                    else if (lotsize === "0.1") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(80)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(80)).toFixed(2);
                    }
                    else if (lotsize === "0.2") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(160)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(160)).toFixed(2);
                    }
                    else if (lotsize === "0.3") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(240)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(240)).toFixed(2);
                    }
                    else if (lotsize === "0.4") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(320)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(320)).toFixed(2);
                    }
                    else if (lotsize === "0.5") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(400)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(400)).toFixed(2);
                    }

                    else if (lotsize === "0.6") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(480)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(480)).toFixed(2);
                    }
                    else if (lotsize === "0.7") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(560)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(560)).toFixed(2);
                    }
                    else if (lotsize === "0.8") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(640)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(640)).toFixed(2);
                    }
                    else if (lotsize === "0.9") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(720)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(720)).toFixed(2);
                    }
                    else if (lotsize === "1") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(800)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(800)).toFixed(2);
                    }
                    else if (lotsize === "2") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(1600)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(1600)).toFixed(2);
                    }
                    else if (lotsize === "3") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(2400)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(2400)).toFixed(2);
                    }
                    else if (lotsize === "4") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(3200)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(3200)).toFixed(2);
                    }
                    else if (lotsize === "5") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(4000)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(4000)).toFixed(2);
                    }
                }
                return null;
            }
            else if (title === "XAG/USD") {
                if (title === formattedCurrencyPair) {


                    if (lotsize === "0.01") {
                        return order_type === "Buy" ? ((parseFloat(ele.bid - bidorask)) - parseFloat(2)).toFixed(2) : ((parseFloat(bidorask - ele.ask)) - parseFloat(2)).toFixed(2);
                    } else if (lotsize === "0.02") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(4)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(4)).toFixed(2);
                    } else if (lotsize === "0.03") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(6)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(6)).toFixed(2);
                    }
                    else if (lotsize === "0.04") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(8)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(8)).toFixed(2);
                    }
                    else if (lotsize === "0.05") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(10)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(10)).toFixed(2);
                    }
                    else if (lotsize === "0.06") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(12)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(12)).toFixed(2);
                    }
                    else if (lotsize === "0.07") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(14)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(14)).toFixed(2);
                    }
                    else if (lotsize === "0.08") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(16)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(16)).toFixed(2);
                    }
                    else if (lotsize === "0.09") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(18)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(18)).toFixed(2);
                    }
                    else if (lotsize === "0.1") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(20)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(20)).toFixed(2);
                    }
                    else if (lotsize === "0.2") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(40)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(40)).toFixed(2);
                    }
                    else if (lotsize === "0.3") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(60)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(60)).toFixed(2);
                    }
                    else if (lotsize === "0.4") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(80)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(80)).toFixed(2);
                    }
                    else if (lotsize === "0.5") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(100)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(100)).toFixed(2);
                    }

                    else if (lotsize === "0.6") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(120)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(120)).toFixed(2);
                    }
                    else if (lotsize === "0.7") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(140)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(140)).toFixed(2);
                    }
                    else if (lotsize === "0.8") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(160)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(160)).toFixed(2);
                    }
                    else if (lotsize === "0.9") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(180)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(180)).toFixed(2);
                    }
                    else if (lotsize === "1") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(200)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(200)).toFixed(2);
                    }
                    else if (lotsize === "2") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(400)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(400)).toFixed(2);
                    }
                    else if (lotsize === "3") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(600)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(600)).toFixed(2);
                    }
                    else if (lotsize === "4") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(800)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(800)).toFixed(2);
                    }
                    else if (lotsize === "5") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(1000)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(1000)).toFixed(2);
                    }
                }
                return null;
            }
            else if (title === "NZD/CAD" || title === "EUR/CHF" || title === "EUR/GBP" || title === "NZD/USD" || title === "USD/CHF" || title === "AUD/USD") {
                if (title === formattedCurrencyPair) {


                    if (lotsize === "0.01") {
                        return order_type === "Buy" ? ((parseFloat(ele.bid - bidorask) * 1000) - parseFloat(0.3)).toFixed(2) : ((parseFloat(bidorask - ele.ask) * 1000) - parseFloat(0.3)).toFixed(2);
                    } else if (lotsize === "0.02") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(0.6)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(0.6)).toFixed(2);
                    } else if (lotsize === "0.03") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(0.9)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(0.9)).toFixed(2);
                    }
                    else if (lotsize === "0.04") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(1.2)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(1.2)).toFixed(2);
                    }
                    else if (lotsize === "0.05") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(1.5)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(1.5)).toFixed(2);
                    }
                    else if (lotsize === "0.06") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(1.8)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(1.8)).toFixed(2);
                    }
                    else if (lotsize === "0.07") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(2.1)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(2.1)).toFixed(2);
                    }
                    else if (lotsize === "0.08") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(2.4)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(2.4)).toFixed(2);
                    }
                    else if (lotsize === "0.09") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(2.7)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(2.7)).toFixed(2);
                    }
                    else if (lotsize === "0.1") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(3)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(3)).toFixed(2);
                    }
                    else if (lotsize === "0.2") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(6)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(6)).toFixed(2);
                    }
                    else if (lotsize === "0.3") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(9)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(9)).toFixed(2);
                    }
                    else if (lotsize === "0.4") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(12)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(12)).toFixed(2);
                    }
                    else if (lotsize === "0.5") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(15)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(15)).toFixed(2);
                    }

                    else if (lotsize === "0.6") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(18)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(18)).toFixed(2);
                    }
                    else if (lotsize === "0.7") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(21)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(21)).toFixed(2);
                    }
                    else if (lotsize === "0.8") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(24)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(24)).toFixed(2);
                    }
                    else if (lotsize === "0.9") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(27)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(27)).toFixed(2);
                    }
                    else if (lotsize === "1") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(30)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(30)).toFixed(2);
                    }
                    else if (lotsize === "2") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(60)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(60)).toFixed(2);
                    }
                    else if (lotsize === "3") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(90)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(90)).toFixed(2);
                    }
                    else if (lotsize === "4") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(120)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(120)).toFixed(2);
                    }
                    else if (lotsize === "5") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(150)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(150)).toFixed(2);
                    }
                }
                return null;
            }
            else if (title === "USD/JPY" || title === "EUR/JPY" || title === "GBP/JPY" || title === "AUD/JPY" || title === "CAD/JPY") {
                if (title === formattedCurrencyPair) {


                    if (lotsize === "0.01") {
                        return order_type === "Buy" ? ((parseFloat(ele.bid - bidorask)) - parseFloat(0.3)).toFixed(2) : ((parseFloat(bidorask - ele.ask)) - parseFloat(0.3)).toFixed(2);
                    } else if (lotsize === "0.02") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(0.6)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(0.6)).toFixed(2);
                    } else if (lotsize === "0.03") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(0.9)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(0.9)).toFixed(2);
                    }
                    else if (lotsize === "0.04") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(1.2)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(1.2)).toFixed(2);
                    }
                    else if (lotsize === "0.05") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(1.5)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(1.5)).toFixed(2);
                    }
                    else if (lotsize === "0.06") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(1.8)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(1.8)).toFixed(2);
                    }
                    else if (lotsize === "0.07") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(2.1)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(2.1)).toFixed(2);
                    }
                    else if (lotsize === "0.08") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(2.4)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(2.4)).toFixed(2);
                    }
                    else if (lotsize === "0.09") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(2.7)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(2.7)).toFixed(2);
                    }
                    else if (lotsize === "0.1") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(3)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(3)).toFixed(2);
                    }
                    else if (lotsize === "0.2") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(6)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(6)).toFixed(2);
                    }
                    else if (lotsize === "0.3") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(9)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(9)).toFixed(2);
                    }
                    else if (lotsize === "0.4") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(12)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(12)).toFixed(2);
                    }
                    else if (lotsize === "0.5") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(15)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(15)).toFixed(2);
                    }

                    else if (lotsize === "0.6") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(18)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(18)).toFixed(2);
                    }
                    else if (lotsize === "0.7") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(21)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(21)).toFixed(2);
                    }
                    else if (lotsize === "0.8") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(24)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(24)).toFixed(2);
                    }
                    else if (lotsize === "0.9") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(27)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(27)).toFixed(2);
                    }
                    else if (lotsize === "1") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(30)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(30)).toFixed(2);
                    }
                    else if (lotsize === "2") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(60)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(60)).toFixed(2);
                    }
                    else if (lotsize === "3") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(90)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(90)).toFixed(2);
                    }
                    else if (lotsize === "4") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(120)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(120)).toFixed(2);
                    }
                    else if (lotsize === "5") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(150)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(150)).toFixed(2);
                    }
                }
                return null; // or handle the case when title doesn't match currencyPair
            }

            else {
                if (title === formattedCurrencyPair) {


                    if (lotsize === "0.01") {
                        return order_type === "Buy" ? ((parseFloat(ele.bid - bidorask) * 1000) - parseFloat(0.3)).toFixed(2) : ((parseFloat(bidorask - ele.ask) * 1000) - parseFloat(0.3)).toFixed(2);
                    } else if (lotsize === "0.02") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(0.6)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(0.6)).toFixed(2);
                    } else if (lotsize === "0.03") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(0.9)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(0.9)).toFixed(2);
                    }
                    else if (lotsize === "0.04") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(1.2)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(1.2)).toFixed(2);
                    }
                    else if (lotsize === "0.05") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(1.5)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(1.5)).toFixed(2);
                    }
                    else if (lotsize === "0.06") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(1.8)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(1.8)).toFixed(2);
                    }
                    else if (lotsize === "0.07") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(2.1)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(2.1)).toFixed(2);
                    }
                    else if (lotsize === "0.08") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(2.4)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(2.4)).toFixed(2);
                    }
                    else if (lotsize === "0.09") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(2.7)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(2.7)).toFixed(2);
                    }
                    else if (lotsize === "0.1") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 10000)) - parseFloat(3)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 10000)) - parseFloat(3)).toFixed(2);
                    }
                    else if (lotsize === "0.2") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 10000)) - parseFloat(6)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 10000)) - parseFloat(6)).toFixed(2);
                    }
                    else if (lotsize === "0.3") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 10000)) - parseFloat(9)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 10000)) - parseFloat(9)).toFixed(2);
                    }
                    else if (lotsize === "0.4") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 10000)) - parseFloat(12)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 10000)) - parseFloat(12)).toFixed(2);
                    }
                    else if (lotsize === "0.5") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 10000)) - parseFloat(15)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 10000)) - parseFloat(15)).toFixed(2);
                    }

                    else if (lotsize === "0.6") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 10000)) - parseFloat(18)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 10000)) - parseFloat(18)).toFixed(2);
                    }
                    else if (lotsize === "0.7") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 10000)) - parseFloat(21)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 10000)) - parseFloat(21)).toFixed(2);
                    }
                    else if (lotsize === "0.8") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 10000)) - parseFloat(24)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 10000)) - parseFloat(24)).toFixed(2);
                    }
                    else if (lotsize === "0.9") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 10000)) - parseFloat(27)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 10000)) - parseFloat(27)).toFixed(2);
                    }
                    else if (lotsize === "1") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 100000)) - parseFloat(30)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 100000)) - parseFloat(30)).toFixed(2);
                    }
                    else if (lotsize === "2") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 100000)) - parseFloat(60)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 100000)) - parseFloat(60)).toFixed(2);
                    }
                    else if (lotsize === "3") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 100000)) - parseFloat(90)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 100000)) - parseFloat(90)).toFixed(2);
                    }
                    else if (lotsize === "4") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 100000)) - parseFloat(120)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 100000)) - parseFloat(120)).toFixed(2);
                    }
                    else if (lotsize === "5") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 100000)) - parseFloat(150)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 100000)) - parseFloat(150)).toFixed(2);
                    }
                }
                return null;
            }
        })


        const firstNonNullValue = relevantValue.find(value => value !== null);

        const relevantVal = Object.entries(data).map(([currencyPair, ele], idx) => {
            // const ele = dataArray && dataArray.length > 0 ? dataArray[0] : {};
            const baseCurrency = currencyPair.substring(0, 3);
            const quoteCurrency = currencyPair.substring(3, 6);
            const formattedCurrencyPair = `${baseCurrency}/${quoteCurrency}`;
            if (title === formattedCurrencyPair) {
                return order_type === "Buy" ? ele.bid : ele.ask;
            }
            return null;
        })



        const firstNonNullVal = relevantVal.find(value => value !== null);




        let obj1 = {
            close_rate: firstNonNullVal,
            manual_auto: "Manual Close",
            order_profit: firstNonNullValue
        }
        if (!isNaN(parseFloat(firstNonNullValue))) {
            totalFirstNonNullValue += parseFloat(firstNonNullValue);
            setTotalpl(totalFirstNonNullValue.toFixed(2))
        }
        // if (firstNonNullVal <= takeprofit || firstNonNullVal == stoploss || (firstNonNullValue) <= -price) {
        //     fetchingsucess(order_id, obj);
        //     profitData(firstNonNullValue)
        // }


        fetchingsucess(order_id, obj1)
        // profitData(firstNonNullValue)
    }




    let closedDealsucess = async ({ title, order_type, order_id, bidorask, takeprofit, stoploss, lotsize, price, changecurr }) => {

        const relevantValue = Object.entries(data).map(([currencyPair, ele], idx) => {
            // const ele = dataArray && dataArray.length > 0 ? dataArray[0] : {};
            const baseCurrency = currencyPair.substring(0, 3);
            const quoteCurrency = currencyPair.substring(3, 6);
            const formattedCurrencyPair = `${baseCurrency}/${quoteCurrency}`;

            if (title === "XAU/USD") {
                if (title === formattedCurrencyPair) {


                    if (lotsize === "0.01") {
                        return order_type === "Buy" ? ((parseFloat(ele.bid - bidorask)) - parseFloat(8)).toFixed(2) : ((parseFloat(bidorask - ele.ask)) - parseFloat(8)).toFixed(2);
                    } else if (lotsize === "0.02") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(16)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(16)).toFixed(2);
                    } else if (lotsize === "0.03") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(24)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(24)).toFixed(2);
                    }
                    else if (lotsize === "0.04") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(32)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(32)).toFixed(2);
                    }
                    else if (lotsize === "0.05") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(40)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(40)).toFixed(2);
                    }
                    else if (lotsize === "0.06") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(48)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(48)).toFixed(2);
                    }
                    else if (lotsize === "0.07") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(56)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(56)).toFixed(2);
                    }
                    else if (lotsize === "0.08") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(64)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(64)).toFixed(2);
                    }
                    else if (lotsize === "0.09") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(72)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(72)).toFixed(2);
                    }
                    else if (lotsize === "0.1") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(80)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(80)).toFixed(2);
                    }
                    else if (lotsize === "0.2") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(160)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(160)).toFixed(2);
                    }
                    else if (lotsize === "0.3") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(240)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(240)).toFixed(2);
                    }
                    else if (lotsize === "0.4") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(320)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(320)).toFixed(2);
                    }
                    else if (lotsize === "0.5") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(400)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(400)).toFixed(2);
                    }

                    else if (lotsize === "0.6") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(480)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(480)).toFixed(2);
                    }
                    else if (lotsize === "0.7") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(560)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(560)).toFixed(2);
                    }
                    else if (lotsize === "0.8") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(640)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(640)).toFixed(2);
                    }
                    else if (lotsize === "0.9") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(720)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(720)).toFixed(2);
                    }
                    else if (lotsize === "1") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(800)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(800)).toFixed(2);
                    }
                    else if (lotsize === "2") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(1600)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(1600)).toFixed(2);
                    }
                    else if (lotsize === "3") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(2400)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(2400)).toFixed(2);
                    }
                    else if (lotsize === "4") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(3200)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(3200)).toFixed(2);
                    }
                    else if (lotsize === "5") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(4000)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(4000)).toFixed(2);
                    }
                }
                return null;
            }
            else if (title === "XAG/USD") {
                if (title === formattedCurrencyPair) {


                    if (lotsize === "0.01") {
                        return order_type === "Buy" ? ((parseFloat(ele.bid - bidorask)) - parseFloat(2)).toFixed(2) : ((parseFloat(bidorask - ele.ask)) - parseFloat(2)).toFixed(2);
                    } else if (lotsize === "0.02") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(4)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(4)).toFixed(2);
                    } else if (lotsize === "0.03") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(6)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(6)).toFixed(2);
                    }
                    else if (lotsize === "0.04") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(8)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(8)).toFixed(2);
                    }
                    else if (lotsize === "0.05") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(10)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(10)).toFixed(2);
                    }
                    else if (lotsize === "0.06") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(12)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(12)).toFixed(2);
                    }
                    else if (lotsize === "0.07") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(14)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(14)).toFixed(2);
                    }
                    else if (lotsize === "0.08") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(16)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(16)).toFixed(2);
                    }
                    else if (lotsize === "0.09") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(18)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(18)).toFixed(2);
                    }
                    else if (lotsize === "0.1") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(20)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(20)).toFixed(2);
                    }
                    else if (lotsize === "0.2") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(40)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(40)).toFixed(2);
                    }
                    else if (lotsize === "0.3") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(60)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(60)).toFixed(2);
                    }
                    else if (lotsize === "0.4") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(80)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(80)).toFixed(2);
                    }
                    else if (lotsize === "0.5") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(100)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(100)).toFixed(2);
                    }

                    else if (lotsize === "0.6") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(120)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(120)).toFixed(2);
                    }
                    else if (lotsize === "0.7") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(140)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(140)).toFixed(2);
                    }
                    else if (lotsize === "0.8") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(160)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(160)).toFixed(2);
                    }
                    else if (lotsize === "0.9") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(180)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(180)).toFixed(2);
                    }
                    else if (lotsize === "1") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(200)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(200)).toFixed(2);
                    }
                    else if (lotsize === "2") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(400)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(400)).toFixed(2);
                    }
                    else if (lotsize === "3") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(600)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(600)).toFixed(2);
                    }
                    else if (lotsize === "4") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(800)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(800)).toFixed(2);
                    }
                    else if (lotsize === "5") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(1000)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(1000)).toFixed(2);
                    }
                }
                return null;
            }
            else if (title === "NZD/CAD" || title === "EUR/CHF" | title === "EUR/GBP" || title === "NZD/USD" || title === "USD/CHF" || title === "AUD/USD") {
                if (title === formattedCurrencyPair) {


                    if (lotsize === "0.01") {
                        return order_type === "Buy" ? ((parseFloat(ele.bid - bidorask) * 1000) - parseFloat(0.3)).toFixed(2) : ((parseFloat(bidorask - ele.ask) * 1000) - parseFloat(0.3)).toFixed(2);
                    } else if (lotsize === "0.02") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(0.6)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(0.6)).toFixed(2);
                    } else if (lotsize === "0.03") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(0.9)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(0.9)).toFixed(2);
                    }
                    else if (lotsize === "0.04") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(1.2)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(1.2)).toFixed(2);
                    }
                    else if (lotsize === "0.05") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(1.5)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(1.5)).toFixed(2);
                    }
                    else if (lotsize === "0.06") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(1.8)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(1.8)).toFixed(2);
                    }
                    else if (lotsize === "0.07") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(2.1)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(2.1)).toFixed(2);
                    }
                    else if (lotsize === "0.08") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(2.4)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(2.4)).toFixed(2);
                    }
                    else if (lotsize === "0.09") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(2.7)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(2.7)).toFixed(2);
                    }
                    else if (lotsize === "0.1") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(3)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(3)).toFixed(2);
                    }
                    else if (lotsize === "0.2") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(6)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(6)).toFixed(2);
                    }
                    else if (lotsize === "0.3") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(9)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(9)).toFixed(2);
                    }
                    else if (lotsize === "0.4") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(12)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(12)).toFixed(2);
                    }
                    else if (lotsize === "0.5") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(15)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(15)).toFixed(2);
                    }

                    else if (lotsize === "0.6") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(18)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(18)).toFixed(2);
                    }
                    else if (lotsize === "0.7") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(21)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(21)).toFixed(2);
                    }
                    else if (lotsize === "0.8") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(24)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(24)).toFixed(2);
                    }
                    else if (lotsize === "0.9") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(27)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(27)).toFixed(2);
                    }
                    else if (lotsize === "1") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(30)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(30)).toFixed(2);
                    }
                    else if (lotsize === "2") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(60)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(60)).toFixed(2);
                    }
                    else if (lotsize === "3") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(90)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(90)).toFixed(2);
                    }
                    else if (lotsize === "4") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(120)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(120)).toFixed(2);
                    }
                    else if (lotsize === "5") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(150)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(150)).toFixed(2);
                    }
                }
                return null;
            }
            else if (title === "USD/JPY" || title === "EUR/JPY" || title === "GBP/JPY" || title === "AUD/JPY" || title === "CAD/JPY") {
                if (title === formattedCurrencyPair) {


                    if (lotsize === "0.01") {
                        return order_type === "Buy" ? ((parseFloat(ele.bid - bidorask)) - parseFloat(0.3)).toFixed(2) : ((parseFloat(bidorask - ele.ask)) - parseFloat(0.3)).toFixed(2);
                    } else if (lotsize === "0.02") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(0.6)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(0.6)).toFixed(2);
                    } else if (lotsize === "0.03") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(0.9)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(0.9)).toFixed(2);
                    }
                    else if (lotsize === "0.04") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(1.2)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(1.2)).toFixed(2);
                    }
                    else if (lotsize === "0.05") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(1.5)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(1.5)).toFixed(2);
                    }
                    else if (lotsize === "0.06") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(1.8)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(1.8)).toFixed(2);
                    }
                    else if (lotsize === "0.07") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(2.1)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(2.1)).toFixed(2);
                    }
                    else if (lotsize === "0.08") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(2.4)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(2.4)).toFixed(2);
                    }
                    else if (lotsize === "0.09") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(2.7)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(2.7)).toFixed(2);
                    }
                    else if (lotsize === "0.1") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(3)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(3)).toFixed(2);
                    }
                    else if (lotsize === "0.2") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(6)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(6)).toFixed(2);
                    }
                    else if (lotsize === "0.3") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(9)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(9)).toFixed(2);
                    }
                    else if (lotsize === "0.4") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(12)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(12)).toFixed(2);
                    }
                    else if (lotsize === "0.5") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(15)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(15)).toFixed(2);
                    }

                    else if (lotsize === "0.6") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(18)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(18)).toFixed(2);
                    }
                    else if (lotsize === "0.7") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(21)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(21)).toFixed(2);
                    }
                    else if (lotsize === "0.8") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(24)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(24)).toFixed(2);
                    }
                    else if (lotsize === "0.9") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(27)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(27)).toFixed(2);
                    }
                    else if (lotsize === "1") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(30)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(30)).toFixed(2);
                    }
                    else if (lotsize === "2") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(60)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(60)).toFixed(2);
                    }
                    else if (lotsize === "3") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(90)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(90)).toFixed(2);
                    }
                    else if (lotsize === "4") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(120)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(120)).toFixed(2);
                    }
                    else if (lotsize === "5") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask))) - parseFloat(150)).toFixed(2) : (((parseFloat(bidorask - ele.ask))) - parseFloat(150)).toFixed(2);
                    }
                }
                return null; // or handle the case when title doesn't match currencyPair
            }

            else {
                if (title === formattedCurrencyPair) {


                    if (lotsize === "0.01") {
                        return order_type === "Buy" ? ((parseFloat(ele.bid - bidorask) * 1000) - parseFloat(0.3)).toFixed(2) : ((parseFloat(bidorask - ele.ask) * 1000) - parseFloat(0.3)).toFixed(2);
                    } else if (lotsize === "0.02") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(0.6)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(0.6)).toFixed(2);
                    } else if (lotsize === "0.03") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(0.9)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(0.9)).toFixed(2);
                    }
                    else if (lotsize === "0.04") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(1.2)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(1.2)).toFixed(2);
                    }
                    else if (lotsize === "0.05") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(1.5)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(1.5)).toFixed(2);
                    }
                    else if (lotsize === "0.06") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(1.8)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(1.8)).toFixed(2);
                    }
                    else if (lotsize === "0.07") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(2.1)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(2.1)).toFixed(2);
                    }
                    else if (lotsize === "0.08") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(2.4)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(2.4)).toFixed(2);
                    }
                    else if (lotsize === "0.09") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 1000)) - parseFloat(2.7)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 1000)) - parseFloat(2.7)).toFixed(2);
                    }
                    else if (lotsize === "0.1") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 10000)) - parseFloat(3)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 10000)) - parseFloat(3)).toFixed(2);
                    }
                    else if (lotsize === "0.2") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 10000)) - parseFloat(6)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 10000)) - parseFloat(6)).toFixed(2);
                    }
                    else if (lotsize === "0.3") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 10000)) - parseFloat(9)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 10000)) - parseFloat(9)).toFixed(2);
                    }
                    else if (lotsize === "0.4") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 10000)) - parseFloat(12)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 10000)) - parseFloat(12)).toFixed(2);
                    }
                    else if (lotsize === "0.5") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 10000)) - parseFloat(15)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 10000)) - parseFloat(15)).toFixed(2);
                    }

                    else if (lotsize === "0.6") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 10000)) - parseFloat(18)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 10000)) - parseFloat(18)).toFixed(2);
                    }
                    else if (lotsize === "0.7") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 10000)) - parseFloat(21)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 10000)) - parseFloat(21)).toFixed(2);
                    }
                    else if (lotsize === "0.8") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 10000)) - parseFloat(24)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 10000)) - parseFloat(24)).toFixed(2);
                    }
                    else if (lotsize === "0.9") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 10000)) - parseFloat(27)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 10000)) - parseFloat(27)).toFixed(2);
                    }
                    else if (lotsize === "1") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 100000)) - parseFloat(30)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 100000)) - parseFloat(30)).toFixed(2);
                    }
                    else if (lotsize === "2") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 100000)) - parseFloat(60)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 100000)) - parseFloat(60)).toFixed(2);
                    }
                    else if (lotsize === "3") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 100000)) - parseFloat(90)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 100000)) - parseFloat(90)).toFixed(2);
                    }
                    else if (lotsize === "4") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 100000)) - parseFloat(120)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 100000)) - parseFloat(120)).toFixed(2);
                    }
                    else if (lotsize === "5") {
                        return order_type === "Buy" ? (((parseFloat(ele.bid - bidorask) * 100000)) - parseFloat(150)).toFixed(2) : (((parseFloat(bidorask - ele.ask) * 100000)) - parseFloat(150)).toFixed(2);
                    }
                }
                return null;
            }
        })


        const firstNonNullValue = relevantValue.find(value => value !== null);



        const relevantVal = Object.entries(data).map(([currencyPair, ele]) => {
            const baseCurrency = currencyPair.substring(0, 3);
            const quoteCurrency = currencyPair.substring(3, 6);
            const formattedCurrencyPair = `${baseCurrency}/${quoteCurrency}`;
            if (title === formattedCurrencyPair) {
                return order_type === "Buy" ? ele.bid : ele.ask;
            }
            return null;
        })




        const firstNonNullVal = relevantVal.find(value => value !== null);


        let obj = {
            close_rate: firstNonNullVal,
            manual_auto: "Auto Close",
            order_profit: firstNonNullValue
        }


        if (!isNaN(parseFloat(firstNonNullValue))) {
            totalFirstNonNullValue += parseFloat(firstNonNullValue);
            setTotalpl(totalFirstNonNullValue.toFixed(2))
        }

        if (stoploss !== null && takeprofit !== null) {
            if ((firstNonNullVal).toFixed(5) > takeprofit ||
                (firstNonNullVal).toFixed(5) === takeprofit ||
                (firstNonNullVal).toFixed(5) < stoploss ||
                (firstNonNullVal).toFixed(5) === stoploss) {
                fetchingsucess(order_id, obj);

            }
        } else if (stoploss !== null && takeprofit === null) {
            if ((firstNonNullVal).toFixed(5) === stoploss ||
                (firstNonNullVal).toFixed(5) < stoploss) {
                fetchingsucess(order_id, obj);

            }

        } else if (takeprofit !== null && stoploss === null)
            if ((firstNonNullVal).toFixed(5) > takeprofit ||
                (firstNonNullVal).toFixed(5) === takeprofit) {
                fetchingsucess(order_id, obj);

            }
    }



    // useEffect(() => {
    //     const currentDate = new Date();
    //     const currentDayOfWeek = currentDate.getDay();
    //     if (currentDayOfWeek === 6 || currentDayOfWeek === 0) {
    //         // Fetch data only once
    //         checkClosedDeals();
    //     } else {
    //         // Fetch data and set interval for other days
    //         checkClosedDeals();
    //         const intervalId = setInterval(checkClosedDeals, 500);
    //         return () => clearInterval(intervalId);
    //     }


    // }, [variousdeal])

    let fetchingsucess = async (order_id, obj) => {
        try {

            let response = await fetch(`https://trading-jz57.onrender.com/close/${result.type.AcNumber}/${order_id}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(obj)
            })
            if (response.ok) {
                let res = await response.json();

                profitData(res.order_profit)
                toast({
                    title: 'Deal closed successfully',
                    description: `Check Close deal`,
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                    position: "bottom-left"
                })

            }


        } catch (error) {
            console.log(error)
        }

    }








    //Edit deal
    const { isOpen: isModal1Open, onOpen: openModal1, onClose: closeModal1 } = useDisclosure();
    //Take profit
    const { isOpen: isModal2Open, onOpen: openModal2, onClose: closeModal2 } = useDisclosure();
    //stoploss
    const { isOpen: isModal3Open, onOpen: openModal3, onClose: closeModal3 } = useDisclosure();
    const { isOpen: isModal4Open, onOpen: openModal4, onClose: closeModal4 } = useDisclosure();
    const { isOpen: isModal5Open, onOpen: openModal5, onClose: closeModal5 } = useDisclosure();
    const { isOpen: isModal6Open, onOpen: openModal6, onClose: closeModal6 } = useDisclosure();

    let handletwoinputs = () => {
        closeModal1()
        openModal6()
    }
    let handletwoinput1 = () => {
        closeModal3()
        openModal6()
    }
    let handleinput3 = () => {
        closeModal2()
        openModal6()
    }
    const [isDrawerOpen, setDrawerOpen] = useState(false);

    const openDrawer = () => setDrawerOpen(true);
    const closeDrawer = () => setDrawerOpen(false);
    let [editdeal, seteditdeal] = useState({})








    const fetchEditdeal = async (order_id) => {
        try {
            let res = await fetch(`https://trading-jz57.onrender.com/makeadeal/singledeal/${order_id}`);
            let deal = await res.json();

            seteditdeal(deal);
            fetchDataProfitDeal(deal.title)
            setUpper(deal.order_type)
            openModal1();
        } catch (error) {
            console.log("Error fetching data:", error);
        }
    };


    let [takeprofitdeal, setprofitdeal] = useState({})
    let [upper, setUpper] = useState({})
    const fetchprofitdeal = async (order_id) => {
        try {
            let res = await fetch(`https://trading-jz57.onrender.com/makeadeal/singledeal/${order_id}`);
            let deal = await res.json();
            setprofitdeal(deal);

            fetchDataProfitDeal(deal.title)
            setUpper(deal.order_type)

            openModal2();
        } catch (error) {
            console.log("Error fetching data:", error);
        }
    };


    let fetchDataProfitDeal = (title) => {



        Object.entries(data).map(([currencyPair, ele], idx) => {
            // const ele = dataArray && dataArray.length > 0 ? dataArray[0] : {};
            // const currencyPair = ele.base_currency + ele.quote_currency;
            const baseCurrency = currencyPair.substring(0, 3);
            const quoteCurrency = currencyPair.substring(3, 6);
            const formattedCurrencyPair = `${baseCurrency}/${quoteCurrency}`;

            const currentBid = ele.bid;
            const currentAsk = ele.ask;

            if (formattedCurrencyPair === title) {
                setinputbid(currentBid)
                setinputask(currentAsk)



                // change = parseFloat(change);

                if (autoIncrement1 === true) {


                    newincask = (parseFloat(currentBid)).toFixed(5)
                    setInputfixedvalueask11(newincask);
                    setInputfixedvalueask21(newincask);
                    newinbid = (parseFloat(currentAsk)).toFixed(5)
                    setInputfixedvaluebid11(newinbid);
                    setInputfixedvaluebid21(newinbid);
                    setDollaraskPrice11(0);
                    setDollarbidPrice11(0);
                    setDollaraskPrice21(0);
                    setDollarbidPrice21(0);
                }


            }
            if (title === "EUR/USD" || title === "GBP/USD" || title === "AUD/USD" || title === "USD/CHF" || title === "USD/CAD" || title === "NZD/USD" || title === "EUR/GBP" || title === "EUR/CHF" || title === "EUR/AUD" || title === "EUR/CAD" || title === "EUR/NZD" || title === "GBP/AUD" || title === "GBP/CAD" || title === "GBP/NZD" || title === "AUD/CAD" || title === "NZD/CAD") {
                setIncreaseDependRate("0.0001")
            }
            if (title === "USD/JPY") {
                setIncreaseDependRate("0.01")
            }
            if (title === "EUR/JPY" || title === "XAU/USD" || title === "XAG/USD") {
                setIncreaseDependRate("0.01")
            }

            if (title === "GBP/JPY") {
                setIncreaseDependRate("0.01")
            }

            if (title === "AUD/JPY") {
                setIncreaseDependRate("0.01")
            }
            if (title === "CAD/JPY") {
                setIncreaseDependRate("0.01")
            }

            // Check if previous data exists

        })
    }





    let [stoplossdeal, setstoplossdeal] = useState({})
    const fetchstoplossdeal = async (order_id) => {
        try {
            let res = await fetch(`https://trading-jz57.onrender.com/makeadeal/singledeal/${order_id}`);
            let deal = await res.json();

            setstoplossdeal(deal);
            fetchDataProfitDeal(deal.title)

            setUpper(deal.order_type)
            openModal3();
        } catch (error) {
            console.log("Error fetching data:", error);
        }
    };




    const truecallRef = useRef(false);


    let profitData = async (value) => {

        try {
            let response = await fetch(`https://trading-jz57.onrender.com/close/addprofit/${result.type.AcNumber}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ "profit": parseFloat(value) })

            })
            let res = await response.json()
            truecallRef.current = false;
            console.log(res);

        } catch (error) {
            console.log(error)
        }
    }
    let isFunctionExecuted = false;

    // Throttle the function to be called only once
    let profitData1 = throttle(async (value) => {
        // Check if the function has already been executed
      

        try {
            let response = await fetch(`https://trading-jz57.onrender.com/close/add/${result.type.AcNumber}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ "profit": parseFloat(value) })
            });

            let res = await response.json();
            truecallRef.current = false;
            console.log(res);

            // Set the flag to true after the first execution
            isFunctionExecuted = true;
            console.log("Function executed successfully for the first time.");

        } catch (error) {
            console.log("Error during API call:", error);
        }
    }, 60000);
    // let [neteq, setNetEquity] = useState(0)

    console.log(neteq)
    let totalProfitNetR = 0;
    let closeAllAcctototalpl = async (totalNetProfit) => {
        profitData1(totalNetProfit);
        // if (truecallRef.current === true) {
        //     profitData1(totalNetProfit);
        //     truecallRef.current = false;
        //     // Reset truecallRef after triggering profitData
        // }
        // profitData(totalNetProfit);
        let obj;
        let results = await Promise.all(variousdeal?.map(async (elem, idx) => {
            const netProfit = Object.entries(data).map(([currencyPair, ele], idx) => {
                // const ele = dataArray && dataArray.length > 0 ? dataArray[0] : {};
                const baseCurrency = currencyPair.substring(0, 3);
                const quoteCurrency = currencyPair.substring(3, 6);
                const formattedCurrencyPair = `${baseCurrency}/${quoteCurrency}`;

                if (elem.title === "XAU/USD") {
                    if (elem.title === formattedCurrencyPair) {
                        if (elem.lotsize === "0.01") {
                            return elem.order_type === "Buy" ? ((parseFloat(ele.bid - elem.bidorask)) - parseFloat(8)).toFixed(2) : ((parseFloat(elem.bidorask - ele.ask)) - parseFloat(8)).toFixed(2);
                        } else if (elem.lotsize === "0.02") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(16)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(16)).toFixed(2);
                        } else if (elem.lotsize === "0.03") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(24)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(24)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.04") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(32)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(32)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.05") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(40)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(40)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.06") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(48)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(48)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.07") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(56)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(56)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.08") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(64)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(64)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.09") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(72)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(72)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.1") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(80)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(80)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.2") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(160)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(160)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.3") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(240)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(240)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.4") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(320)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(320)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.5") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(400)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(400)).toFixed(2);
                        }

                        else if (elem.lotsize === "0.6") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(480)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(480)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.7") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(560)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(560)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.8") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(640)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(640)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.9") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(720)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(720)).toFixed(2);
                        }
                        else if (elem.lotsize === "1") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(800)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(800)).toFixed(2);
                        }
                        else if (elem.lotsize === "2") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(1600)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(1600)).toFixed(2);
                        }
                        else if (elem.lotsize === "3") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(2400)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(2400)).toFixed(2);
                        }
                        else if (elem.lotsize === "4") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(3200)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(3200)).toFixed(2);
                        }
                        else if (elem.lotsize === "5") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(4000)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(4000)).toFixed(2);
                        }
                    }
                    return null; // or handle the case when elem.title doesn't match currencyPair
                }
                else if (elem.title === "XAG/USD") {
                    if (elem.title === formattedCurrencyPair) {
                        if (elem.lotsize === "0.01") {
                            return elem.order_type === "Buy" ? ((parseFloat(ele.bid - elem.bidorask)) - parseFloat(2)).toFixed(2) : ((parseFloat(elem.bidorask - ele.ask)) - parseFloat(2)).toFixed(2);
                        } else if (elem.lotsize === "0.02") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(4)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(4)).toFixed(2);
                        } else if (elem.lotsize === "0.03") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(6)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(6)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.04") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(8)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(8)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.05") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(10)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(10)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.06") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(12)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(12)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.07") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(14)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(14)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.08") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(16)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(16)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.09") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(18)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(18)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.1") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(20)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(20)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.2") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(40)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(40)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.3") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(60)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(60)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.4") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(80)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(80)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.5") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(100)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(100)).toFixed(2);
                        }

                        else if (elem.lotsize === "0.6") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(120)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(120)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.7") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(140)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(140)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.8") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(160)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(160)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.9") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(180)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(180)).toFixed(2);
                        }
                        else if (elem.lotsize === "1") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(200)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(200)).toFixed(2);
                        }
                        else if (elem.lotsize === "2") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(400)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(400)).toFixed(2);
                        }
                        else if (elem.lotsize === "3") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(600)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(600)).toFixed(2);
                        }
                        else if (elem.lotsize === "4") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(800)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(800)).toFixed(2);
                        }
                        else if (elem.lotsize === "5") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(1000)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(1000)).toFixed(2);
                        }
                    }
                    return null; // or handle the case when elem.title doesn't match currencyPair
                }
                else if (elem.title === "NZD/CAD" || elem.title === "EUR/CHF" || elem.title === "EUR/GBP" || elem.title === "NZD/USD" || elem.title === "USD/CHF" || elem.title === "AUD/USD") {
                    if (elem.title === formattedCurrencyPair) {


                        if (elem.lotsize === "0.01") {
                            return elem.order_type === "Buy" ? ((parseFloat(ele.bid - elem.bidorask) * 1000) - parseFloat(0.3)).toFixed(2) : ((parseFloat(elem.bidorask - ele.ask) * 1000) - parseFloat(0.3)).toFixed(2);
                        } else if (elem.lotsize === "0.02") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(0.6)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(0.6)).toFixed(2);
                        } else if (elem.lotsize === "0.03") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(0.9)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(0.9)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.04") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(1.2)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(1.2)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.05") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(1.5)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(1.5)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.06") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(1.8)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(1.8)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.07") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(2.1)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(2.1)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.08") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(2.4)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(2.4)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.09") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(2.7)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(2.7)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.1") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(3)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(3)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.2") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(6)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(6)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.3") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(9)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(9)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.4") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(12)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(12)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.5") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(15)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(15)).toFixed(2);
                        }

                        else if (elem.lotsize === "0.6") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(18)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(18)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.7") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(21)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(21)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.8") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(24)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(24)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.9") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(27)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(27)).toFixed(2);
                        }
                        else if (elem.lotsize === "1") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(30)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(30)).toFixed(2);
                        }
                        else if (elem.lotsize === "2") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(60)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(60)).toFixed(2);
                        }
                        else if (elem.lotsize === "3") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(90)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(90)).toFixed(2);
                        }
                        else if (elem.lotsize === "4") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(120)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(120)).toFixed(2);
                        }
                        else if (elem.lotsize === "5") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(150)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(150)).toFixed(2);
                        }
                    }
                    return null;
                }
                else if (elem.title === "USD/JPY" || elem.title === "EUR/JPY" || elem.title === "GBP/JPY" || elem.title === "AUD/JPY" || elem.title === "CAD/JPY") {
                    if (elem.title === formattedCurrencyPair) {


                        if (elem.lotsize === "0.01") {
                            return elem.order_type === "Buy" ? ((parseFloat(ele.bid - elem.bidorask)) - parseFloat(0.3)).toFixed(2) : ((parseFloat(elem.bidorask - ele.ask)) - parseFloat(0.3)).toFixed(2);
                        } else if (elem.lotsize === "0.02") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(0.6)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(0.6)).toFixed(2);
                        } else if (elem.lotsize === "0.03") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(0.9)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(0.9)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.04") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(1.2)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(1.2)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.05") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(1.5)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(1.5)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.06") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(1.8)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(1.8)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.07") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(2.1)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(2.1)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.08") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(2.4)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(2.4)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.09") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(2.7)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(2.7)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.1") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(3)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(3)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.2") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(6)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(6)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.3") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(9)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(9)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.4") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(12)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(12)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.5") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(15)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(15)).toFixed(2);
                        }

                        else if (elem.lotsize === "0.6") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(18)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(18)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.7") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(21)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(21)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.8") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(24)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(24)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.9") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(27)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(27)).toFixed(2);
                        }
                        else if (elem.lotsize === "1") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(30)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(30)).toFixed(2);
                        }
                        else if (elem.lotsize === "2") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(60)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(60)).toFixed(2);
                        }
                        else if (elem.lotsize === "3") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(90)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(90)).toFixed(2);
                        }
                        else if (elem.lotsize === "4") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(120)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(120)).toFixed(2);
                        }
                        else if (elem.lotsize === "5") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(150)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(150)).toFixed(2);
                        }
                    }
                    return null; // or handle the case when elem.title doesn't match currencyPair
                } else {
                    if (elem.title === formattedCurrencyPair) {


                        if (elem.lotsize === "0.01") {
                            return elem.order_type === "Buy" ? ((parseFloat(ele.bid - elem.bidorask) * 1000) - parseFloat(0.3)).toFixed(2) : ((parseFloat(elem.bidorask - ele.ask) * 1000) - parseFloat(0.3)).toFixed(2);
                        } else if (elem.lotsize === "0.02") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(0.6)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(0.6)).toFixed(2);
                        } else if (elem.lotsize === "0.03") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(0.9)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(0.9)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.04") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(1.2)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(1.2)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.05") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(1.5)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(1.5)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.06") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(1.8)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(1.8)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.07") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(2.1)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(2.1)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.08") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(2.4)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(2.4)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.09") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(2.7)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(2.7)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.1") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 10000)) - parseFloat(3)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 10000)) - parseFloat(3)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.2") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 10000)) - parseFloat(6)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 10000)) - parseFloat(6)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.3") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 10000)) - parseFloat(9)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 10000)) - parseFloat(9)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.4") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 10000)) - parseFloat(12)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 10000)) - parseFloat(12)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.5") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 10000)) - parseFloat(15)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 10000)) - parseFloat(15)).toFixed(2);
                        }

                        else if (elem.lotsize === "0.6") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 10000)) - parseFloat(18)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 10000)) - parseFloat(18)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.7") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 10000)) - parseFloat(21)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 10000)) - parseFloat(21)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.8") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 10000)) - parseFloat(24)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 10000)) - parseFloat(24)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.9") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 10000)) - parseFloat(27)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 10000)) - parseFloat(27)).toFixed(2);
                        }
                        else if (elem.lotsize === "1") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 100000)) - parseFloat(30)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 100000)) - parseFloat(30)).toFixed(2);
                        }
                        else if (elem.lotsize === "2") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 100000)) - parseFloat(60)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 100000)) - parseFloat(60)).toFixed(2);
                        }
                        else if (elem.lotsize === "3") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 100000)) - parseFloat(90)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 100000)) - parseFloat(90)).toFixed(2);
                        }
                        else if (elem.lotsize === "4") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 100000)) - parseFloat(120)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 100000)) - parseFloat(120)).toFixed(2);
                        }
                        else if (elem.lotsize === "5") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 100000)) - parseFloat(150)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 100000)) - parseFloat(150)).toFixed(2);
                        }
                    }
                    return null; // or handle the case when elem.title doesn't match currencyPair
                }
            });




            // Calculate net profit and add it to the sum
            const rowNetProfit = parseFloat(netProfit.find(value => value !== null));
            totalProfitNetR += !isNaN(rowNetProfit) ? rowNetProfit : 0;


            // Display your table rows here

            const relevantVal = Object.entries(data).map(([currencyPair, ele]) => {
                // const ele = dataArray && dataArray.length > 0 ? dataArray[0] : {};
                const baseCurrency = currencyPair.substring(0, 3);
                const quoteCurrency = currencyPair.substring(3, 6);
                const formattedCurrencyPair = `${baseCurrency}/${quoteCurrency}`;
                if (elem.title === formattedCurrencyPair) {
                    return elem.order_type === "Buy" ? ele.bid : ele.ask;
                }
                return null;
            })

            const firstNonNullVal = relevantVal.find(value => value !== null);

            obj = {
                close_rate: firstNonNullVal,
                manual_auto: "Auto Close",
                order_profit: rowNetProfit,
                order_id: elem.order_id  // Add order_id to the payload
            }

            try {



                let response = await fetch(`https://trading-jz57.onrender.com/close/${result.type.AcNumber}`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(obj)
                    // Use the modified JSON string with circular reference handling
                });

                if (response.ok) {
                    let res = await response.json();
                    // profitData(totalNetRemove)
                    // window.location.reload();

                    toast({
                        title: 'Deal closed successfully',
                        description: `Check Close deal`,
                        status: 'success',
                        duration: 2000,
                        isClosable: true,
                        position: "bottom-left"
                    });

                    return { status: 'fulfilled', value: true };
                }
            } catch (error) {
                console.log('Error during closeAllDeal:', error);
                return { status: 'rejected', reason: 'Failed to close deal' };
            }
        }));
        // if (results.every(result => result && result.status === 'fulfilled' && result.value)) {

        //     // profitData(totalProfitNetR);
        //     // window.location.reload();
        //     toast({
        //         title: 'All deals closed successfully',
        //         description: `Check Close deal`,
        //         status: 'success',
        //         duration: 2000,
        //         isClosable: true,
        //         position: "bottom-left"
        //     });
        // } else {
        //     // Handle the case where at least one deal failed to close
        //     console.log('At least one deal failed to close');
        // }



    };
    // let welcomeminusbal = (totalNetProfit) => {

    //     profitData(totalNetProfit);
    //     // profitDataTriggered = true;
    // }
    let totalNetProfit = 0;
    let profitDataTriggered = false;
    let lastTriggerTimestamp = 0; // Timestamp of the last `profitData` trigger
    const MINIMUM_INTERVAL_MS = 5 * 60 * 1000; // 5 minutes in milliseconds


    variousdeal?.map((elem, idx) => {
        const netProfit = Object.entries(data).map(([currencyPair, ele], idx) => {
            // const ele = dataArray && dataArray.length > 0 ? dataArray[0] : {};
            const baseCurrency = currencyPair.substring(0, 3);
            const quoteCurrency = currencyPair.substring(3, 6);
            const formattedCurrencyPair = `${baseCurrency}/${quoteCurrency}`;
            if (elem.title === "XAU/USD") {
                if (elem.title === formattedCurrencyPair) {


                    if (elem.lotsize === "0.01") {
                        return elem.order_type === "Buy" ? ((parseFloat(ele.bid - elem.bidorask)) - parseFloat(8)).toFixed(2) : ((parseFloat(elem.bidorask - ele.ask)) - parseFloat(8)).toFixed(2);
                    } else if (elem.lotsize === "0.02") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(16)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(16)).toFixed(2);
                    } else if (elem.lotsize === "0.03") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(24)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(24)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.04") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(32)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(32)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.05") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(40)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(40)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.06") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(48)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(48)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.07") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(56)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(56)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.08") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(64)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(64)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.09") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(72)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(72)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.1") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(80)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(80)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.2") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(160)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(160)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.3") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(240)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(240)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.4") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(320)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(320)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.5") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(400)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(400)).toFixed(2);
                    }

                    else if (elem.lotsize === "0.6") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(480)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(480)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.7") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(560)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(560)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.8") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(640)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(640)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.9") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(720)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(720)).toFixed(2);
                    }
                    else if (elem.lotsize === "1") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(800)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(800)).toFixed(2);
                    }
                    else if (elem.lotsize === "2") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(1600)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(1600)).toFixed(2);
                    }
                    else if (elem.lotsize === "3") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(2400)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(2400)).toFixed(2);
                    }
                    else if (elem.lotsize === "4") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(3200)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(3200)).toFixed(2);
                    }
                    else if (elem.lotsize === "5") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(4000)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(4000)).toFixed(2);
                    }
                }
                return null; // or handle the case when elem.title doesn't match currencyPair
            } else if (elem.title === "XAG/USD") {
                if (elem.title === formattedCurrencyPair) {


                    if (elem.lotsize === "0.01") {
                        return elem.order_type === "Buy" ? ((parseFloat(ele.bid - elem.bidorask)) - parseFloat(2)).toFixed(2) : ((parseFloat(elem.bidorask - ele.ask)) - parseFloat(2)).toFixed(2);
                    } else if (elem.lotsize === "0.02") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(4)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(4)).toFixed(2);
                    } else if (elem.lotsize === "0.03") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(6)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(6)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.04") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(8)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(8)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.05") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(10)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(10)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.06") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(12)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(12)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.07") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(14)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(14)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.08") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(16)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(16)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.09") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(18)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(18)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.1") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(20)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(20)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.2") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(40)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(40)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.3") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(60)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(60)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.4") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(80)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(80)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.5") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(100)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(100)).toFixed(2);
                    }

                    else if (elem.lotsize === "0.6") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(120)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(120)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.7") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(140)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(140)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.8") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(160)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(160)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.9") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(180)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(180)).toFixed(2);
                    }
                    else if (elem.lotsize === "1") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(200)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(200)).toFixed(2);
                    }
                    else if (elem.lotsize === "2") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(400)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(400)).toFixed(2);
                    }
                    else if (elem.lotsize === "3") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(600)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(600)).toFixed(2);
                    }
                    else if (elem.lotsize === "4") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(800)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(800)).toFixed(2);
                    }
                    else if (elem.lotsize === "5") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(1000)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(1000)).toFixed(2);
                    }
                }
                return null; // or handle the case when elem.title doesn't match currencyPair
            }
            else if (elem.title === "NZD/CAD" || elem.title === "EUR/CHF" || elem.title === "EUR/GBP" || elem.title === "NZD/USD" || elem.title === "USD/CHF" || elem.title === "AUD/USD") {
                if (elem.title === formattedCurrencyPair) {


                    if (elem.lotsize === "0.01") {
                        return elem.order_type === "Buy" ? ((parseFloat(ele.bid - elem.bidorask) * 1000) - parseFloat(0.3)).toFixed(2) : ((parseFloat(elem.bidorask - ele.ask) * 1000) - parseFloat(0.3)).toFixed(2);
                    } else if (elem.lotsize === "0.02") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(0.6)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(0.6)).toFixed(2);
                    } else if (elem.lotsize === "0.03") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(0.9)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(0.9)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.04") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(1.2)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(1.2)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.05") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(1.5)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(1.5)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.06") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(1.8)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(1.8)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.07") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(2.1)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(2.1)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.08") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(2.4)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(2.4)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.09") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(2.7)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(2.7)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.1") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(3)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(3)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.2") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(6)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(6)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.3") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(9)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(9)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.4") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(12)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(12)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.5") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(15)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(15)).toFixed(2);
                    }

                    else if (elem.lotsize === "0.6") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(18)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(18)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.7") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(21)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(21)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.8") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(24)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(24)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.9") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(27)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(27)).toFixed(2);
                    }
                    else if (elem.lotsize === "1") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(30)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(30)).toFixed(2);
                    }
                    else if (elem.lotsize === "2") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(60)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(60)).toFixed(2);
                    }
                    else if (elem.lotsize === "3") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(90)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(90)).toFixed(2);
                    }
                    else if (elem.lotsize === "4") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(120)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(120)).toFixed(2);
                    }
                    else if (elem.lotsize === "5") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(150)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(150)).toFixed(2);
                    }
                }
                return null;
            }
            else if (elem.title === "USD/JPY" || elem.title === "EUR/JPY" || elem.title === "GBP/JPY" || elem.title === "AUD/JPY" || elem.title === "CAD/JPY") {
                if (elem.title === formattedCurrencyPair) {


                    if (elem.lotsize === "0.01") {
                        return elem.order_type === "Buy" ? ((parseFloat(ele.bid - elem.bidorask)) - parseFloat(0.3)).toFixed(2) : ((parseFloat(elem.bidorask - ele.ask)) - parseFloat(0.3)).toFixed(2);
                    } else if (elem.lotsize === "0.02") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(0.6)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(0.6)).toFixed(2);
                    } else if (elem.lotsize === "0.03") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(0.9)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(0.9)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.04") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(1.2)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(1.2)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.05") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(1.5)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(1.5)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.06") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(1.8)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(1.8)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.07") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(2.1)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(2.1)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.08") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(2.4)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(2.4)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.09") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(2.7)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(2.7)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.1") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(3)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(3)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.2") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(6)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(6)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.3") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(9)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(9)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.4") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(12)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(12)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.5") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(15)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(15)).toFixed(2);
                    }

                    else if (elem.lotsize === "0.6") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(18)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(18)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.7") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(21)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(21)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.8") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(24)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(24)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.9") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(27)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(27)).toFixed(2);
                    }
                    else if (elem.lotsize === "1") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(30)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(30)).toFixed(2);
                    }
                    else if (elem.lotsize === "2") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(60)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(60)).toFixed(2);
                    }
                    else if (elem.lotsize === "3") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(90)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(90)).toFixed(2);
                    }
                    else if (elem.lotsize === "4") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(120)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(120)).toFixed(2);
                    }
                    else if (elem.lotsize === "5") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(150)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(150)).toFixed(2);
                    }
                }
                return null; // or handle the case when elem.title doesn't match currencyPair
            }
            else {
                if (elem.title === formattedCurrencyPair) {


                    if (elem.lotsize === "0.01") {
                        return elem.order_type === "Buy" ? ((parseFloat(ele.bid - elem.bidorask) * 1000) - parseFloat(0.3)).toFixed(2) : ((parseFloat(elem.bidorask - ele.ask) * 1000) - parseFloat(0.3)).toFixed(2);
                    } else if (elem.lotsize === "0.02") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(0.6)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(0.6)).toFixed(2);
                    } else if (elem.lotsize === "0.03") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(0.9)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(0.9)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.04") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(1.2)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(1.2)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.05") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(1.5)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(1.5)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.06") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(1.8)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(1.8)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.07") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(2.1)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(2.1)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.08") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(2.4)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(2.4)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.09") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(2.7)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(2.7)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.1") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 10000)) - parseFloat(3)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 10000)) - parseFloat(3)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.2") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 10000)) - parseFloat(6)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 10000)) - parseFloat(6)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.3") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 10000)) - parseFloat(9)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 10000)) - parseFloat(9)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.4") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 10000)) - parseFloat(12)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 10000)) - parseFloat(12)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.5") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 10000)) - parseFloat(15)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 10000)) - parseFloat(15)).toFixed(2);
                    }

                    else if (elem.lotsize === "0.6") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 10000)) - parseFloat(18)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 10000)) - parseFloat(18)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.7") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 10000)) - parseFloat(21)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 10000)) - parseFloat(21)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.8") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 10000)) - parseFloat(24)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 10000)) - parseFloat(24)).toFixed(2);
                    }
                    else if (elem.lotsize === "0.9") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 10000)) - parseFloat(27)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 10000)) - parseFloat(27)).toFixed(2);
                    }
                    else if (elem.lotsize === "1") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 100000)) - parseFloat(30)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 100000)) - parseFloat(30)).toFixed(2);
                    }
                    else if (elem.lotsize === "2") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 100000)) - parseFloat(60)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 100000)) - parseFloat(60)).toFixed(2);
                    }
                    else if (elem.lotsize === "3") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 100000)) - parseFloat(90)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 100000)) - parseFloat(90)).toFixed(2);
                    }
                    else if (elem.lotsize === "4") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 100000)) - parseFloat(120)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 100000)) - parseFloat(120)).toFixed(2);
                    }
                    else if (elem.lotsize === "5") {
                        return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 100000)) - parseFloat(150)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 100000)) - parseFloat(150)).toFixed(2);
                    }
                }
                return null; // or handle the case when elem.title doesn't match currencyPair
            }
        });
        // Calculate net profit and add it to the sum
        const rowNetProfit = parseFloat(netProfit.find(value => value !== null));
        totalNetProfit += !isNaN(rowNetProfit) ? rowNetProfit : 0;

        console.log(totalNetProfit)

        if (totalNetProfit <= -bal) {
            // truecallRef.current = true;
            closeAllAcctototalpl(totalNetProfit);
           
            return true; // Break out of the loop
        }

        return false; // Continue the loop

    });

    console.log(truecallRef.current)





    localStorage.setItem("totalNetProfit", (totalNetProfit));
    let totalNetRemove = 0;
    let closeAllDeal = async () => {
        let obj;
        let results = await Promise.all(variousdeal?.map(async (elem, idx) => {
            const netProfit = Object.entries(data).map(([currencyPair, ele], idx) => {
                // const ele = dataArray && dataArray.length > 0 ? dataArray[0] : {};
                const baseCurrency = currencyPair.substring(0, 3);
                const quoteCurrency = currencyPair.substring(3, 6);
                const formattedCurrencyPair = `${baseCurrency}/${quoteCurrency}`;


                if (elem.title === "XAU/USD") {
                    if (elem.title === formattedCurrencyPair) {


                        if (elem.lotsize === "0.01") {
                            return elem.order_type === "Buy" ? ((parseFloat(ele.bid - elem.bidorask)) - parseFloat(8)).toFixed(2) : ((parseFloat(elem.bidorask - ele.ask)) - parseFloat(8)).toFixed(2);
                        } else if (elem.lotsize === "0.02") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(16)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(16)).toFixed(2);
                        } else if (elem.lotsize === "0.03") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(24)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(24)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.04") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(32)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(32)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.05") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(40)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(40)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.06") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(48)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(48)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.07") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(56)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(56)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.08") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(64)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(64)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.09") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(72)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(72)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.1") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(80)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(80)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.2") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(160)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(160)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.3") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(240)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(240)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.4") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(320)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(320)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.5") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(400)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(400)).toFixed(2);
                        }

                        else if (elem.lotsize === "0.6") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(480)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(480)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.7") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(560)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(560)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.8") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(640)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(640)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.9") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(720)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(720)).toFixed(2);
                        }
                        else if (elem.lotsize === "1") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(800)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(800)).toFixed(2);
                        }
                        else if (elem.lotsize === "2") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(1600)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(1600)).toFixed(2);
                        }
                        else if (elem.lotsize === "3") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(2400)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(2400)).toFixed(2);
                        }
                        else if (elem.lotsize === "4") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(3200)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(3200)).toFixed(2);
                        }
                        else if (elem.lotsize === "5") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(4000)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(4000)).toFixed(2);
                        }
                    }
                    return null; // or handle the case when elem.title doesn't match currencyPair
                } else if (elem.title === "XAG/USD") {
                    if (elem.title === formattedCurrencyPair) {


                        if (elem.lotsize === "0.01") {
                            return elem.order_type === "Buy" ? ((parseFloat(ele.bid - elem.bidorask)) - parseFloat(2)).toFixed(2) : ((parseFloat(elem.bidorask - ele.ask)) - parseFloat(2)).toFixed(2);
                        } else if (elem.lotsize === "0.02") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(4)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(4)).toFixed(2);
                        } else if (elem.lotsize === "0.03") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(6)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(6)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.04") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(8)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(8)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.05") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(10)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(10)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.06") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(12)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(12)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.07") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(14)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(14)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.08") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(16)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(16)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.09") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(18)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(18)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.1") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(20)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(20)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.2") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(40)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(40)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.3") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(60)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(60)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.4") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(80)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(80)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.5") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(100)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(100)).toFixed(2);
                        }

                        else if (elem.lotsize === "0.6") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(120)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(120)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.7") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(140)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(140)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.8") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(160)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(160)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.9") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(180)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(180)).toFixed(2);
                        }
                        else if (elem.lotsize === "1") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(200)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(200)).toFixed(2);
                        }
                        else if (elem.lotsize === "2") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(400)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(400)).toFixed(2);
                        }
                        else if (elem.lotsize === "3") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(600)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(600)).toFixed(2);
                        }
                        else if (elem.lotsize === "4") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(800)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(800)).toFixed(2);
                        }
                        else if (elem.lotsize === "5") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(1000)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(1000)).toFixed(2);
                        }
                    }
                    return null; // or handle the case when elem.title doesn't match currencyPair
                }
                else if (elem.title === "NZD/CAD" || elem.title === "EUR/CHF" || elem.title === "EUR/GBP" || elem.title === "NZD/USD" || elem.title === "USD/CHF" || elem.title === "AUD/USD") {
                    if (elem.title === formattedCurrencyPair) {


                        if (elem.lotsize === "0.01") {
                            return elem.order_type === "Buy" ? ((parseFloat(ele.bid - elem.bidorask) * 1000) - parseFloat(0.3)).toFixed(2) : ((parseFloat(elem.bidorask - ele.ask) * 1000) - parseFloat(0.3)).toFixed(2);
                        } else if (elem.lotsize === "0.02") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(0.6)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(0.6)).toFixed(2);
                        } else if (elem.lotsize === "0.03") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(0.9)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(0.9)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.04") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(1.2)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(1.2)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.05") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(1.5)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(1.5)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.06") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(1.8)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(1.8)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.07") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(2.1)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(2.1)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.08") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(2.4)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(2.4)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.09") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(2.7)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(2.7)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.1") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(3)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(3)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.2") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(6)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(6)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.3") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(9)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(9)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.4") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(12)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(12)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.5") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(15)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(15)).toFixed(2);
                        }

                        else if (elem.lotsize === "0.6") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(18)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(18)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.7") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(21)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(21)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.8") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(24)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(24)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.9") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(27)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(27)).toFixed(2);
                        }
                        else if (elem.lotsize === "1") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(30)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(30)).toFixed(2);
                        }
                        else if (elem.lotsize === "2") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(60)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(60)).toFixed(2);
                        }
                        else if (elem.lotsize === "3") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(90)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(90)).toFixed(2);
                        }
                        else if (elem.lotsize === "4") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(120)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(120)).toFixed(2);
                        }
                        else if (elem.lotsize === "5") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(150)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(150)).toFixed(2);
                        }
                    }
                    return null;
                }
                else if (elem.title === "USD/JPY" || elem.title === "EUR/JPY" || elem.title === "GBP/JPY" || elem.title === "AUD/JPY" || elem.title === "CAD/JPY") {
                    if (elem.title === formattedCurrencyPair) {


                        if (elem.lotsize === "0.01") {
                            return elem.order_type === "Buy" ? ((parseFloat(ele.bid - elem.bidorask)) - parseFloat(0.3)).toFixed(2) : ((parseFloat(elem.bidorask - ele.ask)) - parseFloat(0.3)).toFixed(2);
                        } else if (elem.lotsize === "0.02") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(0.6)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(0.6)).toFixed(2);
                        } else if (elem.lotsize === "0.03") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(0.9)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(0.9)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.04") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(1.2)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(1.2)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.05") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(1.5)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(1.5)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.06") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(1.8)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(1.8)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.07") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(2.1)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(2.1)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.08") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(2.4)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(2.4)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.09") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(2.7)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(2.7)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.1") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(3)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(3)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.2") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(6)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(6)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.3") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(9)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(9)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.4") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(12)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(12)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.5") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(15)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(15)).toFixed(2);
                        }

                        else if (elem.lotsize === "0.6") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(18)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(18)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.7") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(21)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(21)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.8") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(24)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(24)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.9") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(27)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(27)).toFixed(2);
                        }
                        else if (elem.lotsize === "1") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(30)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(30)).toFixed(2);
                        }
                        else if (elem.lotsize === "2") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(60)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(60)).toFixed(2);
                        }
                        else if (elem.lotsize === "3") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(90)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(90)).toFixed(2);
                        }
                        else if (elem.lotsize === "4") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(120)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(120)).toFixed(2);
                        }
                        else if (elem.lotsize === "5") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(150)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(150)).toFixed(2);
                        }
                    }
                    return null; // or handle the case when elem.title doesn't match currencyPair
                } else {
                    if (elem.title === formattedCurrencyPair) {


                        if (elem.lotsize === "0.01") {
                            return elem.order_type === "Buy" ? ((parseFloat(ele.bid - elem.bidorask) * 1000) - parseFloat(0.3)).toFixed(2) : ((parseFloat(elem.bidorask - ele.ask) * 1000) - parseFloat(0.3)).toFixed(2);
                        } else if (elem.lotsize === "0.02") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(0.6)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(0.6)).toFixed(2);
                        } else if (elem.lotsize === "0.03") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(0.9)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(0.9)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.04") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(1.2)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(1.2)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.05") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(1.5)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(1.5)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.06") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(1.8)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(1.8)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.07") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(2.1)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(2.1)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.08") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(2.4)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(2.4)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.09") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(2.7)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(2.7)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.1") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 10000)) - parseFloat(3)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 10000)) - parseFloat(3)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.2") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 10000)) - parseFloat(6)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 10000)) - parseFloat(6)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.3") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 10000)) - parseFloat(9)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 10000)) - parseFloat(9)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.4") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 10000)) - parseFloat(12)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 10000)) - parseFloat(12)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.5") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 10000)) - parseFloat(15)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 10000)) - parseFloat(15)).toFixed(2);
                        }

                        else if (elem.lotsize === "0.6") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 10000)) - parseFloat(18)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 10000)) - parseFloat(18)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.7") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 10000)) - parseFloat(21)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 10000)) - parseFloat(21)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.8") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 10000)) - parseFloat(24)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 10000)) - parseFloat(24)).toFixed(2);
                        }
                        else if (elem.lotsize === "0.9") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 10000)) - parseFloat(27)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 10000)) - parseFloat(27)).toFixed(2);
                        }
                        else if (elem.lotsize === "1") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 100000)) - parseFloat(30)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 100000)) - parseFloat(30)).toFixed(2);
                        }
                        else if (elem.lotsize === "2") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 100000)) - parseFloat(60)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 100000)) - parseFloat(60)).toFixed(2);
                        }
                        else if (elem.lotsize === "3") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 100000)) - parseFloat(90)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 100000)) - parseFloat(90)).toFixed(2);
                        }
                        else if (elem.lotsize === "4") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 100000)) - parseFloat(120)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 100000)) - parseFloat(120)).toFixed(2);
                        }
                        else if (elem.lotsize === "5") {
                            return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 100000)) - parseFloat(150)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 100000)) - parseFloat(150)).toFixed(2);
                        }
                    }
                    return null; // or handle the case when elem.title doesn't match currencyPair
                }
            });




            // Calculate net profit and add it to the sum
            const rowNetProfit = parseFloat(netProfit.find(value => value !== null));
            totalNetRemove += !isNaN(rowNetProfit) ? rowNetProfit : 0;
           

            // Display your table rows here

            const relevantVal = Object.entries(data).map(([currencyPair, ele]) => {
                // const ele = dataArray && dataArray.length > 0 ? dataArray[0] : {};
                const baseCurrency = currencyPair.substring(0, 3);
                const quoteCurrency = currencyPair.substring(3, 6);
                const formattedCurrencyPair = `${baseCurrency}/${quoteCurrency}`;
                if (elem.title === formattedCurrencyPair) {
                    return elem.order_type === "Buy" ? ele.bid : ele.ask;
                }
                return null;
            })

            const firstNonNullVal = relevantVal.find(value => value !== null);

            obj = {
                close_rate: firstNonNullVal,
                manual_auto: "Manual Close",
                order_profit: rowNetProfit,
                order_id: elem.order_id  // Add order_id to the payload
            }

            try {



                let response = await fetch(`https://trading-jz57.onrender.com/close/${result.type.AcNumber}`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(obj)
                    // Use the modified JSON string with circular reference handling
                });

                if (response.ok) {
                    let res = await response.json();
                    // profitData(totalNetRemove)
                    // window.location.reload();

                    toast({
                        title: 'Deal closed successfully',
                        description: `Check Close deal`,
                        status: 'success',
                        duration: 2000,
                        isClosable: true,
                        position: "bottom-left"
                    });

                    return { status: 'fulfilled', value: true };
                }
            } catch (error) {
                console.log('Error during closeAllDeal:', error);
                return { status: 'rejected', reason: 'Failed to close deal' };
            }
        }));
        if (results.every(result => result && result.status === 'fulfilled' && result.value)) {

            profitData(totalNetRemove);
            // window.location.reload();
            toast({
                title: 'All deals closed successfully',
                description: `Check Close deal`,
                status: 'success',
                duration: 2000,
                isClosable: true,
                position: "bottom-left"
            });
        } else {
            // Handle the case where at least one deal failed to close
            console.log('At least one deal failed to close');
        }


    };





    let MakeaDeal = (pri) => {


        if (freeMargin === 0) {



            onClose();
            toast({
                description: `Add Balance in your account`,
                status: `error`,
                duration: 5000,
                isClosable: true,
                position: "bottom-left"
            })
            setAddStopLoss(false)
            setAddTakeProfit(false)
            setTitle('');

        } else if (freeMargin <= pri) {

            onClose();
            toast({
                description: `Deposit Balance in your account`,
                status: `error`,
                duration: 5000,
                isClosable: true,
                position: "bottom-left"
            })
            setAddStopLoss(false)
            setAddTakeProfit(false)
            setTitle('');
        }
        else if (freeMargin >= pri) {

            if (inputfixedvalueask2 > askincinput && selectedSide === "Sell" && above1ask === "Below" && inputfixedvalueask2 !== null) {
                toast({
                    description: `Invalid Value,Enter below value`,
                    status: `warning`,
                    duration: 5000,
                    isClosable: true,
                    position: "top-center",
                });
            }
            if (inputfixedvaluebid2 < bidincinput && selectedSide === "Buy" && above2ask === "Above" && inputfixedvaluebid2 !== null) {
                toast({
                    description: `Invalid Value,Enter above value`,
                    status: `warning`,
                    duration: 5000,
                    isClosable: true,
                    position: "top-center",
                });
            }
            if (inputfixedvalueask1 > askincinput && selectedSide === "Buy" && above1ask === "Below" && inputfixedvalueask1 !== null) {
                toast({
                    description: `Invalid Value, Enter below value`,
                    status: `warning`,
                    duration: 5000,
                    isClosable: true,
                    position: "top-center",
                });
            }
            if (inputfixedvaluebid1 < bidincinput && selectedSide === "Sell" && above2ask === "Above" && inputfixedvaluebid1 !== null) {
                toast({
                    description: `Invalid Value, Enter above value`,
                    status: `warning`,
                    duration: 5000,
                    isClosable: true,
                    position: "top-center",
                });
            }
            if (inputfixedvalueask2 <= bidincinput && selectedSide === "Sell" && above1ask === "Below" || inputfixedvaluebid2 >= askincinput && selectedSide === "Buy" && above2ask === "Above" ||
                inputfixedvalueask1 <= askincinput && selectedSide === "Buy" && above1ask === "Below" ||
                inputfixedvaluebid1 >= bidincinput && selectedSide === "Sell" && above2ask === "Above") {
                onClose();

                fetchdeal();
                // setAutoIncrement(true)
                setAddStopLoss(false)
                setAddTakeProfit(false)
                setTitle('');

            }

        }


    }



    let [table, setTable] = useState("open")
    let handletablechange = (value) => {
        setTable(value)
    }
    let [dataclose, setDataclose] = useState([])
    let fetchDataclosedAllDeal = async () => {
        try {
            let response = await fetch(`https://trading-jz57.onrender.com/close/${result.type.AcNumber}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${result.token}`,
                },
            })
            let res = await response.json();
            setDataclose(res.reverse());

        } catch (error) {
            console.log(error);
        }
    }






    //logic for modal of ed,tl,sl
    let [above11ask, setAbove11ask] = useState("Below")
    let [above21ask, setAbove21ask] = useState("Above")
    // Update onClick handlers to set the selected data
    let onchnageloss1 = () => {
        if (addStopLoss1 === false) {
            // setAutoIncrement(false)
            setAddStopLoss1(true)

        } else {
            setAddStopLoss1(false)
            // setAutoIncrement(true)
        }
    }

    let setaddprofit1 = () => {
        if (addTakeProfit1 === false) {
            // setAutoIncrement(false)
            setAddTakeProfit1(true)
        } else {
            setAddTakeProfit1(false)
            // setAutoIncrement(true)
        }
    }

    // const handleBuyClick1 = () => {

    //     if (selectedSide === "Buy") {
    //         setAbove11ask("Below")
    //         setAbove21ask("Above")
    //         setSelectedSide("Sell");
    //         setDisabledme11(false)
    //     } else {
    //         setAbove11ask("Below")
    //         setAbove21ask("Above")
    //         setSelectedSide("Buy");
    //         setDisabledme11(false)

    //     }
    // };

    // const handleSellClick1 = () => {

    //     if (selectedSide === "Sell") {
    //         setAbove11ask("Below")
    //         setAbove21ask("Above")
    //         setSelectedSide("Buy");
    //         setDisabledme11(false)
    //     } else {
    //         setAbove11ask("Below")
    //         setAbove21ask("Above")
    //         setSelectedSide("Sell");
    //         setDisabledme11(false)
    //     }

    // };
    let decinputask0 = () => {
        setAutoIncrement1(false)

        if (autoIncrement1 === false) {
            if (upper === "Buy" && above21ask === "Above") {
                const newAskValue = (parseFloat(inputfixedvalueask21) - parseFloat(incdependRate)).toFixed(5);
                setInputfixedvalueask21(newAskValue);
            }



            if (upper === "Sell" && above11ask === "Below") {
                setDisabledme11(false)
                const newAskValue = (parseFloat(inputfixedvaluebid21) - parseFloat(incdependRate)).toFixed(5);
                setInputfixedvaluebid21(newAskValue);
            }
        }
    }




    let incinputask0 = () => {
        setAutoIncrement1(false);

        if (autoIncrement === false) {

            if (upper === "Buy" && above21ask === "Above") {
                setDisabledme21(false)
                const newAskValue = (parseFloat(inputfixedvalueask21) + parseFloat(incdependRate)).toFixed(5);
                setInputfixedvalueask21(newAskValue);
            }



            if (inputfixedvaluebid21 === bidincinput && upper === "Sell" && above21ask === "Below") {
                setDisabledme11(true)
            }
            if (inputfixedvaluebid21 < bidincinput && upper === "Sell" && above11ask === "Below") {
                setDisabledme21(false)
                const newAskValue = Math.max((parseFloat(inputfixedvaluebid21) + parseFloat(incdependRate)).toFixed(5))
                setInputfixedvaluebid21(newAskValue);
            }
        }
    }

    let dollarPriceinput0 = (e) => {
        const newDollarPrice = (e.target.value);
        setAutoIncrement1(false)
        if (upper === "Buy" && above21ask === "Above") {
            if (newDollarPrice === "" || !isNaN(parseInt(newDollarPrice))) {
                setDollarbidPrice21(newDollarPrice);

                // Calculate and update inputfixedvalueask based on the newDollarPrice
                const newAskValue = (parseFloat(inputfixedvalueask21) + newDollarPrice * parseFloat(incdependRate)).toFixed(5);
                setInputfixedvalueask21(newAskValue);
            }
        }
        if (upper === "Sell" && above11ask === "Below") {
            if (newDollarPrice === "" || !isNaN(parseInt(newDollarPrice))) {
                setDollaraskPrice21(newDollarPrice);

                // Calculate and update inputfixedvalueask based on the newDollarPrice
                const newAskValue = (parseFloat(inputfixedvaluebid21) - newDollarPrice * parseFloat(incdependRate)).toFixed(5);
                setInputfixedvaluebid21(newAskValue);
            }
        }

    }



    let handleaddDollar0 = () => {
        setAutoIncrement1(false)

        if (upper === "Buy" && above21ask === "Above") {
            setDollarbidPrice21((prevDollarPrice) => prevDollarPrice + 1);

            // Calculate and update inputfixedvalueask based on the increased dollaraskPrice
            const newAskValue = (parseFloat(inputfixedvalueask21) + parseFloat(incdependRate)).toFixed(5);
            setInputfixedvalueask21(newAskValue);
        }


        if (upper === "Sell" && above11ask === "Below") {
            setDollaraskPrice21((prevDollarPrice) => prevDollarPrice + 1);

            // Calculate and update inputfixedvalueask based on the increased dollaraskPrice
            const newAskValue = (parseFloat(inputfixedvaluebid21) - parseFloat(incdependRate)).toFixed(5);
            setInputfixedvaluebid21(newAskValue);
        }
    }



    let handleSubDollar0 = () => {
        if (upper === "Buy" && above21ask === "Above") {
            setDollarbidPrice21((prevDollarPrice) => prevDollarPrice - 1);

            // Calculate and update inputfixedvalueask based on the increased dollaraskPrice
            const newAskValue = (parseFloat(inputfixedvalueask21) - parseFloat(incdependRate)).toFixed(5);
            setInputfixedvalueask21(newAskValue);
        }

        if (upper === "Sell" && above1ask === "Below") {
            setDollaraskPrice21((prevDollarPrice) => prevDollarPrice - 1);

            // Calculate and update inputfixedvalueask based on the increased dollaraskPrice
            const newAskValue = (parseFloat(inputfixedvaluebid21) + parseFloat(incdependRate)).toFixed(5);
            setInputfixedvaluebid21(newAskValue);
        }
    }



    let Decbidvalue0 = () => {
        setAutoIncrement1(false);

        if (autoIncrement1 === false) {
            if (upper === "Buy" && above11ask === "Below") {
                setDisabledme11(false)
                const newAskValue = (parseFloat(inputfixedvalueask11) - parseFloat(incdependRate)).toFixed(5);
                setInputfixedvalueask11(newAskValue);
            }

            if (upper === "Sell" && above21ask === "Above") {
                setDisabledme21(false)
                const newAskValue = (parseFloat(inputfixedvaluebid11) - parseFloat(incdependRate)).toFixed(5);
                setInputfixedvaluebid11(newAskValue);
            }
        }
    }


    let Incbidvalue0 = () => {
        setAutoIncrement1(false);

        if (autoIncrement1 === false) {
            if (inputfixedvaluebid11 === askincinput && upper === "Buy" && above11ask === "Below") {
                setDisabledme11(true)
            }
            if (inputfixedvalueask11 < askincinput && upper === "Buy" && above11ask === "Above") {
                setDisabledme11(false)
                const newAskValue = (parseFloat(inputfixedvalueask11) + parseFloat(incdependRate)).toFixed(5);
                setInputfixedvalueask11(newAskValue);
            }
            if (inputfixedvalueask11 < askincinput && upper === "Buy" && above11ask === "Below") {
                setDisabledme11(false)
                const newAskValue = (parseFloat(inputfixedvalueask11) + parseFloat(incdependRate)).toFixed(5);
                setInputfixedvalueask11(newAskValue);
            }
            if (upper === "Sell" && above21ask === "Above") {
                setDisabledme21(false)
                const newAskValue = (parseFloat(inputfixedvaluebid11) + parseFloat(incdependRate)).toFixed(5);
                setInputfixedvaluebid11(newAskValue);
            }
        }

    }

    let handleaddDollar11 = () => {
        if (upper === "Buy" && above11ask === "Below") {
            setDollarbidPrice11((prevDollarPrice) => prevDollarPrice + 1);

            // Calculate and update inputfixedvalueask based on the increased dollaraskPrice
            const newAskValue = (parseFloat(inputfixedvalueask11) - parseFloat(incdependRate)).toFixed(5);
            setInputfixedvalueask11(newAskValue);
        }
        if (upper === "Sell" && above2ask === "Above") {
            setDollaraskPrice11((prevDollarPrice) => prevDollarPrice + 1);

            // Calculate and update inputfixedvalueask based on the increased dollaraskPrice
            const newAskValue = (parseFloat(inputfixedvaluebid11) + parseFloat(incdependRate)).toFixed(5);
            setInputfixedvaluebid11(newAskValue);
        }
    }

    let Inputdollar11 = (e) => {
        const newDollarPrice = (e.target.value);
        if (upper === "Buy" && above11ask === "Below") {
            if (newDollarPrice === "" || !isNaN(parseInt(newDollarPrice))) {
                setDollarbidPrice11(newDollarPrice);

                // Calculate and update inputfixedvalueask based on the newDollarPrice
                const newAskValue = (parseFloat(inputfixedvalueask11) - newDollarPrice * parseFloat(incdependRate)).toFixed(5);
                setInputfixedvalueask11(newAskValue);
            }
        }
        if (upper === "Sell" && above21ask === "Above") {
            if (newDollarPrice === "" || !isNaN(parseInt(newDollarPrice))) {
                setDollaraskPrice11(newDollarPrice);

                // Calculate and update inputfixedvalueask based on the newDollarPrice
                const newAskValue = (parseFloat(inputfixedvaluebid1) + newDollarPrice * parseFloat(incdependRate)).toFixed(5);
                setInputfixedvaluebid11(newAskValue);
            }
        }
    }

    let handleSubDollar11 = () => {

        if (upper === "Buy" && above11ask === "Below") {
            setDollarbidPrice11((prevDollarPrice) => prevDollarPrice - 1);

            // Calculate and update inputfixedvalueask based on the increased dollaraskPrice
            const newAskValue = (parseFloat(inputfixedvalueask11) + parseFloat(incdependRate)).toFixed(5);
            setInputfixedvalueask11(newAskValue);
        }
        if (upper === "Sell" && above2ask === "Above") {
            setDollaraskPrice11((prevDollarPrice) => prevDollarPrice - 1);

            // Calculate and update inputfixedvalueask based on the increased dollaraskPrice
            const newAskValue = (parseFloat(inputfixedvaluebid11) - parseFloat(incdependRate)).toFixed(5);
            setInputfixedvaluebid11(newAskValue);
        }
    }


    let handleallclose1 = () => {
        closeModal6();
        setAddStopLoss1(false)
        setAddTakeProfit1(false)
    }



    let handleDemoLogin = async () => {
        try {

            let response = await fetch(`https://trading-jz57.onrender.com/registerdemo/${result.type.AcNumber}`)
            let res = await response.json();

            sessionStorage.setItem("demo", JSON.stringify(res))
            navigate("/demo")
        } catch (error) {
            console.log(error);
        }


    }

    useEffect(() => {
        fetchData();
        const currentDate = new Date();
        const currentDayOfWeek = currentDate.getDay(); // Sunday is 0, Monday is 1, ..., Saturday is 6

        // Check if it's Saturday (day 6) or Sunday (day 0)
        if (currentDayOfWeek === 6 || currentDayOfWeek === 0) {
            // Fetch data only once
            fetchData();
        } else {
            // Fetch data and set interval for other days 
            fetchData();
            const intervalId = setInterval(fetchData, 1000);
            return () => clearInterval(intervalId);
        }
    }, []);






    useEffect(() => {

        const storedData = sessionStorage.getItem('forexD');
        if (storedData) {
            setPreviousData1(JSON.parse(storedData));
        }

    }, []);
    useEffect(() => {
        fetchDataclosedAllDeal();
    }, [])

    useEffect(() => {
        fetchtotalBal();
        dealPrice();
        setFreeMargin(eq - margin);

        // setMargin(eq+margin);
    }, [bal, totalNetProfit])

    // useEffect(() => {
    //     if (truecallRef.current) {
    //         profitData(totalNetProfit);
    //         truecallRef.current = false;
    //         // Reset truecallRef after triggering profitData
    //     }

    //     // setMargin(eq+margin);
    // }, [ truecallRef])

    useEffect(() => {
        fetchVaroiusdeal()
        checkClosedDeals();
    }, [variousdeal])


    useEffect(() => {

        setLotSize(0.01);
        setPip(0.10);
        setPrice(initialPrice);


    }, [initialPrice]);

    useEffect(() => {
        // setLoading(true); // Set loading to true before starting the fetch request
        fetchPrice(title); // Call fetchPrice with the specified title

        // const intervalID = setInterval(() => {
        //     fetchPrice(title); // Call fetchPrice with the specified title inside the interval
        // }, 1000);

        // return () => {
        //     clearInterval(intervalID);
        // };
    }, [autoIncrement, changecurr, title, data]);
    useEffect(() => {


        const storedData = sessionStorage.getItem('forexData');
        if (storedData) {
            setPreviousData(JSON.parse(storedData));
        }


    }, []);
    useEffect(() => {
        fetchCommodities();

        // const intervalId = setInterval(fetchCommodities, 3000);
        // return () => clearInterval(intervalId);
    }, [data])

    return (
        <>
            <Box >
                <Box

                //boxShadow= "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px" 
                >
                    <Box bg={"rgb(55,67,78)"} boxShadow="rgba(0, 0, 0, 0.1) 0px 4px 12px">
                        <Flex justifyContent={"space-between"}>
                            <Box p={"20px"} w={{ base: "100%", sm: "100%", md: "100%", lg: "38%", xl: "38%" }} onClick={() => { navigate("/home") }} >
                                <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "38%", xl: "38%" }}>
                                    <Image cursor={"pointer"} src={logo}></Image>
                                </Box>
                            </Box>
                            <Box p={"18px"}>
                                <Button onClick={() => { navigate("/depositpage") }} size={{ base: "sm", sm: "sm", md: "md", lg: "md", xl: "md" }} display={{ base: "block", sm: "block", md: "block", lg: 'none', xl: "none" }} bg={"rgb(255,218,69)"} color={"black"} cursor={"pointer"}>Deposit</Button>
                            </Box>
                            <Box mt={"-2px"} color={"white"} alignItems={"center"} pr={{ base: "18px", sm: "18px", md: "18px", lg: "0px", xl: "0px" }} fontSize={{ base: "40px", sm: "40px", md: "50px", lg: "30px", xl: "30px" }} display={{ base: "block", sm: "block", md: "block", lg: "none", xl: "none" }} onClick={() => openModal5()} >
                                <HamburgerIcon ></HamburgerIcon></Box>
                            <Drawer
                                isOpen={isModal5Open}
                                placement='right'
                                onClose={closeModal5}
                            >
                                <DrawerOverlay />
                                <DrawerContent bg={"rgb(55,67,78)"} color={"white"}>
                                    <DrawerCloseButton />
                                    {/* <DrawerHeader>Create your account</DrawerHeader> */}

                                    <DrawerBody mt="60px" >
                                        {
                                            result === "" ? <Box>
                                            </Box> : <Flex gap={"5px"} alignItems={"center"}>
                                                <ion-icon name="key-outline" size="small"></ion-icon>
                                                <Text fontSize={"md"} cursor={"pointer"} _hover={{ color: 'rgb(255,218,69)' }} > Ac No:- {result.type.AcNumber}</Text>
                                            </Flex>
                                        }
                                        <Divider mt={"10px"} color={"rgb(250,250,250)"} />
                                        {
                                            result === "" ? <Box>
                                                <Text onClick={() => navigate("/login")}>LOGIN</Text>
                                            </Box> :
                                                <Flex mt="10px" gap={"5px"} alignItems={"center"}>
                                                    <ion-icon name="person-circle-outline" size="small"></ion-icon>
                                                    <Text fontWeight="semibold" _hover={{ color: "rgb(255,218,69)", cursor: "pointer" }} onClick={() => navigate("/profile")}>{result.type.first_name + " " + result.type.last_name}</Text>
                                                </Flex>

                                        }
                                        <Divider mt={"10px"} color={"rgb(250,250,250)"} />

                                        {
                                            result === "" ? <Box mt={"20px"}>
                                                <Text onClick={() => navigate("/register")}>REGISTER</Text>
                                            </Box> :
                                                <Flex mt="10px" gap={"5px"} alignItems={"center"}>
                                                    <ion-icon name="stats-chart-outline" size="small"></ion-icon>
                                                    <Text fontWeight="bold" _hover={{ color: "rgb(255,218,69)", cursor: "pointer" }} onClick={() => navigate("/livechart")}>Live chart</Text>
                                                </Flex>

                                        }
                                        <Divider mt={"10px"} color={"rgb(250,250,250)"} />

                                        {
                                            result === "" ? null :
                                                <Flex mt="10px" gap={"5px"} alignItems={"center"}>
                                                    <ion-icon name="home-outline" size="small"></ion-icon>
                                                    <Text fontWeight="semibold" _hover={{ color: "rgb(255,218,69)", cursor: "pointer" }} onClick={() => navigate('/home')}>Dashboard</Text>
                                                </Flex>

                                        }
                                        <Divider mt={"10px"} color={"rgb(250,250,250)"} />

                                        {
                                            result === "" ? null :
                                                <Flex mt="10px" gap={"5px"} alignItems={"center"}>
                                                    <ion-icon name="cash-outline" size="small"></ion-icon>
                                                    <Text fontWeight="semibold" _hover={{ color: "rgb(255,218,69)", cursor: "pointer" }} onClick={() => navigate("/withdraw")}>Withdraw</Text>
                                                </Flex>


                                        }
                                        <Divider mt={"10px"} color={"rgb(250,250,250)"} />
                                        {
                                            result === "" ? null :
                                                <Flex mt="10px" gap={"5px"} alignItems={"center"}>
                                                    <ion-icon name="document-lock-outline" size="small"></ion-icon>
                                                    <Text fontWeight="semibold" _hover={{ color: "rgb(255,218,69)", cursor: "pointer" }} onClick={() => navigate("/document")}>Document Verification</Text>
                                                </Flex>

                                        }
                                        <Divider mt={"10px"} color={"rgb(250,250,250)"} />


                                        {
                                            result === "" ? null :
                                                <Flex mt="10px" gap={"5px"} alignItems={"center"}>
                                                    <ion-icon name="close-circle-outline" size="small"></ion-icon>
                                                    <Text fontWeight="semibold" _hover={{ color: "rgb(255,218,69)", cursor: "pointer" }} onClick={() => navigate("/closeDeal")}>Closed deals</Text>
                                                </Flex>

                                        }
                                        <Divider mt={"10px"} color={"rgb(250,250,250)"} />
                                        {
                                            result === "" ? null :
                                                <Flex mt="10px" gap={"5px"} alignItems={"center"}>
                                                    <ion-icon name="eye-outline" size="small"></ion-icon>
                                                    <Text fontWeight="semibold" _hover={{ color: "rgb(255,218,69)", cursor: "pointer" }} onClick={() => navigate("/changepass")}>Change Password</Text>
                                                </Flex>

                                        }
                                        <Divider mt={"10px"} color={"rgb(250,250,250)"} />

                                        {/* {
                                            result === "" ? null :
                                                <Flex mt="10px" gap={"5px"} alignItems={"center"}>
                                                    <ion-icon name="key-outline" size="small"></ion-icon>
                                                    <Text  fontWeight="semibold" _hover={{ color: "rgb(255,218,69)", cursor: "pointer" }}
                                                    // onClick={()=>navigate("/")}
                                                    >Document Verification</Text>
                                                </Flex>

                                        } */}

                                        {
                                            result === "" ? null :
                                                <Flex mt="10px" gap={"5px"} alignItems={"center"}>
                                                    <ion-icon name="wallet-outline" size="small"></ion-icon>
                                                    <Text fontWeight="semibold" _hover={{ color: "rgb(255,218,69)", cursor: "pointer" }} onClick={() => navigate("/depositpage")}>Deposit</Text>
                                                </Flex>

                                        }



                                    </DrawerBody>

                                    <DrawerFooter>
                                        {
                                            result === "" ? null : <Button onClick={logOut} mr={3}>
                                                Logout
                                            </Button>
                                        }
                                    </DrawerFooter>
                                </DrawerContent>
                            </Drawer>
                            <Box bg={"rgb(55,67,78)"} p={"10px"} w={"70%"} display={{ base: "none", sm: "none", md: "none", lg: "block", xl: "block" }}>
                                <Flex justifyContent={"space-between"} alignItems={"center"} p={"10px"}>
                                    <Box w={"20%"} color={"white"} onClick={openDrawer} cursor="pointer">
                                        <Flex gap={"5px"} alignItems={"center"} _hover={{ color: "rgb(255,218,69)" }}>
                                            <Text fontWeight={"bold"} fontSize='2xl'  >$ {isNaN(eq) ? "0.00" : eq.toFixed(2)}</Text>
                                            <ChevronDownIcon fontSize='3xl'></ChevronDownIcon>
                                        </Flex>
                                        <AccountSummary totalpl={totalNetProfit} isOpen={isDrawerOpen} onClose={closeDrawer} />
                                    </Box>
                                    {/* <Box>
                                                        <DragHandleIcon fontSize={'3xl'} color={"white"}></DragHandleIcon>
                                                    </Box> */}
                                    <Box fontSize={'4xl'} color={"white"} cursor="pointer" justifyContent={"flex-end"} alignItems={"center"}>
                                        <Popover>
                                            <PopoverTrigger cursor="pointer">
                                                <ion-icon name="person-circle-outline" ></ion-icon>
                                            </PopoverTrigger>
                                            <Portal>
                                                <PopoverContent bg={"rgb(55,67,78)"} color={"white"}>
                                                    <PopoverArrow />

                                                    <PopoverCloseButton />
                                                    <PopoverBody >
                                                        <Box>
                                                            <Text cursor={"pointer"} _hover={{ color: 'rgb(255,218,69)' }} display={{ base: "none", sm: "none", md: "none", lg: "block", xl: "block" }} onClick={() => navigate("/profile")}><ChevronLeftIcon></ChevronLeftIcon> Ac No:- {result.type.AcNumber}</Text>
                                                            <Text mt={"8px"} cursor={"pointer"} _hover={{ color: "rgb(255,218,69)" }} onClick={() => navigate("/profile")}><ChevronLeftIcon></ChevronLeftIcon> {result.type.first_name + " " + result.type.last_name}</Text>
                                                            <Text mt={"8px"} cursor={"pointer"} _hover={{ color: 'rgb(255,218,69)' }} onClick={() => navigate("/document")}><ChevronLeftIcon></ChevronLeftIcon> Document verification</Text>
                                                            <Text mt={"8px"} cursor={"pointer"} _hover={{ color: "rgb(255,218,69)" }} onClick={() => navigate("/changepass")}><ChevronLeftIcon></ChevronLeftIcon> Change Passowrd</Text>
                                                            <Text mt={"8px"} cursor={"pointer"} _hover={{ color: "rgb(255,218,69)" }} onClick={logOut}><ChevronLeftIcon></ChevronLeftIcon> Log Out</Text>
                                                        </Box>
                                                    </PopoverBody>

                                                </PopoverContent>
                                            </Portal>
                                        </Popover>

                                    </Box>
                                    <Box >
                                        <Text color={"white"} cursor={"pointer"} _hover={{ color: "rgb(255,218,69)" }} fontWeight={"bold"} onClick={() => navigate("/home")}>DASHBOARD</Text>
                                    </Box>

                                    <Box >
                                        <Text color={"white"} cursor={"pointer"} _hover={{ color: "rgb(255,218,69)" }} fontWeight={"bold"} onClick={() => navigate("/closeDeal")}>CLOSED DEALS</Text>
                                    </Box>
                                    <Box >
                                        <Text color={"white"} cursor={"pointer"} _hover={{ color: "rgb(255,218,69)" }} fontWeight={"bold"} onClick={() => navigate("/withdraw")}>WITHDRAW</Text>
                                    </Box>

                                    <Box>
                                        <Button color={"black"} _hover={{ bg: 'white', color: "black" }} cursor={"pointer"} bg={"rgb(255,218,69)"} onClick={() => navigate("/depositpage")}>DEPOSIT</Button>
                                    </Box>

                                    <Box>
                                        <Button color={"black"} bg={"rgb(255,218,69)"} _hover={{ bg: 'white', color: "black" }} cursor={"pointer"} onClick={handleDemoLogin}>SWITCH TO DEMO</Button>
                                    </Box>

                                </Flex>
                            </Box>
                        </Flex>
                    </Box>
                    <Box
                        pointerEvents="none"
                        cursor="default"
                    >
                        <coingecko-coin-price-marquee-widget coin-ids="bitcoin,ethereum,eos,ripple,litecoin" currency="usd" background-color="#ffffff" locale="en"></coingecko-coin-price-marquee-widget>
                    </Box>
                    <Modal blockScrollOnMount={false} isOpen={isModal4Open} onClose={closeModal4}>
                        <ModalOverlay />
                        <ModalContent bg={"rgb(55,67,78)"} color={"white"}>
                            <ModalHeader></ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <Text fontWeight='bold' mb='1rem'>
                                    Order excuted successfully
                                </Text>

                            </ModalBody>

                            <ModalFooter>
                                <Button bg={"rgb(255,218,69)"} color='black' mr={3} onClick={closeModal4}>
                                    Close
                                </Button>

                            </ModalFooter>
                        </ModalContent>
                    </Modal>

                    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="xl">
                        <ModalOverlay />
                        <ModalContent bg={"rgb(55,67,78)"} color={"white"}>
                            <ModalHeader>{title}</ModalHeader>
                            <ModalCloseButton onClick={chnageaddlossprofit} />
                            <ModalBody >
                                {
                                    loading ? <>
                                        <Flex direction={{ base: "column", sm: 'column', md: "column", lg: "column", xl: "column", "2xl": "column" }} justifyContent={"space-between"} alignContent={"center"}>
                                            <Box >
                                                <Text>Instrument</Text>
                                                <Select name="title" value={title} onChange={changeTitle} style={{ backgroundColor: "rgb(55,67,78)", color: "white" }} isDisabled>
                                                    <option value="EURUSD" data-selectiontype="3" style={{ backgroundColor: "rgb(55,67,78)" }}>EUR/USD</option>
                                                    <option value="USDJPY" data-selectiontype="3" style={{ backgroundColor: "rgb(55,67,78)" }}>USD/JPY</option>
                                                    <option value="GBPUSD" data-selectiontype="3" style={{ backgroundColor: "rgb(55,67,78)" }}>GBP/USD</option>
                                                    <option value="AUDUSD" data-selectiontype="3" style={{ backgroundColor: "rgb(55,67,78)" }}>AUD/USD</option>
                                                    <option value="USDCHF" data-selectiontype="3" style={{ backgroundColor: "rgb(55,67,78)" }}>USD/CHF</option>
                                                    <option value="USDCAD" data-selectiontype="3" style={{ backgroundColor: "rgb(55,67,78)" }}>USD/CAD</option>
                                                    <option value="NZDUSD" data-selectiontype="3" style={{ backgroundColor: "rgb(55,67,78)" }}>NZD/USD</option>
                                                    <option value="EURJPY" data-selectiontype="3" style={{ backgroundColor: "rgb(55,67,78)" }}>EUR/JPY</option>
                                                    <option value="EURGBP" data-selectiontype="3" style={{ backgroundColor: "rgb(55,67,78)" }}>EUR/GBP</option>
                                                    <option value="EURCHF" data-selectiontype="3" style={{ backgroundColor: "rgb(55,67,78)" }}>EUR/CHF</option>
                                                    <option value="EURAUD" data-selectiontype="3" style={{ backgroundColor: "rgb(55,67,78)" }}>EUR/AUD</option>
                                                    <option value="EURCAD" data-selectiontype="3" style={{ backgroundColor: "rgb(55,67,78)" }}>EUR/CAD</option>
                                                    <option value="EURNZD" data-selectiontype="3" style={{ backgroundColor: "rgb(55,67,78)" }}>EUR/NZD</option>
                                                    <option value="GBPJPY" data-selectiontype="3" style={{ backgroundColor: "rgb(55,67,78)" }}>GBP/JPY</option>
                                                    <option value="GBPAUD" data-selectiontype="3" style={{ backgroundColor: "rgb(55,67,78)" }}>GBP/AUD</option>
                                                    <option value="GBPCAD" data-selectiontype="3" style={{ backgroundColor: "rgb(55,67,78)" }}>GBP/CAD</option>
                                                    <option value="GBPNZD" data-selectiontype="3" style={{ backgroundColor: "rgb(55,67,78)" }}>GBP/NZD</option>
                                                    <option value="AUDJPY" data-selectiontype="3" style={{ backgroundColor: "rgb(55,67,78)" }}>AUD/JPY</option>
                                                    <option value="CADJPY" data-selectiontype="3" style={{ backgroundColor: "rgb(55,67,78)" }}>CAD/JPY</option>
                                                    <option value="AUDCAD" data-selectiontype="3" style={{ backgroundColor: "rgb(55,67,78)" }}>AUD/CAD</option>
                                                    <option value="NZDCAD" data-selectiontype="3" style={{ backgroundColor: "rgb(55,67,78)" }}>NZD/CAD</option>
                                                    <option value="XAUUSD" data-selectiontype="3" style={{ backgroundColor: "rgb(55,67,78)" }}>XAU/USD</option>
                                                    <option value="XAGUSD" data-selectiontype="3" style={{ backgroundColor: "rgb(55,67,78)" }}>XAG/USD</option>
                                                </Select>
                                            </Box>
                                            <Box mt={"15px"}>
                                                <Text>Size</Text>
                                                <Flex>
                                                    <Input type="number" value={lotSize}

                                                        onBlur={() => {
                                                            if (title === "XAUUSD") {
                                                                // Your logic for XAU/USD
                                                                const clampedValue = Math.max(0.01, Math.min(5, lotSize));
                                                                setLotSize(clampedValue);
                                                                const priceval = Math.max(initialPrice, Math.min(17500, price));
                                                                setPrice(priceval);
                                                                const pipval = Math.max(initialPip, Math.min(50, pip));
                                                                setPip(pipval);
                                                            } else {
                                                                // Your logic for other cases
                                                                const clampedValue = Math.max(0.01, Math.min(5, lotSize));
                                                                setLotSize(clampedValue);
                                                                const priceval = Math.max(initialPrice, Math.min(1085, price));
                                                                setPrice(priceval);
                                                                const pipval = Math.max(initialPip, Math.min(50, pip));
                                                                setPip(pipval);
                                                            }
                                                        }}
                                                        onChange={storeLotsize} borderRadius="none" />
                                                    <Button onClick={handleDecreaseLotSize} color={"black"} borderRadius="none">-</Button>
                                                    <Button bg={"rgb(255,218,69)"} color={"black"} onClick={handleIncreaseLotSize} borderRadius="none">+</Button>
                                                </Flex>
                                            </Box>

                                            <Box w={{ base: "100%", sm: '100%', md: "100%", lg: "100%", xl: "100%" }} mt={"15px"}>
                                                {Object.entries(data).map(([currencyPair, dataArray], idx) => {
                                                    // const ele = dataArray.length > 0 ? dataArray[0] : {};

                                                    if (currencyPair === title) {
                                                        return (
                                                            // Your JSX content goes here
                                                            // For example:
                                                            <Flex key={idx} alignItems={"end"} mt={"12px"}>
                                                                <Box

                                                                    w={{ base: "100%", sm: '100%', md: "100%", lg: "100%", xl: "100%" }}
                                                                    bg={(selectedSide === 'Sell') ? (dataArray.bidColor === 'green' ? 'darkgreen' : 'rgb(252, 2, 2)') : (dataArray.bidColor === 'green' ? 'lightgreen' : 'rgb(249, 110, 110)')}
                                                                    borderBottom={selectedSide === "Sell" ? "5px solid white" : ""}
                                                                    fontWeight={"bold"}
                                                                    textAlign={"center"}
                                                                    color={'white'}
                                                                    // borderRadius="3px"
                                                                    cursor="pointer"
                                                                    onClick={handleSellClick}
                                                                >
                                                                    <Text>Sell</Text>
                                                                    <Text>{dataArray.bid}</Text>
                                                                </Box>
                                                                <Box
                                                                    w={{ base: "100%", sm: '100%', md: "100%", lg: "100%", xl: "100%" }}
                                                                    bg={(selectedSide === 'Buy') ? (dataArray.bidColor === 'green' ? 'darkgreen' : 'rgb(252, 2, 2)') : (dataArray.bidColor === 'green' ? 'lightgreen' : 'rgb(249, 110, 110)')}
                                                                    borderBottom={selectedSide === "Buy" ? "5px solid white" : ""}
                                                                    fontWeight={"bold"}
                                                                    textAlign={"center"}
                                                                    color={'white'}
                                                                    // borderRadius="3px"
                                                                    cursor="pointer"
                                                                    onClick={handleBuyClick}
                                                                >
                                                                    <Text>Buy</Text>
                                                                    <Text>{dataArray.ask}</Text>
                                                                </Box>

                                                            </Flex>
                                                        );
                                                    } else {
                                                        return null;
                                                    }
                                                })}
                                            </Box>







                                        </Flex>
                                        <Box mt={"20px"} alignContent={"center"}>
                                            <Accordion allowToggle>
                                                <AccordionItem>
                                                    <h2>
                                                        <AccordionButton bg={"rgb(221,221,221)"} _hover={{ cursor: "pointer" }}>
                                                            <Box as="span" flex='1' color={"black"}>
                                                                Add Stop Loss/Take Profit
                                                            </Box>
                                                            <AccordionIcon color={"black"} />
                                                        </AccordionButton>
                                                    </h2>
                                                    <AccordionPanel p={"5px"}
                                                        pb={4}>
                                                        <Box  >
                                                            <Flex justifyContent={"space-between"} flexDirection={{ base: "column", sm: 'column', md: "column", lg: "row", xl: "row" }}>
                                                                <Box >
                                                                    <Checkbox onChange={onchnageloss}>ADD STOP LOSS</Checkbox>
                                                                    <Flex flexDirection={{ base: "column", sm: 'column', md: "column", lg: "row", xl: "row" }}
                                                                        alignItems={"center"}
                                                                        gap={{ base: "0px", sm: "0px", md: "0px", lg: "30px", xl: "30px" }}
                                                                    >

                                                                        <Box mt={{ base: "0px", sm: "0px", md: "0px", lg: "10px", xl: "10px" }}
                                                                            // maxW={{ base: "400px", sm: '400px', md: "400px", lg: "200px", xl: "200px" }}
                                                                            maxW={{ base: "100%", sm: '100%', md: "100%", lg: "100%", xl: "100%" }}
                                                                        >
                                                                            <Text>Rate</Text>
                                                                            <InputGroup alignItems={"center"} >
                                                                                <Input
                                                                                    size="sm"
                                                                                    w={{ base: "60%", sm: "60%", md: "60%", lg: "70%", xl: "70%" }}
                                                                                    borderRadius="none"
                                                                                    name="inputfixedvaluebid"
                                                                                    value={selectedSide === "Buy" ? inputfixedvalueask1 : inputfixedvaluebid1}
                                                                                    isDisabled={addStopLoss === false}
                                                                                    onChange={enterNewRateloss}

                                                                                />
                                                                                <InputRightElement position="relative">
                                                                                    <Flex position="absolute" right="-6" top="50%" transform="translateY(-50%)">
                                                                                        <Button
                                                                                            borderRadius="none"
                                                                                            color={"black"}

                                                                                            size="sm"
                                                                                            h={"31.8px"}
                                                                                            w={"30px"}
                                                                                            fontSize="sm"
                                                                                            isDisabled={addStopLoss === false}
                                                                                            onClick={Decbidvalue}
                                                                                        >
                                                                                            -
                                                                                        </Button>
                                                                                        <Button
                                                                                            bg={"rgb(255,218,69)"}
                                                                                            borderRadius="none"
                                                                                            color={"black"}
                                                                                            size="sm"
                                                                                            h={"31.8px"}
                                                                                            w={"30px"}
                                                                                            fontSize="sm"
                                                                                            isDisabled={addStopLoss === false || disbaledme === true}
                                                                                            onClick={Incbidvalue}
                                                                                        >
                                                                                            +
                                                                                        </Button>
                                                                                    </Flex>
                                                                                </InputRightElement>
                                                                            </InputGroup>
                                                                            <Text mt={"5px"}>{selectedSide === "Buy" ? above1ask : above2ask} ${selectedSide === "Buy" ? askincinput : bidincinput}</Text>
                                                                        </Box>
                                                                    </Flex>
                                                                </Box>
                                                                <Center fontWeight={"bold"}>
                                                                    <Divider fontWeight={"bold"} orientation='vertical' />
                                                                </Center>
                                                                <Box mt={{ base: "0px", sm: "0px", md: "0px", lg: "0px", xl: "0px" }}>
                                                                    <Checkbox textAlign={"right"} onChange={setaddprofit}>ADD TAKE PROFIT</Checkbox>
                                                                    <Flex flexDirection={{ base: "column", sm: 'column', md: "column", lg: "row", xl: "row" }}
                                                                        alignItems={"center"}
                                                                        gap={{ base: "0px", sm: "0px", md: "0px", lg: "30px", xl: "30px" }} >

                                                                        <Box mt={{ base: "0px", sm: "0px", md: "0px", lg: "10px", xl: "10px" }}
                                                                            maxW={{ base: "100%", sm: '100%', md: "100%", lg: "100%", xl: "100%" }}
                                                                        //  maxW={{ base: "400px", sm: '400px', md: "400px", lg: "160px", xl: "160px" }}
                                                                        >
                                                                            <Text>Rate</Text>
                                                                            <InputGroup alignItems={"center"}>
                                                                                <Input
                                                                                    size="sm"
                                                                                    w={{ base: "60%", sm: "60%", md: "60%", lg: "70%", xl: "70%" }}
                                                                                    borderRadius="none"
                                                                                    name="inputfixedvalueask1"
                                                                                    isDisabled={addTakeProfit === false}
                                                                                    value={selectedSide === "Buy" ? inputfixedvalueask2 : inputfixedvaluebid2}
                                                                                    onChange={enterNewAskprofit}

                                                                                />
                                                                                <InputRightElement position="relative">
                                                                                    <Flex position="absolute" right="-6" top="50%" transform="translateY(-50%)">
                                                                                        <Button
                                                                                            borderRadius="none"
                                                                                            color={"black"}
                                                                                            size="sm"
                                                                                            h={"31.8px"}
                                                                                            w={"30px"}
                                                                                            fontSize="sm"
                                                                                            isDisabled={addTakeProfit === false || disbaledme1 === true}
                                                                                            onClick={decinputask}
                                                                                        // Add onClick or other button properties as needed
                                                                                        >
                                                                                            -
                                                                                        </Button>
                                                                                        <Button
                                                                                            borderRadius="none"
                                                                                            bg={"rgb(255,218,69)"}
                                                                                            color={"black"}
                                                                                            size="sm"
                                                                                            h={"31.8px"}
                                                                                            w={"30px"}
                                                                                            fontSize="sm"
                                                                                            isDisabled={addTakeProfit === false || disbaledme1 === true}
                                                                                            onClick={incinputask}
                                                                                        >
                                                                                            +
                                                                                        </Button>
                                                                                    </Flex>
                                                                                </InputRightElement>
                                                                            </InputGroup>
                                                                            <Text mt={"5px"}>{selectedSide === "Buy" ? above2ask : above1ask} ${selectedSide === "Buy" ? askincinput : bidincinput}</Text>
                                                                        </Box>
                                                                    </Flex>
                                                                </Box>
                                                            </Flex>
                                                        </Box>
                                                    </AccordionPanel>
                                                </AccordionItem>
                                            </Accordion>
                                        </Box>

                                        <Box textAlign={'center'} mt={"15px"}>
                                            <Text>
                                                You need $ {price} of margin for this deal
                                            </Text>
                                            <Text>
                                                Each pip is worth $ {pip !== undefined ? pip.toFixed(2) : '0.00'}.
                                            </Text>
                                        </Box>


                                        <Box>
                                            <Button bg={"rgb(255,218,69)"} color='black' mt={'15px'} w={"100%"} onClick={() => MakeaDeal(price)}>
                                                MAKE A DEAL
                                            </Button>
                                        </Box>
                                        <Box mt={"20px"} alignContent={"center"}>
                                            <Accordion allowToggle>
                                                <AccordionItem>
                                                    <h2>
                                                        <AccordionButton bg={"rgb(221,221,221)"} _hover={{ cursor: "pointer" }}>
                                                            <Box as="span" flex='1' color={"black"}>
                                                                Live Chart
                                                            </Box>
                                                            <AccordionIcon color={"black"} />
                                                        </AccordionButton>
                                                    </h2>
                                                    <AccordionPanel pb={3}
                                                        // borderBottom="none"
                                                        boxShadow="none"
                                                    >
                                                        <AspectRatio >
                                                            <iframe

                                                                title='Trading'
                                                                src='https://s.tradingview.com/financemagnates/widgetembed/?hideideas=1&overrides=%7B%7D&enabled_features=%5B%5D&disabled_features=%5B%5D&locale=en#%7B%22symbol%22%3A%22FX%3AEURUSD%22%2C%22frameElementId%22%3A%22tradingview_4b970%22%2C%22interval%22%3A%221D%22%2C%22range%22%3A%2212M%22%2C%22hide_side_toolbar%22%3A%220%22%2C%22allow_symbol_change%22%3A%221%22%2C%22save_image%22%3A%221%22%2C%22studies%22%3A%22%5B%5D%22%2C%22theme%22%3A%22dark%22%2C%22style%22%3A%221%22%2C%22timezone%22%3A%22Etc%2FUTC%22%2C%22withdateranges%22%3A%221%22%2C%22studies_overrides%22%3A%22%7B%7D%22%2C%22utm_source%22%3A%22www.forexlive.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22chart%22%2C%22utm_term%22%3A%22FX%3AEURUSD%22%2C%22page-uri%22%3A%22www.forexlive.com%2FLiveCharts%22%7D'
                                                                allowFullScreen
                                                            />

                                                        </AspectRatio>
                                                    </AccordionPanel>
                                                </AccordionItem>
                                            </Accordion>
                                        </Box>
                                    </> : <><Loading /></>
                                }
                            </ModalBody>

                            <ModalFooter>


                            </ModalFooter>
                        </ModalContent>
                    </Modal>

                    <Box style={{
                        //     height: '100%'

                        // , overflow: 'hidden', 
                        display: 'flex', flexDirection: 'column'
                    }}>
                        <Flex style={{ height: '100%' }}

                            flexDirection={{ base: "column", sm: "column", md: "column", lg: "row", xl: "row" }}
                        // p={"10px"} 
                        >


                            <Box borderRight={"1px solid white"} style={{
                                // height: '100vh', overflow: 'hidden',

                                display: 'flex', flexDirection: 'column'
                            }}
                                boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                                w={{ base: "100%", sm: '100%', md: "100%", lg: "30%", xl: "30%" }}
                                // h={{ base: "100%", sm: '100%', md: "100%", lg: "93.2vh", xl: "93.2vh" }}
                                bg={"rgb(21,27,31)"}
                                color={"white"}


                            >


                                <Box mt={"0px"} mb={"5px"} p={"20px"} bg={"rgb(21,27,31)"}>

                                    {/* <Select onChange={setPagechange} value={setpagecurr} style={{ backgroundColor: "black", color: "white", border: "1px solid aqua" }}>
                                        <option style={{ backgroundColor: "black" }} value="currency">Currency</option>
                                        <option style={{ backgroundColor: "black" }} value="commodities">Commodities</option>
                                    </Select> */}
                                    <Flex justifyContent={"space-between"}>

                                        <Button size={"xs"} onClick={() => handlechangeCurrency("currency")} bg={"rgb(255,218,69)"} color={"black"} cursor={"pointer"}>Currency</Button>
                                        <Button size={"xs"} onClick={() => handlechangeCurrency("commodities")} bg={"rgb(255,218,69)"} color={"black"} cursor={"pointer"}>Commidities</Button>
                                        <Button size={"xs"} bg={"rgb(255,218,69)"} onClick={handleDemoLogin} color={"black"} cursor={"pointer"} display={{ base: "block", sm: "block", md: "block", lg: "none", xl: "none" }} >Demo</Button>
                                        <Button size={"xs"} bg={"rgb(255,218,69)"} color={"black"} cursor={"pointer"} display={{ base: "block", sm: "block", md: "block", lg: "none", xl: "none" }} onClick={openDrawer}> Summary</Button>
                                    </Flex>

                                </Box>
                                {
                                    setpagecurr === "currency" &&
                                    <TableContainer mt="5px">
                                        <Table size='sm'>
                                            <Thead>
                                                <Tr>
                                                    <Th textAlign="center">Symbol</Th>
                                                    <Th textAlign="center">Bid</Th>
                                                    <Th textAlign="center">Ask</Th>
                                                    <Th textAlign="center">Change</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {Object.entries(data).map(([currencyPair, ele], idx) => {
                                                    const overallColor = ele.bidColor === 'green' || ele.askColor === 'green' ? 'rgb(34,176,73)' :
                                                        ele.bidColor === 'red' || ele.askColor === 'red' ? 'red' : 'black';

                                                    return (
                                                        <Tr key={idx} borderColor="rgb(242,242,242)">
                                                            <Td borderColor="rgb(242,242,242)" textAlign="center">
                                                                {currencyPair.slice(0, 3)}/{currencyPair.slice(3)}
                                                            </Td>
                                                            <Td textAlign="center">
                                                                <Box
                                                                    style={{
                                                                        backgroundColor: ele.bidColor,
                                                                        color: 'white',
                                                                        padding: '5px'
                                                                    }}
                                                                    onClick={() => gotopage('Sell', ele, currencyPair)}
                                                                    cursor="pointer"
                                                                >
                                                                    {ele.bid}
                                                                </Box>
                                                            </Td>
                                                            <Td textAlign="center">
                                                                <Box
                                                                    style={{
                                                                        backgroundColor: ele.askColor,
                                                                        color: 'white',
                                                                        padding: '5px'
                                                                    }}
                                                                    onClick={() => gotopage('Buy', ele, currencyPair)}
                                                                    cursor="pointer"
                                                                >
                                                                    {ele.ask}
                                                                </Box>
                                                            </Td>
                                                            <Td>
                                                                <Box
                                                                    p="5px"
                                                                    gap="3px"
                                                                    alignItems="center"
                                                                    style={{
                                                                        backgroundColor: overallColor,
                                                                        color: overallColor === 'rgb(34,176,73)' || overallColor === 'red' ? 'black' : 'inherit',
                                                                        textAlign: 'center'
                                                                    }}
                                                                >
                                                                    {ele.bidColor === 'red' ? <ArrowDownIcon color="white" /> : <ArrowUpIcon color="white" />}
                                                                </Box>
                                                            </Td>
                                                        </Tr>
                                                    );
                                                })}
                                            </Tbody>
                                        </Table>
                                    </TableContainer>
                                }

                                {
                                    setpagecurr === "commodities" &&
                                    <TableContainer mt="5px">
                                        <Table size='sm'>
                                            <Thead>
                                                <Tr>
                                                    <Th textAlign="center">Symbol</Th>
                                                    <Th textAlign="center">Bid</Th>
                                                    <Th textAlign="center">Ask</Th>
                                                    <Th textAlign="center">Change</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {Object.entries(commidity).map(([currencyPair, ele], idx) => {
                                                    const overallColor = ele.bidColor === 'green' || ele.askColor === 'green' ? 'rgb(34,176,73)' :
                                                        ele.bidColor === 'red' || ele.askColor === 'red' ? 'red' : 'black';

                                                    return (
                                                        <Tr key={idx} borderColor="rgb(242,242,242)">
                                                            <Td borderColor="rgb(242,242,242)" textAlign="center">
                                                                {ele.currencyPair.slice(0, 3)}/{ele.currencyPair.slice(3)}
                                                            </Td>
                                                            <Td textAlign="center">
                                                                <Box
                                                                    style={{
                                                                        backgroundColor: ele.bidColor,
                                                                        color: 'white',
                                                                        padding: '5px'
                                                                    }}
                                                                    onClick={() => gotopage('Sell', ele, currencyPair)}
                                                                    cursor="pointer"
                                                                >
                                                                    {ele.bid}
                                                                </Box>
                                                            </Td>
                                                            <Td textAlign="center">
                                                                <Box
                                                                    style={{
                                                                        backgroundColor: ele.askColor,
                                                                        color: 'white',
                                                                        padding: '5px'
                                                                    }}
                                                                    onClick={() => gotopage('Buy', ele, currencyPair)}
                                                                    cursor="pointer"
                                                                >
                                                                    {ele.ask}
                                                                </Box>
                                                            </Td>
                                                            <Td>
                                                                <Box
                                                                    p="5px"
                                                                    gap="3px"
                                                                    alignItems="center"
                                                                    style={{
                                                                        backgroundColor: overallColor,
                                                                        color: overallColor === 'rgb(34,176,73)' || overallColor === 'red' ? 'black' : 'inherit',
                                                                        textAlign: 'center'
                                                                    }}
                                                                >
                                                                    {ele.bidColor === 'red' ? <ArrowDownIcon color="white" /> : <ArrowUpIcon color="white" />}
                                                                </Box>
                                                            </Td>
                                                        </Tr>
                                                    );
                                                })}
                                            </Tbody>
                                        </Table>
                                    </TableContainer>
                                }
                            </Box>

                            <Box width={"70%"} w={{ base: "100%", sm: "100%", md: "100%", lg: "70%", xl: "70%" }}


                            >
                                <Box width={"100%"} >
                                    <Box w={"100%"} h={"100%"} overflow="hidden"
                                        // border="1px solid blue" 
                                        boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                                        // p={"10px"}
                                        display={{ base: "none", sm: 'none', md: "none", lg: "block", xl: "block" }}>
                                        <AspectRatio  >
                                            <iframe
                                                style={{ width: '100%', height: '100%' }}
                                                title='Trading'
                                                src='https://s.tradingview.com/financemagnates/widgetembed/?hideideas=1&overrides=%7B%7D&enabled_features=%5B%5D&disabled_features=%5B%5D&locale=en#%7B%22symbol%22%3A%22FX%3AEURUSD%22%2C%22frameElementId%22%3A%22tradingview_4b970%22%2C%22interval%22%3A%221D%22%2C%22range%22%3A%2212M%22%2C%22hide_side_toolbar%22%3A%220%22%2C%22allow_symbol_change%22%3A%221%22%2C%22save_image%22%3A%221%22%2C%22studies%22%3A%22%5B%5D%22%2C%22theme%22%3A%22dark%22%2C%22style%22%3A%221%22%2C%22timezone%22%3A%22Etc%2FUTC%22%2C%22withdateranges%22%3A%221%22%2C%22studies_overrides%22%3A%22%7B%7D%22%2C%22utm_source%22%3A%22www.forexlive.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22chart%22%2C%22utm_term%22%3A%22FX%3AEURUSD%22%2C%22page-uri%22%3A%22www.forexlive.com%2FLiveCharts%22%7D'
                                            // allowFullScreen
                                            />
                                            {/* <iframe id="tradingview_83bf5" name="tradingview_83bf5" src="blob:https://my.exness.com/8fc2be8e-e99e-47f4-9e18-e580fb5335c2" 
                                data-widget-options="symbol=USD%2FJPY&amp;interval=1&amp;widgetbar=%7B%22details%22%3Afalse%2C%22watchlist%22%3Afalse%2C%22news%22%3Afalse%2C%22datawindow%22%3Afalse%2C%22watchlist_settings%22%3A%7B%22default_symbols%22%3A%5B%5D%7D%7D&amp;timeFrames=%5B%7B%22text%22%3A%225y%22%2C%22resolution%22%3A%221W%22%7D%2C%7B%22text%22%3A%221y%22%2C%22resolution%22%3A%221W%22%7D%2C%7B%22text%22%3A%226m%22%2C%22resolution%22%3A%22120%22%7D%2C%7B%22text%22%3A%223m%22%2C%22resolution%22%3A%2260%22%7D%2C%7B%22text%22%3A%221m%22%2C%22resolution%22%3A%2230%22%7D%2C%7B%22text%22%3A%225d%22%2C%22resolution%22%3A%225%22%7D%2C%7B%22text%22%3A%221d%22%2C%22resolution%22%3A%221%22%7D%5D&amp;locale=en&amp;uid=tradingview_83bf5&amp;clientId=0&amp;userId=0&amp;chartsStorageVer=1.0&amp;customCSS=%2Fwebtrading%2Ftrading_view%2Fcustom.css%3Fv2&amp;debug=false&amp;timezone=Etc%2FUTC&amp;theme=dark"
                                 title="Financial Chart" frameborder="0" allowtransparency="true" scrolling="no" 
                                allowfullscreen="" >
                                </iframe> */}
                                        </AspectRatio>
                                    </Box>
                                    <Box bg={"rgb(21,27,31)"} color={"white"} >
                                        <Box
                                            boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px">


                                            <Box pt={"10px"}>
                                                <Flex p={"8px"} alignItems={"center"} justifyContent={"space-between"}>
                                                    <Box >
                                                        <Flex gap={"10px"} alignItems={"center"} >
                                                            <Text onClick={() => handletablechange("open")}
                                                                fontWeight={"bold"}
                                                                cursor={"pointer"}
                                                                fontSize={{ base: "xs", sm: "xs", md: "xs", lg: "xl", xl: "xl" }}
                                                                style={{ textDecoration: table === "open" ? "underline" : "none", color: table === "open" ? "rgb(255,218,69)" : "white" }}
                                                            >Open Orders</Text>
                                                            <Text onClick={() => handletablechange("pending")}
                                                                fontWeight={"bold"}
                                                                cursor={"pointer"}
                                                                fontSize={{ base: "xs", sm: "xs", md: "xs", lg: "xl", xl: "xl" }}
                                                                style={{ textDecoration: table === "pending" ? "underline" : "none", color: table === "pending" ? "rgb(255,218,69)" : "white" }}
                                                            >Pending Orders</Text>
                                                            <Text onClick={() => handletablechange("closed")}
                                                                fontWeight={"bold"}
                                                                cursor={"pointer"}
                                                                fontSize={{ base: "xs", sm: "xs", md: "xs", lg: "xl", xl: "xl" }}
                                                                style={{ textDecoration: table === "closed" ? "underline" : "none", color: table === "closed" ? "rgb(255,218,69)" : "white" }}
                                                            >Closed Deals</Text>
                                                        </Flex>
                                                    </Box>
                                                    <Box >

                                                        <Button color={"black"}
                                                            size={{ base: "xs", sm: "xs", md: "xs", lg: "lg", xl: "lg" }}

                                                            bg={"rgb(255,218,69)"} onClick={() => gotopage('Buy', null, 'EURUSD')}>New Deal</Button>


                                                    </Box>
                                                </Flex>
                                                <Box mt={"20px"}>
                                                    {table === "open" && <TableContainer >
                                                        <Table variant='simple' colorScheme='teal'>
                                                            {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
                                                            <Thead bg={"rgb(231,236,241)"}
                                                            >
                                                                <Tr textAlign={"center"}>
                                                                    <Th>
                                                                        <Flex direction={"column"} gap={"10px"}>

                                                                            <Box textAlign={"center"}>Order Type</Box>
                                                                        </Flex>
                                                                    </Th>
                                                                    <Th>
                                                                        <Flex direction={"column"} gap={"10px"}>
                                                                            <Box textAlign={"center"}>Lot Size</Box>
                                                                        </Flex>
                                                                    </Th>
                                                                    <Th textAlign={"center"}>Open Time</Th>
                                                                    <Th textAlign={"center"}>Open Rate</Th>
                                                                    <Th textAlign={"center"}>Market Rate</Th>
                                                                    <Th textAlign={"center"}>Net Profit</Th>
                                                                    <Th>
                                                                        <Flex direction={"column"} gap={"10px"}>
                                                                            <Box textAlign={"center"}>P/L ( {totalNetProfit.toFixed(2)}) </Box>

                                                                        </Flex>
                                                                    </Th>
                                                                    <Th>
                                                                        <Button
                                                                            onClick={closeAllDeal}
                                                                            color={"black"}
                                                                            bg={"rgb(255,218,69)"}
                                                                            _hover={{ cursor: "pointer" }}
                                                                            size={"xs"}

                                                                        >
                                                                            Close All Deals
                                                                        </Button>
                                                                    </Th>
                                                                </Tr>
                                                            </Thead>
                                                            <Tbody>
                                                                {
                                                                    variousdeal.length > 0 ? variousdeal.map((elem, idx) => {

                                                                        return <Tr key={idx} textAlign={"center"}>
                                                                            <Td >
                                                                                <Flex direction={"column"} gap={"10px"}>
                                                                                    <Box textAlign={"center"}>{elem.order_id}</Box>
                                                                                    <Box textAlign={"center"}>{elem.order_type}</Box>
                                                                                </Flex>
                                                                            </Td>
                                                                            <Td textAlign={"center"}>

                                                                                <Flex direction={"column"} gap={"10px"}>
                                                                                    <Box textAlign={"center"}> {elem.title && elem.title.length === 6 ? (
                                                                                        `${elem.title.slice(0, 3)}/${elem.title.slice(3)}`
                                                                                    ) : (
                                                                                        elem.title
                                                                                    )}</Box>
                                                                                    <Box textAlign={"center"}>{elem.lotsize}</Box>
                                                                                </Flex>
                                                                            </Td>
                                                                            <Td textAlign={"center"}> {
                                                                                new Intl.DateTimeFormat('en-GB', {
                                                                                    timeZone: 'Europe/London',
                                                                                    day: 'numeric',
                                                                                    month: 'short',
                                                                                    year: 'numeric',
                                                                                    hour: 'numeric',
                                                                                    minute: 'numeric',
                                                                                    second: 'numeric',
                                                                                    hour12: false,
                                                                                }).format(new Date(elem.created_at))
                                                                                    .replace(/ /, '-')
                                                                                    .replace(/,/g, '')
                                                                            }</Td>
                                                                            <Td textAlign={"center"}>{elem.bidorask}</Td>
                                                                            <Td textAlign={"center"}>{Object.entries(data).map(([currencyPair, ele], idx) => {
                                                                                const baseCurrency = currencyPair.substring(0, 3);
                                                                                const quoteCurrency = currencyPair.substring(3, 6);
                                                                                const formattedCurrencyPair = `${baseCurrency}/${quoteCurrency}`

                                                                                if (elem.title === formattedCurrencyPair) {

                                                                                    return elem.order_type === "Buy" ? (ele.bid).toFixed(5) : (ele.ask).toFixed(5);
                                                                                }
                                                                                return null; // or handle the case when elem.title doesn't match currencyPair
                                                                            })}
                                                                            </Td>
                                                                            <Td textAlign={"center"}>

                                                                                $
                                                                                {Object.entries(data).map(([currencyPair, ele], idx) => {
                                                                                    const baseCurrency = currencyPair.substring(0, 3);
                                                                                    const quoteCurrency = currencyPair.substring(3, 6);
                                                                                    const formattedCurrencyPair = `${baseCurrency}/${quoteCurrency}`
                                                                                    if (elem.title === "XAU/USD") {
                                                                                        if (elem.title === formattedCurrencyPair) {


                                                                                            if (elem.lotsize === "0.01") {
                                                                                                return elem.order_type === "Buy" ? ((parseFloat(ele.bid - elem.bidorask)) - parseFloat(8)).toFixed(2) : ((parseFloat(elem.bidorask - ele.ask)) - parseFloat(8)).toFixed(2);
                                                                                            } else if (elem.lotsize === "0.02") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(16)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(16)).toFixed(2);
                                                                                            } else if (elem.lotsize === "0.03") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(24)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(24)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.04") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(32)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(32)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.05") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(40)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(40)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.06") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(48)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(48)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.07") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(56)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(56)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.08") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(64)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(64)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.09") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(72)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(72)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.1") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(80)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(80)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.2") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(160)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(160)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.3") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(240)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(240)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.4") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(320)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(320)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.5") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(400)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(400)).toFixed(2);
                                                                                            }

                                                                                            else if (elem.lotsize === "0.6") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(480)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(480)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.7") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(560)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(560)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.8") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(640)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(640)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.9") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(720)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(720)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "1") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(800)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(800)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "2") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(1600)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(1600)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "3") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(2400)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(2400)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "4") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(3200)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(3200)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "5") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(4000)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(4000)).toFixed(2);
                                                                                            }
                                                                                        }
                                                                                        return null; // or handle the case when elem.title doesn't match currencyPair
                                                                                    } else if (elem.title === "NZD/CAD" || elem.title === "EUR/CHF" || elem.title === "EUR/GBP" || elem.title === "NZD/USD" || elem.title === "USD/CHF" || elem.title === "AUD/USD") {
                                                                                        if (elem.title === formattedCurrencyPair) {


                                                                                            if (elem.lotsize === "0.01") {
                                                                                                return elem.order_type === "Buy" ? ((parseFloat(ele.bid - elem.bidorask) * 1000) - parseFloat(0.3)).toFixed(2) : ((parseFloat(elem.bidorask - ele.ask) * 1000) - parseFloat(0.3)).toFixed(2);
                                                                                            } else if (elem.lotsize === "0.02") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(0.6)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(0.6)).toFixed(2);
                                                                                            } else if (elem.lotsize === "0.03") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(0.9)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(0.9)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.04") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(1.2)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(1.2)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.05") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(1.5)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(1.5)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.06") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(1.8)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(1.8)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.07") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(2.1)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(2.1)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.08") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(2.4)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(2.4)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.09") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(2.7)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(2.7)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.1") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(3)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(3)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.2") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(6)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(6)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.3") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(9)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(9)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.4") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(12)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(12)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.5") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(15)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(15)).toFixed(2);
                                                                                            }

                                                                                            else if (elem.lotsize === "0.6") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(18)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(18)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.7") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(21)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(21)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.8") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(24)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(24)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.9") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(27)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(27)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "1") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(30)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(30)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "2") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(60)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(60)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "3") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(90)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(90)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "4") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(120)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(120)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "5") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(150)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(150)).toFixed(2);
                                                                                            }
                                                                                        }
                                                                                        return null; // or handle the case when elem.title doesn't match currencyPair
                                                                                    }
                                                                                    else if (elem.title === "XAG/USD") {
                                                                                        if (elem.title === formattedCurrencyPair) {

                                                                                            if (elem.lotsize === "0.01") {
                                                                                                return elem.order_type === "Buy" ? ((parseFloat(ele.bid - elem.bidorask)) - parseFloat(2)).toFixed(2) : ((parseFloat(elem.bidorask - ele.ask)) - parseFloat(2)).toFixed(2);
                                                                                            } else if (elem.lotsize === "0.02") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(4)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(4)).toFixed(2);
                                                                                            } else if (elem.lotsize === "0.03") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(6)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(6)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.04") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(8)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(8)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.05") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(10)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(10)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.06") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(12)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(12)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.07") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(14)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(14)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.08") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(16)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(16)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.09") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(18)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(18)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.1") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(20)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(20)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.2") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(40)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(40)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.3") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(60)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(60)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.4") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(80)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(80)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.5") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(100)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(100)).toFixed(2);
                                                                                            }

                                                                                            else if (elem.lotsize === "0.6") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(120)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(120)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.7") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(140)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(140)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.8") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(160)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(160)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.9") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(180)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(180)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "1") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(200)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(200)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "2") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(400)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(400)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "3") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(600)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(600)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "4") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(800)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(800)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "5") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(1000)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(1000)).toFixed(2);
                                                                                            }
                                                                                        }
                                                                                        return null; // or handle the case when elem.title doesn't match currencyPair
                                                                                    }


                                                                                    else if (elem.title === "USD/JPY" || elem.title === "EUR/JPY" || elem.title === "GBP/JPY" || elem.title === "AUD/JPY" || elem.title === "CAD/JPY") {
                                                                                        if (elem.title === formattedCurrencyPair) {


                                                                                            if (elem.lotsize === "0.01") {
                                                                                                return elem.order_type === "Buy" ? ((parseFloat(ele.bid - elem.bidorask)) - parseFloat(0.3)).toFixed(2) : ((parseFloat(elem.bidorask - ele.ask)) - parseFloat(0.3)).toFixed(2);
                                                                                            } else if (elem.lotsize === "0.02") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(0.6)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(0.6)).toFixed(2);
                                                                                            } else if (elem.lotsize === "0.03") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(0.9)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(0.9)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.04") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(1.2)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(1.2)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.05") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(1.5)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(1.5)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.06") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(1.8)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(1.8)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.07") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(2.1)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(2.1)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.08") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(2.4)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(2.4)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.09") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(2.7)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(2.7)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.1") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(3)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(3)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.2") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(6)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(6)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.3") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(9)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(9)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.4") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(12)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(12)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.5") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(15)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(15)).toFixed(2);
                                                                                            }

                                                                                            else if (elem.lotsize === "0.6") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(18)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(18)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.7") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(21)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(21)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.8") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(24)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(24)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.9") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(27)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(27)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "1") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(30)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(30)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "2") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(60)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(60)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "3") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(90)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(90)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "4") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(120)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(120)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "5") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask))) - parseFloat(150)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask))) - parseFloat(150)).toFixed(2);
                                                                                            }
                                                                                        }
                                                                                        return null; // or handle the case when elem.title doesn't match currencyPair
                                                                                    } else {
                                                                                        if (elem.title === formattedCurrencyPair) {


                                                                                            if (elem.lotsize === "0.01") {
                                                                                                return elem.order_type === "Buy" ? ((parseFloat(ele.bid - elem.bidorask) * 1000) - parseFloat(0.3)).toFixed(2) : ((parseFloat(elem.bidorask - ele.ask) * 1000) - parseFloat(0.3)).toFixed(2);
                                                                                            } else if (elem.lotsize === "0.02") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(0.6)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(0.6)).toFixed(2);
                                                                                            } else if (elem.lotsize === "0.03") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(0.9)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(0.9)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.04") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(1.2)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(1.2)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.05") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(1.5)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(1.5)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.06") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(1.8)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(1.8)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.07") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(2.1)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(2.1)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.08") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(2.4)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(2.4)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.09") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 1000)) - parseFloat(2.7)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 1000)) - parseFloat(2.7)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.1") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 10000)) - parseFloat(3)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 10000)) - parseFloat(3)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.2") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 10000)) - parseFloat(6)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 10000)) - parseFloat(6)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.3") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 10000)) - parseFloat(9)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 10000)) - parseFloat(9)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.4") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 10000)) - parseFloat(12)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 10000)) - parseFloat(12)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.5") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 10000)) - parseFloat(15)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 10000)) - parseFloat(15)).toFixed(2);
                                                                                            }

                                                                                            else if (elem.lotsize === "0.6") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 10000)) - parseFloat(18)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 10000)) - parseFloat(18)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.7") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 10000)) - parseFloat(21)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 10000)) - parseFloat(21)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.8") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 10000)) - parseFloat(24)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 10000)) - parseFloat(24)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "0.9") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 10000)) - parseFloat(27)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 10000)) - parseFloat(27)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "1") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 100000)) - parseFloat(30)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 100000)) - parseFloat(30)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "2") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 100000)) - parseFloat(60)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 100000)) - parseFloat(60)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "3") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 100000)) - parseFloat(90)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 100000)) - parseFloat(90)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "4") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 100000)) - parseFloat(120)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 100000)) - parseFloat(120)).toFixed(2);
                                                                                            }
                                                                                            else if (elem.lotsize === "5") {
                                                                                                return elem.order_type === "Buy" ? (((parseFloat(ele.bid - elem.bidorask) * 100000)) - parseFloat(150)).toFixed(2) : (((parseFloat(elem.bidorask - ele.ask) * 100000)) - parseFloat(150)).toFixed(2);
                                                                                            }
                                                                                        }
                                                                                        return null; // or handle the case when elem.title doesn't match currencyPair
                                                                                    }
                                                                                })}


                                                                            </Td>

                                                                            <Td>
                                                                                <Flex direction={"column"} gap={"10px"}>
                                                                                    <Box textAlign={"center"}>
                                                                                        {elem.takeprofit === null ? <Button size='xs'
                                                                                            width="100%"  // Set explicit width
                                                                                            //  height="40px" // Set explicit height
                                                                                            onClick={() => fetchprofitdeal(elem.order_id)} colorScheme="green"
                                                                                        // colorScheme={elem.takeprofit === null ? "green" :elem.takeprofit}
                                                                                        >Take profit</Button> : elem.takeprofit}
                                                                                    </Box>
                                                                                    <Modal isOpen={isModal2Open} onClose={closeModal2} size="md">
                                                                                        <ModalOverlay />
                                                                                        <ModalContent bg={"rgb(55,67,78)"} color={"white"}>
                                                                                            <ModalHeader>{takeprofitdeal.order_id}</ModalHeader>
                                                                                            <ModalCloseButton />
                                                                                            <ModalBody>
                                                                                                <Box>
                                                                                                    <Grid templateColumns={{ base: "repeat(1, 1fr)", sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)", lg: "repeat(1, 1fr)", xl: "repeat(1, 1fr)" }} alignItems={"center"}>
                                                                                                        <GridItem w='100%' h='8' border={"1px solid black"}><Flex alignItems={"center"} >
                                                                                                            <Text p={"5px"} fontWeight={"bold"}>Instrument : </Text>
                                                                                                            <Text p={"5px"}>{takeprofitdeal.title}</Text>
                                                                                                        </Flex></GridItem>
                                                                                                        <GridItem w='100%' h='8' border={"1px solid black"}> <Flex>
                                                                                                            <Text p={"5px"} fontWeight={"bold"}>Order Type:</Text>
                                                                                                            <Text p={"5px"}>{takeprofitdeal.order_type}</Text>
                                                                                                        </Flex></GridItem>
                                                                                                        <GridItem w='100%' h='8' border={"1px solid black"}><Flex>
                                                                                                            <Text p={"5px"} fontWeight={"bold"}>Order Date : </Text>
                                                                                                            <Text p={"5px"}>{new Date(takeprofitdeal.created_at).toLocaleString('en-GB', {
                                                                                                                timeZone: 'Europe/London',
                                                                                                                day: 'numeric',
                                                                                                                month: 'numeric',
                                                                                                                year: 'numeric',
                                                                                                                hour: '2-digit',
                                                                                                                minute: '2-digit',
                                                                                                                second: '2-digit'
                                                                                                            })}</Text>
                                                                                                        </Flex></GridItem>
                                                                                                        <GridItem w='100%' h='8' border={"1px solid black"} ><Flex>
                                                                                                            <Text p={"5px"} fontWeight={"bold"}>Lot Size :</Text>
                                                                                                            <Text p={"5px"}>{takeprofitdeal.lotsize}</Text>
                                                                                                        </Flex></GridItem>

                                                                                                    </Grid>
                                                                                                    <Grid templateColumns={{ base: "repeat(1, 1fr)", sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)", lg: "repeat(2, 1fr)", xl: "repeat(2, 1fr)" }} >
                                                                                                        <GridItem w='100%' h='8' border={"1px solid black"}><Flex>
                                                                                                            <Text p={"5px"} fontWeight={"bold"}>Open Price : </Text>
                                                                                                            <Text p={"5px"}>{takeprofitdeal.bidorask}</Text>
                                                                                                        </Flex></GridItem>
                                                                                                        <GridItem w='100%' h='8' border={"1px solid black"}><Flex>
                                                                                                            <Text p={"5px"} fontWeight={"bold"}>Current Price:</Text>
                                                                                                            <Text p={"5px"}>{Object.entries(data).map(([currencyPair, ele], idx) => {
                                                                                                                // const ele = dataArray && dataArray.length > 0 ? dataArray[0] : {};
                                                                                                                const baseCurrency = currencyPair.substring(0, 3);
                                                                                                                const quoteCurrency = currencyPair.substring(3, 6);
                                                                                                                const formattedCurrencyPair = `${baseCurrency}/${quoteCurrency}`
                                                                                                                if (takeprofitdeal.title === formattedCurrencyPair) {
                                                                                                                    return takeprofitdeal.order_type === "Buy" ? ele.bid : ele.ask;
                                                                                                                }
                                                                                                                return null; // or handle the case when elem.title doesn't match currencyPair
                                                                                                            })}</Text>
                                                                                                        </Flex></GridItem>
                                                                                                    </Grid>
                                                                                                    <Box mt={"20px"} p={"5px"} alignContent={"center"}>
                                                                                                        <Accordion allowToggle>
                                                                                                            <AccordionItem>
                                                                                                                <h2>
                                                                                                                    <AccordionButton bg={"rgb(221,221,221)"} _hover={{ cursor: "pointer" }}>
                                                                                                                        <Box as="span" flex='1' color={"black"}>
                                                                                                                            Add Stop Loss/Take Profit
                                                                                                                        </Box>
                                                                                                                        <AccordionIcon />
                                                                                                                    </AccordionButton>
                                                                                                                </h2>
                                                                                                                <AccordionPanel pb={4}>
                                                                                                                    <Box>
                                                                                                                        <Flex justifyContent={"space-between"} flexDirection={{ base: "column", sm: 'column', md: "column", lg: "column", xl: "column" }}>
                                                                                                                            <Box>
                                                                                                                                <Checkbox onChange={onchnageloss1}>ADD STOP LOSS</Checkbox>
                                                                                                                                <Flex alignItems={"center"} gap={"30px"}>
                                                                                                                                    <Box mt={"10px"} maxW={"120px"}>
                                                                                                                                        <Text>Amount in $</Text>
                                                                                                                                        <InputGroup alignItems={"center"}>
                                                                                                                                            <Input
                                                                                                                                                size="sm"
                                                                                                                                                w={"50%"}
                                                                                                                                                borderRadius={"none"}
                                                                                                                                                value={takeprofitdeal.order_type === "Buy" ? dollorPricebid11 : dollorPriceask11}
                                                                                                                                                name="dollarPrice"
                                                                                                                                                onChange={Inputdollar11}

                                                                                                                                            />
                                                                                                                                            <InputRightElement position="relative">
                                                                                                                                                <Flex position="absolute" right="-6" top="50%" transform="translateY(-50%)">
                                                                                                                                                    <Button
                                                                                                                                                        borderRadius="none"
                                                                                                                                                        color={"black"}
                                                                                                                                                        size="sm"
                                                                                                                                                        h={"31.8px"}
                                                                                                                                                        w={"30px"}
                                                                                                                                                        fontSize="sm"
                                                                                                                                                        isDisabled={addStopLoss1 === false || takeprofitdeal.order_type === "Buy" ? dollorPricebid1 === 0 : dollorPriceask1}
                                                                                                                                                        onChange={handleSubDollar11}
                                                                                                                                                    >
                                                                                                                                                        -
                                                                                                                                                    </Button>
                                                                                                                                                    <Button
                                                                                                                                                        bg={"rgb(255,218,69)"}
                                                                                                                                                        borderRadius="none"
                                                                                                                                                        color={"black"}
                                                                                                                                                        size="sm"
                                                                                                                                                        h={"31.8px"}
                                                                                                                                                        w={"30px"}
                                                                                                                                                        fontSize="sm"
                                                                                                                                                        isDisabled={addStopLoss1 === false}
                                                                                                                                                        onClick={handleaddDollar11}

                                                                                                                                                    >
                                                                                                                                                        +
                                                                                                                                                    </Button>
                                                                                                                                                </Flex>
                                                                                                                                            </InputRightElement>
                                                                                                                                        </InputGroup>
                                                                                                                                        <Text mt={"5px"}>Above $0.00</Text>
                                                                                                                                    </Box>
                                                                                                                                    <Box>
                                                                                                                                        <Text fontWeight={"bold"}>OR</Text>
                                                                                                                                    </Box>
                                                                                                                                    <Box mt={"10px"} maxW={"160px"}>
                                                                                                                                        <Text>Rate</Text>
                                                                                                                                        <InputGroup alignItems={"center"}>
                                                                                                                                            <Input
                                                                                                                                                size="sm"
                                                                                                                                                borderRadius="none"
                                                                                                                                                w={"60%"}
                                                                                                                                                isDisabled={addStopLoss1 === false}
                                                                                                                                                value={
                                                                                                                                                    takeprofitdeal.stoploss === null || takeprofitdeal.stoploss === ""
                                                                                                                                                        ? (takeprofitdeal.order_type === "Buy" ? inputfixedvalueask11 : inputfixedvaluebid11)
                                                                                                                                                        : takeprofitdeal.stoploss
                                                                                                                                                }
                                                                                                                                                readOnly

                                                                                                                                            />
                                                                                                                                            <InputRightElement position="relative">
                                                                                                                                                <Flex position="absolute" right="-6" top="50%" transform="translateY(-50%)">
                                                                                                                                                    <Button
                                                                                                                                                        borderRadius="none"
                                                                                                                                                        color={"black"}
                                                                                                                                                        size="sm"
                                                                                                                                                        h={"31.8px"}
                                                                                                                                                        w={"30px"}
                                                                                                                                                        fontSize="sm"
                                                                                                                                                        isDisabled={addStopLoss1 === false}
                                                                                                                                                        onClick={Decbidvalue0}

                                                                                                                                                    >
                                                                                                                                                        -
                                                                                                                                                    </Button>
                                                                                                                                                    <Button
                                                                                                                                                        bg={"rgb(255,218,69)"}
                                                                                                                                                        borderRadius="none"
                                                                                                                                                        color={"black"}
                                                                                                                                                        size="sm"
                                                                                                                                                        h={"31.8px"}
                                                                                                                                                        w={"30px"}
                                                                                                                                                        fontSize="sm"
                                                                                                                                                        isDisabled={addStopLoss1 === false}
                                                                                                                                                        onClick={Incbidvalue0}

                                                                                                                                                    >
                                                                                                                                                        +
                                                                                                                                                    </Button>
                                                                                                                                                </Flex>
                                                                                                                                            </InputRightElement>
                                                                                                                                        </InputGroup>
                                                                                                                                        <Text mt={"5px"}>{takeprofitdeal.order_type === "Buy" ? above11ask : above21ask}${Object.entries(data).map(([currencyPair, ele], idx) => {
                                                                                                                                            const baseCurrency = currencyPair.substring(0, 3);
                                                                                                                                            const quoteCurrency = currencyPair.substring(3, 6);
                                                                                                                                            const formattedCurrencyPair = `${baseCurrency}/${quoteCurrency}`;
                                                                                                                                            if (takeprofitdeal.title === formattedCurrencyPair) {
                                                                                                                                                return takeprofitdeal.order_type === "Buy" ? ele.bid : ele.ask;
                                                                                                                                            }
                                                                                                                                            return null; // or handle the case when elem.title doesn't match currencyPair
                                                                                                                                        })}</Text>
                                                                                                                                    </Box>
                                                                                                                                </Flex>
                                                                                                                            </Box>
                                                                                                                            <Center fontWeight={"bold"}>
                                                                                                                                <Divider fontWeight={"bold"} orientation='vertical' />
                                                                                                                            </Center>
                                                                                                                            <Box mt={{ base: "25px", sm: '25px', md: "25px", lg: "25px", xl: "25px" }}>
                                                                                                                                <Checkbox textAlign={"right"} onChange={setaddprofit1}>ADD TAKE PROFIT</Checkbox>
                                                                                                                                <Flex alignItems={"center"} gap={"30px"}>
                                                                                                                                    <Box mt={"10px"} maxW={"120px"}>
                                                                                                                                        <Text>Amount in $</Text>
                                                                                                                                        <InputGroup alignItems={"center"}>
                                                                                                                                            <Input
                                                                                                                                                size="sm"
                                                                                                                                                w={"50%"}
                                                                                                                                                borderRadius="none"
                                                                                                                                                isDisabled={addTakeProfit1 === false}
                                                                                                                                                value={takeprofitdeal.order_type === "Buy" ? dollorPricebid21 : dollorPriceask21}
                                                                                                                                                onChange={dollarPriceinput0}

                                                                                                                                            />
                                                                                                                                            <InputRightElement position="relative">
                                                                                                                                                <Flex position="absolute" right="-6" top="50%" transform="translateY(-50%)">
                                                                                                                                                    <Button
                                                                                                                                                        borderRadius="none"
                                                                                                                                                        color={"black"}
                                                                                                                                                        size="sm"
                                                                                                                                                        h={"31.8px"}
                                                                                                                                                        w={"30px"}
                                                                                                                                                        fontSize="sm"
                                                                                                                                                        isDisabled={addTakeProfit1 === false}
                                                                                                                                                        onClick={handleSubDollar0}


                                                                                                                                                    >
                                                                                                                                                        -
                                                                                                                                                    </Button>
                                                                                                                                                    <Button
                                                                                                                                                        borderRadius="none"
                                                                                                                                                        bg={"rgb(255,218,69)"}
                                                                                                                                                        color={"black"}
                                                                                                                                                        size="sm"
                                                                                                                                                        h={"31.8px"}
                                                                                                                                                        w={"30px"}
                                                                                                                                                        fontSize="sm"
                                                                                                                                                        onClick={handleaddDollar0}
                                                                                                                                                        isDisabled={addTakeProfit1 === false}


                                                                                                                                                    >
                                                                                                                                                        +
                                                                                                                                                    </Button>
                                                                                                                                                </Flex>
                                                                                                                                            </InputRightElement>
                                                                                                                                        </InputGroup>
                                                                                                                                        <Text mt={"5px"}>Above $0.00</Text>
                                                                                                                                    </Box>
                                                                                                                                    <Box>
                                                                                                                                        <Text fontWeight={"bold"}>OR</Text>
                                                                                                                                    </Box>
                                                                                                                                    <Box mt={"10px"} maxW={"160px"}>
                                                                                                                                        <Text>Rate</Text>
                                                                                                                                        <InputGroup alignItems={"center"}>
                                                                                                                                            <Input
                                                                                                                                                size="sm"
                                                                                                                                                borderRadius="none"
                                                                                                                                                w={"60%"}

                                                                                                                                                value={
                                                                                                                                                    takeprofitdeal.takeprofit === null || takeprofitdeal.takeprofit === ""
                                                                                                                                                        ? (takeprofitdeal.order_type === "Buy" ? inputfixedvalueask21 : inputfixedvaluebid21)
                                                                                                                                                        : takeprofitdeal.takeprofit
                                                                                                                                                }
                                                                                                                                                readOnly
                                                                                                                                                isDisabled={addTakeProfit1 === false}


                                                                                                                                            />
                                                                                                                                            <InputRightElement position="relative">
                                                                                                                                                <Flex position="absolute" right="-6" top="50%" transform="translateY(-50%)">
                                                                                                                                                    <Button
                                                                                                                                                        borderRadius="none"
                                                                                                                                                        color={"black"}
                                                                                                                                                        size="sm"
                                                                                                                                                        h={"31.8px"}
                                                                                                                                                        w={"30px"}
                                                                                                                                                        fontSize="sm"
                                                                                                                                                        isDisabled={addTakeProfit1 === false || disbaledme21 === true}
                                                                                                                                                        onClick={decinputask0}
                                                                                                                                                    >
                                                                                                                                                        -
                                                                                                                                                    </Button>
                                                                                                                                                    <Button
                                                                                                                                                        borderRadius="none"
                                                                                                                                                        bg={"rgb(255,218,69)"}
                                                                                                                                                        color={"black"}
                                                                                                                                                        size="sm"
                                                                                                                                                        h={"31.8px"}
                                                                                                                                                        w={"30px"}
                                                                                                                                                        fontSize="sm"
                                                                                                                                                        isDisabled={addTakeProfit1 === false || disbaledme21 === true}
                                                                                                                                                        onClick={incinputask0}

                                                                                                                                                    >
                                                                                                                                                        +
                                                                                                                                                    </Button>
                                                                                                                                                </Flex>
                                                                                                                                            </InputRightElement>
                                                                                                                                        </InputGroup>
                                                                                                                                        <Text mt={"5px"}> {takeprofitdeal.title === "Buy" ? above21ask : above11ask} ${Object.entries(data).map(([currencyPair, ele], idx) => {
                                                                                                                                            const baseCurrency = currencyPair.substring(0, 3);
                                                                                                                                            const quoteCurrency = currencyPair.substring(3, 6);
                                                                                                                                            const formattedCurrencyPair = `${baseCurrency}/${quoteCurrency}`;
                                                                                                                                            if (takeprofitdeal.title === formattedCurrencyPair) {
                                                                                                                                                return takeprofitdeal.order_type === "Buy" ? ele.bid : ele.ask;
                                                                                                                                            }
                                                                                                                                            return null; // or handle the case when elem.title doesn't match currencyPair
                                                                                                                                        })}</Text>
                                                                                                                                    </Box>
                                                                                                                                </Flex>
                                                                                                                            </Box>
                                                                                                                        </Flex>
                                                                                                                    </Box>
                                                                                                                </AccordionPanel>
                                                                                                            </AccordionItem>
                                                                                                        </Accordion>
                                                                                                    </Box>
                                                                                                </Box>
                                                                                            </ModalBody>

                                                                                            <ModalFooter>
                                                                                                <Button bg='rgb(255,218,69)' color={"black"} cursor={"pointer"} mr={3} onClick={handleinput3}>
                                                                                                    Update
                                                                                                </Button>
                                                                                            </ModalFooter>
                                                                                        </ModalContent>
                                                                                    </Modal>

                                                                                    <Modal isOpen={isModal6Open} onClose={handleallclose1}>
                                                                                        <ModalOverlay />
                                                                                        <ModalContent bg={"rgb(55,67,78)"} color={"white"}>
                                                                                            <ModalHeader>Invalid value</ModalHeader>
                                                                                            <ModalCloseButton />
                                                                                            <ModalBody>
                                                                                                <Text>Entered value is not valid for the order</Text>
                                                                                            </ModalBody>

                                                                                            <ModalFooter>
                                                                                                <Button bg='rgb(255,218,69)' color={"black"} cursor={"pointer"} mr={3} onClick={handleallclose1}>
                                                                                                    Close
                                                                                                </Button>

                                                                                            </ModalFooter>
                                                                                        </ModalContent>
                                                                                    </Modal>

                                                                                    <Box textAlign={"center"}>
                                                                                        {elem.stoploss === null ? <Button size='xs'
                                                                                            width="100%"  // Set explicit width
                                                                                            //  height="40px" // Set explicit height
                                                                                            onClick={() => fetchstoplossdeal(elem.order_id)} colorScheme="red"
                                                                                        //  colorScheme={elem.stoploss === null ? "red" : elem.stoploss}
                                                                                        >Stop   Loss</Button> : elem.stoploss}
                                                                                    </Box>
                                                                                    <Modal isOpen={isModal3Open} onClose={closeModal3} size="md">
                                                                                        <ModalOverlay />
                                                                                        <ModalContent bg={"rgb(55,67,78)"} color={"white"}>
                                                                                            <ModalHeader>{stoplossdeal.order_id}</ModalHeader>
                                                                                            <ModalCloseButton />
                                                                                            <ModalBody>
                                                                                                <Box>
                                                                                                    <Grid templateColumns={{ base: "repeat(1, 1fr)", sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)", lg: "repeat(1, 1fr)", xl: "repeat(1, 1fr)" }} alignItems={"center"}>
                                                                                                        <GridItem w='100%' h='8' border={"1px solid black"}><Flex alignItems={"center"}>
                                                                                                            <Text fontWeight={"bold"}>Instrument : </Text>
                                                                                                            <Text>{stoplossdeal.title}</Text>
                                                                                                        </Flex></GridItem>
                                                                                                        <GridItem w='100%' h='8' border={"1px solid black"}> <Flex>
                                                                                                            <Text fontWeight={"bold"}>Order Type:</Text>
                                                                                                            <Text>{stoplossdeal.order_type}</Text>
                                                                                                        </Flex></GridItem>
                                                                                                        <GridItem w='100%' h='8' border={"1px solid black"}><Flex>
                                                                                                            <Text fontWeight={"bold"}>Order Date : </Text>
                                                                                                            <Text>{new Date(stoplossdeal.created_at).toLocaleString('en-GB', {
                                                                                                                timeZone: 'Europe/London',
                                                                                                                day: 'numeric',
                                                                                                                month: 'numeric',
                                                                                                                year: 'numeric',
                                                                                                                hour: '2-digit',
                                                                                                                minute: '2-digit',
                                                                                                                second: '2-digit'
                                                                                                            })}</Text>
                                                                                                        </Flex></GridItem>
                                                                                                        <GridItem w='100%' h='8' border={"1px solid black"} ><Flex>
                                                                                                            <Text fontWeight={"bold"}>Lot Size :</Text>
                                                                                                            <Text>{stoplossdeal.lotsize}</Text>
                                                                                                        </Flex></GridItem>

                                                                                                    </Grid>
                                                                                                    <Grid templateColumns={{ base: "repeat(1, 1fr)", sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)", lg: "repeat(2, 1fr)", xl: "repeat(2, 1fr)" }} >
                                                                                                        <GridItem w='100%' h='8' border={"1px solid black"}><Flex>
                                                                                                            <Text fontWeight={"bold"}>Open Price : </Text>
                                                                                                            <Text>{stoplossdeal.bidorask}</Text>
                                                                                                        </Flex></GridItem>
                                                                                                        <GridItem w='100%' h='8' border={"1px solid black"}><Flex>
                                                                                                            <Text fontWeight={"bold"}>Current Price:</Text>
                                                                                                            <Text>{Object.entries(data).map(([currencyPair, ele], idx) => {
                                                                                                                const baseCurrency = currencyPair.substring(0, 3);
                                                                                                                const quoteCurrency = currencyPair.substring(3, 6);
                                                                                                                const formattedCurrencyPair = `${baseCurrency}/${quoteCurrency}`;
                                                                                                                if (stoplossdeal.title === formattedCurrencyPair) {
                                                                                                                    return stoplossdeal.order_type === "Buy" ? ele.bid : ele.ask;
                                                                                                                }
                                                                                                                return null; // or handle the case when elem.title doesn't match currencyPair
                                                                                                            })}</Text>
                                                                                                        </Flex></GridItem>



                                                                                                    </Grid>
                                                                                                    <Box mt={"20px"}>
                                                                                                        <Accordion allowToggle>
                                                                                                            <AccordionItem>
                                                                                                                <h2>
                                                                                                                    <AccordionButton bg={"rgb(221,221,221)"} _hover={{ cursor: "pointer" }}>
                                                                                                                        <Box as="span" flex='1' color={'black'} >
                                                                                                                            Add Stop Loss/Take Profit
                                                                                                                        </Box>
                                                                                                                        <AccordionIcon />
                                                                                                                    </AccordionButton>
                                                                                                                </h2>
                                                                                                                <AccordionPanel pb={4}>
                                                                                                                    <Box>
                                                                                                                        <Flex justifyContent={"space-between"} flexDirection={{ base: "column", sm: 'column', md: "column", lg: "column", xl: "column" }}>
                                                                                                                            <Box>
                                                                                                                                <Checkbox onChange={onchnageloss1}>ADD STOP LOSS</Checkbox>
                                                                                                                                <Flex alignItems={"center"} gap={"30px"}>
                                                                                                                                    <Box mt={"10px"} maxW={"120px"}>
                                                                                                                                        <Text>Amount in $</Text>
                                                                                                                                        <InputGroup alignItems={"center"}>
                                                                                                                                            <Input
                                                                                                                                                size="sm"
                                                                                                                                                borderRadius={"none"}
                                                                                                                                                isDisabled={addStopLoss1 === false}
                                                                                                                                                w={"50%"}
                                                                                                                                                value={takeprofitdeal.order_type === "Buy" ? dollorPricebid11 : dollorPriceask11}
                                                                                                                                                name="dollarPrice"
                                                                                                                                                onChange={Inputdollar11}

                                                                                                                                            />
                                                                                                                                            <InputRightElement position="relative">
                                                                                                                                                <Flex position="absolute" right="-6" top="50%" transform="translateY(-50%)">
                                                                                                                                                    <Button
                                                                                                                                                        borderRadius="none"
                                                                                                                                                        color={"black"}
                                                                                                                                                        size="sm"
                                                                                                                                                        h={"31.8px"}
                                                                                                                                                        w={"30px"}
                                                                                                                                                        fontSize="sm"
                                                                                                                                                        isDisabled={addStopLoss1 === false || stoplossdeal.order_type === "Buy" ? dollorPricebid1 === 0 : dollorPriceask1 === 0}
                                                                                                                                                        onChange={handleSubDollar11}
                                                                                                                                                    >
                                                                                                                                                        -
                                                                                                                                                    </Button>
                                                                                                                                                    <Button
                                                                                                                                                        bg={"rgb(255,218,69)"}
                                                                                                                                                        borderRadius="none"
                                                                                                                                                        color={"black"}
                                                                                                                                                        size="sm"
                                                                                                                                                        h={"31.8px"}
                                                                                                                                                        w={"30px"}
                                                                                                                                                        fontSize="sm"
                                                                                                                                                        isDisabled={addStopLoss1 === false}
                                                                                                                                                        onClick={handleaddDollar11}

                                                                                                                                                    >
                                                                                                                                                        +
                                                                                                                                                    </Button>
                                                                                                                                                </Flex>
                                                                                                                                            </InputRightElement>
                                                                                                                                        </InputGroup>
                                                                                                                                        <Text mt={"5px"}>Above $0.00</Text>
                                                                                                                                    </Box>
                                                                                                                                    <Box>
                                                                                                                                        <Text fontWeight={"bold"}>OR</Text>
                                                                                                                                    </Box>
                                                                                                                                    <Box mt={"10px"} maxW={"160px"}>
                                                                                                                                        <Text>Rate</Text>
                                                                                                                                        <InputGroup alignItems={"center"}>
                                                                                                                                            <Input
                                                                                                                                                size="sm"
                                                                                                                                                borderRadius="none"
                                                                                                                                                w={"60%"}
                                                                                                                                                name="inputfixedvaluebid"
                                                                                                                                                isDisabled={addStopLoss1 === false}
                                                                                                                                                value={
                                                                                                                                                    stoplossdeal.stoploss === null || stoplossdeal.stoploss === ""
                                                                                                                                                        ? (stoplossdeal.order_type === "Buy" ? inputfixedvalueask11 : inputfixedvaluebid11)
                                                                                                                                                        : stoplossdeal.stoploss
                                                                                                                                                }
                                                                                                                                                readOnly

                                                                                                                                            />
                                                                                                                                            <InputRightElement position="relative">
                                                                                                                                                <Flex position="absolute" right="-6" top="50%" transform="translateY(-50%)">
                                                                                                                                                    <Button
                                                                                                                                                        borderRadius="none"
                                                                                                                                                        color={"black"}
                                                                                                                                                        size="sm"
                                                                                                                                                        h={"31.8px"}
                                                                                                                                                        w={"30px"}
                                                                                                                                                        fontSize="sm"
                                                                                                                                                        isDisabled={addStopLoss1 === false}
                                                                                                                                                        onClick={Decbidvalue0}

                                                                                                                                                    >
                                                                                                                                                        -
                                                                                                                                                    </Button>
                                                                                                                                                    <Button
                                                                                                                                                        bg={"rgb(255,218,69)"}
                                                                                                                                                        borderRadius="none"
                                                                                                                                                        color={"black"}
                                                                                                                                                        size="sm"
                                                                                                                                                        h={"31.8px"}
                                                                                                                                                        w={"30px"}
                                                                                                                                                        fontSize="sm"
                                                                                                                                                        isDisabled={addStopLoss1 === false}
                                                                                                                                                        onClick={Incbidvalue0}

                                                                                                                                                    >
                                                                                                                                                        +
                                                                                                                                                    </Button>
                                                                                                                                                </Flex>
                                                                                                                                            </InputRightElement>
                                                                                                                                        </InputGroup>
                                                                                                                                        <Text mt={"5px"}>{stoplossdeal.title === "Buy" ? above1ask : above2ask}${Object.entries(data).map(([currencyPair, ele], idx) => {
                                                                                                                                            const baseCurrency = currencyPair.substring(0, 3);
                                                                                                                                            const quoteCurrency = currencyPair.substring(3, 6);
                                                                                                                                            const formattedCurrencyPair = `${baseCurrency}/${quoteCurrency}`;
                                                                                                                                            if (stoplossdeal.title === formattedCurrencyPair) {
                                                                                                                                                return stoplossdeal.order_type === "Buy" ? ele.bid : ele.ask;
                                                                                                                                            }
                                                                                                                                            return null; // or handle the case when elem.title doesn't match currencyPair
                                                                                                                                        })}</Text>
                                                                                                                                    </Box>
                                                                                                                                </Flex>
                                                                                                                            </Box>
                                                                                                                            <Center fontWeight={"bold"}>
                                                                                                                                <Divider fontWeight={"bold"} orientation='vertical' />
                                                                                                                            </Center>
                                                                                                                            <Box mt={{ base: "25px", sm: '25px', md: "25px", lg: "25px", xl: "25px" }}>
                                                                                                                                <Checkbox textAlign={"right"} onChange={setaddprofit1}>ADD TAKE PROFIT</Checkbox>
                                                                                                                                <Flex alignItems={"center"} gap={"30px"}>
                                                                                                                                    <Box mt={"10px"} maxW={"120px"}>
                                                                                                                                        <Text>Amount in $</Text>
                                                                                                                                        <InputGroup alignItems={"center"}>
                                                                                                                                            <Input
                                                                                                                                                size="sm"
                                                                                                                                                borderRadius="none"
                                                                                                                                                w={"50%"}
                                                                                                                                                isDisabled={addTakeProfit1 === false}
                                                                                                                                                value={stoplossdeal.order_type === "Buy" ? dollorPricebid21 : dollorPriceask21}
                                                                                                                                                onChange={dollarPriceinput0}


                                                                                                                                            />
                                                                                                                                            <InputRightElement position="relative">
                                                                                                                                                <Flex position="absolute" right="-6" top="50%" transform="translateY(-50%)">
                                                                                                                                                    <Button
                                                                                                                                                        borderRadius="none"
                                                                                                                                                        color={"black"}
                                                                                                                                                        size="sm"
                                                                                                                                                        h={"31.8px"}
                                                                                                                                                        w={"30px"}
                                                                                                                                                        fontSize="sm"
                                                                                                                                                        isDisabled={addTakeProfit1 === false}

                                                                                                                                                        onClick={handleSubDollar0}                                                                                             >
                                                                                                                                                        -
                                                                                                                                                    </Button>
                                                                                                                                                    <Button
                                                                                                                                                        borderRadius="none"
                                                                                                                                                        bg={"rgb(255,218,69)"}
                                                                                                                                                        color={"black"}
                                                                                                                                                        size="sm"
                                                                                                                                                        h={"31.8px"}
                                                                                                                                                        w={"30px"}
                                                                                                                                                        fontSize="sm"
                                                                                                                                                        isDisabled={addTakeProfit1 === false}
                                                                                                                                                        onClick={handleSubDollar0}

                                                                                                                                                    >
                                                                                                                                                        +
                                                                                                                                                    </Button>
                                                                                                                                                </Flex>
                                                                                                                                            </InputRightElement>
                                                                                                                                        </InputGroup>
                                                                                                                                        <Text mt={"5px"}>Above $0.00</Text>
                                                                                                                                    </Box>
                                                                                                                                    <Box>
                                                                                                                                        <Text fontWeight={"bold"}>OR</Text>
                                                                                                                                    </Box>
                                                                                                                                    <Box mt={"10px"} maxW={"160px"}>
                                                                                                                                        <Text>Rate</Text>
                                                                                                                                        <InputGroup alignItems={"center"}>
                                                                                                                                            <Input
                                                                                                                                                size="sm"
                                                                                                                                                w={"60%"}
                                                                                                                                                borderRadius="none"
                                                                                                                                                name="inputfixedvalueask1"
                                                                                                                                                value={
                                                                                                                                                    stoplossdeal.takeprofit === null || stoplossdeal.takeprofit === ""
                                                                                                                                                        ? (stoplossdeal.order_type === "Buy" ? inputfixedvalueask21 : inputfixedvaluebid21)
                                                                                                                                                        : stoplossdeal.takeprofit
                                                                                                                                                }
                                                                                                                                                readOnly
                                                                                                                                                isDisabled={addTakeProfit1 === false}

                                                                                                                                            />
                                                                                                                                            <InputRightElement position="relative">
                                                                                                                                                <Flex position="absolute" right="-6" top="50%" transform="translateY(-50%)">
                                                                                                                                                    <Button
                                                                                                                                                        borderRadius="none"
                                                                                                                                                        color={"black"}
                                                                                                                                                        size="sm"
                                                                                                                                                        h={"31.8px"}
                                                                                                                                                        w={"30px"}
                                                                                                                                                        fontSize="sm"
                                                                                                                                                        isDisabled={addTakeProfit1 === false}
                                                                                                                                                        onClick={decinputask}

                                                                                                                                                    >
                                                                                                                                                        -
                                                                                                                                                    </Button>
                                                                                                                                                    <Button
                                                                                                                                                        borderRadius="none"
                                                                                                                                                        bg={"rgb(255,218,69)"}
                                                                                                                                                        color={"black"}
                                                                                                                                                        size="sm"
                                                                                                                                                        h={"31.8px"}
                                                                                                                                                        w={"30px"}
                                                                                                                                                        fontSize="sm"
                                                                                                                                                        isDisabled={addTakeProfit1 === false || disbaledme21 === true}
                                                                                                                                                        onClick={incinputask0}

                                                                                                                                                    >
                                                                                                                                                        +
                                                                                                                                                    </Button>
                                                                                                                                                </Flex>
                                                                                                                                            </InputRightElement>
                                                                                                                                        </InputGroup>
                                                                                                                                        <Text mt={"5px"}>{stoplossdeal.title === "Buy" ? above2ask : above1ask} ${Object.entries(data).map(([currencyPair, ele], idx) => {
                                                                                                                                            const baseCurrency = currencyPair.substring(0, 3);
                                                                                                                                            const quoteCurrency = currencyPair.substring(3, 6);
                                                                                                                                            const formattedCurrencyPair = `${baseCurrency}/${quoteCurrency}`;
                                                                                                                                            if (stoplossdeal.title === formattedCurrencyPair) {
                                                                                                                                                return stoplossdeal.order_type === "Buy" ? ele.bid : ele.ask;
                                                                                                                                            }
                                                                                                                                            return null; // or handle the case when elem.title doesn't match currencyPair
                                                                                                                                        })} </Text>
                                                                                                                                    </Box>
                                                                                                                                </Flex>
                                                                                                                            </Box>
                                                                                                                        </Flex>
                                                                                                                    </Box>
                                                                                                                </AccordionPanel>
                                                                                                            </AccordionItem>
                                                                                                        </Accordion>
                                                                                                    </Box>
                                                                                                </Box>
                                                                                            </ModalBody>

                                                                                            <ModalFooter>
                                                                                                <Button colorScheme='red' mr={3} onClick={handletwoinput1}>
                                                                                                    Update
                                                                                                </Button>
                                                                                            </ModalFooter>
                                                                                        </ModalContent>
                                                                                    </Modal>

                                                                                </Flex>
                                                                            </Td>
                                                                            <Td>
                                                                                <Flex direction={"column"} gap={"10px"}>
                                                                                    <Button size='xs' onClick={() => fetchEditdeal(elem.order_id)}>EDIT DEAL</Button>
                                                                                    <Modal isOpen={isModal1Open} onClose={closeModal1} size="md">
                                                                                        <ModalOverlay />
                                                                                        <ModalContent bg={"rgb(55,67,78)"} color={"white"}>
                                                                                            <ModalHeader>{editdeal.order_id}</ModalHeader>
                                                                                            <ModalCloseButton />
                                                                                            <ModalBody>
                                                                                                <Box>
                                                                                                    <Grid templateColumns={{ base: "repeat(1, 1fr)", sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)", lg: "repeat(1, 1fr)", xl: "repeat(1, 1fr)" }} alignItems={"center"}>
                                                                                                        <GridItem w='100%' h='8' border={"1px solid black"}><Flex alignItems={"center"}>
                                                                                                            <Text fontWeight={"bold"}>Instrument : </Text>
                                                                                                            <Text>{editdeal.title}</Text>
                                                                                                        </Flex></GridItem>
                                                                                                        <GridItem w='100%' h='8' border={"1px solid black"}> <Flex>
                                                                                                            <Text fontWeight={"bold"}>Order Type:</Text>
                                                                                                            <Text>{editdeal.order_type}</Text>
                                                                                                        </Flex></GridItem>
                                                                                                        <GridItem w='100%' h='8' border={"1px solid black"}><Flex>
                                                                                                            <Text fontWeight={"bold"}>Order Date : </Text>
                                                                                                            <Text>{new Date(editdeal.created_at).toLocaleString('en-GB', {
                                                                                                                timeZone: 'Europe/London',
                                                                                                                day: 'numeric',
                                                                                                                month: 'numeric',
                                                                                                                year: 'numeric',
                                                                                                                hour: '2-digit',
                                                                                                                minute: '2-digit',
                                                                                                                second: '2-digit'
                                                                                                            })}</Text>
                                                                                                        </Flex></GridItem>
                                                                                                        <GridItem w='100%' h='8' border={"1px solid black"} ><Flex>
                                                                                                            <Text fontWeight={"bold"}>Lot Size :</Text>
                                                                                                            <Text>{editdeal.lotsize}</Text>
                                                                                                        </Flex></GridItem>

                                                                                                    </Grid>
                                                                                                    <Grid templateColumns={{ base: "repeat(1, 1fr)", sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)", lg: "repeat(2, 1fr)", xl: "repeat(2, 1fr)" }}  >
                                                                                                        <GridItem w='100%' h='8' border={"1px solid black"}><Flex>
                                                                                                            <Text fontWeight={"bold"}>Open Price : </Text>
                                                                                                            <Text>{editdeal.bidorask}</Text>
                                                                                                        </Flex></GridItem>
                                                                                                        <GridItem w='100%' h='8' border={"1px solid black"}><Flex>
                                                                                                            <Text fontWeight={"bold"}>Current Price:</Text>
                                                                                                            <Text>{Object.entries(data).map(([currencyPair, ele], idx) => {
                                                                                                                const baseCurrency = currencyPair.substring(0, 3);
                                                                                                                const quoteCurrency = currencyPair.substring(3, 6);
                                                                                                                const formattedCurrencyPair = `${baseCurrency}/${quoteCurrency}`;
                                                                                                                if (editdeal.title === formattedCurrencyPair) {
                                                                                                                    return editdeal.order_type === "Buy" ? ele.bid : ele.ask;
                                                                                                                }
                                                                                                                return null; // or handle the case when elem.title doesn't match currencyPair
                                                                                                            })}</Text>
                                                                                                        </Flex></GridItem>
                                                                                                        {/* <GridItem w='100%' h='8' border={"1px solid black"}> <Flex>
                                                                                                            <Text fontWeight={"bold"}>Order Profit :</Text>
                                                                                                            <Text>{editdeal.order_type === "Buy" ? (parseFloat(bidincinput - data.bidorask) * 1000).toFixed(2) : (parseFloat(askincinput - data.bidorask) * 1000).toFixed(2)}</Text>
                                                                                                        </Flex></GridItem> */}


                                                                                                    </Grid>
                                                                                                    <Box mt={"20px"}>
                                                                                                        <Accordion allowToggle>
                                                                                                            <AccordionItem>
                                                                                                                <h2>
                                                                                                                    <AccordionButton bg={"rgb(221,221,221)"} _hover={{ cursor: "pointer" }}>
                                                                                                                        <Box as="span" flex='1' color={"black"} >
                                                                                                                            Add Stop Loss/Take Profit
                                                                                                                        </Box>
                                                                                                                        <AccordionIcon />
                                                                                                                    </AccordionButton>
                                                                                                                </h2>
                                                                                                                <AccordionPanel pb={4}>
                                                                                                                    <Box>
                                                                                                                        <Flex justifyContent={"space-between"} flexDirection={{ base: "column", sm: 'column', md: "column", lg: "column", xl: "column" }}>
                                                                                                                            <Box>
                                                                                                                                <Checkbox onChange={onchnageloss1}>ADD STOP LOSS</Checkbox>
                                                                                                                                <Flex alignItems={"center"} gap={"30px"}>
                                                                                                                                    <Box mt={"10px"} maxW={"120px"}>
                                                                                                                                        <Text>Amount in $</Text>
                                                                                                                                        <InputGroup alignItems={"center"}>
                                                                                                                                            <Input
                                                                                                                                                size="sm"
                                                                                                                                                w={"50%"}
                                                                                                                                                borderRadius={"none"}
                                                                                                                                                value={takeprofitdeal.order_type === "Buy" ? dollorPricebid11 : dollorPriceask11}
                                                                                                                                                name="dollarPrice"
                                                                                                                                                onChange={Inputdollar11}
                                                                                                                                            />
                                                                                                                                            <InputRightElement position="relative">
                                                                                                                                                <Flex position="absolute" right="-6" top="50%" transform="translateY(-50%)">
                                                                                                                                                    <Button
                                                                                                                                                        borderRadius="none"
                                                                                                                                                        color={"black"}
                                                                                                                                                        size="sm"
                                                                                                                                                        h={"31.8px"}
                                                                                                                                                        w={"30px"}
                                                                                                                                                        fontSize="sm"
                                                                                                                                                        isDisabled={addStopLoss1 === false || takeprofitdeal.order_type === "Buy" ? dollorPricebid1 === 0 : dollorPriceask1}
                                                                                                                                                        onChange={handleSubDollar11}

                                                                                                                                                    >
                                                                                                                                                        -
                                                                                                                                                    </Button>
                                                                                                                                                    <Button

                                                                                                                                                        borderRadius="none"
                                                                                                                                                        color={"black"}
                                                                                                                                                        bg={"rgb(255,218,69)"}
                                                                                                                                                        size="sm"
                                                                                                                                                        h={"31.8px"}
                                                                                                                                                        w={"30px"}
                                                                                                                                                        fontSize="sm"
                                                                                                                                                        isDisabled={addStopLoss1 === false}
                                                                                                                                                        onChange={handleSubDollar11}

                                                                                                                                                    >
                                                                                                                                                        +
                                                                                                                                                    </Button>
                                                                                                                                                </Flex>
                                                                                                                                            </InputRightElement>
                                                                                                                                        </InputGroup>
                                                                                                                                        <Text mt={"5px"}>Above $0.00</Text>
                                                                                                                                    </Box>
                                                                                                                                    <Box>
                                                                                                                                        <Text fontWeight={"bold"}>OR</Text>
                                                                                                                                    </Box>
                                                                                                                                    <Box mt={"10px"} maxW={"160px"}>
                                                                                                                                        <Text>Rate</Text>
                                                                                                                                        <InputGroup alignItems={"center"}>
                                                                                                                                            <Input
                                                                                                                                                size="sm"
                                                                                                                                                borderRadius="none"
                                                                                                                                                w={"60%"}
                                                                                                                                                name="inputfixedvaluebid"
                                                                                                                                                isDisabled={addStopLoss1 === false}
                                                                                                                                                value={
                                                                                                                                                    editdeal.stoploss === null || editdeal.stoploss === ""
                                                                                                                                                        ? (editdeal.order_type === "Buy" ? inputfixedvalueask11 : inputfixedvaluebid11)
                                                                                                                                                        : editdeal.stoploss
                                                                                                                                                }
                                                                                                                                                readOnly

                                                                                                                                            />
                                                                                                                                            <InputRightElement position="relative">
                                                                                                                                                <Flex position="absolute" right="-6" top="50%" transform="translateY(-50%)">
                                                                                                                                                    <Button
                                                                                                                                                        borderRadius="none"
                                                                                                                                                        color={"black"}
                                                                                                                                                        size="sm"
                                                                                                                                                        h={"31.8px"}
                                                                                                                                                        w={"30px"}
                                                                                                                                                        fontSize="sm"
                                                                                                                                                        isDisabled={addStopLoss1 === false}
                                                                                                                                                        onClick={Decbidvalue0}

                                                                                                                                                    >
                                                                                                                                                        -
                                                                                                                                                    </Button>
                                                                                                                                                    <Button
                                                                                                                                                        bg={"rgb(255,218,69)"}
                                                                                                                                                        borderRadius="none"
                                                                                                                                                        color={"black"}
                                                                                                                                                        size="sm"
                                                                                                                                                        h={"31.8px"}
                                                                                                                                                        w={"30px"}
                                                                                                                                                        fontSize="sm"
                                                                                                                                                        isDisabled={addStopLoss1 === false}
                                                                                                                                                        onClick={Incbidvalue0}

                                                                                                                                                    >
                                                                                                                                                        +
                                                                                                                                                    </Button>
                                                                                                                                                </Flex>
                                                                                                                                            </InputRightElement>
                                                                                                                                        </InputGroup>
                                                                                                                                        <Text mt={"5px"}>{editdeal.title === "Buy" ? above1ask : above2ask}${Object.entries(data).map(([currencyPair, ele], idx) => {
                                                                                                                                            const baseCurrency = currencyPair.substring(0, 3);
                                                                                                                                            const quoteCurrency = currencyPair.substring(3, 6);
                                                                                                                                            const formattedCurrencyPair = `${baseCurrency}/${quoteCurrency}`;
                                                                                                                                            if (editdeal.title === formattedCurrencyPair) {
                                                                                                                                                return editdeal.order_type === "Buy" ? ele.bid : ele.ask;
                                                                                                                                            }
                                                                                                                                            return null; // or handle the case when elem.title doesn't match currencyPair
                                                                                                                                        })}</Text>
                                                                                                                                    </Box>
                                                                                                                                </Flex>
                                                                                                                            </Box>
                                                                                                                            <Center fontWeight={"bold"}>
                                                                                                                                <Divider fontWeight={"bold"} orientation='vertical' />
                                                                                                                            </Center>
                                                                                                                            <Box>
                                                                                                                                <Checkbox textAlign={"right"} onChange={setaddprofit1}>ADD TAKE PROFIT</Checkbox>
                                                                                                                                <Flex alignItems={"center"} gap={"30px"}>
                                                                                                                                    <Box mt={"10px"} maxW={"120px"}>
                                                                                                                                        <Text>Amount in $</Text>
                                                                                                                                        <InputGroup alignItems={"center"}>
                                                                                                                                            <Input
                                                                                                                                                size="sm"
                                                                                                                                                w={"50%"}
                                                                                                                                                borderRadius="none"
                                                                                                                                                isDisabled={addTakeProfit1 === false}
                                                                                                                                                value={takeprofitdeal.order_type === "Buy" ? dollorPricebid21 : dollorPriceask21}
                                                                                                                                                onChange={dollarPriceinput0}


                                                                                                                                            />
                                                                                                                                            <InputRightElement position="relative">
                                                                                                                                                <Flex position="absolute" right="-6" top="50%" transform="translateY(-50%)">
                                                                                                                                                    <Button
                                                                                                                                                        borderRadius="none"
                                                                                                                                                        color={"black"}
                                                                                                                                                        size="sm"
                                                                                                                                                        h={"31.8px"}
                                                                                                                                                        w={"30px"}
                                                                                                                                                        fontSize="sm"
                                                                                                                                                        isDisabled={addTakeProfit1 === false}
                                                                                                                                                        onClick={handleSubDollar0}


                                                                                                                                                    >
                                                                                                                                                        -
                                                                                                                                                    </Button>
                                                                                                                                                    <Button
                                                                                                                                                        borderRadius="none"
                                                                                                                                                        bg={"rgb(255,218,69)"}
                                                                                                                                                        color={"black"}
                                                                                                                                                        size="sm"
                                                                                                                                                        h={"31.8px"}
                                                                                                                                                        w={"30px"}
                                                                                                                                                        fontSize="sm"
                                                                                                                                                        onClick={handleaddDollar0}
                                                                                                                                                        isDisabled={addTakeProfit1 === false}


                                                                                                                                                    >
                                                                                                                                                        +
                                                                                                                                                    </Button>
                                                                                                                                                </Flex>
                                                                                                                                            </InputRightElement>
                                                                                                                                        </InputGroup>
                                                                                                                                        <Text mt={"5px"}>Above $0.00</Text>
                                                                                                                                    </Box>
                                                                                                                                    <Box>
                                                                                                                                        <Text fontWeight={"bold"}>OR</Text>
                                                                                                                                    </Box>
                                                                                                                                    <Box mt={"10px"} maxW={"160px"}>
                                                                                                                                        <Text>Rate</Text>
                                                                                                                                        <InputGroup alignItems={"center"}>
                                                                                                                                            <Input
                                                                                                                                                size="sm"
                                                                                                                                                borderRadius="none"
                                                                                                                                                name="inputfixedvalueask1"
                                                                                                                                                value={
                                                                                                                                                    editdeal.takeprofit === null || editdeal.takeprofit === ""
                                                                                                                                                        ? (editdeal.order_type === "Buy" ? inputfixedvalueask21 : inputfixedvaluebid21)
                                                                                                                                                        : editdeal.takeprofit
                                                                                                                                                }
                                                                                                                                                readOnly
                                                                                                                                                isDisabled={addTakeProfit1 === false}


                                                                                                                                            />
                                                                                                                                            <InputRightElement position="relative">
                                                                                                                                                <Flex position="absolute" right="-6" top="50%" transform="translateY(-50%)">
                                                                                                                                                    <Button
                                                                                                                                                        borderRadius="none"
                                                                                                                                                        color={"black"}
                                                                                                                                                        size="sm"
                                                                                                                                                        h={"31.8px"}
                                                                                                                                                        w={"30px"}
                                                                                                                                                        fontSize="sm"
                                                                                                                                                        isDisabled={addTakeProfit1 === false || disbaledme21 === true}
                                                                                                                                                        onClick={decinputask0}

                                                                                                                                                    >
                                                                                                                                                        -
                                                                                                                                                    </Button>
                                                                                                                                                    <Button
                                                                                                                                                        borderRadius="none"
                                                                                                                                                        color={"black"}
                                                                                                                                                        bg={"rgb(255,218,69)"}

                                                                                                                                                        size="sm"
                                                                                                                                                        h={"31.8px"}
                                                                                                                                                        w={"30px"}
                                                                                                                                                        fontSize="sm"
                                                                                                                                                        isDisabled={addTakeProfit1 === false || disbaledme21 === true}
                                                                                                                                                        onClick={incinputask0}

                                                                                                                                                    >
                                                                                                                                                        +
                                                                                                                                                    </Button>
                                                                                                                                                </Flex>
                                                                                                                                            </InputRightElement>
                                                                                                                                        </InputGroup>
                                                                                                                                        <Text mt={"5px"}>{editdeal.title === "Buy" ? above2ask : above1ask} ${Object.entries(data).map(([currencyPair, ele], idx) => {
                                                                                                                                            const baseCurrency = currencyPair.substring(0, 3);
                                                                                                                                            const quoteCurrency = currencyPair.substring(3, 6);
                                                                                                                                            const formattedCurrencyPair = `${baseCurrency}/${quoteCurrency}`;
                                                                                                                                            if (editdeal.title === formattedCurrencyPair) {
                                                                                                                                                return editdeal.order_type === "Buy" ? ele.bid : ele.ask;
                                                                                                                                            }
                                                                                                                                            return null; // or handle the case when elem.title doesn't match currencyPair
                                                                                                                                        })} </Text>
                                                                                                                                    </Box>
                                                                                                                                </Flex>
                                                                                                                            </Box>
                                                                                                                        </Flex>
                                                                                                                    </Box>
                                                                                                                </AccordionPanel>
                                                                                                            </AccordionItem>
                                                                                                        </Accordion>
                                                                                                    </Box>
                                                                                                </Box>
                                                                                            </ModalBody>

                                                                                            <ModalFooter>
                                                                                                <Button color={"black"}
                                                                                                    bg={"rgb(255,218,69)"} mr={3} onClick={handletwoinputs}>
                                                                                                    Update
                                                                                                </Button>
                                                                                            </ModalFooter>
                                                                                        </ModalContent>
                                                                                    </Modal>
                                                                                    <Button size='xs' onClick={() => onclickClosedDeal(elem)}>CLOSE DEAL</Button>
                                                                                </Flex>
                                                                            </Td>
                                                                        </Tr>
                                                                    }) : <Tr>
                                                                        <Td textAlign={"center"} colSpan={8}>No Pending Withdraw</Td>
                                                                    </Tr>
                                                                }


                                                            </Tbody>

                                                        </Table>
                                                    </TableContainer>
                                                    }
                                                    {
                                                        table === "pending" && <TableContainer >
                                                            <Table variant='simple' colorScheme='teal'>
                                                                {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
                                                                <Thead bg={"rgb(231,236,241)"}
                                                                >
                                                                    <Tr textAlign={"center"}>
                                                                        <Th>
                                                                            <Flex direction={"column"} gap={"10px"}>

                                                                                <Box textAlign={"center"}>Order Type</Box>
                                                                            </Flex>
                                                                        </Th>
                                                                        <Th>
                                                                            <Flex direction={"column"} gap={"10px"}>
                                                                                <Box textAlign={"center"}>Lot Size</Box>
                                                                            </Flex>
                                                                        </Th>
                                                                        <Th textAlign={"center"}>Open Time</Th>
                                                                        <Th textAlign={"center"}>Open Rate</Th>
                                                                        <Th textAlign={"center"}>Market Rate</Th>
                                                                        <Th textAlign={"center"}>Net Profit</Th>
                                                                        <Th>
                                                                            <Flex direction={"column"} gap={"10px"}>
                                                                                <Box textAlign={"center"}>P/L </Box>

                                                                            </Flex>
                                                                        </Th>
                                                                        <Th></Th>
                                                                    </Tr>
                                                                </Thead>
                                                                <Tbody>
                                                                    <Tr >
                                                                        <Td colSpan={7} textAlign={"center"}>No pending orders</Td>
                                                                    </Tr>


                                                                </Tbody>

                                                            </Table>
                                                        </TableContainer>
                                                    }
                                                    {
                                                        table === "closed" && <TableContainer mt={"10px"}>
                                                            <Table variant='simple' colorScheme='teal'>
                                                                {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
                                                                <Thead bg={"rgb(231,236,241)"}
                                                                >
                                                                    <Tr textAlign={"center"}>
                                                                        <Th>

                                                                            <Flex direction={"column"} gap={"10px"}>

                                                                                <Box textAlign={"center"}>Instrument</Box>
                                                                            </Flex>
                                                                        </Th>
                                                                        <Th>
                                                                            <Flex direction={"column"} gap={"10px"}>

                                                                                <Box textAlign={"center"}>Lot Size</Box>
                                                                            </Flex>
                                                                        </Th>
                                                                        <Th>
                                                                            <Flex direction={"column"} gap={"10px"}>

                                                                                <Box textAlign={"center"}>TP/SL</Box>
                                                                            </Flex>
                                                                        </Th>
                                                                        <Th textAlign={"center"}>Open Time</Th>
                                                                        <Th textAlign={"center"}>Open Rate</Th>
                                                                        <Th textAlign={"center"}>Close Rate</Th>
                                                                        <Th textAlign={"center"}>Order Profit</Th>
                                                                        <Th textAlign={"center"}>Close Time</Th>
                                                                        <Th>

                                                                        </Th>

                                                                    </Tr>
                                                                </Thead>
                                                                <Tbody>
                                                                    {
                                                                        dataclose.map((ele, idx) => {


                                                                            return <Tr textAlign={"center"} key={idx} >
                                                                                <Td>
                                                                                    <Flex direction={"column"} gap={"10px"}>
                                                                                        <Box textAlign={"center"}>{ele.order_id}</Box>
                                                                                        <Box textAlign={"center"}>{ele.title}</Box>
                                                                                    </Flex>
                                                                                </Td>
                                                                                <Td textAlign={"center"}>
                                                                                    <Flex direction={"column"} gap={"10px"}>
                                                                                        <Box textAlign={"center"}>{ele.order_type}</Box>
                                                                                        <Box textAlign={"center"}>{ele.lotsize}</Box>
                                                                                    </Flex>
                                                                                </Td>
                                                                                <Td textAlign={"center"}>
                                                                                    <Flex direction={"column"} gap={"10px"}>
                                                                                        <Box textAlign={"center"}>{ele.takeprofit}</Box>
                                                                                        <Box textAlign={"center"}>{ele.stoploss}</Box>
                                                                                    </Flex> </Td>
                                                                                <Td textAlign={"center"}>{new Intl.DateTimeFormat('en-GB', {
                                                                                    timeZone: 'Europe/London',
                                                                                    day: 'numeric',
                                                                                    month: 'short',
                                                                                    year: 'numeric',
                                                                                    hour: 'numeric',
                                                                                    minute: 'numeric',
                                                                                    second: 'numeric',
                                                                                    hour12: false,
                                                                                }).format(new Date(ele.created_at))
                                                                                    .replace(/ /, '-')
                                                                                    .replace(/,/g, '')
                                                                                }</Td>
                                                                                <Td textAlign={"center"}>{ele.bidorask}
                                                                                </Td>
                                                                                <Td textAlign={"center"}>{ele.close_rate}


                                                                                </Td>
                                                                                <Td textAlign={"center"}>
                                                                                    {ele.order_profit}
                                                                                </Td>
                                                                                <Td textAlign={"center"}>
                                                                                    {new Intl.DateTimeFormat('en-GB', {
                                                                                        timeZone: 'Europe/London',
                                                                                        day: 'numeric',
                                                                                        month: 'short',
                                                                                        year: 'numeric',
                                                                                        hour: 'numeric',
                                                                                        minute: 'numeric',
                                                                                        second: 'numeric',
                                                                                        hour12: false,
                                                                                    }).format(new Date(ele.closed_at))
                                                                                        .replace(/ /, '-')
                                                                                        .replace(/,/g, '')
                                                                                    }
                                                                                </Td>
                                                                                <Td>{ele.manual_auto}</Td>
                                                                            </Tr>
                                                                        })
                                                                    }


                                                                </Tbody>

                                                            </Table>
                                                        </TableContainer>
                                                    }
                                                </Box>
                                            </Box>
                                        </Box>

                                    </Box>

                                </Box>
                            </Box>

                        </Flex>
                    </Box>


                </Box>

            </Box>
        </>
    )
}

export default Home;