import { Box, Button, Flex, Input, Text, Textarea } from "@chakra-ui/react";
import Navbar from "../../Navbar/Navbar";
import NavbarNew from "../../Navbar/NavbarNew";
import NavbarHome from "../../Navbar/NavbarHome";
import Footer from "../../Navbar/Footer";



function AccountC() {
    return (
        <>
            <Box>
                <NavbarHome />
            </Box>
            <Box padding={{base:'10px',sm:"10px",md:"10px",xl:"30px 45px",lg:"30px 45px"}} >
                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"50px"}>Account Comparison</Text>
            </Box>
            <Box paddingLeft={{base:'10px',sm:"10px",md:"10px",xl:"45px",lg:"45px"}}>
                <Flex gap={"20px"} flexDirection={{base:"column",sm:"column",md:"column",lg:"row",xl:"row"}}>
                    <Box w={{base:'100%',sm:"100%",md:"100%",xl:"70%",lg:"70%"}}>
                        <Box>
                            <Text fontSize={"15px"}>
                            There are two different types of accounts to compare in terms of account types. Each account has unique strengths and restrictions.
                            </Text>
                            
                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>Demo Account</Text>
                            </Box>
                            <Box>
                            <Text fontSize={"15px"} mt={"30px"}>
                            Starting with a demo account is the best (and safest) approach to practise FOREX Trading tips and tricks. According to experts, having two demo accounts is always preferable for successful outcomes. The trading account is essentially a mirror image of the demo account. Therefore, you must practise the game’s rules in order to master them.
                            </Text>
                            <Text fontSize={"15px"} mt={"30px"}>
                            We at GloabFx provide excellent demo accounts that help you improve your trading techniques so you can perform better in a live trading scenario. You do not run the danger of losing any money because the demo account’s loss and gain are both virtual.
                            </Text>
                            </Box>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>Live account</Text>
                            </Box>
                            <Box>
                            <Text fontSize={"15px"} mt={"30px"}>
                            Every account has a unique personality and set of unique features when it is opened as a live account on a FOREX trading platform. You have your own objectives, and the account you choose will rely on a number of factors, including your investor type, the amount you plan to invest, and your risk tolerance. Each characteristic in the fascinating world of forex trading presents a different window of opportunity. There are numerous types of live trading accounts that open doors to the financial dreams of investors, regardless of whether they are small or large investors, experienced traders, or newcomers.
                            </Text>
                            <Text fontSize={"15px"} mt={"30px"}>
                            Before opening an account, you must be clear about your expectations for FOREX trading. Your approach and manner are heavily influenced by the projected results.
                            </Text>
                            <Text fontSize={"15px"} mt={"30px"}>
                            The minimum deposits, stop levels (limits), commissions, and spreads for our various live accounts vary. You must pick the option that best satisfies your goals and expectations. To choose the best one, compare them all.
                            </Text>
                            
                            </Box>

                        </Box>
                    </Box>

                    <Box w={{base:"100%",sm:"100%",md:"100%",lg:"30%",xl:"30%"}} p={{base:'5px',sm:"5px",md:"5px",lg:"30px",xl:"30px"}}>
                        <Input placeholder="Your Name" />
                        <Input mt={"20px"} placeholder="Your Email" />
                        <Input mt={"20px"} placeholder="Phone Number" />
                        <Textarea mt={"20px"} placeholder="Your Messages"/>
                        <Button mt={"20px"}  bg={"rgb(255,218,69)"} w={"100%"}>SEND MAIL</Button>
                    </Box>
                </Flex>
            </Box>
            <Box>
                <Footer/>
            </Box>
        </>
    )
}
export default AccountC;