import { Box, Spinner, Button, Divider, Flex, Table, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr, useToast } from "@chakra-ui/react";
import LeftNav from "./LeftNav";
import { useEffect, useState } from "react";




function WithdrawReq() {
    let [data, setData] = useState([])
    let result = JSON.parse(sessionStorage.getItem("key"));
    const [currentPage, setCurrentPage] = useState(1);
    let toast = useToast();



    let fetchBankWithdraw = async () => {
        try {
            let response = await fetch(`https://trading-jz57.onrender.com/requestmoney/admin`)
            let res = await response.json();
            console.log(res);
            setData(res.reverse());
        } catch (error) {
            console.log(error)
        }
    }





    useEffect(() => {
        fetchBankWithdraw();
    }, [])

    let ApproveRequest = async (IDNumber, withdraw_id, approve) => {
        console.log(approve)
        console.log(withdraw_id)

        try {
            let response = await fetch(`https://trading-jz57.onrender.com/requestmoney/${IDNumber}/${withdraw_id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ "status": approve })
            });

            if (response.ok) {
                let res = await response.json();
                console.log(res);
                toast({
                    description: `${res.msg}`,
                    status: `success`,
                    duration: 5000,
                    isClosable: true,
                    position: "top-center",
                })
                // Reload the page after a successful approveRequest
                setData(prevData => prevData.map(user => {
                    if (user.withdraw_id === withdraw_id) {
                        return { ...user, status: approve }; // Update the status of the specific user
                    }
                    return user;
                }));
            } else {
                console.log(`Error: ${response.status}`);
            }
        } catch (error) {
            console.log(error);
        }
    }



    const userPerPage = 10;
    const lastIndex = currentPage * userPerPage;
    const firstIndex = lastIndex - userPerPage;
    const user = data.slice(firstIndex, lastIndex);
    const totalPages = Math.ceil(data.length / userPerPage);

    return (
        <>
            <Box>
                <Flex gap={"20px"} flexDirection={{ base: "column", sm: "column", md: "column", lg: "row", xl: "row" }}>
                    <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "20%", xl: '20%' }}>
                        <LeftNav />
                    </Box>
                    <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "80%", xl: '80%' }} p={"20px"}>

                        <Text fontSize={"2xl"}>All Withdraw</Text>
                        <Divider></Divider>
                        <TableContainer>
                            <Table variant='simple'>

                                <Thead>
                                    <Tr>

                                        <Th color={"white"}> User Account </Th>
                                        <Th color={"white"}>Date </Th>
                                        <Th color={"white"}>Withdraw Id</Th>
                                        <Th color={"white"}>Bank name</Th>
                                        <Th color={"white"}>Account no</Th>
                                        <Th color={"white"}>IFSC code</Th>
                                        <Th color={"white"}>Name</Th>
                                        <Th color={"white"}>Status</Th>
                                        <Th color={"white"}>Amount</Th>

                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {
                                        user.length > 0 ? user.map((ele, idx) => {
                                            return <Tr>
                                                <Td>{ele.IDNumber}</Td>
                                                <Td>{new Intl.DateTimeFormat('en-IN', {
                                                    timeZone: 'Asia/Kolkata',
                                                    day: 'numeric',
                                                    month: 'short',
                                                    year: 'numeric',
                                                    hour: 'numeric',
                                                    minute: 'numeric',
                                                    second: 'numeric',
                                                    hour12: false,
                                                }).format(new Date(ele.created_at))
                                                    .replace(/ /, '-')
                                                    .replace(/,/g, '')
                                                }</Td>
                                                <Td>{ele.withdraw_id}</Td>
                                                <Td>{ele.bank_name}</Td>
                                                <Td>{ele.account_number}</Td>
                                                <Td>{ele.ifsc_code}</Td>
                                                <Td>{ele.full_name}</Td>
                                                <Td>{ele.status}</Td>

                                                <Td>{ele.withdraw_money}</Td>
                                                <Td>
                                                    <Button
                                                        color={"white"}
                                                        bg={"rgb(10,179,156)"}
                                                        _hover={{ cursor: "pointer", color: "black", bg: "white" }}
                                                        onClick={() => ApproveRequest(ele.IDNumber, ele.withdraw_id, "approved")}
                                                        display={ele.status === "approved" ? "none" : "block"}
                                                    >
                                                        Approve
                                                    </Button>
                                                </Td>
                                                <Td>
                                                    <Button
                                                        color={"white"}
                                                        bg={"red"}
                                                        _hover={{ cursor: "pointer", color: "black", bg: "white" }}
                                                        display={ele.status === "approved" ? "none" : "block"}
                                                        onClick={() => ApproveRequest(ele.IDNumber, ele.withdraw_id, "rejected")}
                                                    >
                                                        Reject
                                                    </Button>
                                                </Td>

                                            </Tr>
                                        }) : <Tr>
                                            <Td colSpan={7} textAlign={"center"}>No Withdraw Request</Td>

                                        </Tr>
                                    }


                                </Tbody>
                                <Tfoot>

                                </Tfoot>
                            </Table>
                        </TableContainer>
                        {user.length > 0 ? (
                            <Flex alignItems="center" justifyContent="right" gap="10px" mt="10px">
                                <Text fontWeight="bold">
                                    {currentPage} of {totalPages}
                                </Text>
                                <Button
                                    bg={"rgb(255,218,69)"} _hover={{ color: "black", bg: "white", cursor: "pointer" }}
                                    color="black"
                                    isDisabled={currentPage == 1}
                                    onClick={() => setCurrentPage(currentPage - 1)}
                                >
                                    {"<"}
                                </Button>
                                <Button
                                    bg={"rgb(255,218,69)"} _hover={{ color: "black", bg: "white", cursor: "pointer" }}
                                    color="black"
                                    isDisabled={currentPage == totalPages}
                                    onClick={() => setCurrentPage(currentPage + 1)}
                                >
                                    {">"}
                                </Button>
                            </Flex>
                        ) : null}
                    </Box>
                </Flex>
            </Box>
        </>
    )
}


export default WithdrawReq;