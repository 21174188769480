import { Box, Button, Flex, Input, Text, Textarea } from "@chakra-ui/react";
import Navbar from "../../Navbar/Navbar";
import NavbarNew from "../../Navbar/NavbarNew";
import NavbarHome from "../../Navbar/NavbarHome";
import Footer from "../../Navbar/Footer";



function Forex() {
    return (
        <>
            <Box>
                <NavbarHome />
            </Box>
            <Box padding={{base:'10px',sm:"10px",md:"10px",xl:"30px 45px",lg:"30px 45px"}} >
                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"50px"}>What is Forex</Text>
            </Box>
            <Box paddingLeft={{base:'10px',sm:"10px",md:"10px",xl:"45px",lg:"45px"}}>
                <Flex gap={"20px"} flexDirection={{base:"column",sm:"column",md:"column",lg:"row",xl:"row"}}>
                    <Box w={{base:'100%',sm:"100%",md:"100%",xl:"70%",lg:"70%"}}>
                        <Box>
                            <Text fontSize={"15px"}>
                                Forex is a commonly used synonym for “foreign exchange,” and it is often used to represent investor and speculator trading in the foreign exchange market.
                            </Text>
                            <Text fontSize={"15px"} mt={"30px"}>
                                Consider the case when the US dollar is predicted to fall in value relative to the euro. In this case, a forex trader will sell dollars and purchase euros. If the euro strengthens, the purchasing power of dollars will rise. The trader can now buy back more dollars than they had before, resulting in a profit.
                            </Text>
                            <Text fontSize={"15px"} mt={"30px"}>
                                This is just like stock trading. A stock trader will buy a stock if they believe the price will climb in the future and sell a stock if they believe the price will decline. Similarly, a forex trader will buy a currency pair if they believe the exchange rate will rise in the future and sell a currency pair if they believe the exchange rate will fall.
                            </Text>
                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>What exactly is an exchange rate?</Text>
                            </Box>
                            <Box>
                            <Text fontSize={"15px"} mt={"30px"}>
                            The foreign exchange market is a global, decentralised marketplace where the relative values of various currencies are determined. In contrast to conventional markets, there is no centralised depository or exchange where transactions take place. Instead, these transactions are carried out by a number of market players in various places. It is unusual for two currencies to have equal values, and it is even more unusual for two currencies to have the same relative value for more than a brief period of time. The exchange rate between two currencies in forex is continually changing.
                            </Text>
                            
                            </Box>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>Why do exchange rates change over time?</Text>
                            </Box>
                            <Box>
                            <Text fontSize={"15px"} mt={"30px"}>
                            Currencies, like stocks, bonds, computers, automobiles, and many other products and services, are traded on an open market. The value of a currency fluctuates with supply and demand, just like anything else.
                            </Text>
                            <Text fontSize={"15px"} mt={"30px"}>
                            The value of a currency can fall due to an increase in supply or a fall in demand. A decrease in supply or an increase in demand for a currency might cause its value to rise.
                            </Text>
                            <Text fontSize={"15px"} mt={"30px"}>
                            One significant advantage of forex trading is the ability to purchase or sell any currency pair at any time, subject to sufficient liquidity. If you believe the Eurozone will break down, you can sell the euro and purchase the dollar (sell EUR/USD). If you believe the price of gold will rise, you can use past connection patterns to purchase the Australian dollar and sell the US dollar (buy AUD/USD).
                            </Text>
                            <Text fontSize={"15px"} mt={"30px"}>
                            This also means that there is no such thing as a “bear market,” in the common sense. When the market is moving up or down, you can make (or lose) money.
                            </Text>
                            </Box>

                        </Box>
                    </Box>

                    <Box w={{base:"100%",sm:"100%",md:"100%",lg:"30%",xl:"30%"}} p={{base:'5px',sm:"5px",md:"5px",lg:"30px",xl:"30px"}}>
                        <Input placeholder="Your Name" />
                        <Input mt={"20px"} placeholder="Your Email" />
                        <Input mt={"20px"} placeholder="Phone Number" />
                        <Textarea mt={"20px"} placeholder="Your Messages"/>
                        <Button mt={"20px"}  bg={"rgb(255,218,69)"} w={"100%"}>SEND MAIL</Button>
                    </Box>
                </Flex>
            </Box>
            <Box>
                <Footer/>
            </Box>
        </>
    )
}
export default Forex;