import { Box, Button, Flex, Input, Text, Textarea } from "@chakra-ui/react";
import Navbar from "../../Navbar/Navbar";
import NavbarNew from "../../Navbar/NavbarNew";
import NavbarHome from "../../Navbar/NavbarHome";
import Footer from "../../Navbar/Footer";



function RealChart() {
    return (
        <>
            <Box>
                <NavbarHome />
            </Box>
            <Box padding={{base:'10px',sm:"10px",md:"10px",xl:"30px 45px",lg:"30px 45px"}} >
                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"50px"}>Real Time Charts</Text>
            </Box>
            <Box paddingLeft={{base:'10px',sm:"10px",md:"10px",xl:"45px",lg:"45px"}}>
                <Flex gap={"20px"} flexDirection={{base:"column",sm:"column",md:"column",lg:"row",xl:"row"}}>
                    <Box w={{base:'100%',sm:"100%",md:"100%",xl:"70%",lg:"70%"}}>
                        <Box>
                            <Text fontSize={"15px"}>
                            Clients of GloabFx have free direct access to innovative market indicators and trading charts. Our charts give traders the best possible trading experience by enabling them to perform sophisticated technical analysis across a variety of time frames. Investors can use our charts to graphically follow changes in currency prices and assess market trends. All currency pairs also have real-time and historical prices available. All levels of traders can profit from our user-friendly, customisable charting software.
                            </Text>
                          
                        </Box>
                        <Box >
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>The benefits from using our Trading Charts</Text>
                            </Box>
                            <Box p={"20px"}>
                            <Text fontSize={"15px"} mt={"10px"}>
                            <li>The study of many time frames enables traders to quickly identify trends and patterns.</li>
                            </Text>
                            <Text fontSize={"15px"} >
                            <li>Tools that can be altered to suit the needs of each trader.</li>
                            </Text>
                            <Text fontSize={"15px"} >
                            <li>the ability to layer several indicators on top of any commodity or currency combination.</li>
                            </Text>
                            <Text fontSize={"15px"} >
                            <li>Join us today and benefit from GloabFx’s sophisticated charts!</li>
                            </Text>
                            
                            </Box>

                        </Box>
                       
                    </Box>

                    <Box w={{base:"100%",sm:"100%",md:"100%",lg:"30%",xl:"30%"}} p={{base:'5px',sm:"5px",md:"5px",lg:"30px",xl:"30px"}}>
                        <Input placeholder="Your Name" />
                        <Input mt={"20px"} placeholder="Your Email" />
                        <Input mt={"20px"} placeholder="Phone Number" />
                        <Textarea mt={"20px"} placeholder="Your Messages"/>
                        <Button mt={"20px"}  bg={"rgb(255,218,69)"} w={"100%"}>SEND MAIL</Button>
                    </Box>
                </Flex>
            </Box>
            <Box>
                <Footer/>
            </Box>
        </>
    )
}
export default RealChart;