import { Box, Button, Divider, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerOverlay, Flex, Image, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverFooter, PopoverHeader, PopoverTrigger, Portal, Text, useDisclosure, useToast } from "@chakra-ui/react";
import logo from "../images/logo.png";
import { ChevronDownIcon, ChevronLeftIcon, DragHandleIcon, HamburgerIcon } from "@chakra-ui/icons";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { myContext } from "../context/Context";




function Navbar() {
    let result = JSON.parse(sessionStorage.getItem("demo"));
    let navigate = useNavigate();
    let { logout, totalbalance, fetchBalance } = useContext(myContext);
    let toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure()


    let logOut = () => {

        logout();
        navigate("/login")
        toast({
            description: `Logged Out`,
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-center",
        })
        window.location.reload(true)
        console.log(result)
    }











    return (
        <>
            <Box bg={"rgb(55,67,78)"} boxShadow="rgba(0, 0, 0, 0.1) 0px 4px 12px">
                <Flex justifyContent={"space-between"}>
                    {
                        result === "" ? <Box p={"20px"} w={{ base: "100%", sm: "100%", md: "100%", lg: "38%", xl: "38%" }} onClick={() => { navigate("/mainpagehome") }}>
                            <Box w={{  base: "62.5%", sm: "62.5%", md: "62.5%", lg: "38%", xl: "38%" }}>
                                <Image cursor={"pointer"} src={logo}></Image>
                            </Box>
                        </Box> :
                            <Box p={"20px"} w={{ base: "100%", sm: "100%", md: "100%", lg: "38%", xl: "38%" }} onClick={() => { navigate("/demo") }}>
                                <Box w={{ base: "62.5%", sm: "62.5%", md: "62.5%", lg: "38%", xl: "38%" }}>
                                    <Image cursor={"pointer"} src={logo}></Image>
                                </Box>
                            </Box>
                    }

                    

                    <Box mt={"-2px"} color={"white"} alignItems={"center"} pr={{ base: "18px", sm: "18px", md: "18px", lg: "0px", xl: "0px" }} fontSize={{ base: "40px", sm: "40px", md: "50px", lg: "30px", xl: "30px" }} display={{ base: "block", sm: "block", md: "block", lg: "none", xl: "none" }} onClick={() => onOpen()} >
                        <HamburgerIcon ></HamburgerIcon></Box>
                    <Drawer
                        isOpen={isOpen}
                        placement='right'
                        onClose={onClose}
                    >
                        <DrawerOverlay />
                        <DrawerContent bg={"rgb(55,67,78)"} color={"white"}>
                            <DrawerCloseButton />
                            {/* <DrawerHeader>Create your account</DrawerHeader> */}

                            <DrawerBody mt="60px" >
                                {
                                    result === "" ? <Box>
                                    </Box> : <Flex gap={"5px"} alignItems={"center"}>
                                        <ion-icon name="key-outline" size="small"></ion-icon>
                                        <Text fontSize={"md"} cursor={"pointer"} _hover={{ color: 'rgb(255,218,69)' }} > Ac No:- {result.type.AcNumber}</Text>
                                    </Flex>
                                }
                                {
                                    result === "" ? <Box></Box> : <Divider mt={"10px"} color={"rgb(250,250,250)"} />
                                }
                                {
                                    result === "" ? <Box>
                                        <Flex gap={"5px"} alignItems={"center"}>
                                            <ion-icon name="log-in-outline"></ion-icon>
                                            <Text onClick={() => navigate("/login")}>LOGIN</Text>
                                        </Flex>

                                    </Box> :
                                        <Flex mt="10px" gap={"5px"} alignItems={"center"}>
                                            <ion-icon name="person-circle-outline" size="small"></ion-icon>
                                            <Text fontWeight="semibold" _hover={{ color: "rgb(255,218,69)", cursor: "pointer" }} >{result.type.first_name + " " + result.type.last_name}</Text>
                                        </Flex>

                                }
                                <Divider mt={"10px"} color={"rgb(250,250,250)"} />

                                {
                                    result === "" ? <Box mt={"20px"}>
                                        <Flex gap={"5px"} alignItems={"center"}>
                                            <ion-icon name="person-add-outline"></ion-icon>
                                            <Text _hover={{ color: "rgb(255,218,69)", cursor: "pointer" }} onClick={() => navigate("/register")}>REGISTER</Text>
                                        </Flex>

                                    </Box> :
                                        <Flex mt="10px" gap={"5px"} alignItems={"center"}>
                                            <ion-icon name="stats-chart-outline" size="small"></ion-icon>
                                            <Text fontWeight="bold" _hover={{ color: "rgb(255,218,69)", cursor: "pointer" }} onClick={() => navigate("/livechart")}>Live chart</Text>
                                        </Flex>

                                }
                                <Divider mt={"10px"} color={"rgb(250,250,250)"} />
                                {
                                    result === "" ? <Box mt={"20px"}>
                                    <Flex gap={"5px"} alignItems={"center"}>
                                        <ion-icon name="home-outline"></ion-icon>
                                        <Text _hover={{ color: "rgb(255,218,69)", cursor: "pointer" }} onClick={() => navigate("/mainpagehome")}>Dashboard</Text>
                                    </Flex></Box> : <Divider mt={"10px"} color={"rgb(250,250,250)"} />
                                }

                                {
                                    result === "" ? null :
                                        <Flex mt="10px" gap={"5px"} alignItems={"center"}>
                                            <ion-icon name="home-outline" size="small"></ion-icon>
                                            <Text fontWeight="semibold" _hover={{ color: "rgb(255,218,69)", cursor: "pointer" }} onClick={() => navigate('/demo')}>Home</Text>
                                        </Flex>

                                }
                                {
                                    result === "" ? <Box></Box> : <Divider mt={"10px"} color={"rgb(250,250,250)"} />
                                }

                               
                               
                               
                                <Divider mt={"10px"} color={"rgb(250,250,250)"} />


                                {
                                    result === "" ? null :
                                        <Flex mt="10px" gap={"5px"} alignItems={"center"}>
                                            <ion-icon name="close-circle-outline" size="small"></ion-icon>
                                            <Text fontWeight="semibold" _hover={{ color: "rgb(255,218,69)", cursor: "pointer" }} onClick={() => navigate("/closedemo")}>Closed deals</Text>
                                        </Flex>

                                }
                                {
                                    result === "" ? <Box></Box> : <Divider mt={"10px"} color={"rgb(250,250,250)"} />
                                }
                               
                               


                                {/* {
                                            result === "" ? null :
                                                <Flex mt="10px" gap={"5px"} alignItems={"center"}>
                                                    <ion-icon name="key-outline" size="small"></ion-icon>
                                                    <Text  fontWeight="semibold" _hover={{ color: "red", cursor: "pointer" }}
                                                    // onClick={()=>navigate("/")}
                                                    >Document Verification</Text>
                                                </Flex>

                                        } */}

                               



                            </DrawerBody>

                            <DrawerFooter>
                                {
                                    result === "" ? null : <Button onClick={logOut} mr={3}>
                                        Logout
                                    </Button>
                                }
                            </DrawerFooter>
                        </DrawerContent>
                    </Drawer>
                    <Box bg={"rgb(55,67,78)"} p={"10px"} w={"50%"} display={{ base: "none", sm: "none", md: "none", lg: "block", xl: "block" }}>

                        <Flex justifyContent={"space-between"} alignItems={"center"} p={"10px"}>
                            {/* <Box color={"white"}
                                // onClick={openDrawer} 
                                cursor="pointer">
                                <Flex gap={"5px"} alignItems={"center"}>
                                    <Text fontWeight={"bold"} fontSize='2xl'>${neteq === NaN ? "0.00": neteq.toFixed(2)}</Text>
                                    <ChevronDownIcon fontSize='3xl'></ChevronDownIcon >
                                </Flex>

                            </Box> */}

                            <Box color={"white"} cursor="pointer" alignItems={"center"} justifyContent={"flex-end"}>
                                {
                                    result === "" ? null :
                                        <Box fontSize={'4xl'} color={"white"} cursor="pointer">
                                            <Popover>
                                                <PopoverTrigger cursor="pointer" >
                                                    <ion-icon name="person-circle-outline" ></ion-icon>
                                                </PopoverTrigger>
                                                <Portal>
                                                    <PopoverContent bg={"rgb(55,67,78)"} color={"white"}>
                                                        <PopoverArrow />

                                                        <PopoverCloseButton />
                                                        <PopoverBody>
                                                            <Box>
                                                                <Text cursor={"pointer"} _hover={{ color: 'rgb(255,218,69)' }} display={{ base: "none", sm: "none", md: "none", lg: "block", xl: "block" }} ><ChevronLeftIcon></ChevronLeftIcon> Ac No:- {result.type.AcNumber}</Text>
                                                                <Text cursor={"pointer"} _hover={{ color: "rgb(255,218,69)" }} ><ChevronLeftIcon></ChevronLeftIcon> {result === "" ? "" : result.type.first_name + " " + result.type.last_name}</Text>
                                                                
                                                               
                                                                <Text mt={"8px"} cursor={"pointer"} _hover={{ color: "rgb(255,218,69)" }} onClick={logOut}><ChevronLeftIcon></ChevronLeftIcon> Log Out</Text>
                                                            </Box>
                                                        </PopoverBody>

                                                    </PopoverContent>
                                                </Portal>
                                            </Popover>
                                        </Box>
                                }

                            </Box>
                            {
                                result === "" ? <Box>
                                    <Text></Text>
                                </Box> :
                                    <Text fontWeight="bold" color={"white"} _hover={{ color: "rgb(255,218,69)", cursor: "pointer" }} onClick={() => navigate('/demo')}>HOME</Text>
                            }
                            {
                                result === "" ? <Box>
                                    <Button color={"black"} bg={"rgb(255,218,69)"} _hover={{ color: "black", bg: "white", cursor: "pointer" }} onClick={() => navigate("/mainpagehome")}>DASHBOARD</Button>
                                </Box> : <Box >
                                    <Text fontWeight={"bold"} color={"white"} _hover={{ color: "rgb(255,218,69)", cursor: "pointer" }} onClick={() => navigate("/closedemo")}>CLOSED DEALS</Text>
                                </Box>
                            }
                            {
                                result === "" ? 
                                     <Box>
                                    <Button color={"black"} bg={"rgb(255,218,69)"} _hover={{ color: "black", bg: "white", cursor: "pointer" }} onClick={() => navigate("/login")}>LOGIN</Button>
                            
                                </Box> :
                                    <Box>
                                       
                                    </Box>
                            }
                             


                            {
                                result === "" ? <Box>
                                    <Button color={"black"} bg={"rgb(255,218,69)"} _hover={{ color: "black", cursor: "pointer", bg: "white" }} onClick={() => navigate("/register")}>REGISTER</Button>
                                </Box> : <Box >
                                    
                                </Box>
                            }
                            {
                                result === "" ? 
                                     <Box>
                                   
                            
                                </Box> :
                                    <Box>
                                        <Button color={"black"} bg={"rgb(255,218,69)"} _hover={{ bg: 'white', color: "black" }} cursor={"pointer"} onClick={() => navigate("/home")}>SWITCH TO LIVE</Button>
                                    </Box>
                            }

                        </Flex>
                    </Box>
                </Flex>
            </Box>
        </>
    )
}
export default Navbar;