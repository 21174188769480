import { Box, Image, Text, Input, InputGroup, InputRightElement, Button, useToast, Spinner } from "@chakra-ui/react";
import logo from "../../images/logo.png"
import { useState } from "react";
import { useNavigate } from "react-router-dom";


function AdminLogin() {
    const [show, setShow] = useState(false);
    const handleClick = () => setShow(!show);
    let navigate = useNavigate()
    const toast = useToast()
    let [load,setLoad]=useState(true)

    let [data, setData] = useState({
        email: "",
        password: ""
    })

    let saveData = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    }
    console.log(data)

    let fetchData = async (e) => {
        e.preventDefault()
        setLoad(false)
        try {
            let response = await fetch(`https://trading-jz57.onrender.com/adminlogin`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });
    
            
    
            if (response.ok) {
                console.log("Login successful!");
                let res = await response.json();
                sessionStorage.setItem("key",JSON.stringify(res));
                navigate("/adminuser");
                toast({
                    title: 'Welcome Admin',
                    description: `Logged in successfully`,
                    status: 'success',
                    position: "top",
                    duration: 9000,
                    isClosable: true,
                });
                setLoad(true)
            } else {
                console.log("Login failed!");
                toast({
                    title: 'Invalid Entry.',
                    description: "Invalid username and password",
                    status: 'error',
                    position: "top",
                    duration: 9000,
                    isClosable: true,
                });
                setLoad(true)
            }
        } catch (error) {
            console.error(error);
            setLoad(true)
        }
    };
    
    return (
        <>
            <Box mt={"5rem"}>
                <Box margin={"auto"} w={"25%"} h={"30rem"} mt={"50px"} p={"40px"}
                    boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                    borderRadius={"15px"}
                >
                    <Image w={"250px"} h={"50px"}
                        display={"block"} margin={"0 auto"} src={logo} />
                    <Text textAlign={"center"} mt={"20px"} fontWeight={"bold"} fontSize={"3xl"}>Welcome</Text>
                    <form onSubmit={fetchData}>
                        <Text mt={"20px"}>Email</Text>
                        <Input mt={"10px"} fontSize="14px"
                            type="email"
                            name="email"
                            _placeholder={{ fontSize: "14px" }}
                            placeholder="Enter Password"
                            value={data.email}
                            onChange={saveData}
                            isRequired />
                        <Text mt={"20px"}>Password</Text>
                        <InputGroup size="md">
                            <Input

                                name="password"
                                mt={"10px"}
                                pr="4.5rem"
                                type={show ? "text" : "password"}
                                fontSize="14px"
                                _placeholder={{ fontSize: "14px" }}
                                placeholder="Enter Password"
                                value={data.password}
                                onChange={saveData}
                                isRequired
                            />
                            <InputRightElement width="4.5rem" mt={"10px"}>
                                <Button h="1.75rem" size="sm" onClick={handleClick}>
                                    {show ? "Hide" : "Show"}
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                        <Box textAlign={"center"}>
                            {
                                load? <Button type="submit" bg={"red"} color={"white"} mt={"20px"} >LOGIN</Button>:
                                <Button type="submit" bg={"red"} color={"white"} mt={"20px"} ><Spinner/></Button>
                            }
                           
                        </Box>
                    </form>
                </Box>
            </Box>
        </>
    )
}

export default AdminLogin;