import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Divider, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, Image, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Text, useDisclosure } from "@chakra-ui/react"
import logo from "../images/logo.png";
import { ChevronDownIcon, HamburgerIcon } from "@chakra-ui/icons";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";


function NavbarHome() {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen: isModal1Open, onOpen: openModal1, onClose: closeModal1 } = useDisclosure();
    //Take profit
    const { isOpen: isModal2Open, onOpen: openModal2, onClose: closeModal2 } = useDisclosure();
    //stoploss
    const { isOpen: isModal3Open, onOpen: openModal3, onClose: closeModal3 } = useDisclosure();
    const { isOpen: isModal4Open, onOpen: openModal4, onClose: closeModal4 } = useDisclosure();
    const { isOpen: isModal5Open, onOpen: openModal5, onClose: closeModal5 } = useDisclosure();
    let navigate = useNavigate();
    return (
        <>
            <Box bg={"rgb(55,67,78)"} boxShadow="rgba(0, 0, 0, 0.1) 0px 4px 12px" position={"relative"}>
                <Flex alignItems={"center"} justifyContent={{ base: "space-between", sm: "space-between", md: "space-between", lg: "", xl: "" }}>
                    <Box w={{ base: "70%", sm: "70%", md: "70%", lg: "20%", xl: "20%" }}  >
                        <Box p={"20px"} w={{ base: "100%", sm: "100%", md: "100%", lg: "70%", xl: "70%" }} m={"auto"}>
                            <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "70%%", xl: "70%%" }}>
                                <Image cursor={"pointer"} src={logo}></Image>
                            </Box>
                        </Box>
                    </Box>
                    <Box fontSize={"45px"} pr={"10px"} display={{ base: 'block', sm: "block", md: "block", lg: "none", xl: 'none' }}>
                        <HamburgerIcon onClick={onOpen}></HamburgerIcon>
                        <Drawer

                            isOpen={isOpen}
                            placement='right'
                            onClose={onClose}

                        >
                            <DrawerOverlay />
                            <DrawerContent bg={"rgb(55,67,78)"} color={"white"}>
                                <DrawerCloseButton />


                                <DrawerBody mt="60px">
                                    <Box>
                                        <Flex ml={"13px"} mt="10px" gap={"5px"} alignItems={"center"}>
                                            <Text fontWeight="semibold" _hover={{ color: "rgb(255,218,69)", cursor: "pointer" }} onClick={() => navigate('/mainpagehome')}>Home</Text>
                                        </Flex>

                                    </Box>
                                    <Box position={"relative"} zIndex={100} >
                                        <Accordion  allowMultiple>
                                            <AccordionItem>
                                                <h2>
                                                    <AccordionButton>
                                                        <Box flex='1' textAlign='left'>
                                                            <Text fontWeight="semibold" _hover={{ color: "rgb(255,218,69)", cursor: "pointer" }} >Forex</Text>
                                                        </Box>
                                                        <AccordionIcon />
                                                    </AccordionButton>
                                                </h2>
                                                <AccordionPanel pb={4}>
                                                    <Text cursor={"pointer"} onClick={() => navigate("/forex")}>What is Forex</Text>
                                                    <Text cursor={"pointer"} mt={"10px"} onClick={() => navigate("/commodities")}>Commodities</Text>
                                                    <Text cursor={"pointer"} mt={"10px"} onClick={() => navigate("/indices")}>Indices</Text>
                                                    <Text cursor={"pointer"} mt={"10px"} onClick={() => {
                                                        navigate("/stock")
                                                    }}>Stocks</Text>
                                                    <Text cursor={"pointer"} mt={"10px"} onClick={() => navigate("/currencytrad")}>Currency Trading</Text>
                                                    <Text cursor={"pointer"} mt={"10px"} onClick={() => navigate("/cryptocurr")}>Crypto Currency</Text>
                                                    <Text cursor={"pointer"} mt={"10px"} onClick={() => navigate("/faq")}>Trading FAQs</Text>
                                                </AccordionPanel>
                                            </AccordionItem>


                                        </Accordion>
                                    </Box>
                                    <Box>
                                        <Accordion  allowMultiple>
                                            <AccordionItem>
                                                <h2>
                                                    <AccordionButton>
                                                        <Box flex='1' textAlign='left'>
                                                            <Text fontWeight="semibold" _hover={{ color: "rgb(255,218,69)", cursor: "pointer" }} >About Us</Text>
                                                        </Box>
                                                        <AccordionIcon />
                                                    </AccordionButton>
                                                </h2>
                                                <AccordionPanel pb={4}>
                                                    <Text cursor={"pointer"} mt={"10px"} onClick={() => navigate("/goal")}>Our Goal</Text>
                                                    <Text cursor={"pointer"} mt={"10px"} onClick={() => navigate("/benfit")}>Benefits of Trading</Text>
                                                    <Text cursor={"pointer"} mt={"10px"} onClick={() => navigate("/costtrading")}>Costs and Trading</Text>
                                                    <Text cursor={"pointer"} mt={"10px"} onClick={() => navigate("/help")}>GloabFx Help</Text>
                                                </AccordionPanel>
                                            </AccordionItem>


                                        </Accordion>
                                    </Box>
                                    <Box>
                                        <Accordion  allowMultiple>
                                            <AccordionItem>
                                                <h2>
                                                    <AccordionButton>
                                                        <Box flex='1' textAlign='left'>
                                                            <Text fontWeight="semibold" _hover={{ color: "rgb(255,218,69)", cursor: "pointer" }} >Trading</Text>
                                                        </Box>
                                                        <AccordionIcon />
                                                    </AccordionButton>
                                                </h2>
                                                <AccordionPanel pb={4}>
                                                    <Text cursor={"pointer"} mt={"10px"} onClick={() => navigate("/openlive")}>Open Live Account</Text>
                                                    <Text cursor={"pointer"} mt={"10px"} onClick={() => navigate("/accountc")}>Account Comparison</Text>
                                                    <Text cursor={"pointer"} mt={"10px"} onClick={() => navigate("/accountv")}>Account Verification</Text>
                                                </AccordionPanel>
                                            </AccordionItem>


                                        </Accordion>
                                    </Box>
                                    <Box>
                                        <Accordion  allowMultiple>
                                            <AccordionItem>
                                                <h2>
                                                    <AccordionButton>
                                                        <Box flex='1' textAlign='left'>
                                                            <Text fontWeight="semibold" _hover={{ color: "rgb(255,218,69)", cursor: "pointer" }} >Tools</Text>
                                                        </Box>
                                                        <AccordionIcon />
                                                    </AccordionButton>
                                                </h2>
                                                <AccordionPanel pb={4}>
                                                    <Text mt={"10px"} cursor={"pointer"} onClick={() => navigate("/dailym")}>Daily Market Review</Text>
                                                    <Text mt={"10px"} cursor={"pointer"} onClick={() => navigate("/fincal")}>Financial Calendar</Text>
                                                    <Text mt={"10px"} cursor={"pointer"} onClick={() => navigate("/realchart")}>Real Time Charts</Text>
                                                    <Text mt={"10px"} cursor={"pointer"} onClick={() => navigate("/signal")}>Signals</Text>
                                                    <Text mt={"10px"} cursor={"pointer"} onClick={() => navigate("/trading")}>Full List of Trading Signals</Text>
                                                    <Text mt={"10px"} cursor={"pointer"} onClick={() => navigate("/platform")}>Platform</Text>
                                                    <Text mt={"10px"} cursor={"pointer"} onClick={() => navigate("/education")}>Education Package</Text>
                                                </AccordionPanel>
                                            </AccordionItem>


                                        </Accordion>
                                    </Box>
                                    <Box>
                                        <Accordion  allowMultiple>
                                            <AccordionItem>
                                                <h2>
                                                    <AccordionButton>
                                                        <Box flex='1' textAlign='left'>
                                                            <Text fontWeight="semibold" _hover={{ color: "rgb(255,218,69)", cursor: "pointer" }} >Legal</Text>
                                                        </Box>
                                                        <AccordionIcon />
                                                    </AccordionButton>
                                                </h2>
                                                <AccordionPanel pb={4}>
                                                    <Text cursor={"pointer"} mt={"10px"} onClick={() => navigate("/policy")}>Our Trading Policy</Text>
                                                    <Text cursor={"pointer"} mt={"10px"} onClick={() => navigate("/terms")}>Trading Terms</Text>
                                                    <Text cursor={"pointer"} mt={"10px"} onClick={() => navigate("/risk")}>Risk Caution for Forex</Text>
                                                </AccordionPanel>
                                            </AccordionItem>


                                        </Accordion>
                                    </Box>
                                    <Box ml={"13px"} mt="10px">
                                        <Text fontSize={"16px"} cursor={"pointer"} onClick={() => navigate("/login")}>Login</Text>
                                        <Divider mt="10px" />
                                    </Box>
                                    <Box ml={"13px"} mt="10px">
                                        <Text fontSize={"16px"} cursor={"pointer"} onClick={() => navigate("/register")}>Register</Text>
                                        <Divider mt="10px" />
                                    </Box>
                                    <Box ml={"13px"} mt="10px">
                                        <Text fontSize={"16px"} cursor={"pointer"} onClick={() => navigate("/contactus")}>Contact Us</Text>
                                        <Divider mt="10px" />
                                    </Box>
                                </DrawerBody>

                                <DrawerFooter>

                                </DrawerFooter>
                            </DrawerContent>
                        </Drawer>
                    </Box>
                    <Box w={"70%"} m={"auto"} display={{ base: 'none', sm: "none", md: "none", lg: "block", xl: 'block' }}>
                        <Flex justifyContent={"space-between"}>
                            <Text fontSize={"16px"} cursor={"pointer"} onClick={() => navigate('/mainpagehome')}>Home</Text>
                            <Box>
                                <ForexPopover />
                            </Box>
                            <Box >

                                <Box
                                    cursor={"pointer"}

                                >
                                    <AboutPopover />
                                </Box>

                            </Box>
                            <Box>
                                <TradingPopover />
                            </Box>
                            <Box>
                                <ToolsPopover />
                            </Box>
                            <Box>
                                <LegalPopover />
                            </Box>
                            <Text fontSize={"16px"} cursor={"pointer"} onClick={() => navigate("/login")}>Login</Text>
                            <Text fontSize={"16px"} cursor={"pointer"} onClick={() => navigate("/register")}>Register</Text>
                            <Text fontSize={"16px"} cursor={"pointer"} onClick={() => navigate("/contactus")}>Contact Us</Text>
                        </Flex>
                    </Box>
                </Flex>
            </Box>
        </>
    )
}
const ForexPopover = () => {
    let navigate = useNavigate()
    return (
        <Popover>
            <PopoverTrigger>
                <Box cursor={"pointer"}>
                    <Flex alignItems={"center"}>
                        <Text fontSize={"16px"}>Forex</Text>
                        <ChevronDownIcon fontSize={"16px"} />
                    </Flex>
                </Box>
            </PopoverTrigger>
            <PopoverContent width="200px" border={"none"} boxShadow="rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;" borderRadius={0} bg={"rgb(55,67,78)"} color="rgb(255,218,69)"> {/* Set borderRadius to 0 */}



                <PopoverBody color="rgb(255,218,69)" p={"20px"}>
                    <Text cursor={"pointer"} onClick={() => navigate("/forex")}>What is Forex</Text>
                    <Text cursor={"pointer"} mt={"10px"} onClick={() => navigate("/commodities")}>Commodities</Text>
                    <Text cursor={"pointer"} mt={"10px"} onClick={() => navigate("/indices")}>Indices</Text>
                    <Text cursor={"pointer"} mt={"10px"} onClick={() => {
                        navigate("/stock")
                    }}>Stocks</Text>
                    <Text cursor={"pointer"} mt={"10px"} onClick={() => navigate("/currencytrad")}>Currency Trading</Text>
                    <Text cursor={"pointer"} mt={"10px"} onClick={() => navigate("/cryptocurr")}>Crypto Currency</Text>
                    <Text cursor={"pointer"} mt={"10px"} onClick={() => navigate("/faq")}>Trading FAQs</Text>

                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
}

const AboutPopover = () => {
    let navigate = useNavigate()
    return (
        <Popover>
            <PopoverTrigger>
                <Box cursor={"pointer"}>
                    <Flex alignItems={"center"}>
                        <Text fontSize={"16px"}>About us</Text>
                        <ChevronDownIcon fontSize={"16px"}></ChevronDownIcon>
                    </Flex>
                </Box>
            </PopoverTrigger>
            <PopoverContent width="200px" border={"none"} boxShadow="rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;" borderRadius={0} bg={"rgb(55,67,78)"} color="rgb(255,218,69)"> {/* Set borderRadius to 0 */}

                

                <PopoverBody color="rgb(255,218,69)" p={"20px"}>
                    <Text cursor={"pointer"} onClick={() => navigate("/goal")}>Our Goal</Text>
                    <Text cursor={"pointer"} mt={"10px"} onClick={() => navigate("/benfit")}>Benefits of Trading</Text>
                    <Text cursor={"pointer"} mt={"10px"} onClick={() => navigate("/costtrading")}>Costs and Trading</Text>
                    <Text cursor={"pointer"} mt={"10px"} onClick={() => navigate("/help")}>GloabFx Help</Text>

                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
}
const TradingPopover = () => {
    let navigate = useNavigate()
    return (
        <Popover>
            <PopoverTrigger>
                <Box cursor={"pointer"}>
                    <Flex alignItems={"center"}>
                        <Text fontSize={"16px"}>
                            Trading
                        </Text>
                        <ChevronDownIcon fontSize={"16px"}></ChevronDownIcon>
                    </Flex>
                </Box>
            </PopoverTrigger>
            <PopoverContent width="200px" border={"none"} boxShadow="rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;" borderRadius={0} bg={"rgb(55,67,78)"} color="rgb(255,218,69)"> {/* Set borderRadius to 0 */}

               

                <PopoverBody color="rgb(255,218,69)" p={"20px"}>
                    <Text cursor={"pointer"} onClick={() => navigate("/openlive")}>Open Live Account</Text>
                    <Text cursor={"pointer"} mt={"10px"} onClick={() => navigate("/accountc")}>Account Comparison</Text>
                    <Text cursor={"pointer"} mt={"10px"} onClick={() => navigate("/accountv")}>Account Verification</Text>


                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
}

const ToolsPopover = () => {
    let navigate = useNavigate()
    return (
        <Popover>
            <PopoverTrigger>
                <Box cursor={"pointer"}>
                    <Flex alignItems={"center"}>
                        <Text fontSize={"16px"}>
                            Tools
                        </Text>
                        <ChevronDownIcon fontSize={"16px"}></ChevronDownIcon>
                    </Flex>
                </Box>
            </PopoverTrigger>
            <PopoverContent width="230px" border={"none"} boxShadow="rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;" borderRadius={0} bg={"rgb(55,67,78)"} color="rgb(255,218,69)"> {/* Set borderRadius to 0 */}

               

                <PopoverBody color="rgb(255,218,69)" p={"20px"}>
                    <Text cursor={"pointer"} onClick={() => navigate("/dailym")}>Daily Market Review</Text>
                    <Text cursor={"pointer"} mt={"10px"} onClick={() => navigate("/fincal")}>Financial Calendar</Text>
                    <Text cursor={"pointer"} mt={"10px"} onClick={() => navigate("/realchart")}>Real Time Charts</Text>
                    <Text cursor={"pointer"} mt={"10px"} onClick={() => navigate("/signal")}>Signals</Text>
                    <Text cursor={"pointer"} mt={"10px"} onClick={() => navigate("/trading")}>Full List of Trading Signals</Text>
                    <Text cursor={"pointer"} mt={"10px"} onClick={() => navigate("/platform")}>Platform</Text>
                    <Text cursor={"pointer"} mt={"10px"} onClick={() => navigate("/education")}>Education Package</Text>


                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
}
const LegalPopover = () => {
    let navigate = useNavigate();
    return (
        <Popover>
            <PopoverTrigger>
                <Box cursor={"pointer"}>
                    <Flex alignItems={"center"}>
                        <Text fontSize={"16px"}>
                            Legal
                        </Text >
                        <ChevronDownIcon fontSize={"16px"}></ChevronDownIcon>
                    </Flex>
                </Box>
            </PopoverTrigger>
            <PopoverContent width="200px" border={"none"}  borderRadius={0} bg={"rgb(55,67,78)"} color="rgb(255,218,69)"> {/* Set borderRadius to 0 */}

               

                <PopoverBody color="rgb(255,218,69)" p={"20px"}>
                    <Text cursor={"pointer"} onClick={() => navigate("/policy")}>Our Trading Policy</Text>
                    <Text cursor={"pointer"} mt={"10px"} onClick={() => navigate("/terms")}>Trading Terms</Text>
                    <Text cursor={"pointer"} mt={"10px"} onClick={() => navigate("/risk")}>Risk Caution for Forex</Text>


                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
}
export default NavbarHome;


