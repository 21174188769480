import { Box, Button, Flex, Input, Text, Textarea } from "@chakra-ui/react";
import Navbar from "../../Navbar/Navbar";
import NavbarNew from "../../Navbar/NavbarNew";
import NavbarHome from "../../Navbar/NavbarHome";
import Footer from "../../Navbar/Footer";



function Stocks() {
    return (
        <>
            <Box>
                <NavbarHome />
            </Box>
            <Box padding={{base:'10px',sm:"10px",md:"10px",xl:"30px 45px",lg:"30px 45px"}} >
                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"50px"}>Stocks</Text>
            </Box>
            <Box paddingLeft={{base:'10px',sm:"10px",md:"10px",xl:"45px",lg:"45px"}}>
                <Flex gap={"20px"} flexDirection={{base:"column",sm:"column",md:"column",lg:"row",xl:"row"}}>
                    <Box w={{base:'100%',sm:"100%",md:"100%",xl:"70%",lg:"70%"}}>
                        <Box>
                            <Text fontSize={"15px"}>Stock is a claim to the assets and income of the company. Your ownership position in the company increases as you purchase additional stock. A stock certificate serves as a representation of a stock and is now referred to as having shares “in street name”. Your voting power in the annual meetings of the firm is limited to one vote per share for the election of the board of directors. The goal of the company’s management is to make the business more valuable to shareholders. Being a shareholder is significant because it gives you a claim on corporate assets and a share of the earnings. Although stocks have the potential to generate enormous wealth, they also carry a high level of risk. What does it mean when individuals say, “The market performed exceptionally well today?” What exactly is “the market”? When most individuals refer to “the market,” they are referring to an index. An index is a statistical measure of movements in a portfolio of equities that represents a subset of the broader market. It would be impossible to keep track of every single security transaction in the country. To avoid this, we use a smaller sample of the market that is indicative of the entire market. For more than a century, trading major market indexes has been a popular way to invest, but it has not always been available to individual traders.
                            </Text>
                            
                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"50px"}>Benefits:</Text>
                            </Box>
                            <Box p={"30px"}>
                            <Text fontSize={"15px"} mt={"10px"}>
                           <li>Promote the expansion of the economy.</li>
                            </Text>
                            <Text fontSize={"15px"} >
                           <li>The best strategy to beat inflation.</li>
                            </Text>
                            <Text fontSize={"15px"} >
                           <li>Buy and sell with ease.</li>
                            </Text>
                            <Text fontSize={"15px"} >
                           <li>Invest less in your portfolio by trading equities commission-free.</li>
                            </Text>
                            
                            </Box>

                        </Box>
                       
                    </Box>

                    <Box w={{base:"100%",sm:"100%",md:"100%",lg:"30%",xl:"30%"}} p={{base:'5px',sm:"5px",md:"5px",lg:"30px",xl:"30px"}}>
                        <Input placeholder="Your Name" />
                        <Input mt={"20px"} placeholder="Your Email" />
                        <Input mt={"20px"} placeholder="Phone Number" />
                        <Textarea mt={"20px"} placeholder="Your Messages"/>
                        <Button mt={"20px"}  bg={"rgb(255,218,69)"} w={"100%"}>SEND MAIL</Button>
                    </Box>
                </Flex>
            </Box>
            <Box>
                <Footer/>
            </Box>
        </>
    )
}
export default Stocks;