import { Box, Button, Flex, Input, Text, Textarea } from "@chakra-ui/react";
import Navbar from "../../Navbar/Navbar";
import NavbarNew from "../../Navbar/NavbarNew";
import NavbarHome from "../../Navbar/NavbarHome";
import Footer from "../../Navbar/Footer";



function Risk() {
    return (
        <>
            <Box>
                <NavbarHome />
            </Box>
            <Box padding={{base:'10px',sm:"10px",md:"10px",xl:"30px 45px",lg:"30px 45px"}} >
                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"50px"}>Risk Caution for Forex</Text>
            </Box>
            <Box paddingLeft={{base:'10px',sm:"10px",md:"10px",xl:"45px",lg:"45px"}}>
                <Flex gap={"20px"} flexDirection={{base:"column",sm:"column",md:"column",lg:"row",xl:"row"}}>
                    <Box w={{base:'100%',sm:"100%",md:"100%",xl:"70%",lg:"70%"}}>
                        <Box p={"20px"}>
                            <Text fontSize={"15px"}>
                               <li>FOREX offers a number of financial products (referred to as “the Products”) that permit, among other things, the execution of contracts for difference (CFD) transactions involving, but not limited to, forex, foreign exchange rates, indices, commodities, futures and options, and other financial assets. However, limits may apply and some of the financial products offered by GloabFx may not be available to clients in some locations due to the fact that different legislation apply in different regions. CFDs, for instance, could not be accessible in Romania. Because of this, certain of the products provided by GloabFx are not intended for or available to certain clients in some countries or territories, and they are not provided in places where the prohibitions against offering and trading the products apply. Some of the products indicated in the risk warnings are available to recipients who live in nations where the provision of such products and services would not be in violation of the laws or regulations that are mandatory and relevant.</li>
                            </Text>
                            <Text fontSize={"15px"} mt={"30px"}>
                                <li>This general risk warning applies to all of the products supplied by GloabFx, and the information provided below is of a general nature. The risks associated with dealing in spot foreign exchange and foreign exchange contracts for difference (collectively, “Spot Forex” and “CFDs”) cannot and are not fully disclosed in this risk warning notice (“Risk Warning Notice”). The main hazards of trading spot forex and CFDs are described in this notice.</li>
                            </Text>
                            <Text fontSize={"15px"} mt={"30px"}>
                               <li>If you do not comprehend the fundamentals of such trading and the risks involved, you should not engage in speculative spot FX or CFD trading. For instance, how positions are opened and closed, how gains and losses are generated, and the degree of your risk and loss exposure.</li>
                            </Text>
                            <Text fontSize={"15px"} mt={"30px"}>
                               <li>Spot Forex and CFD trading is speculative and has a high level of risk. Price swings in spot forex or CFD can result in big losses, especially since it will be done using margin, which only covers a small portion of the value of the foreign currency exchanged.</li>
                            </Text>
                            <Text fontSize={"15px"} mt={"30px"}>
                               <li>Trading in these contracts is thus only acceptable for those who are aware of and prepared to accept the financial, legal, and other risks associated with such transactions. In light of your financial situation and risk tolerance, you should be confident that spot FX and CFD trading are suitable for you. Please seek independent advice from a financial services expert if you have any questions about whether spot FX or CFD trading is right for you. These suggestions are not made by GloabFx.</li>
                            </Text>
                        </Box>
                        
                    </Box>

                    <Box w={{base:"100%",sm:"100%",md:"100%",lg:"30%",xl:"30%"}} p={{base:'5px',sm:"5px",md:"5px",lg:"30px",xl:"30px"}}>
                        <Input placeholder="Your Name" />
                        <Input mt={"20px"} placeholder="Your Email" />
                        <Input mt={"20px"} placeholder="Phone Number" />
                        <Textarea mt={"20px"} placeholder="Your Messages"/>
                        <Button mt={"20px"}  bg={"rgb(255,218,69)"} w={"100%"}>SEND MAIL</Button>
                    </Box>
                </Flex>
            </Box>
            <Box>
                <Footer/>
            </Box>
        </>
    )
}
export default Risk;