import { Box, Text } from "@chakra-ui/react";
import NavbarNew from "../../Navbar/NavbarNew";


function RegistrationThank() {
    return (
        <>
            <Box>
                <Box>
                    <NavbarNew />
                </Box>
                <Box mt={"60px"} textAlign={"center"}>
                    <Text fontSize={{base:"2xl",sm:"2xl",md:"2xl",lg:"6xl",xl:"6xl"}}
                    //  fontSize={"6xl"}
                     >Thank you for Registration with us</Text>
                    <Text mt={"20px"} 
                    // fontSize={"3xl"}
                    fontSize={{base:"md",sm:"md",md:"md",lg:"3xl",xl:"3xl"}}
                    >Email has been sent sucessfully, Check your gmail.</Text>
                    <Text mt={"20px"} 
                    fontSize={{base:"sm",sm:"sm",md:"sm",lg:"2xl",xl:"2xl"}}
                    // fontSize={"2xl"} 
                                        color={"rgb(255,218,69)"}>Verify it through your Gmail</Text>
                </Box>
            </Box>
        </>
    )
}

export default RegistrationThank;