import { Box, Button, Flex, Input, Text, Textarea } from "@chakra-ui/react";
import Navbar from "../../Navbar/Navbar";
import NavbarNew from "../../Navbar/NavbarNew";
import NavbarHome from "../../Navbar/NavbarHome";
import Footer from "../../Navbar/Footer";



function Commodities() {
    return (
        <>
            <Box>
                <NavbarHome />
            </Box>
            <Box padding={{base:'10px',sm:"10px",md:"10px",xl:"30px 45px",lg:"30px 45px"}} >
                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"50px"}>Commodities</Text>
            </Box>
            <Box paddingLeft={{base:'10px',sm:"10px",md:"10px",xl:"45px",lg:"45px"}}>
                <Flex gap={"20px"} flexDirection={{base:"column",sm:"column",md:"column",lg:"row",xl:"row"}}>
                    <Box w={{base:'100%',sm:"100%",md:"100%",xl:"70%",lg:"70%"}}>
                        <Box>
                            <Text fontSize={"15px"}>
                            Commodities are tangible items such as corn, silver, gold, crude oil, and so on. Consider them general phrases for market descriptions. It is very similar to how “stocks” and “equities” are used by investors when discussing the stock market. They are always in the news, and it is always a good and profitable place to start. Individual investors can trade a variety of financial assets, such as indices and commodities, without needing to physically own them through commodity trading. Because it allows for the use of leverage, it has become a popular form of investment among individual investors. As a result, you can increase your spending power while just risking your initial investment.
                            </Text>
                            
                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"50px"}>Benefits:</Text>
                            </Box>
                            <Box>
                            <Text fontSize={"15px"} mt={"30px"}>
                            You gain access to a wide range of well-known commodities and stock indices without having to physically buy them.
                            </Text>
                            <Text fontSize={"15px"} mt={"30px"}>
                            Increase the potential of your portfolio by utilising leverage on commodities of up to 400:1 (where permitted by local legislation).
                            </Text>
                            <Text fontSize={"15px"} mt={"30px"}>
                            Trading commodities commission-free can help you save money on your portfolio.
                            </Text>
                            
                            </Box>

                        </Box>
                       
                    </Box>

                    <Box w={{base:"100%",sm:"100%",md:"100%",lg:"30%",xl:"30%"}} p={{base:'5px',sm:"5px",md:"5px",lg:"30px",xl:"30px"}}>
                        <Input placeholder="Your Name" />
                        <Input mt={"20px"} placeholder="Your Email" />
                        <Input mt={"20px"} placeholder="Phone Number" />
                        <Textarea mt={"20px"} placeholder="Your Messages"/>
                        <Button mt={"20px"}  bg={"rgb(255,218,69)"} w={"100%"}>SEND MAIL</Button>
                    </Box>
                </Flex>
            </Box>
            <Box>
                <Footer/>
            </Box>
        </>
    )
}
export default Commodities;