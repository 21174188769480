import { Box, Button, Divider, Flex, Input, Spinner, Text, useToast } from "@chakra-ui/react";
import NavbarNew from "../../Navbar/NavbarNew";
import { useContext, useEffect, useRef, useState } from "react";
import { myContext } from "../../context/Context";
import { useNavigate } from "react-router-dom";


function ChangePassword() {
    let result = JSON.parse(sessionStorage.getItem('key'));
    let navigate = useNavigate()
    const btnRef = useRef()
    let [data, setData] = useState({})
    let toast = useToast();
    let [pass, setPass] = useState({
        oldpassword: "",
        newPassword: "",

    })
    let [load, setLoad] = useState(true);

    let [confirmpass, setConfirmpass] = useState()



    let saveDetails = (e) => {
        setPass({ ...pass, [e.target.name]: e.target.value })
    }

    let changePasswordData = async (e) => {
        setLoad(false);
        e.preventDefault();
        if (pass.newPassword === confirmpass) {
            try {
                let response = await fetch(`https://trading-jz57.onrender.com/changepassword/${result.type.AcNumber}`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(pass)
                })
                if (response.ok) {
                    let res = await response.json();
                   
                    toast({
                        description: `${res.msg}`,
                        status: `${res.status}`,
                        duration: 5000,
                        isClosable: true,
                        position: "top-center",
                    })
                    setLoad(true);
                }

            } catch (error) {
                console.log(error);
                toast({
                    description: `Unable to fetch`,
                    status: `error`,
                    duration: 5000,
                    isClosable: true,
                    position: "top-center",
                })
                setLoad(true);
            }
        } else {
            toast({
                description: `Password doesn't match`,
                status: `error`,
                duration: 5000,
                isClosable: true,
                position: "top-center",
            })
            setLoad(true);
        }


    }
    let [neteq, setNetEquity] = useState(0)
    let pl = JSON.parse(localStorage.getItem("totalpl"))


    let { totalbalance, fetchBalance } = useContext(myContext)

    let margin = JSON.parse(localStorage.getItem("margin"))
    let res = JSON.parse(localStorage.getItem("account_type"))
    useEffect(() => {
        fetchBalance();
        setNetEquity((totalbalance) + parseFloat(pl))

    }, [totalbalance])
    return (
        <>
            <Box>
                <NavbarNew />
            </Box>
            <Box position={"relative"}>
                <Flex gap={"20px"}>
                    <Box display={{ base: "none", sm: "none", md: "none", lg: "none", xl: "block" }} h={"100vh"}
                        w={"20%"}
                        p={"20px"}
                        color={"white"}
                        bg={"black"}
                        position={"fixed"}
                        zIndex={1} >
                        <Box mt={"30px"}>
                            <Text textAlign={"center"} fontSize='2xl'>Account Summary</Text>
                        </Box>
                        <Box mt={"30px"}>
                            <Flex direction={"column"} gap={"20px"}>
                                <Box>
                                    <Flex justifyContent={"space-between"}>
                                        <Text fontWeight={"bold"}> Package</Text>
                                        <Text fontWeight={"500"}>{res.account_type} </Text>
                                    </Flex>
                                </Box>
                                <Box>
                                    <Flex justifyContent={"space-between"}>
                                        <Text fontWeight={"bold"}> Balance</Text>
                                        <Text fontWeight={"500"}> $ {totalbalance !== undefined ? totalbalance.toFixed(2) : "0.00"}</Text>
                                    </Flex>
                                </Box>
                                <Box>
                                    <Flex justifyContent={"space-between"}>
                                        <Text fontWeight={"bold"}> Open P/L</Text>
                                        <Text fontWeight={"500"}> $ {pl !== null ? pl.toFixed(2) : "0.00"}</Text>
                                    </Flex>
                                </Box>
                                <Box>
                                    <Flex justifyContent={"space-between"}>
                                        <Text fontWeight={"bold"}> Net Equity</Text>
                                        <Text fontWeight={"500"}> $ {neteq !== undefined ? neteq.toFixed(2) : "0.00"}</Text>
                                    </Flex>
                                </Box>
                                <Box>
                                    <Flex justifyContent={"space-between"}>
                                        <Text fontWeight={"bold"}> Margin</Text>
                                        <Text fontWeight={"500"}> $ {margin}</Text>
                                    </Flex>
                                </Box>
                            </Flex>
                        </Box>

                    </Box>

                    <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "80%", xl: "80%" }} ml={{ xl: "22%" }} mt={"20px"} p={"20px"} boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px;">
                        <Text fontSize={"2xl"} color={"rgb(255,218,69)"}>
                            Change Password
                        </Text>
                        <Divider></Divider>
                        <form onSubmit={changePasswordData}>
                            <Box mt={"20px"}>
                                <Text>
                                    Enter Old Password
                                </Text>
                                <Input type="text" name="oldpassword" value={pass.oldpassword} onChange={saveDetails} />
                            </Box>
                            <Box mt={"20px"}>
                                <Text>
                                    Enter New Password
                                </Text>
                                <Input type="text" name="newPassword" value={pass.newPassword} onChange={saveDetails} />
                            </Box>
                            <Box mt={"20px"}>
                                <Text>
                                    Re-Enter New Password
                                </Text>
                                <Input type="text" name="confirmpass" value={confirmpass} onChange={(e) => setConfirmpass(e.target.value)} />
                            </Box>
                            <Box mt={"20px"}>
                                {
                                    load ? <Button type="submit" color={"black"} bg="rgb(255,218,69)">CHANGE PASSWORD</Button> : <Button type="submit" color={"black"} bg="rgb(255,218,69)"><Spinner /></Button>
                                }

                            </Box>
                        </form>
                    </Box>
                </Flex>
            </Box>
        </>
    )
}



export default ChangePassword;