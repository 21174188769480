import { useContext } from "react";
import { myContext } from "../../context/Context";
import { Register1 } from "../Register1";
import { Register2 } from "../Register2";
import Register3 from "../Register3";



function RegisterHome(){
    let {setPage,page}=useContext(myContext)




return (
    <>
    {
        page===1 && <Register1/>
    }
    {
        page===2 && <Register2/>
    }
    {
        page===3 && <Register3/>
    }
    </>
)
}
export default RegisterHome;