import { Box, Button, Center, Divider, Flex, Image, Input, Select, Spinner, Table, TableCaption, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr, useToast } from "@chakra-ui/react";
import NavbarNew from "../Navbar/NavbarNew";
import { useEffect, useState } from "react";



function DocumentVerification() {

    let [document, setdocument] = useState("");
    let [url, seturl] = useState()
    const [img, setImg] = useState();
    let [data, setData] = useState({})
    let res = JSON.parse(sessionStorage.getItem("key"));
    let toast = useToast()
    let [load, setLoad] = useState(true)


    const handleImg = async (e) => {
        setImg(e.target.files[0]);

        console.log(img)
        const formData = new FormData();
        formData.append("Image", e.target.files[0]);
        console.log(formData)
        try {
            const result = await fetch(`https://trading-jz57.onrender.com/verify/upload`, {
                method: "POST",
                body: formData,
            });

            const ans = await result.json();
            console.log(ans);
            seturl(ans.url);
        } catch (err) {
            console.log(err);
        }
    };

    let [verification, setVerification] = useState()
    let fetchDataMain = async () => {
        try {
            let response = await fetch(`https://trading-jz57.onrender.com/register/${res.type.AcNumber}`)
            let result = await response.json();
            setVerification(result.verification_status)
        } catch (error) {
            console.log(error)
        }

    }


    const uploadImage = async (e) => {
        e.preventDefault();
        setLoad(false)
        // Check if url is available
        if (!url) {
            console.error('URL is not available. Please upload an image first.');
            return;
        }

        const data = {
            document,
            'Image': url,
        };

        console.log(data);

        try {
            const result = await fetch(`https://trading-jz57.onrender.com/verify/addDetail/${res.type.AcNumber}`, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            // Check if the response status is OK (200)
            if (!result.ok) {
                toast({
                    description: `Unable to add details`,
                    status: `error`,
                    duration: 5000,
                    isClosable: true,
                    position: "top-center",
                })
                setLoad(true)
            } else {

                const ans = await result.json();
                console.log('Server response:', ans);
                toast({
                    description: `Added details successfully`,
                    status: `success`,
                    duration: 5000,
                    isClosable: true,
                    position: "top-center",
                })
                setLoad(true)
                window.location.reload();
            }
        } catch (err) {
            console.log(err);

            toast({
                description: `something went wrong`,
                status: `error`,
                duration: 5000,
                isClosable: true,
                position: "top-center",
            })
            setLoad(true)
        }
    };
    let [document1, setdocument1] = useState([])
    let fetchDataprofile = async () => {
        try {
            let response = await fetch(`https://trading-jz57.onrender.com/verify/${res.type.AcNumber}`)
            let result = await response.json();
            console.log(result)
            setdocument1(result);
            setData(result.type_at)


        } catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {
        fetchDataprofile();
        fetchDataMain()
    }, [])
    return (
        <>
            <Box>
                <Box>
                    <Box >
                        <NavbarNew />
                    </Box>
                </Box>
                <Center mt={"20px"} mb={"10px"}>
                    <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "80%", xl: '80%' }} p={{ base: "20px", sm: "20px", md: "20px", lg: "100px", xl: "100px" }}  >
                        <Box>
                            <Text fontSize={"2xl"}>Upload Verification Document</Text>
                            {
                                verification === true ? <Button mt={"10px"} bg={"rgb(10,179,156)"} size={"xs"} color={"white"}>verified</Button> : <Button mt={"10px"} bg={"red"} size={"xs"} color={"white"}>pending</Button>
                            }

                        </Box>
                        <form encType="multipart/form-data" onSubmit={uploadImage}>
                            <Box mt={"20px"}>
                                <Text >Tap the box below to choose file*</Text>
                                <Input type="file" name="Image" onChange={(e) => { handleImg(e) }} isRequired />
                            </Box>
                            <Box mt={"20px"}>
                                <Text >Method of Verification*</Text>
                                <Select onChange={(e) => setdocument(e.target.value)} style={{ backgroundColor: "rgb(55,67,78)", color: "white" }} value={document} name="document" isRequired>
                                    <option value="" style={{ backgroundColor: "rgb(55,67,78)" }}>Select Document</option>
                                    <option value="Driving_License" style={{ backgroundColor: "rgb(55,67,78)" }}>Driving License</option>
                                    <option value="passport" style={{ backgroundColor: "rgb(55,67,78)" }}>International Passport</option>
                                    <option value="National_ID" style={{ backgroundColor: "rgb(55,67,78)" }}>National ID</option>
                                </Select>
                            </Box>
                            <Box mt={"20px"}>
                                {
                                    load ? <Button type="submit" color={"black"} bg="rgb(255,218,69)">UPLOAD</Button> : <Button type="submit" color={"black"} bg="rgb(255,218,69)"><Spinner /></Button>
                                }

                            </Box>
                        </form>
                    </Box>
                </Center>

                {/* <Center mb={"20px"}>
              <Text fontSize={"2xl"}>Uploaded Documents</Text>
              
              </Center> */}

                <Box w={{ base: "100%", sm: "100%", md: '100%', lg: "60%", xl: "50%" }} display={"block"} margin={"auto"} >
                    <TableContainer mb={"20px"}>
                        <Table variant='simple'>

                            <Thead>
                                <Tr>
                                    <Th color={"white"}>Uploaded Document</Th>
                                    <Th color={"white"}>Document Type</Th>
                                    <Th color={"white"}>Date</Th>
                                    <Th color={"white"}>Status</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {
                                    document1.length > 0 ? document1.map((ele, idx) => {
                                        return <Tr key={idx}>

                                            <Td><Image w={"30%"} src={ele.Image} /></Td>
                                            <Td>{ele.document}</Td>
                                            <Td>
                                                {new Date(ele.created_at).toLocaleString('en-GB', {
                                                    timeZone: 'Europe/London',
                                                    day: 'numeric',
                                                    month: 'short',
                                                    year: 'numeric',
                                                    hour: 'numeric',
                                                    minute: 'numeric',
                                                    second: 'numeric',
                                                    hour12: false,
                                                })}
                                            </Td>
                                            <Td >{ele.type_at}</Td>
                                        </Tr>
                                    }) : <Tr>
                                        <Td textAlign={"center"}>No data...</Td>
                                    </Tr>
                                }


                            </Tbody>
                            <Tfoot>
                            </Tfoot>
                        </Table>
                    </TableContainer>
                </Box>

            </Box>
        </>
    )
}
export default DocumentVerification;