import { Box, Button, Divider, Flex, Input, InputGroup, InputRightElement, Spinner, Text, useToast } from "@chakra-ui/react";
import { useState } from "react";
import LeftNav from "./LeftNav";





function ChangePasswordAdmin() {
    const [show, setShow] = useState(false);
    const handleClick = () => setShow(!show);
    let [load, setLoad] = useState(true);
    let login = JSON.parse(sessionStorage.getItem("key")) || []
    console.log(login.type.email);
    let [state, setState] = useState({
        email: login.type.email,
        password: ""
    })
    let [confirm, setConfirm] = useState()
    let toast = useToast()

    let saveDetails = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }
    let handleSubmit = async (e) => {
        e.preventDefault();
        setLoad(false)
        if (state.password === confirm) {
            try {
                let response = await fetch(`https://trading-jz57.onrender.com/admin/change`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(state)
                })
                if (response.ok) {
                    let res = await response.json();
                    toast({
                        description: `Password change successfully`,
                        status: `success`,
                        duration: 5000,
                        isClosable: true,
                        position: "top-center",
                    });
                    setState({
                        email: login.type.email,
                        password: ""
                    })
                    setConfirm("")
                    setLoad(true)
                }

            } catch (error) {
                console.log(error)
                toast({
                    description: `Unable to save try again`,
                    status: `error`,
                    duration: 5000,
                    isClosable: true,
                    position: "top-center",
                });
                setLoad(true)
            }
        } else {
            toast({
                description: `Password and confirm password should match`,
                status: `warning`,
                duration: 5000,
                isClosable: true,
                position: "top-center",
            });
            setLoad(true)
        }

    }
    console.log(state)
    console.log(confirm)
    return (
        <>
            <Box>
                <Flex flexDirection={{ base: "column", sm: "column", md: "column", lg: "row", xl: "row" }}>
                    <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "20%", xl: '20%' }}>
                        <LeftNav />
                    </Box>
                    <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "80%", xl: "80%" }} mt={"20px"} p={"20px"} boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px;">
                        <Text fontSize={"2xl"} color={"rgb(255,218,69)"}>
                            Change Password
                        </Text>
                        <Divider></Divider>
                        <form onSubmit={handleSubmit}>

                            <Box mt={"20px"}>
                                <Text>
                                    Password
                                </Text>
                                <InputGroup size="md">
                                    <Input
                                        name="password"
                                        mt={"20px"}
                                        pr="4.5rem"
                                        type={show ? "text" : "password"}
                                        fontSize="14px"
                                        _placeholder={{ fontSize: "14px" }}
                                        placeholder="Enter Password"
                                        onChange={saveDetails}
                                        value={state.password}
                                        isRequired
                                    />
                                    <InputRightElement width="4.5rem" mt={"20px"}>
                                        <Button h="1.75rem" size="sm" onClick={handleClick}>
                                            {show ? "Hide" : "Show"}
                                        </Button>
                                    </InputRightElement>
                                </InputGroup>
                            </Box>
                            <Box mt={"20px"}>
                                <Text>
                                    Confirm Password
                                </Text>
                                <Input type="text" name="confirmpass"
                                    value={confirm}
                                    onChange={(e) => setConfirm(e.target.value)}
                                    isRequired
                                />
                            </Box>
                            <Box mt={"20px"}>
                                {
                                    load ? <Button type="submit" color={"black"} bg="rgb(255,218,69)">CHANGE PASSWORD</Button> : <Button type="submit" color={"black"} bg="rgb(255,218,69)"><Spinner /></Button>
                                }

                            </Box>
                        </form>
                    </Box>
                </Flex>
            </Box>
        </>
    )
}

export default ChangePasswordAdmin;