import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Box, Text, Flex, Input, Button, Spinner, Image, Modal, ModalOverlay, ModalContent, ModalHeader, Center, Heading, ModalCloseButton, ModalBody, FormControl, HStack, PinInputField, ModalFooter, useDisclosure, useColorModeValue, PinInput, useToast } from "@chakra-ui/react";
import { useState } from "react";
import NavbarNew from "../../Navbar/NavbarNew";
import otp1 from "../../images/otp.jpg"
import { useNavigate } from "react-router-dom";


function ForgetPass() {
    let [next, setNext] = useState(true)
    let [email, setEmail] = useState()
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [otp, setOpt] = useState("");
    let navigate = useNavigate()
    let toast = useToast();

    let sendOtp = async (e) => {
        localStorage.setItem("email", JSON.stringify(email));
        e.preventDefault();
        setNext(false)
        try {
            let response = await fetch(`https://trading-jz57.onrender.com/forget/send-otp`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ email: email })

            })
            if (response.ok) {


                let res = await response.json();
              
                toast({
                    description: `Email sent successfully`,
                    status: `success`,
                    duration: 5000,
                    isClosable: true,
                    position: "top-center",
                })
                onOpen();
                setNext(true)
            } else {
                toast({
                    description: `Signup first`,
                    status: `error`,
                    duration: 5000,
                    isClosable: true,
                    position: "top-center",
                })
                setNext(true)
            }
        } catch (error) {
         
            toast({
                description: `error`,
                status: `error`,
                duration: 5000,
                isClosable: true,
                position: "top-center",
            })
            setNext(true)
        }

    }
    let handlePinChange = (value) => {
        setOpt(value);
    }

    let verifyOtp = async () => {
        try {
            let response = await fetch(`https://trading-jz57.onrender.com/forget/verify-otp`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ email: email, otp: otp })
            })
            if (response.ok) {


                let res = await response.json();
                
                toast({
                    description: `Verdied successfully`,
                    status: `success`,
                    duration: 5000,
                    isClosable: true,
                    position: "top-center",
                })
                navigate("/forgetchange")


            } else {
                toast({
                    description: `Invalid otp`,
                    status: `error`,
                    duration: 5000,
                    isClosable: true,
                    position: "top-center",
                })
            }
        } catch (error) {
           
            toast({
                description: `Something went wrong`,
                status: `error`,
                duration: 5000,
                isClosable: true,
                position: "top-center",
            })
        }
    }
    return (
        <>
            <Box>
                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent bg={"rgb(55,67,78)"} color={"white"} p="20px" mt="90px" w={{ base: "90%", sm: "70%", md: "40%", lg: "30%", xl: "30%" }}>
                        <ModalHeader>
                            <Center>
                                <Heading lineHeight={1.1}
                                    fontSize={{ base: 'l', md: 'xl' }}
                                    color={"rgb(255,218,69)"}
                                >
                                    Verify OTP
                                </Heading>
                            </Center>
                            <Center
                                fontSize={{ base: 'sm', sm: 'md' }}
                                fontWeight="bold"
                                mt="10px"
                               
                                color={useColorModeValue('white.800', 'white.400')}>
                                {email}
                            </Center>
                            {/* <Center>
                <Button onClick={sendOtp}>Resend otp in { counter}</Button>
              </Center> */}

                        </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            {/* <Center>
              <div id="recaptcha-container"/>
              </Center> */}
                            <FormControl>
                                <Center>
                                    <HStack>
                                        <PinInput mt="20px" onChange={handlePinChange}>
                                            <PinInputField />
                                            <PinInputField />
                                            <PinInputField />
                                            <PinInputField />
                                            <PinInputField />
                                            <PinInputField />
                                        </PinInput>
                                    </HStack>
                                </Center>
                            </FormControl>


                        </ModalBody>
                        <Center>
                            <ModalFooter>

                                <Button color={"black"}
                                    bg={"rgb(255,218,69)"} _hover={{ cursor: "pointer" }} borderRadius={"5px 5px 5px 5px"} onClick={verifyOtp}>Verify</Button>



                            </ModalFooter>
                        </Center>
                    </ModalContent>
                </Modal>
                <Box>
                    <NavbarNew />
                </Box>
                <Flex display="flex"
                    flexDirection={{ base: "column", md: "row" }}
                    gap={{ base: "10px", md: "20px" }}

                >

                    <Box w={{base:"100%",sm:"100%",md:"100%",lg:"40%",xl:"40%"}}>
                        <Box p={"100px"}>

                            <form onSubmit={sendOtp}>

                                <Box>
                                <Center>
                                    <Flex gap={{base:"10px",sm:"10px",md:"10px",lg:"20px",xl:"20px"}}>
                                     
                                        <Text fontSize={{base:"lg",sm:"lg",md:"lg",lg:"3xl",xl:"3xl"}}>FORGET</Text>
                                        <Text
                                            color={"rgb(255,218,69)"} fontSize={{base:"lg",sm:"lg",md:"lg",lg:"3xl",xl:"3xl"}}>PASSWORD</Text>
                                           
                                    </Flex>
                                    </Center>
                                    <Box mt={"30px"}>
                                        <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter Your Email" />
                                    </Box>
                                </Box>
                                {
                                    next ? <Button
                                        w="100%"
                                        mt="20px"
                                        // leftIcon={<ArrowForwardIcon />}

                                        variant="solid"
                                        color={"black"}
                                        bg={"rgb(255,218,69)"}
                                        type="submit"
                                    >
                                        Submit
                                    </Button> : <Button
                                        w="100%"
                                        mt="20px"
                                        // leftIcon={<ArrowForwardIcon />}

                                        variant="solid"
                                        color={"black"}
                                        bg={"rgb(255,218,69)"}
                                        type="submit"
                                    > <Spinner /> </Button>
                                }

                            </form>
                        </Box>

                    </Box>
                    <Box display={{base:"none",sm:"none",md:"none",lg:"block",xl:"block"}}>
                        <Image w={"100%"} h={"100vh"} src={otp1}></Image>
                    </Box>
                </Flex>
            </Box>
        </>
    )
}

export default ForgetPass;