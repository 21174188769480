import { Box, Button, Divider, Input, useDisclosure } from "@chakra-ui/react";
import { useContext, useEffect, useRef, useState } from "react";
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Text, Flex, Center
} from '@chakra-ui/react'
import { myContext } from "../context/Context";



function AccountSummaryDemo({ btnref, isOpen, onClose, totalpl }) {
    // const { isOpen, onOpen, onClose } = useDisclosure()

    const btnRef = useRef()
    let result = JSON.parse(sessionStorage.getItem("demo"))
    let [data, setData] = useState({})

    let [margin, setMargin] = useState(0);
    let [neteq, setNetEquity] = useState(0)
    let [eq,setEq]=useState();
    let [totbalance, setBalance] = useState(0);
    // let [bonus,setBonus]=useState();
    let [freeMargin,setFreeMargin]=useState();
    let [pl,setpl]=useState();
    // let { setpl } = useContext(myContext)

    //  console.log(pl);
    let fetchData = async () => {
        try {
            let response = await fetch(`https://trading-jz57.onrender.com/registerdemo/user/${result.type.AcNumber}`)
            let res = await response.json();
            setData(res.type)
            setBalance(res.type.totalbalance);
            setEq(res.type.neteq+parseFloat(totalpl))
            // setBonus(res.bonus)
            // console.log(res);

        } catch (error) {
            console.log(error)
        }
    }

    let dealPrice = async () => {
        try {
            let response = await fetch(`https://trading-jz57.onrender.com/makeadealdemo/${result.type.AcNumber}/dealprice`)
            let res = await response.json();
            setMargin(res.total_price)
            // console.log(res.total_price);
            localStorage.setItem("margindemo", JSON.stringify(res.total_price))
            // console.log(res);
        } catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {
        fetchData();

        dealPrice();
        setpl(totalpl)
        setFreeMargin(eq-margin);
    }, [totalpl, margin, totbalance])
    localStorage.setItem("totalpldemo", JSON.stringify(totalpl))
    

    return (
        <>

            <Drawer
                isOpen={isOpen}
                placement='right'
                onClose={onClose}
                finalFocusRef={btnRef}
            >
                <DrawerOverlay />
                <DrawerContent bg={"rgb(55,67,78)"} color={"white"}>
                    <DrawerCloseButton />
                    <DrawerHeader mt={"20px"} textAlign={"center"} fontSize='2xl' fontWeight={"bold"}>Account Summary</DrawerHeader>


                    <DrawerBody>
                        <Box>
                            <Flex direction={"column"} gap={"20px"}>
                                
                                <Box>
                                    <Flex justifyContent={"space-between"}>
                                        <Text fontWeight={"bold"}> Balance</Text>
                                        <Text fontWeight={"500"}> $ {totbalance !== undefined ? totbalance.toFixed(2) : "0.00"}</Text>
                                    </Flex>
                                </Box>


                                <Box>
                                    <Flex justifyContent={"space-between"}>
                                        <Text fontWeight={"bold"}> Open P/L</Text>
                                        <Text fontWeight={"500"}> $ {totalpl !== undefined ? totalpl.toFixed(2) : "0:00"}</Text>
                                    </Flex>
                                </Box>
                                <Box>
                                    <Flex justifyContent={"space-between"}>
                                        <Text fontWeight={"bold"}> Net Equity</Text>
                                        <Text fontWeight={"500"}> $ {isNaN(eq) ? "0.00" : eq.toFixed(2)}</Text>
                                    </Flex>
                                </Box>
                                
                                <Box>
                                    <Flex justifyContent={"space-between"}>
                                        <Text fontWeight={"bold"}> Margin</Text>
                                        <Text fontWeight={"500"}> $ {isNaN(margin) ? "0.00" : margin.toFixed(2)}</Text>
                                    </Flex>
                                </Box>
                                <Box>
                                    <Flex justifyContent={"space-between"}>
                                        <Text fontWeight={"bold"}>Free Margin</Text>
                                        <Text fontWeight={"500"}> $ {isNaN(freeMargin) ? "0.00" : freeMargin.toFixed(2)}</Text>
                                    </Flex>
                                </Box>
                            </Flex>
                        </Box>
                    </DrawerBody>

                    <DrawerFooter>

                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default AccountSummaryDemo;