import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";

import success from "../images/success.png";
import styles from "./style.module.css";
import { Button, Text } from "@chakra-ui/react";


const EmailVerify = () => {
  const [validUrl, setValidUrl] = useState(true);
  const param = useParams();

  useEffect(() => {
    const verifyEmailUrl = async () => {
      try {
        const url = `https://trading-jz57.onrender.com/register/${param.id}/verify/${param.token}`;
        const response = await fetch(url);


        const data = await response.json();
        console.log(data);
        setValidUrl(true);

      } catch (error) {
        console.log(error);
        setValidUrl(false);
      }
    };

    verifyEmailUrl();
  }, [param]);
  console.log(param)


  // color={"black"}
  //                                       bg={"rgb(255,218,69)"}

  return (

    <>
      {validUrl ? (
        <div className={styles.container}>
          <img src={success} alt="success_img" className={styles.success_img} />
          <Text fontSize={"xl"} style={{marginTop:"20px"}}>Email verified successfully</Text>
          <Link to="/login">
            <Button style={{marginTop:"20px",backgroundColor:"rgb(255,218,69)",color:"black"}}  >LOGIN</Button>
          </Link>
        </div>
      ) : (
        <Text fontSize={"4xl"} fontWeight={"bold"}>404 Not Found</Text>
      )}
    </>
  );
};

export default EmailVerify;