import { Box, Button, Flex, Input, Text, Textarea } from "@chakra-ui/react";
import Navbar from "../../Navbar/Navbar";
import NavbarNew from "../../Navbar/NavbarNew";
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
  } from '@chakra-ui/react'
import NavbarHome from "../../Navbar/NavbarHome";
import Footer from "../../Navbar/Footer";



function TradingList() {
    return (
        <>
            <Box>
                <NavbarHome />
            </Box>
            <Box padding={{ base: '10px', sm: "10px", md: "10px", xl: "30px 45px", lg: "30px 45px" }} >
                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"50px"}>Full List of Trading Signals</Text>
            </Box>
            <Box paddingLeft={{ base: '10px', sm: "10px", md: "10px", xl: "45px", lg: "45px" }} mb={"40px"}>
                <Flex gap={"20px"} flexDirection={{ base: "column", sm: "column", md: "column", lg: "row", xl: "row" }}>
                    <Box w={{ base: '100%', sm: "100%", md: "100%", xl: "70%", lg: "70%" }}>

                        <Box>
                            <Box mt={"30px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>Currencies</Text>
                            </Box>
                            <Box mt={"30px"}>
                                <TableContainer border={"1px solid white"}>
                                    <Table  >

                                       
                                        <Tbody>
                                            <Tr>
                                                <Td textAlign={"center"}>AUD/CAD</Td>
                                                <Td textAlign={"center"}>AUD/CHF</Td>
                                                <Td textAlign={"center"}>AUD/JPY</Td>
                                                <Td textAlign={"center"}>AUD/NZD</Td>
                                            </Tr>
                                            <Tr>
                                                <Td textAlign={"center"}>AUD/USD</Td>
                                                <Td textAlign={"center"}>CAD/JPY</Td>
                                                <Td textAlign={"center"}>CHF/JPY</Td>
                                                <Td textAlign={"center"}>CHF/PLN</Td>
                                            </Tr>
                                            <Tr>
                                                <Td textAlign={"center"}>EUR/AUD</Td>
                                                <Td textAlign={"center"}>EUR/CAD</Td>
                                                <Td textAlign={"center"}>EUR/CHF</Td>
                                                <Td textAlign={"center"}>EUR/CZK</Td>
                                            </Tr>
                                            <Tr>
                                                <Td textAlign={"center"}>EUR/GBP</Td>
                                                <Td textAlign={"center"}>EUR/HUF</Td>
                                                <Td textAlign={"center"}>EUR/JPY</Td>
                                                <Td textAlign={"center"}>EUR/NOK</Td>
                                            </Tr>
                                            <Tr>
                                                <Td textAlign={"center"}>EUR/NZD</Td>
                                                <Td textAlign={"center"}>EUR/PLN</Td>
                                                <Td textAlign={"center"}>EUR/SEK</Td>
                                                <Td textAlign={"center"}>EUR/TRY</Td>
                                            </Tr>
                                            <Tr>
                                                <Td textAlign={"center"}>EUR/USD</Td>
                                                <Td textAlign={"center"}>GBP/AUD</Td>
                                                <Td textAlign={"center"}>GBP/CAD</Td>
                                                <Td textAlign={"center"}>GBP/CHF</Td>
                                            </Tr>
                                            <Tr>
                                                <Td textAlign={"center"}>GBP/JPY</Td>
                                                <Td textAlign={"center"}>GBP/NZD</Td>
                                                <Td textAlign={"center"}>GBP/USD</Td>
                                                <Td textAlign={"center"}>NZD/CAD</Td>
                                            </Tr>
                                            <Tr>
                                                <Td textAlign={"center"}>NZD/CHF</Td>
                                                <Td textAlign={"center"}>NZD/JPY</Td>
                                                <Td textAlign={"center"}>NZD/USD</Td>
                                                <Td textAlign={"center"}>SGD/JPY</Td>
                                            </Tr>
                                            <Tr>
                                                <Td textAlign={"center"}>USD/CAD</Td>
                                                <Td textAlign={"center"}>USD/CHF</Td>
                                                <Td textAlign={"center"}>USD/CZK</Td>
                                                <Td textAlign={"center"}>USD/DKK</Td>
                                            </Tr>
                                            <Tr>
                                                <Td textAlign={"center"}>USD/HKD</Td>
                                                <Td textAlign={"center"}>USD/HUF</Td>
                                                <Td textAlign={"center"}>USD/ILS</Td>
                                                <Td textAlign={"center"}>USD/JPY</Td>
                                            </Tr>
                                            <Tr>
                                                <Td textAlign={"center"}>USD/MXN</Td>
                                                <Td textAlign={"center"}>USD/NOK</Td>
                                                <Td textAlign={"center"}>USD/PLN</Td>
                                                <Td textAlign={"center"}>USD/SEK</Td>
                                            </Tr>
                                            <Tr>
                                                <Td textAlign={"center"}>USD/SGD</Td>
                                                <Td textAlign={"center"}>USD/TRY</Td>
                                                <Td textAlign={"center"}>USD/ZAR</Td>
                                                <Td textAlign={"center"}></Td>
                                            </Tr>

                                        </Tbody>
                                        <Tfoot>

                                        </Tfoot>
                                    </Table>
                                </TableContainer>

                            </Box>

                        </Box>
                        <Box>
                            <Box mt={"30px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>Commodities</Text>
                            </Box>
                            <Box mt={"30px"}>
                                <TableContainer border={"1px solid white"}>
                                    <Table  >

                                       
                                        <Tbody>
                                            <Tr>
                                                <Td textAlign={"center"}>COF/USD</Td>
                                                <Td textAlign={"center"}>COR/USD</Td>
                                                <Td textAlign={"center"}>GAS/USD</Td>
                                                
                                            </Tr>
                                            <Tr>
                                                <Td textAlign={"center"}>OIL/USD</Td>
                                                <Td textAlign={"center"}>SOY/USD</Td>
                                                <Td textAlign={"center"}>SUG/USD</Td>
                                                
                                            </Tr>
                                            <Tr>
                                                <Td textAlign={"center"}> XAG/USD</Td>
                                                <Td textAlign={"center"}>XAU/USD</Td>
                                                <Td textAlign={"center"}>XPT/USD</Td>
                                                
                                            </Tr>
                                            

                                        </Tbody>
                                        <Tfoot>

                                        </Tfoot>
                                    </Table>
                                </TableContainer>

                            </Box>

                        </Box>

                        <Box>
                            <Box mt={"30px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>Indices</Text>
                            </Box>
                            <Box mt={"30px"}>
                                <TableContainer border={"1px solid white"}>
                                    <Table  >

                                       
                                        <Tbody>
                                            <Tr>
                                                <Td textAlign={"center"}>DAX/EUR</Td>
                                                <Td textAlign={"center"}>DOW/USD</Td>
                                                <Td textAlign={"center"}>ESX/EUR</Td>
                                                
                                            </Tr>
                                            <Tr>
                                                <Td textAlign={"center"}>CAC/EUR</Td>
                                                <Td textAlign={"center"}>FTS/GBP</Td>
                                                <Td textAlign={"center"}>IBX/EUR
</Td>
                                                
                                            </Tr>
                                            <Tr>
                                                <Td textAlign={"center"}>MIB/EUR</Td>
                                                <Td textAlign={"center"}>NDQ/USD

</Td>
                                                <Td textAlign={"center"}>NKY/JPY</Td>
                                                
                                            </Tr>
                                            

                                        </Tbody>
                                        <Tfoot>

                                        </Tfoot>
                                    </Table>
                                </TableContainer>

                            </Box>

                        </Box>
                    </Box>

                    <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "30%", xl: "30%" }} p={{ base: '5px', sm: "5px", md: "5px", lg: "30px", xl: "30px" }}>
                        <Input placeholder="Your Name" />
                        <Input mt={"20px"} placeholder="Your Email" />
                        <Input mt={"20px"} placeholder="Phone Number" />
                        <Textarea mt={"20px"} placeholder="Your Messages" />
                        <Button mt={"20px"} bg={"rgb(255,218,69)"} w={"100%"}>SEND MAIL</Button>
                    </Box>
                </Flex>
            </Box>
            <Box>
                <Footer/>
            </Box>
        </>
    )
}
export default TradingList;