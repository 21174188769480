import {
    Box, Text, Flex, Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Button,
    useToast,
    useDisclosure,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverArrow,
    PopoverCloseButton,
    PopoverBody,
    PopoverFooter,
    ButtonGroup,
    Portal,
    Input
} from "@chakra-ui/react";
import LeftNav from "./LeftNav";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";




function AdminUser() {
    let [data, setData] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    let navigate = useNavigate()
    let toast = useToast();
    const { isOpen, onToggle, onClose } = useDisclosure()
    // let [deleteuser,setDelete]=useState(false);
    let [deleteuser, setDelete] = useState(false);
    let [selectedUser, setSelectedUser] = useState(null);
    let [q, setQ] = useState("");


    let fetchDataprofile = async () => {
        try {
            let response = await fetch(`https://trading-jz57.onrender.com/register/admin`)
            let res = await response.json();
            console.log(res);
            setData(res.reverse());

        } catch (error) {
            console.log(error)
        }
    }

    let Verify = async (AcNumber, approve) => {
        try {
            let response = await fetch(`https://trading-jz57.onrender.com/register/${AcNumber}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ verified: approve })

            })
            if (response.ok) {
                let res = await response.json();
                console.log(res);
                setData(prevData => prevData.map(user => {
                    if (user.AcNumber === AcNumber) {
                        return { ...user, verified: approve }; // Update the verified status of the specific user
                    }
                    return user;
                }));
            }
        } catch (error) {
            console.log(error);
        }
    }



    let unVerify = async (AcNumber, approve) => {
        try {
            let response = await fetch(`https://trading-jz57.onrender.com/register/${AcNumber}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ verified: approve })
            })
            if (response.ok) {
                let res = await response.json();
                console.log(res);
                setData(prevData => prevData.map(user => {
                    if (user.AcNumber === AcNumber) {
                        return { ...user, verified: approve }; // Update the verified status of the specific user
                    }
                    return user;
                }));
            }
        } catch (error) {
            console.log(error);
        }
    }

    let handleSearchInput = (e) => {
        setQ(e.target.value);
    }

    let id;

    const debounce = (func) => {
        if (id) {
            clearTimeout(id);
        }

        id = setTimeout(() => {
            func();
        }, 2000);
    };

    let handleSearchQ = async () => {
        try {
            let response = await fetch(`https://trading-jz57.onrender.com/register?q=${q}`)
            let res = await response.json();
            console.log(res);
            setData(res)
        } catch (error) {
            console.log(error)
        }
    }


    let handleDelete = async () => {

        try {
            let res = await fetch(`https://trading-jz57.onrender.com/register/${selectedUser}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json"
                },

            })
            if (res.ok) {
                let response = await res.json();
                console.log(response);
                // setData(prevData => prevData.filter(user => user.id !== selectedUser));
                window.location.reload();
            }


        } catch (error) {
            console.log(error);
        }
    }
    let deleteOne = async (AcNumber) => {
        onToggle();
        setSelectedUser(AcNumber);
    }
    let handlelogin = async ({ email, password }) => {
        try {
            let res = await fetch(`https://trading-jz57.onrender.com/login/user`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ email, password })
            })
            if (res.ok) {
                let response = await res.json();
                console.log(response);

                if (response.type.verified === true) {
                    const newTab = window.open("/adminuser", "_blank");
                    window.location.reload();

                    // Use useHistory to navigate
                    //    navigate("/home")

                    sessionStorage.setItem('key', JSON.stringify(response));

                    toast({
                        description: `Welcome ${response.type.first_name}`,
                        status: `success`,
                        duration: 5000,
                        isClosable: true,
                        position: 'top-center',
                    });
                }
            }

        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        fetchDataprofile();
    }, [])

    const userPerPage = 10;
    const lastIndex = currentPage * userPerPage;
    const firstIndex = lastIndex - userPerPage;
    const user = data.slice(firstIndex, lastIndex);
    const totalPages = Math.ceil(data.length / userPerPage);
    return (
        <>
            <Box>
                <Flex gap={"20px"} flexDirection={{ base: "column", sm: "column", md: "column", lg: "row", xl: "row" }}>
                    <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "20%", xl: '20%' }}>
                        <LeftNav />
                    </Box>
                    <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "80%", xl: '80%' }}>
                        <Box mt={"10px"} mb={"20px"} pr={"20px"}>
                            <Flex gap={"10px"}>
                                <Input name="q" value={q} onChange={handleSearchInput} onInput={() => debounce(handleSearchQ)} />
                                <Button color={"black"} onClick={handleSearchQ} bg={"rgb(255,218,69)"} _hover={{ color: "black", bg: "white", cursor: "pointer" }}>
                                    SEARCH
                                </Button>
                            </Flex>

                        </Box>
                        <TableContainer>
                            <Table variant='simple'>

                                <Thead>
                                    <Tr>
                                        <Th color={"white"}>User Name</Th>
                                        <Th color={"white"}>Mobile</Th>
                                        <Th color={"white"}>Date</Th>
                                        <Th color={"white"}>Account No</Th>
                                        <Th color={"white"}>Balance</Th>
                                        <Th color={"white"}>Remarks</Th>
                                        <Th color={"white"}>Verification</Th>
                                        <Th color={"white"}>Action</Th>

                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {
                                        user.map((ele, idx) => {
                                            return <Tr key={idx}>
                                                <Td onClick={() => handlelogin(ele)} cursor={"pointer"}>{ele.first_name}{" "}{ele.last_name} </Td>
                                                <Td>{ele.phone}</Td>
                                                <Td>{new Intl.DateTimeFormat('en-IN', {
                                                    timeZone: 'Asia/Kolkata',
                                                    day: 'numeric',
                                                    month: 'short',
                                                    year: 'numeric',
                                                    hour: 'numeric',
                                                    minute: 'numeric',
                                                    second: 'numeric',
                                                    hour12: false,
                                                }).format(new Date(ele.created_at))
                                                    .replace(/ /, '-')
                                                    .replace(/,/g, '')
                                                }</Td>

                                                <Td >{ele.AcNumber}</Td>
                                                <Td>{(ele.totalbalance).toFixed(2)}</Td>
                                                <Td>{ele.remarks === "" ? "none" : ele.remarks}</Td>
                                                <Td >{ele.verified ?
                                                    <Button _hover={{color:"black",bg:"white"}} bg={ele.verified === true ? "rgb(10,179,156)" : "red"} color={"white"}
                                                        onClick={() => { unVerify(ele.AcNumber, false) }}
                                                    >
                                                        Active</Button>
                                                    :
                                                    <Button color={"white"} _hover={{color:"black",bg:"white"}} bg={ele.verification_status === true ? "rgb(10,179,156)" : "red"} onClick={() => { Verify(ele.AcNumber, true) }}>Deactive</Button>}</Td>
                                                <Td>
                                                    <Button color={"white"} bg={"red"} _hover={{color:"black",bg:"white"}} onClick={() => deleteOne(ele.AcNumber)}>Delete</Button>
                                                    <Popover
                                                        returnFocusOnClose={false}
                                                        isOpen={isOpen}
                                                        onClose={onClose}
                                                        placement='bottom'
                                                        closeOnBlur={false}

                                                    >
                                                        <Portal>
                                                            <PopoverContent bg={"black"} >
                                                                <PopoverArrow />
                                                                <PopoverHeader>Are u sure</PopoverHeader>
                                                                <PopoverCloseButton />
                                                                <PopoverBody>
                                                                    <Text fontSize={"xl"}>Do you want to delete the user</Text>
                                                                </PopoverBody>
                                                                <PopoverFooter border={"none"}>
                                                                    <Flex justifyContent={"space-between"}>
                                                                        <Button size={"xs"} onClick={onClose}>Cancel</Button>
                                                                        <Button size={"xs"} onClick={() => handleDelete(selectedUser)}>Yes</Button>
                                                                    </Flex>
                                                                </PopoverFooter>
                                                            </PopoverContent>
                                                        </Portal>
                                                    </Popover>
                                                </Td>


                                            </Tr>
                                        })
                                    }


                                </Tbody>
                                <Tfoot>

                                </Tfoot>
                            </Table>
                        </TableContainer>
                        {user.length > 0 ? (
                            <Box>
                                <Flex justifyContent={"space-between"}>
                                    <Box textAlign={"left"} mt={"10px"} fontWeight={"bold"}>
                                        Total users: {data.length}
                                    </Box>
                                    <Flex alignItems="center" justifyContent="right" gap="10px" mt="10px">
                                        <Text fontWeight="bold">
                                            {currentPage} of {totalPages}
                                        </Text>
                                        <Button
                                            color="black"
                                            bg={"rgb(255,218,69)"} _hover={{ color: "black", bg: "white", cursor: "pointer" }}
                                            isDisabled={currentPage == 1}
                                            onClick={() => setCurrentPage(currentPage - 1)}
                                        >
                                            {"<"}
                                        </Button>
                                        <Button
                                            color="black"
                                            bg={"rgb(255,218,69)"} _hover={{ color: "black", bg: "white", cursor: "pointer" }}
                                            isDisabled={currentPage == totalPages}
                                            onClick={() => setCurrentPage(currentPage + 1)}
                                        >
                                            {">"}
                                        </Button>
                                    </Flex>
                                </Flex>
                            </Box>
                        ) : null}
                    </Box>
                </Flex>
            </Box>
        </>
    )
}

export default AdminUser;