import { Navigate } from "react-router-dom";
import Home from "../Components/Home";


function PrivateRouter({ children, allowed }) {
    let result = JSON.parse(sessionStorage.getItem("key")) || "";
    console.log(allowed);
    const userType = result.type.type;
    console.log("User Type:", userType);
    if (allowed && allowed.includes(userType)) {
        return children;
    } if ((userType === "user") && (window.location.pathname === "/login" || window.location.pathname === "/register")) {
        return <Navigate to={"/home"} />
    }
    else {
        return <Navigate to={"/mainpagehome"} />
    }

}

export default PrivateRouter;