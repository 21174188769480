import { useEffect, useState } from "react";
import {
    Spinner,
    Box,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Flex,
    Text,
    Button
} from '@chakra-ui/react'
import LeftNav from "./LeftNav";


function DepositHist() {
    let [data, setData] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    let historyDeposit = async () => {
        try {
            let response = await fetch(`https://trading-jz57.onrender.com/deposit/asdf1234`)
            let res = await response.json();
            console.log(res);
            setData(res.reverse());
        } catch (error) {
            console.log(error)
        }
    }

    const userPerPage = 10;
    const lastIndex = currentPage * userPerPage;
    const firstIndex = lastIndex - userPerPage;

    // Filter data where type_at === "deposit"
    const filteredData = data.filter(ele => ele.type_at === "deposit");

    const user = filteredData.slice(firstIndex, lastIndex);
    const totalPages = Math.ceil(filteredData.length / userPerPage);

    useEffect(() => {
        historyDeposit();
    }, [])

    if(user.length>0){
    return (
        <>
        <Box>
        <Flex gap={"20px"} flexDirection={{ base: "column", sm: "column", md: "column", lg: "row", xl: "row" }}>
                    <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "20%", xl: '20%' }}>
                        <LeftNav />
                    </Box>
                    <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "80%", xl: '80%' }} p={"20px"}>
            {filteredData && filteredData.length > 0 && (
                <Box>
                    <TableContainer border={"1px solid white"}>
                        <Table variant='simple'>

                            <Thead>
                                <Tr color={"white"}>
                                    <Th borderRight={"1px solid white"} color={"white"} fontWeight={"bold"}>Account No</Th>
                                    <Th borderRight={"1px solid white"} color={"white"} fontWeight={"bold"}>Balance</Th>
                                    <Th color={"white"} fontWeight={"bold"}>Deposit Time</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {user.map((ele, idx) => (
                                    <Tr key={idx}>
                                        <Td borderRight={"1px solid white"}>{ele.AccountNo}</Td>
                                        <Td borderRight={"1px solid white"}>{ele.balance}</Td>
                                        <Td  >{new Intl.DateTimeFormat('en-IN', {
                                            timeZone: 'Asia/Kolkata',
                                            day: 'numeric',
                                            month: 'short',
                                            year: 'numeric',
                                            hour: 'numeric',
                                            minute: 'numeric',
                                            second: 'numeric',
                                            hour12: false,
                                        }).format(new Date(ele.created_at))
                                            .replace(/ /, '-')
                                            .replace(/,/g, '')
                                        }</Td>
                                    </Tr>
                                ))}
                            </Tbody>
                            <Tfoot>
                            </Tfoot>
                        </Table>
                    </TableContainer>
                    {user.length > 0 && (
                        <Flex alignItems="center" justifyContent="right" gap="10px" mt="10px">
                            <Text fontWeight="bold">
                                {currentPage} of {totalPages}
                            </Text>
                            <Button
                                color="black"
                                bg={"rgb(255,218,69)"} _hover={{ color: "black", bg: "white", cursor: "pointer" }}
                                isDisabled={currentPage === 1}
                                onClick={() => setCurrentPage(currentPage - 1)}
                            >
                                {"<"}
                            </Button>
                            <Button
                                 color="black"
                                 bg={"rgb(255,218,69)"} _hover={{ color: "black", bg: "white", cursor: "pointer" }}
                                isDisabled={currentPage === totalPages}
                                onClick={() => setCurrentPage(currentPage + 1)}
                            >
                                {">"}
                            </Button>
                        </Flex>
                    )}
                </Box>
            )}
            </Box>
            </Flex>
            </Box>
        </>
    )
}else{
    return <Box>
            <Flex gap={"20px"} flexDirection={{ base: "column", sm: "column", md: "column", lg: "row", xl: "row" }}>
                <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "20%", xl: '20%' }}>
                    <LeftNav />
                </Box>
                <Box  w={{ base: "100%", sm: "100%", md: "100%", lg: "80%", xl: '80%' }}>
                    <Spinner
                        thickness='4px'
                        speed='0.65s'
                        emptyColor='white'
                        color='blue.500'
                        size='xl'
                    />
                </Box>
            </Flex>
        </Box>
}
}

export default DepositHist;
