import { Box, Button, Divider, Flex,Text } from "@chakra-ui/react";



function BankDetails(){
    
    return (
        <>
        <Box>
            <Box>
                <Flex justifyContent={"space-between"}>
                    <Text fontSize={"xl"}>Bank Account</Text>
                    <Button bg={"red"}>ADD NEW BANK ACCOUNT</Button>
                </Flex>
            </Box>
            <Divider></Divider>
            
        </Box>
        </>
    )
}

export default BankDetails;