import { Box, Button, Flex, Input, Text, Textarea } from "@chakra-ui/react";
import Navbar from "../../Navbar/Navbar";
import NavbarNew from "../../Navbar/NavbarNew";
import NavbarHome from "../../Navbar/NavbarHome";
import Footer from "../../Navbar/Footer";



function Indices() {
    return (
        <>
            <Box>
                <NavbarHome />
            </Box>
            <Box padding={{base:'10px',sm:"10px",md:"10px",xl:"30px 45px",lg:"30px 45px"}} >
                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"50px"}>Indices</Text>
            </Box>
            <Box paddingLeft={{base:'10px',sm:"10px",md:"10px",xl:"45px",lg:"45px"}}>
                <Flex gap={"20px"} flexDirection={{base:"column",sm:"column",md:"column",lg:"row",xl:"row"}}>
                    <Box w={{base:'100%',sm:"100%",md:"100%",xl:"70%",lg:"70%"}}>
                        <Box>
                            <Text fontSize={"15px"}>
                            What does it mean when individuals say, “The market performed exceptionally well today?” What exactly is “the market”? When most individuals refer to “the market,” they are referring to an index. An index is a statistical measure of movements in a portfolio of equities that represents a subset of the broader market. It would be impossible to keep track of every single security transaction in the country. To avoid this, we use a smaller sample of the market that is indicative of the entire market. For more than a century, trading major market indexes has been a popular way to invest, but it has not always been available to individual traders.
                            </Text>
                            
                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"50px"}>Benefits:</Text>
                            </Box>
                            <Box p={"30px"}>
                            <Text fontSize={"15px"} mt={"10px"}>
                           <li>Lower management expense ratios, usually between half and two-thirds of those of actively managed funds</li>
                            </Text>
                            <Text fontSize={"15px"} >
                           <li>All that has traditionally happened is that index funds have done better than most actively managed funds.</li>
                            </Text>
                            
                            </Box>

                        </Box>
                       
                    </Box>

                    <Box w={{base:"100%",sm:"100%",md:"100%",lg:"30%",xl:"30%"}} p={{base:'5px',sm:"5px",md:"5px",lg:"30px",xl:"30px"}}>
                        <Input placeholder="Your Name" />
                        <Input mt={"20px"} placeholder="Your Email" />
                        <Input mt={"20px"} placeholder="Phone Number" />
                        <Textarea mt={"20px"} placeholder="Your Messages"/>
                        <Button mt={"20px"}  bg={"rgb(255,218,69)"} w={"100%"}>SEND MAIL</Button>
                    </Box>
                </Flex>
            </Box>
            <Box>
                <Footer/>
            </Box>
        </>
    )
}
export default Indices;