import { Box, Button, Flex, Input, Text, Textarea } from "@chakra-ui/react";
import Navbar from "../../Navbar/Navbar";
import NavbarNew from "../../Navbar/NavbarNew";
import NavbarHome from "../../Navbar/NavbarHome";
import Footer from "../../Navbar/Footer";



function FAQs() {
    return (
        <>
            <Box>
                <NavbarHome />
            </Box>
            <Box padding={{base:'10px',sm:"10px",md:"10px",xl:"30px 45px",lg:"30px 45px"}}>
                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"50px"}>Trading FAQs</Text>
            </Box>
            <Box paddingLeft={{base:'10px',sm:"10px",md:"10px",xl:"45px",lg:"45px"}}>
                <Flex gap={"20px"}>
                    <Box w={{base:'100%',sm:"100%",md:"100%",xl:"70%",lg:"70%"}}>
                        <Box>
                            <Text fontSize={"15px"}>
                            Online trading is available for a variety of financial instruments with Gloabfx, including shares, indices, currencies, and commodities in CFD, Forex, and binary option formats. By purchasing a financial item at a discount and selling it at a higher price, or vice versa, investors can profit from price changes in CFDs and Forex. The majority of investors utilise the optional leverage feature, which enables them to have significant exposure for a comparatively small initial investment. The high level of leverage available in CFD and Forex trading can be used to your advantage as well as disadvantage.
                            </Text>
                           
                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>What is a CFD?</Text>
                            </Box>
                            <Box>
                            <Text fontSize={"15px"} mt={"10px"}>
                            An agreement between two parties to trade the difference between a contract’s opening and closing prices is known as a contract for difference, or CFD. With CFDs, you can trade on real-time price swings in the market without really holding the underlying asset that your contract is based on. CFDs allow you to make predictions about how market prices will move in the future, independent of how the underlying markets are doing. You can buy and profit from growing prices, or you can sell and profit from decreasing prices. Furthermore, you can enter markets you would not have previously had access to, thanks to our wide range of markets. We provide CFDs on commodities, indices, and shares.
                            </Text>
                            
                            </Box>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>What is Forex?</Text>
                            </Box>
                            <Box>
                            <Text fontSize={"15px"} mt={"10px"}>
                            “Foreign Exchange” is referred to by the abbreviation “forex.” It is the exchange of currency by purchasing and selling. The world’s largest and most liquid financial market is the foreign currency market. The market, which is made up of central banks, currency speculators, organisations, governments, retail investors, and foreign investors, is open around-the-clock from Sunday night through Friday. The size of the forex market has been steadily growing over time. The average daily volume in the global Forex markets was estimated to be $5.345 trillion, according to the Bank for International Settlements’ (BIS) 2013 Triennial Survey of global FX market volumes. This is a 34% increase over the $3.971 trillion in April 2010 ($3.21 trillion daily in April 2007 and $1.7 trillion in 1998).
                            </Text>
                            
                            </Box>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>How do CFD and Forex trading work?</Text>
                            </Box>
                            <Box>
                            <Text fontSize={"15px"} mt={"10px"}>
                            Weekly action typically starts on Sunday at 22:00 GMT and runs continuously through Friday at 21:00 GMT during the winter season in Europe and North America. The weekly market activity starts on Sunday at 21:00 GMT and finishes on Friday at 20:00 during these regions’ Daylight Savings Time. Holidays observed by the government or unusual liquidity conditions resulting from extraordinary international events may cause changes in the hours of market activity. Gloabfx has the right to change the opening or closing times in order to manage risk and maintain liquidity. It should be noted that although the majority of instruments are traded continuously around the clock, certain instruments—primarily shares and indices—have specific trading hours.
                            </Text>
                            
                            </Box>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>What are the market's trading hours?</Text>
                            </Box>
                            <Box>
                            <Text fontSize={"15px"} mt={"10px"}>
                            All you need to trade is a funded trading account and a device with an internet connection. To help you reduce the dangers in the market, we also strongly advise you to arm yourself with Forex, CFDs, or other financial education and trading instruments.
                            </Text>
                            
                            </Box>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>What tools do I need to trade CFDs and Forex?</Text>
                            </Box>
                            <Box>
                            <Text fontSize={"15px"} mt={"10px"}>
                            All you need to trade is a funded trading account and a device with an internet connection. To help you reduce the dangers in the market, we also strongly advise you to arm yourself with Forex, CFDs, or other financial education and trading instruments.
                            </Text>
                            
                            </Box>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>How old do I need to be to trade?</Text>
                            </Box>
                            <Box>
                            <Text fontSize={"15px"} mt={"10px"}>
                            To trade, you have to be older than 18.
                            </Text>
                            
                            </Box>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>What is a pip?</Text>
                            </Box>
                            <Box>
                            <Text fontSize={"15px"} mt={"10px"}>
                            A gadget with an internet connection and a funded trading account are all you need to trade. We also strongly urge you to arm yourself with Forex, CFDs, or other financial education and trading instruments in order to help you lessen the risks in the market.
                            </Text>
                            
                            </Box>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>What is a spread?</Text>
                            </Box>
                            <Box>
                            <Text fontSize={"15px"} mt={"10px"}>
                            The gap between the prices of two instruments—the buy and sell prices—is known as the spread. For instance, the spread is two pip if the EUR/USD is trading at 1.3100 (buy) and 1.3098 (sell).
                            </Text>
                            
                            </Box>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>What do “long” and “short” mean?</Text>
                            </Box>
                            <Box>
                            <Text fontSize={"15px"} mt={"10px"}>
                            Buying an asset with the expectation of a future increase in value is known as going “long.” Opening a long position is another term for this. Traders who sell an asset with the expectation that its price will drop and they may later buy it back at a cheaper price are said to be going “short,” or initiating a short position.
                            </Text>
                            
                            </Box>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>Does the forex market have a central location?</Text>
                            </Box>
                            <Box>
                            <Text fontSize={"15px"} mt={"10px"}>
                            The Forex market is decentralised, in contrast to the stock market. Because transactions happen over the phone or the internet, the market is open around the clock.
                            </Text>
                            
                            </Box>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>How are prices determined?</Text>
                            </Box>
                            <Box>
                            <Text fontSize={"15px"} mt={"10px"}>
                            Price changes can occur in a number of ways. In addition to interest rates, inflation, supply and demand, and other factors, economic and political circumstances typically impact an asset’s value.
                            </Text>
                            
                            </Box>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>Are orders executed even if the underlying market is closed?</Text>
                            </Box>
                            <Box>
                            <Text fontSize={"15px"} mt={"10px"}>
                            No, orders are not executed by GLOABFX during off-peak hours.
                            </Text>
                            
                            </Box>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>Does GloabFx change or re-quote market order prices?</Text>
                            </Box>
                            <Box>
                            <Text fontSize={"15px"} mt={"10px"}>
                            Nope. Market orders are not always executed at the precise price requested, especially when there is significant market volatility or when there is a delay in the internet and communications. The order is denied in order to prevent a situation where a position opens at a market price that differs from the requested price. We take this step to prevent uninvited market orders, which then frees the trader to make a trade at the precise price they see. Limit orders, such as stop losses, are executed “at the best market” rate, which is the closest possible rate when they cannot be executed at the precise price requested. You can see our trading conditions for additional details.
                            </Text>
                            
                            </Box>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>What are the costs of trading commodity and index-based CFDs with GloabFx?</Text>
                            </Box>
                            <Box>
                            <Text fontSize={"15px"} mt={"10px"}>
                            When trading commodity and index-based CFDs, GloabFx does not impose any fees or direct commission-based costs. The spread, which is the difference between the buy and sell prices and is constantly shown on your trading screen, is the only source of the clients’ expenses.
                            </Text>
                            
                            </Box>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>What is the validity of a spot transaction, and what is an automatic rollover?</Text>
                            </Box>
                            <Box>
                            <Text fontSize={"15px"} mt={"10px"}>
                            Rollover is the technique of extending an open position’s settlement date as it hits its value date in the Forex and precious metals spot markets. A position remains open with validation for two business days following its execution date in the majority of currencies and other financial spot trades. The position’s settlement period is extended by two more business days by rolling it over. A price adjustment resulting from interest rate variations between the two financial products, plus or minus a markup (interest spread), depending on the type of position you hold (long or short), is part of the rollover process, which is analogous to the forward mechanism. These changes have the potential to go both ways, so you could be rewarded or debited for them.
                            </Text>
                            
                            </Box>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>What happens if a CFD position is held open overnight?</Text>
                            </Box>
                            <Box>
                            <Text fontSize={"15px"} mt={"10px"}>
                            CFD products, like future-based indices and/or commodities, are closed when the underlying asset reaches its expiration date and remain open until then. These products do not have rollover premiums or other overnight expenses associated with them, nor do CFDs based on them get carried over by GloabFx. CFDs on shares (stocks), as opposed to those based on indices or commodities, are subject to overnight funding. Depending on the type of position you hold (long or short), your CFD position may be credited or debited if you hold a stock CFD position overnight. This is determined by the relevant interbank offer rate for the currency in which the underlying share is traded, plus or minus a markup. You can see our trading conditions for additional details.
                            </Text>
                            
                            </Box>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>What are binary options?</Text>
                            </Box>
                            <Box>
                            <Text fontSize={"15px"} mt={"10px"}>
                            Based on the prediction of whether the price of an asset (such as a currency pair, commodity, or stock index) will close above or below the present price within a predetermined time period, binary options are an easy and thrilling way to trade the financial markets. Trading binary options is enjoyable, simple, and very profitable.
                            </Text>
                            
                            </Box>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>What is a CALL option?</Text>
                            </Box>
                            <Box>
                            <Text fontSize={"15px"} mt={"10px"}>
                            If you believe that an asset’s price will close above the current price when the option expires, you should purchase a CALL option.
                            </Text>
                            
                            </Box>

                        </Box>
                        
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>What does ‘in-the-money’ mean?</Text>
                            </Box>
                            <Box>
                            <Text fontSize={"15px"} mt={"10px"}>
                            If your prediction of an asset’s price at expiration is accurate, you are “in the money.”
                            </Text>
                            
                            </Box>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>What does ‘out-of-the-money’ mean?</Text>
                            </Box>
                            <Box>
                            <Text fontSize={"15px"} mt={"10px"}>
                            If, at the time of expiry, the market swings against your estimate of an asset’s price, you are “out-of-the-money.”
                            </Text>
                            
                            </Box>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>How much can I lose if the investment I made turns out wrong (out of the money)?</Text>
                            </Box>
                            <Box>
                            <Text fontSize={"15px"} mt={"10px"}>
                            You are only allowed to lose the money you decided to invest. Furthermore, in the event that you were incorrect, certain options return 10% of your initial investment.
                            </Text>
                            
                            </Box>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>What happens if my option expires at the option rate (strike price)?</Text>
                            </Box>
                            <Box>
                            <Text fontSize={"15px"} mt={"10px"}>
                            You are deemed to be “at-the-money” if your option’s expiration rate and rate are the same. You will receive a reimbursement of 50% of your initial investment in this particular case.
                            </Text>
                            
                            </Box>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>How much do I need to invest in a trade?</Text>
                            </Box>
                            <Box>
                            <Text fontSize={"15px"} mt={"10px"}>
                            Any sum between $10 and $1000 can be traded, based on your degree of risk tolerance and confidence.
                            </Text>
                            
                            </Box>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>What return will I receive for a successful investment?</Text>
                            </Box>
                            <Box>
                            <Text fontSize={"15px"} mt={"10px"}>
                            The reward specified for that specific asset and time period—typically between 70% and 92% of the deal value—will be awarded to you if your forecast is accurate (or “in the money”).
                            </Text>
                            
                            </Box>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>What options can I trade?</Text>
                            </Box>
                            <Box>
                            <Text fontSize={"15px"} mt={"10px"}>
                            More than 25 assets are available for you to trade binary options on with GloabFx. This covers a wide range of markets, such as the S&P500, gold, oil, AUD/USD, GBP/USD, USD/CHF, GBP/JPY, and EUR/JPY.
                            </Text>
                            
                            </Box>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>What is an underlying asset?</Text>
                            </Box>
                            <Box>
                            <Text fontSize={"15px"} mt={"10px"}>
                            The financial instrument (such as an index, commodity, or Forex pair) that determines the price of an option is known as the underlying asset.
                            </Text>
                            
                            </Box>

                        </Box><Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>What is the expiry time?</Text>
                            </Box>
                            <Box>
                            <Text fontSize={"15px"} mt={"10px"}>
                            The moment and date that an option expires is known as its expiry time. At the time of expiration, your payout or loss is computed, and your account is automatically updated.
                            </Text>
                            
                            </Box>

                        </Box><Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>What is the time zone of the expiry time?</Text>
                            </Box>
                            <Box>
                            <Text fontSize={"15px"} mt={"10px"}>
                            Do you have a query that isn’t addressed on this page? Please email it to info@GloabFx.com, and we would be happy to include it.
                            </Text>
                            
                            </Box>

                        </Box>
                    </Box>

                    <Box w={{base:"100%",sm:"100%",md:"100%",lg:"30%",xl:"30%"}} p={{base:'5px',sm:"5px",md:"5px",lg:"30px",xl:"30px"}}>
                        <Input placeholder="Your Name" />
                        <Input mt={"20px"} placeholder="Your Email" />
                        <Input mt={"20px"} placeholder="Phone Number" />
                        <Textarea mt={"20px"} placeholder="Your Messages"/>
                        <Button mt={"20px"}  bg={"rgb(255,218,69)"} w={"100%"}>SEND MAIL</Button>
                    </Box>
                </Flex>
            </Box>
            <Box>
                <Footer/>
            </Box>
        </>
    )
}
export default FAQs;