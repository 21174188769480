import { Box, Button, Flex, Input, Text, Textarea } from "@chakra-ui/react";
import Navbar from "../../Navbar/Navbar";
import NavbarNew from "../../Navbar/NavbarNew";
import NavbarHome from "../../Navbar/NavbarHome";
import Footer from "../../Navbar/Footer";



function FinCal() {
    return (
        <>
            <Box>
                <NavbarHome />
            </Box>
            <Box padding={{ base: '10px', sm: "10px", md: "10px", xl: "30px 45px", lg: "30px 45px" }} >
                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"50px"}>Financial Calendar</Text>
            </Box>
            <Box paddingLeft={{ base: '10px', sm: "10px", md: "10px", xl: "45px", lg: "45px" }}>
                <Flex gap={"20px"} flexDirection={{ base: "column", sm: "column", md: "column", lg: "row", xl: "row" }}>
                    <Box w={{ base: '100%', sm: "100%", md: "100%", xl: "70%", lg: "70%" }}>
                        <Box>
                            <Text fontSize={"15px"}>
                            The financial calendar or economic calendar is a calendar that details specific dates for events that will have an impact on the overall momentum of market prices. In essence, it is utilised by investors as well as traders to plan their traders to make large profits.
                            </Text>
                            <Text fontSize={"15px"} mt={"30px"}>
                            The majority of the time, you can see that many businesses are using this calendar for free because it is a marketing tactic to attract people to the website by offering them the free services, but the idea is not just about the free services. In actuality, we offer our clients high-calibre services since we understand how important both cost and quality are and preserve both.
                            </Text>
                            <Box p={"20px"}>
                                <Text><li>There are certain days in an economic or financial calendar that could have an impact on the entire market.</li></Text>
                                <Text><li>This is used by traders and investors to plan transactions with distinct entry and exit locations, read chart patterns prior to trades, and to learn the impact of events through charts.</li></Text>
                                <Text><li>There are two types of these events: the first are recent or upcoming events that are driving the market. Each has an own significance and worth</li></Text>
                                
                            </Box>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>Introduction</Text>
                            </Box>
                            <Box>
                                <Text fontSize={"15px"} mt={"30px"}>
                                Investors and traders who wish to engage in short-term positional trading use the Financial Calendar. Although it goes without saying that news and events are unpredictable, one must pay close attention to the market and its news in order to comprehend this. You can swiftly enter a trade with safe entrance and exit once you break the news of what it actually is about, but this is only available within the news hour.
                                </Text>
                               
                            </Box>
                           

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>How to Use the Calendar or the Daily News?</Text>
                            </Box>
                            <Box>
                                <Text fontSize={"15px"} mt={"30px"}>
                                Most websites offer a free economic calendar so that traders can place trades according to the calendar. However, there is a way to explore the calendar and determine which news and events are advantageous or not.
                                </Text>
                                <Text fontSize={"15px"} mt={"30px"}>
                                Since the US Market is the largest market ever and is where most traders get their news, its impact on other markets has also been felt by others. Additionally, the majority of websites have US Market events in their calendars for users, but you can navigate it and modify it as needed.
                                </Text>
                                <Text fontSize={"15px"} mt={"30px"}>
                                for example – A calendar can also function as an indication, if a trader or investor wishes to tailor their tool based on the regions where oil is produced. This filter includes the findings of the US Energy Information Administration’s weekly petroleum report.
                                </Text>
                                <Text fontSize={"15px"} mt={"30px"}>
                                The major provider of all services related to the forex market is GloabFx, and in order to take advantage of all of their services, one must comprehend the fundamentals of the market and how it operates. so that one may profit from and enjoy proper use of these services.
                                </Text>
                                <Text fontSize={"15px"} mt={"30px"}>
                                We offer a free financial calendar for investors and traders, which enables them to stay up to date on happenings in order to make lucrative trades.
                                </Text>

                                

                            </Box>

                        </Box>
                    </Box>

                    <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "30%", xl: "30%" }} p={{ base: '5px', sm: "5px", md: "5px", lg: "30px", xl: "30px" }}>
                        <Input placeholder="Your Name" />
                        <Input mt={"20px"} placeholder="Your Email" />
                        <Input mt={"20px"} placeholder="Phone Number" />
                        <Textarea mt={"20px"} placeholder="Your Messages" />
                        <Button mt={"20px"} bg={"rgb(255,218,69)"} w={"100%"}>SEND MAIL</Button>
                    </Box>
                </Flex>
            </Box>
            <Box>
                <Footer/>
            </Box>
        </>
    )
}
export default FinCal;