import logo from './logo.svg';
import './App.css';
import { Register2 } from './Components/Register2';
import Register3 from './Components/Register3';
import Login from './Components/Login';
import AllRoutes from './AllRoutes/AllRoutes';
import NavbarSmall from './Navbar/NavbarSmall';
import Navbar from './Navbar/Navbar';
import AccountSummary from './ModalPages/AccountSummary';
import AdminLogin from './Components/Admin/AdminLogin';
import { Box } from '@chakra-ui/react';
import BankDetails from './Bank/BankDetails';
import AddnewBank from './Bank/AddnewBank';
import NavbarNew from './Navbar/NavbarNew';
import Deposit from './Components/Withdraw/Deposit';
import AddDeposit from './Components/Withdraw/AddDeposit';
import ForgetPass from './Components/ThankYou/ForgetPass';
import Profile from './Navbar/Profile';
import DocumentVerification from './ModalPages/DocumentVerification';
import ForgetPasswordChange from './Components/ThankYou/ForgetPasswordChange';
import RegistrationThank from './Components/ThankYou/RegistrationThank';
import VerifiedSucess from './Components/ThankYou/VerifiedSucess';
import EmailVerify from './Components/EmailVerify';
import WithdrawRequestPaypal from './Components/Withdraw/WithdrawRequestPaypal';
import Loading from './Components/Withdraw/Loading';
import MainHome from './Components/MainHome';
import MainPage from './Components/MainPage';
import Forex from './Components/HomePageSmall/Forex';
import Commodities from './Components/HomePageSmall/Commodities';
import Indices from './Components/HomePageSmall/Indices';
import Stocks from './Components/HomePageSmall/Stocks';
import CurrencyTrading from './Components/HomePageSmall/CurrencyTrading';
import CryptoCurr from './Components/HomePageSmall/CryptoCurr';
import FAQs from './Components/HomePageSmall/FAQs';
import Goal from './Components/HomePageSmall/Goal';
import BenfitTrad from './Components/HomePageSmall/BenfitTrad';
import CostTrading from './Components/HomePageSmall/CostTrading';
import GloabFxHelp from './Components/HomePageSmall/GloabFxHelp';
import OpenLive from './Components/HomePageSmall/OpenLive';
import AccountC from './Components/HomePageSmall/AccountC';
import AccountV from './Components/HomePageSmall/AccountV';
import DailyM from './Components/HomePageSmall/DailyM';
import FinCal from './Components/HomePageSmall/FinCal';
import RealChart from './Components/HomePageSmall/RealChart';
import Signal from './Components/HomePageSmall/Signal';
import TradingList from './Components/HomePageSmall/TradingList';
import Platform from './Components/HomePageSmall/Platform';
import Education from './Components/HomePageSmall/Education';
import Policy from './Components/HomePageSmall/Policy';
import Terms from './Components/HomePageSmall/Terms';
import Risk from './Components/HomePageSmall/Risk';
import ContactUs from './Components/HomePageSmall/ContactUs';
import NavbarHome from './Navbar/NavbarHome';
import Footer from './Navbar/Footer';
import InactivityLogout from './Components/InactivityLogout';
import { useEffect } from 'react';


// 

function App() {
  let result = JSON.parse(localStorage.getItem("key")) || ""
    useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    document.addEventListener('contextmenu', handleContextMenu);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);
  return (
    <>
    {/* <InactivityLogout logoutTime={300000}/> */}
      
      {/* <InactivityLogout logoutTime={300000}/> */}
      <AllRoutes/>
      
      {/* <Forex/> */}
    </>

  );
}

export default App;
