import { Box, Button, Flex, Input, Text, Textarea } from "@chakra-ui/react";
import Navbar from "../../Navbar/Navbar";
import NavbarNew from "../../Navbar/NavbarNew";
import NavbarHome from "../../Navbar/NavbarHome";
import Footer from "../../Navbar/Footer";



function Terms() {
    return (
        <>
            <Box>
                <NavbarHome />
            </Box>
            <Box padding={{base:'10px',sm:"10px",md:"10px",xl:"30px 45px",lg:"30px 45px"}} >
                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"50px"}>Trading Terms</Text>
            </Box>
            <Box paddingLeft={{base:'10px',sm:"10px",md:"10px",xl:"45px",lg:"45px"}}>
                <Flex gap={"20px"} flexDirection={{base:"column",sm:"column",md:"column",lg:"row",xl:"row"}}>
                    <Box w={{base:'100%',sm:"100%",md:"100%",xl:"70%",lg:"70%"}}>
                        <Box>
                            <Text fontSize={"15px"}>
                            The data used to create the site’s content was gathered through reports, publicly available and reputable sources, and/or other sources thought to be trustworthy. We do not claim that the information is complete, accurate, or reliable, and we have not validated or otherwise checked it.
                            </Text>
                            <Text fontSize={"15px"} mt={"30px"}>
                            In the event that the website, including its various services, malfunctions or is unavailable due to a problem with the Internet, a problem with the computers used by Internet service providers, a problem with the computers used by GloabFx, a problem with the computers used by customers, or any other reason, GloabFx disclaims all liability for damages of any kind that may result from the use of the website and/or the information it contains.
                            </Text>
                            <Text fontSize={"15px"} mt={"30px"}>
                            The information recorded in GloabFx records shall always take preference over information appearing on the Internet in any situation where there is a conflict or disagreement. Any losses or damages of any sort that may occur from relying on the information on the website are not the responsibility of GloabFx.
                            </Text>
                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>Copyright</Text>
                            </Box>
                            <Box>
                            <Text fontSize={"15px"} mt={"30px"}>
                            The content on this website, the programmes that can be downloaded from it, and any items that are marketed there are all covered by and protected by international copyright laws.
                            </Text>
                            <Text fontSize={"15px"} mt={"30px"}>
                            The website may only be used in accordance with these regulations and in a fair manner.  Except with the consent of the copyright holders, it is strictly prohibited to reproduce, modify, distribute, or otherwise use the information for commercial purposes.
                            </Text>
                            
                            </Box>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>Modification of Conditions</Text>
                            </Box>
                            <Box>
                            <Text fontSize={"15px"} mt={"30px"}>
                            In its sole discretion, GloabFx has the right to make changes to the website from time to time, including adding or removing services, changing the guidelines for using the website, or suspending all or some of its operations. No complaint, claim, or right of insurance of any sort may be made by the consumer over any harm he claims that the change has caused him.
                            </Text>
                            
                            </Box>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>Rules for AML (Anti Money Laundering) and documentation</Text>
                            </Box>
                            <Box>
                            <Text fontSize={"15px"} mt={"30px"}>
                            In order to comply with anti-money laundering regulations, GloabFx makes every effort to check the client’s identification. You are kindly asked to submit original copies of a number of documents as a client of GloabFx. Clients may have their accounts restricted until these papers are e-mailed or faxed to our compliance department if they fail to furnish such valid copies within 7 days of the time their accounts were activated. If you have any queries about this request, please email <a href="mailto:gloabfxtrading@gmail.com" style={{ color: "rgb(255,218,69)",fontWeight:"bold" }}>compliance@GloabFx.com</a>.
                            </Text>
                            
                            </Box>

                        </Box>
                    </Box>

                    <Box w={{base:"100%",sm:"100%",md:"100%",lg:"30%",xl:"30%"}} p={{base:'5px',sm:"5px",md:"5px",lg:"30px",xl:"30px"}}>
                        <Input placeholder="Your Name" />
                        <Input mt={"20px"} placeholder="Your Email" />
                        <Input mt={"20px"} placeholder="Phone Number" />
                        <Textarea mt={"20px"} placeholder="Your Messages"/>
                        <Button mt={"20px"}  bg={"rgb(255,218,69)"} w={"100%"}>SEND MAIL</Button>
                    </Box>
                </Flex>
            </Box>
            <Box>
                <Footer/>
            </Box>
        </>
    )
}
export default Terms;