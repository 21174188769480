import { Box, Button, Collapse, Divider, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerOverlay, Flex, Image, Text, useDisclosure, useToast } from "@chakra-ui/react";
import logo from "../../images/logo.png"
import { useNavigate } from "react-router-dom";
import { HamburgerIcon } from "@chakra-ui/icons";
import { useContext } from "react";
import { myContext } from "../../context/Context";


function LeftNav() {
    const { isOpen, onToggle } = useDisclosure()
    const { isOpen: isModal1Open, onOpen: openModal1, onClose: closeModal1 } = useDisclosure();
    let navigate = useNavigate();
    let result = JSON.parse(sessionStorage.getItem("key"))
    let { logout, totalbalance, fetchBalance } = useContext(myContext);
    let toast = useToast();
    let logOut = () => {
        sessionStorage.clear();
        navigate("/login")
        toast({
            description: `Logged Out`,
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-center",
        })
        window.location.reload(true)
        console.log(result)
    }
    return (
        <>
            <Box display={{ base: "block", sm: "block", md: "block", lg: "none", xl: "none" }} boxShadow="rgba(0, 0, 0, 0.1) 0px 4px 12px">
                <Flex >
                    <Box p={"20px"} w={{ base: "100%", sm: "100%", md: "100%", lg: "38%", xl: "38%" }} onClick={() => { navigate("/home") }}>
                        <Box w={{ base: "50%", sm: "50%", md: "50%", lg: "38%", xl: "38%" }}>
                            <Image cursor={"pointer"} src={logo}></Image>
                        </Box>
                    </Box>
                    <Box mt={"-2px"} color={"white"} alignItems={"center"} pr={{ base: "18px", sm: "18px", md: "18px", lg: "0px", xl: "0px" }} fontSize={{ base: "40px", sm: "40px", md: "50px", lg: "30px", xl: "30px" }} display={{ base: "block", sm: "block", md: "block", lg: "none", xl: "none" }}
                        onClick={() => openModal1()}
                    >
                        <HamburgerIcon ></HamburgerIcon></Box>
                </Flex>
                <Drawer
                    isOpen={isModal1Open}
                    placement='right'
                    onClose={closeModal1}
                >
                    <DrawerOverlay />
                    <DrawerContent bg={"rgb(55,67,78)"} color={"white"}>
                        <DrawerCloseButton />
                        {/* <DrawerHeader>Create your account</DrawerHeader> */}

                        <DrawerBody mt="60px" >



                            <Flex mt="10px" gap={"5px"} alignItems={"center"}>
                                <ion-icon name="person-circle-outline" size="small"></ion-icon>
                                <Text fontWeight="semibold" _hover={{ color: "rgb(255,218,69)", cursor: "pointer" }} onClick={() => navigate("/adminuser")}>All Users</Text>
                            </Flex>


                            <Divider mt={"10px"} color={"rgb(250,250,250)"} />

                            <Flex mt="10px" gap={"5px"} alignItems={"center"}>
                                <ion-icon name="checkbox-outline" size="small"></ion-icon>
                                <Text fontWeight="bold" _hover={{ color: "rgb(255,218,69)", cursor: "pointer" }} onClick={() => navigate("/verifyall")}>Verify Users</Text>
                            </Flex>


                            <Divider mt={"10px"} color={"rgb(250,250,250)"} />
                            <Box>
                                <Flex mt="10px" gap={"5px"} alignItems={"center"}>
                                    <ion-icon name="card-outline"></ion-icon>
                                    <Text fontWeight="bold" _hover={{ color: "rgb(255,218,69)", cursor: "pointer" }} onClick={() => navigate("/admindeposit")}>Deposit Money</Text>
                                </Flex>
                                <Divider mt={"10px"} color={"rgb(250,250,250)"} />
                            </Box>


                            <Box mt={"10px"}>
                                <Flex gap={"5px"} alignItems={"center"}>
                                    <ion-icon name="card-outline"></ion-icon>
                                    <Text fontWeight="bold" _hover={{ color: "rgb(255,218,69)", cursor: "pointer" }} onClick={() => navigate("/admindepositdemo")}>Demo Deposit</Text>
                                </Flex>
                                <Divider mt={"10px"} color={"rgb(250,250,250)"} />
                            </Box>




                            <Flex mt="10px" gap={"5px"} alignItems={"center"}>
                                <ion-icon name="caret-forward-outline"></ion-icon>
                                <Text fontWeight="semibold" _hover={{ color: "rgb(255,218,69)", cursor: "pointer" }} onClick={() => navigate('/admindepositreq')}>Deposit Request</Text>
                            </Flex>


                            <Divider mt={"10px"} color={"rgb(250,250,250)"} />



                            <Flex mt="10px" gap={"5px"} alignItems={"center"}>
                                <ion-icon name="caret-forward-outline"></ion-icon>
                                <Text fontWeight="semibold" _hover={{ color: "rgb(255,218,69)", cursor: "pointer" }} onClick={() => navigate("/adminwithdraw")}>Bank Withdraw Request</Text>
                            </Flex>



                            <Divider mt={"10px"} color={"rgb(250,250,250)"} />




                            <Flex mt="10px" gap={"5px"} alignItems={"center"}>
                                <ion-icon name="caret-forward-outline"></ion-icon>
                                <Text fontWeight="semibold" _hover={{ color: "rgb(255,218,69)", cursor: "pointer" }} onClick={() => navigate("/otherwithdraw")}>Other Withdraw Request</Text>
                            </Flex>



                            <Divider mt={"10px"} color={"rgb(250,250,250)"} />


                            <Box mt={"10px"}>
                                <Flex gap={"5px"} alignItems={"center"}>
                                    <ion-icon name="card-outline"></ion-icon>
                                    <Text fontWeight="bold" _hover={{ color: "rgb(255,218,69)", cursor: "pointer" }} onClick={() => navigate("/deposithistory")}>Deposit History</Text>
                                </Flex>
                                <Divider mt={"10px"} color={"rgb(250,250,250)"} />
                            </Box>



                        </DrawerBody>

                        <DrawerFooter>
                            <Button mr={3} onClick={logOut}>
                                Logout
                            </Button>

                        </DrawerFooter>
                    </DrawerContent>
                </Drawer>
            </Box>
            <Box border={"1px solid black"} p={"20px"} h={"100vh"}

                bg={"rgb(9,10,12)"} color={"white"} display={{ base: "none", sm: "none", md: "none", lg: "block", xl: "block" }}>

                <Box>
                    <Image w={"80%"} h={"40px"}
                        display={"block"} margin={"0 auto"} mt={"20px"} src={logo} />
                </Box>
                <Text mt={"60px"} cursor={"pointer"}>Menu</Text>
                {/* <Box alignItems={"center"}>
                    <Text mt={"20px"} onClick={()=>navigate("/adminhome")} cursor={"pointer"}><ion-icon name="home-outline"></ion-icon> Dashboard</Text>
                </Box> */}
                <Box>
                    <Text mt={"20px"} onClick={onToggle} cursor={"pointer"} alignItems={"center"}><ion-icon name="people-outline"></ion-icon> Users Management</Text>
                    <Collapse in={isOpen} animateOpacity>
                        <Box

                            color='white'
                            mt='4'
                            bg='rgb(9,10,12)'
                            rounded='md'
                            shadow='md'
                            alignItems={"center"}
                        >
                            <Text cursor={"pointer"} onClick={() => navigate("/adminuser")}>- All Users</Text>
                            <Text cursor={"pointer"} onClick={() => navigate("/verifyall")} mt={"10px"}>- Verify Users</Text>
                        </Box>
                    </Collapse>
                    <Box alignItems={"center"}>

                        <Text mt={"20px"} onClick={() => navigate("/admindeposit")} cursor={"pointer"}><ion-icon style={{ marginRight: "5px" }} name="card-outline"></ion-icon>Deposit Money </Text>
                    </Box>
                    <Box alignItems={"center"}>

                        <Text mt={"20px"} onClick={() => navigate("/admindepositdemo")} cursor={"pointer"}><ion-icon style={{ marginRight: "5px" }} name="card-outline"></ion-icon>Demo Deposit Money </Text>
                    </Box>
                </Box>
                <Box alignItems={"center"}>
                    <Text mt={"20px"} cursor={"pointer"} onClick={() => {
                        navigate('/admindepositreq')
                    }}><ion-icon style={{ marginRight: "5px" }} name="caret-forward-outline"></ion-icon>Deposit Request </Text>
                </Box>

                <Box alignItems={"center"}>
                    <Text mt={"20px"} onClick={() => navigate("/adminwithdraw")} cursor={"pointer"}><ion-icon style={{ marginRight: "5px" }} name="caret-forward-outline"></ion-icon>Bank Withdraw Request </Text>
                </Box>


                <Box alignItems={"center"}>
                    <Text mt={"20px"} onClick={() => navigate("/otherwithdraw")} cursor={"pointer"}><ion-icon style={{ marginRight: "5px" }} name="caret-forward-outline"></ion-icon>Other Withdraw Request </Text>
                </Box>

                <Box alignItems={"center"}>
                    <Text mt={"20px"} cursor={"pointer"} onClick={() => {
                        navigate('/deposithistory')
                    }}><ion-icon style={{ marginRight: "5px" }} name="reader-outline"></ion-icon>Deposit History </Text>
                </Box>

                <Box alignItems={"center"}>
                    <Text mt={"20px"} cursor={"pointer"} onClick={() => {
                        navigate('/changepassword')
                    }}><ion-icon style={{ marginRight: "5px" }} name="lock-closed-outline"></ion-icon>Change password</Text>
                </Box>

                <Box alignItems={"center"} display={{base:"none",sm:"none",md:"none",lg:"block",xl:"block"}}>
                    <Text mt={"20px"} cursor={"pointer"} onClick={logOut}
                   ><ion-icon style={{ marginRight: "5px" }} name="person-outline"></ion-icon>Logout</Text>
                </Box>

            </Box>
            {/* <Box mt={{ base: "0px", sm: "0px", md: "0px", lg: "10px", xl: "10px" }} maxW={{ base: "100%", sm: '100%', md: "100%", lg: "100%", xl: "100%" }} >
                                                                            <Text>Amount in $</Text>
                                                                            <InputGroup alignItems={"center"} >
                                                                                <Input
                                                                                    size="sm"
                                                                                    w={{ base: "60%", sm: "60%", md: "60%", lg: "70%", xl: "70%" }}
                                                                                    borderRadius={"none"}
                                                                                    isDisabled={addStopLoss === false}
                                                                                    name="dollarPrice"
                                                                                    type="Number"
                                                                                    value={selectedSide === "Buy" ? dollorPricebid1 : dollorPriceask1}
                                                                                    onChange={Inputdollar}
                                                                                    defaultValue={0}
                                                                                // Add other input properties as needed
                                                                                />
                                                                                <InputRightElement position="relative">
                                                                                    <Flex
                                                                                        position="absolute"
                                                                                        right="-6" top="50%" transform="translateY(-50%)"
                                                                                    >
                                                                                        <Button
                                                                                            borderRadius="none"
                                                                                            color={"black"}

                                                                                            size="sm"
                                                                                            h={"31.8px"}
                                                                                            w={"30px"}
                                                                                            fontSize="sm"
                                                                                            isDisabled={addStopLoss === false || selectedSide === "Buy" ? dollorPricebid1 === 0 : dollorPriceask1 === 0}
                                                                                            onClick={handleSubDollar1}
                                                                                        // Add onClick or other button properties as needed
                                                                                        >
                                                                                            -
                                                                                        </Button>
                                                                                        <Button

                                                                                            borderRadius="none"
                                                                                            color={"black"}
                                                                                            bg={"rgb(255,218,69)"}
                                                                                            size="sm"
                                                                                            h={"31.8px"}
                                                                                            w={"30px"}
                                                                                            fontSize="sm"
                                                                                            isDisabled={addStopLoss === false}
                                                                                            onClick={handleaddDollar1}
                                                                                        // Add onClick or other button properties as needed
                                                                                        >
                                                                                            +
                                                                                        </Button>
                                                                                    </Flex>
                                                                                </InputRightElement>
                                                                            </InputGroup>
                                                                            <Text mt={"5px"}>Above $0.00</Text>
                                                                        </Box>
                                                                        <Box mt={{ base: "0px", sm: "0px", md: "0px", lg: "10px", xl: "10px" }}>
                                                                            <Text fontWeight={"bold"}>OR</Text>
                                                                        </Box> */}
                                                                        {/* <Box mt={{ base: "0px", sm: "0px", md: "0px", lg: "10px", xl: "10px" }} maxW={{ base: "100%", sm: '100%', md: "100%", lg: "100%", xl: "100%" }}>
                                                                            <Text>Amount in $</Text>
                                                                            <InputGroup alignItems={"center"}>
                                                                                <Input
                                                                                    size="sm"
                                                                                    w={{ base: "60%", sm: "60%", md: "60%", lg: "70%", xl: "70%" }}
                                                                                    borderRadius="none"
                                                                                    isDisabled={addTakeProfit === false}
                                                                                    value={selectedSide === "Buy" ? dollorPricebid2 : dollorPriceask2}
                                                                                    onChange={dollarPriceinput}
                                                                                    defaultValue={0}
                                                                                    type="Number"
                                                                                // Add other input properties as needed
                                                                                />
                                                                                <InputRightElement position="relative">
                                                                                    <Flex position="absolute" right="-6" top="50%" transform="translateY(-50%)">
                                                                                        <Button
                                                                                            borderRadius="none"
                                                                                            color={"black"}
                                                                                            size="sm"

                                                                                            h={"31.8px"}
                                                                                            w={"30px"}
                                                                                            fontSize="sm"
                                                                                            isDisabled={addTakeProfit === false || selectedSide === "Buy" ? dollorPricebid2 === 0 : dollorPriceask2 === 0}
                                                                                            onClick={handleSubDollar}

                                                                                        >
                                                                                            -
                                                                                        </Button>
                                                                                        <Button
                                                                                            borderRadius="none"
                                                                                            bg={"rgb(255,218,69)"}
                                                                                            color={"black"}
                                                                                            size="sm"
                                                                                            h={"31.8px"}
                                                                                            w={"30px"}
                                                                                            fontSize="sm"
                                                                                            isDisabled={addTakeProfit === false}
                                                                                            onClick={handleaddDollar}

                                                                                        >
                                                                                            +
                                                                                        </Button>
                                                                                    </Flex>
                                                                                </InputRightElement>
                                                                            </InputGroup>
                                                                            <Text mt={"5px"}>Above $0.00</Text>
                                                                        </Box>
                                                                        <Box mt={{ base: "0px", sm: "0px", md: "0px", lg: "10px", xl: "10px" }}>
                                                                            <Text fontWeight={"bold"}>OR</Text>
                                                                        </Box> */}
        </>
    )
}

export default LeftNav;



