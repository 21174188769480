import { Box, Flex, Input, Text, InputRightElement, InputGroup, Button, useToast, Spinner, Image } from "@chakra-ui/react";
import { useState } from "react";
import { CheckCircleIcon, SpinnerIcon } from "@chakra-ui/icons"
import { useNavigate } from "react-router-dom";
import NavbarNew from "../Navbar/NavbarNew";
import back from "../../src/images/back1.jpg"

function Login() {
    const [show, setShow] = useState(false);
    const handleClick = () => setShow(!show);
    let navigate = useNavigate();
    const [error, setError] = useState("");
    let [next, setnext] = useState(true);
    let toast = useToast();

    const [state, setState] = useState({
        email: "",
        password: ""
    })


    let storeData = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    }

    let login = async (e) => {
        setnext(false);
        e.preventDefault();
        try {
            let res = await fetch(`https://trading-jz57.onrender.com/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(state),
                // credentials: 'include' // Add this line
            });

            if (res.ok) {
                let result = await res.json();
                console.log('Response Headers:', res.headers);
                if (result.type.type === "user") {
                    // const cookieHeader = res.headers.get('Set-Cookie');
                    // if (cookieHeader) {
                    //     // Extract the cookie value from the header (this might need adjustment based on your server response)
                    //     const cookieValue = cookieHeader.split(';')[0];
                    //     console.log('Received cookie:', cookieValue);

                    //     // Log all cookies
                    //     console.log('All cookies:', document.cookie);

                    //     // Now you can use the cookieValue as needed in your application
                    // }

                    console.log(result);
                    sessionStorage.setItem("key", JSON.stringify(result))
                    navigate("/home")
                    toast({
                        description: `Welcome ${result.type.first_name}`,
                        status: `success`,
                        duration: 5000,
                        isClosable: true,
                        position: "top-center",
                    })
                    setnext(true);
                } else if (result.type.type === "Admin") {
                    navigate('/adminuser')
                    sessionStorage.setItem("key", JSON.stringify(result))
                    toast({
                        description: `Admin`,
                        status: `success`,
                        duration: 5000,
                        isClosable: true,
                        position: "top-center",
                    })
                    setnext(true);
                } else if(result.type.verified === false){
                    toast({
                        description: `Please verify your Email`,
                        status: `error`,
                        duration: 5000,
                        isClosable: true,
                        position: "top-center",
                    })
                    setnext(true);
                }else{
                    toast({
                        description: `Invalid username or password`,
                        status: `error`,
                        duration: 5000,
                        isClosable: true,
                        position: "top-center",
                    })
                    setnext(true);
                }
            } else {
                toast({
                    description: `Invalid username or password`,
                    status: `error`,
                    duration: 5000,
                    isClosable: true,
                    position: "top-center",
                })
                setnext(true);
            }
        } catch (error) {
            if (
                error.response &&
                error.response.status >= 400 &&
                error.response.status <= 500
            ) {
                setError(error.response.data.message);
                setnext(true);
            }
            setnext(true);
        }
    }


    return (
        <Box >
            <Box>
                <NavbarNew />
            </Box>
            <Box w={"100%"} >
                <Flex gap={{ base: "10px", lg: "30px" }} flexDirection={{ base: "column", lg: "row" }}>
                    <Box width={{ base: "100%", sm: "100%", md: "100%", lg: "50%", xl: "50%" }} p={{ base: "20px", sm: "20px", md: "20px", lg: "100px", xl: "100px" }}>
                        <form onSubmit={login}>
                            <Text fontSize={{ base: "24px", lg: "30px" }} color={"white"} fontWeight="bold">LOGIN</Text>
                            <Input
                                mt={"30px"}
                                name="email"
                                placeholder="Enter Email Address"
                                value={state.email}
                                onChange={storeData}
                                isRequired
                                autoComplete="email"  // Add this line
                            />

                            <InputGroup size="md">
                                <Input
                                    name="password"
                                    mt="20px"
                                    pr="4.5rem"
                                    type={show ? "text" : "password"}
                                    fontSize="14px"
                                    _placeholder={{ fontSize: "14px" }}
                                    placeholder="Enter Password"
                                    value={state.password}
                                    onChange={storeData}
                                    // onKeyDown={(e) => login(e)}
                                    isRequired
                                />
                                <InputRightElement width="4.5rem" mt="20px">
                                    <Button h="1.75rem" size="sm" onClick={handleClick}>
                                        {show ? "Hide" : "Show"}
                                    </Button>
                                </InputRightElement>
                            </InputGroup>
                            <Flex justifyContent={{ base: "center", lg: "space-between" }} alignItems="center" flexDirection={{ base: "column", lg: "row" }}>
                                {
                                    next ? <Button
                                        mt="20px"
                                        color="black"
                                        bg="rgb(255,218,69)"
                                        size="md"
                                        type="submit"
                                    >
                                        LOGIN
                                    </Button> : <Button
                                        mt="20px"
                                        color="black"
                                        bg="rgb(255,218,69)"
                                        size="md"
                                        type="submit"
                                    >
                                        <Spinner />
                                    </Button>
                                }
                                <Text mt={{ base: "20px", lg: "0" }} color={"white"} _hover={{ color: "rgb(255,218,69)" }} onClick={() => navigate("/forget")} cursor={"pointer"}>
                                    Forget Password ?
                                </Text>
                            </Flex>
                        </form>
                    </Box>
                    <Box width={"50%"} display={{ base: "none", sm: "none", md: "none", lg: "block", xl: "block" }}>
                        <Image src={back} style={{ width: '100%', height: '100vh', objectFit: 'cover' }} />
                    </Box>
                </Flex>
            </Box>
        </Box>
    );
}

export default Login;
