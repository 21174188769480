import { Box, Button, Divider, Flex, Input, Spinner, Table, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import LeftNav from "./LeftNav";


function DepositMoney() {
    let [data, setData] = useState({
        AccountNo: "",
        balance: 0,
        bonus:0,

    })
    let [deposit, setDeposit] = useState([])
    let [load, setLoad] = useState(true)
    const [currentPage, setCurrentPage] = useState(1);
    let toast=useToast();

    let saveData = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    }
    console.log(data);
    let submitData = async (e) => {
        e.preventDefault();
        setLoad(false);
       

        
        try {
            let response = await fetch(`https://trading-jz57.onrender.com/deposit`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            })
            if (response.ok) {
                let res = await response.json();
                console.log(res);
                toast({
                    description: `Balance added successfully`,
                    status: `success`,
                    duration: 5000,
                    isClosable: true,
                    position: "top-center",
                })
                setLoad(true);
            }else{
                toast({
                    description: `user not found`,
                    status: `error`,
                    duration: 5000,
                    isClosable: true,
                    position: "top-center",
                })
                setLoad(true)
            }


        } catch (error) {
            console.log(error);
            toast({
                description: `Unable to fetch`,
                status: `error`,
                duration: 5000,
                isClosable: true,
                position: "top-center",
            })
            setLoad(true)
        }
    

    }

  

    const userPerPage = 10;
    const lastIndex = currentPage * userPerPage;
    const firstIndex = lastIndex - userPerPage;
    const user = deposit.slice(firstIndex, lastIndex);
    const totalPages = Math.ceil(deposit.length / userPerPage);
    return (
        <>
            <Box>
            <Flex gap={"20px"} flexDirection={{base:"column",sm:"column",md:"column",lg:"row",xl:"row"}}>
                    <Box w={{base:"100%",sm:"100%",md:"100%",lg:"20%",xl:'20%'}}>
                        <LeftNav />
                    </Box>
                    <Box w={{base:"100%",sm:"100%",md:"100%",lg:"80%",xl:'80%'}}>

                        <Flex justifyContent={"space-between"} alignContent={"center"}>
                            <Box p={"30px"}>
                                <Text>
                                    Deposit Money in User Account
                                </Text>
                                <Divider></Divider>
                                <form onSubmit={submitData}>
                                    <Box mt={"30px"}>
                                        <Text>User Account Number</Text>
                                        <Input type="text"
                                            name="AccountNo"
                                            value={data.AccountNo}
                                            onChange={saveData}
                                            isRequired />
                                    </Box>
                                    
                                    <Box mt={"30px"}>
                                        <Text>Amount Add</Text>
                                        <Input
                                            type="Number"
                                            name="balance"
                                            value={data.balance}
                                            onChange={saveData}
                                            isRequired />
                                    </Box>
                                    <Box mt={"30px"}>
                                        <Text>Bonus</Text>
                                        <Input type="Number"
                                            name="bonus"
                                            value={data.bonus}
                                            onChange={saveData}
                                            isRequired />
                                    </Box>
                                    {
                                        load ? <Button type="submit" bg={"rgb(255,218,69)"} color={"black"} mt={"20px"}>Submit</Button> :
                                            <Button type="submit" bg={"rgb(255,218,69)"} color={"black"} mt={"20px"}><Spinner /></Button>
                                    }

                                </form>
                            </Box>

                        </Flex>
                    </Box>
                </Flex>

            </Box>
        </>
    )
}

export default DepositMoney;