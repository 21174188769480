import { Box, Button, Flex, Input, Text, Textarea } from "@chakra-ui/react";
import Navbar from "../../Navbar/Navbar";
import NavbarNew from "../../Navbar/NavbarNew";
import NavbarHome from "../../Navbar/NavbarHome";
import Footer from "../../Navbar/Footer";



function Education() {
    return (
        <>
            <Box>
                <NavbarHome />
            </Box>
            <Box padding={{base:'10px',sm:"10px",md:"10px",xl:"30px 45px",lg:"30px 45px"}} >
                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"50px"}>Education Package</Text>
            </Box>
            <Box paddingLeft={{base:'10px',sm:"10px",md:"10px",xl:"45px",lg:"45px"}}>
                <Flex gap={"20px"} flexDirection={{base:"column",sm:"column",md:"column",lg:"row",xl:"row"}}>
                    <Box w={{base:'100%',sm:"100%",md:"100%",xl:"70%",lg:"70%"}}>
                        <Box p={"20px"}>
                            <Text fontSize={"15px"}>
                               <li>EAP Training Program: – Minimum Amount – 300 Dollar (19577) INR</li>
                            </Text>
                            <Text fontSize={"15px"} >
                                <li>Advanced Pattern Mastery Course 500 Dollar (34000) INR</li>
                            </Text>
                            <Text fontSize={"15px"} >
                                <li>$100 for each email update; FREE training; email list</li>
                            </Text>
                        </Box>
                        <Text fontSize={"15px"} mt={"30px"}>
                        Our platform’s online resources, which include training videos, online tutorials, and PDF instructions, are available to new traders who want to launch a lucrative career in the Forex market.
                        </Text>
                        <Text fontSize={"15px"} mt={"30px"}>
                        The goal of Forex Journey’s strategy is to open trading in the forex market to all people. Before beginning your adventure with forex, you simply need some rapid training.
                        </Text>
                        <Text fontSize={"15px"} mt={"30px"}>
                        There are many kinds of accounts, and everything will rely on your level of expertise and ambition. For instance, if you don’t feel very safe when using the service, try opening a sample account with a small budget that doesn’t go beyond $100.
                        </Text>
                        <Text fontSize={"15px"} mt={"30px"}>
                        Customers who do not yet have a lot of forex trading experience can make advantage of the instructive demo account option. You may quickly learn how to trade currencies with this option.

 
                        </Text>
                        <Text fontSize={"15px"} mt={"30px"}>
                        Customers can use normal accounts or small accounts if they have some experience with Forex trading strategies.
                        </Text>
                        <Text fontSize={"15px"} mt={"30px"}>
                        You it can solidify your Forex expertise by using these types of accounts to get all the tools and support services you need.
                        </Text>
                        <Text fontSize={"15px"} mt={"30px"}>
                        If the frequently asked questions in our forum do not have an answer that fulfils your needs, you may contact our specialists, who are always available to assist you in getting past any obstacles you may be facing.
                        </Text>
                        <Text fontSize={"15px"} mt={"30px"}>
                        For the best traders, there is also a Premium option. This is a way to increase knowledge while receiving a small discount on the services provided. Simply open your first Premium account if you are interested in this type of account and don’t be afraid to speak with a Forex professional.
                        </Text>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>Type of training-</Text>
                            </Box>
                            <Box>
                            <Text fontSize={"15px"} mt={"30px"}>
                            Online, Monday through Friday, between 9:00 and 10:00 IST
                            </Text>
                            
                            </Box>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>22 training days-</Text>
                            </Box>
                            <Box>
                            <Text fontSize={"15px"} mt={"30px"}>
                            from Monday to Friday from 9.00AM to 10:00AM IST
                            </Text>
                            
                            </Box>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>Method of payment-</Text>
                            </Box>
                            <Box>
                            <Text fontSize={"15px"} mt={"30px"}>
                            Here, customers can pay online
                            </Text>
                            
                            </Box>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>The cancellation and refund-</Text>
                            </Box>
                            <Box>
                            <Text fontSize={"15px"} mt={"30px"}>
                            After purchasing the education package, there is no going back and no money will be refunded.
                            </Text>
                            
                            </Box>

                        </Box>
                        
                    </Box>

                    <Box w={{base:"100%",sm:"100%",md:"100%",lg:"30%",xl:"30%"}} p={{base:'5px',sm:"5px",md:"5px",lg:"30px",xl:"30px"}}>
                        <Input placeholder="Your Name" />
                        <Input mt={"20px"} placeholder="Your Email" />
                        <Input mt={"20px"} placeholder="Phone Number" />
                        <Textarea mt={"20px"} placeholder="Your Messages"/>
                        <Button mt={"20px"}  bg={"rgb(255,218,69)"} w={"100%"}>SEND MAIL</Button>
                    </Box>
                </Flex>
            </Box>
            <Box>
                <Footer/>
            </Box>
        </>
    )
}
export default Education;