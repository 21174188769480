import { Box, Button, Flex, Input, Text, Textarea } from "@chakra-ui/react";
import Navbar from "../../Navbar/Navbar";
import NavbarNew from "../../Navbar/NavbarNew";
import NavbarHome from "../../Navbar/NavbarHome";
import Footer from "../../Navbar/Footer";



function Signal() {
    return (
        <>
            <Box>
                <NavbarHome />
            </Box>
            <Box padding={{base:'10px',sm:"10px",md:"10px",xl:"30px 45px",lg:"30px 45px"}} >
                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"50px"}>Signals</Text>
            </Box>
            <Box paddingLeft={{base:'10px',sm:"10px",md:"10px",xl:"45px",lg:"45px"}}>
                <Flex gap={"20px"} flexDirection={{base:"column",sm:"column",md:"column",lg:"row",xl:"row"}}>
                    <Box w={{base:'100%',sm:"100%",md:"100%",xl:"70%",lg:"70%"}}>
                        <Box>
                            <Text fontSize={"15px"}>
                            With the help of our advanced trading signals, you can now make well-informed trading choices and get a continuous live feed of the most recent market moves for 65 of the most popular currency pairs, indices, and commodities.
                            </Text>
                            <Text fontSize={"15px"} mt={"30px"}>
                            Our Advanced Trading Signals are broadcast directly to your smartphone and are completely adjustable, enabling you to change them to suit your trading requirements and eventually aiding in the making of informed trading decisions.                            </Text>
                           
                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>What advantages can our trading signals offer you?</Text>
                            </Box>
                            <Box>
                            <Text fontSize={"15px"} mt={"30px"}>
                            You might begin to investigate and learn about the most recent and well-liked market trends and movements.
                            </Text>
                            <Text fontSize={"15px"} mt={"30px"}>
                            Our sophisticated system promptly notifies you of the movements of your favourite products.
                            </Text>
                            <Text fontSize={"15px"} mt={"30px"}>
                            Even if you have never traded before, you can learn about new product movements and begin!
                            </Text>
                            
                            </Box>

                        </Box>
                        
                    </Box>

                    <Box w={{base:"100%",sm:"100%",md:"100%",lg:"30%",xl:"30%"}} p={{base:'5px',sm:"5px",md:"5px",lg:"30px",xl:"30px"}}>
                        <Input placeholder="Your Name" />
                        <Input mt={"20px"} placeholder="Your Email" />
                        <Input mt={"20px"} placeholder="Phone Number" />
                        <Textarea mt={"20px"} placeholder="Your Messages"/>
                        <Button mt={"20px"}  bg={"rgb(255,218,69)"} w={"100%"}>SEND MAIL</Button>
                    </Box>
                </Flex>
            </Box>
            <Box>
                <Footer/>
            </Box>
        </>
    )
}
export default Signal;