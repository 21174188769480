import { Box, Button, Center, Divider, Flex, Image, Input, Spinner, Text, useClipboard, useToast } from "@chakra-ui/react";
import btc from "../../images/btc.png";
import eth from "../../images/eth.svg";
import bnb from "../../images/bnb.png";
import ltc from "../../images/ltc.png";
import usdt from "../../images/usdt.png";
import { useEffect, useState } from "react";
import chartbtc from "../../images/chartbtc.png";
import charteth from "../../images/charteth.png";
import chartbnb from "../../images/chartbnb.png";
import chartltc from "../../images/chartltc.png";
import charterc20 from "../../images/charterc20.png";
import charttrc20 from "../../images/charttrc20.png";
import axios from "axios";
import NavbarNew from "../../Navbar/NavbarNew";
import { useNavigate } from "react-router-dom";

function AddDeposit() {
   const [clickedButton, setClickedButton] = useState("BTC");
   const [text, setText] = useState("Scan the Qr code or copy the Bitcoin (BTC) address below. Enter amount sent in USD, upload proof of payment. Payment will be reviewed and processed.");
   const [scanner, setScanner] = useState(chartbtc);
   const [code, setCode] = useState("btbtbtbtbtbtb");
   const { hasCopied, onCopy } = useClipboard(code);
   let [amount, setAmount] = useState();
   let [url, seturl] = useState()
   const [img, setImg] = useState();
   let res=JSON.parse(sessionStorage.getItem("key"));
   let [load,setLoad]=useState(true);
   let toast=useToast();
   let navigate=useNavigate();

   const handleButtonClick = (buttonName) => {
      setClickedButton(buttonName);
      switch (buttonName) {
         case "BTC":
            setText("Scan the Qr code or copy the Bitcoin (BTC) address below. Enter amount sent in USD, upload proof of payment. Payment will be reviewed and processed.");
            setScanner(chartbtc);
            setCode("btbtbtbtbtbtb");
            break;
         case "ETH":
            setText("Scan the Qr code or copy the Ethereum (ETH) address below. Enter amount sent in USD, upload proof of payment. Payment will be reviewed and processed.");
            setScanner(charteth);
            setCode("ehththththth");
            break;
         case "BNB":
            setText("Scan the Qr code or copy the BNB (BNB) address below. Enter amount sent in USD, upload proof of payment. Payment will be reviewed and processed.");
            setScanner(chartbnb);
            setCode("bnbnbnbnbnbnbnbnbnb");
            break;
         case "LTC":
            setText("Scan the Qr code or copy the Litecoin (ltc) address below. Enter amount sent in USD, upload proof of payment. Payment will be reviewed and processed.");
            setScanner(chartltc);
            setCode("ltltltltltltltltl");
            break;
         case "USDT-ERC20":
            setText("Scan the Qr code or copy the Tether USD (ERC20) address below. Enter amount sent in USD, upload proof of payment. Payment will be reviewed and processed.");
            setScanner(charterc20);
            setCode("usususususususus");
            break;
         case "USDT-TRC20":
            setText("Scan the Qr code or copy the Tether USD (TRC20) address below. Enter amount sent in USD, upload proof of payment. Payment will be reviewed and processed.");
            setScanner(charttrc20);
            setCode("ususususuusus");
            break;
         default:
            break;
      }
   };

   const isButtonClicked = (buttonName) => {
      return clickedButton === buttonName;
   };



   const handleImg = async (e) => {
      setImg(e.target.files[0]);

    
      const formData = new FormData();
      formData.append("Image", e.target.files[0]);
  
      try {
         const result = await fetch(`https://trading-jz57.onrender.com/file/upload`, {
            method: "POST",
            body: formData,
         });

         const ans = await result.json();
         
         seturl(ans.url);
      } catch (err) {
         toast({
            description: `something went wrong`,
            status: `error`,
            duration: 5000,
            isClosable: true,
            position: "top-center",
        })
      }
   };



   const uploadImage = async (e) => {
      e.preventDefault();
      setLoad(false);
  
      // Check if url is available
      if (!url) {
          console.error('URL is not available. Please upload an image first.');
          setLoad(true);
          return;
      }
  
      const data = {
          code,
          amount,
          'Image': url,
      };
  
      console.log(data);
  
      try {
          // Check if res and res.type are available
          if (!res || !res.type || !res.type.AcNumber) {
              throw new Error('Invalid response data. Unable to fetch AcNumber.');
          }
  
          const result = await fetch(`https://trading-jz57.onrender.com/file/addDetail/${res.type.AcNumber}`, {
              method: 'POST',
              body: JSON.stringify(data),
              headers: {
                  'Content-Type': 'application/json',
              },
          });
  
          // Check if the response status is OK (200)
          if (!result.ok) {
              setLoad(true);
              throw new Error(`Error adding detail: ${result.status} - ${result.statusText}`);
          }
  
          const ans = await result.json();
          console.log(ans);
  
          toast({
              description: `Deposit added successfully`,
              status: `success`,
              duration: 5000,
              isClosable: true,
              position: "top-center",
          });
  
          navigate("/depositpage");
          setLoad(true);
      } catch (err) {
          console.error(err);
          toast({
              description: `Something went wrong: ${err.message}`,
              status: `error`,
              duration: 5000,
              isClosable: true,
              position: "top-center",
          });
          setLoad(true);
      }
  };
  

   return (
      <>
         <Box>
            <NavbarNew />
         </Box>
         <Box w={{ base: "", sm: "", md: "", lg: "", xl: "" }} borderTop={"1px"} p={"10px"} bg={"rgb(55,67,78)"} color={"white"} >

            <Box boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px">
               <Box p={"10px"}>
                  <Text fontWeight={"bold"} fontSize={"3xl"}>Deposit Details</Text>
                  {/* <Divider></Divider> */}
                  <Box mt={"20px"}>
                     <Flex gap={"30px"} flexDirection={{ base: "column", sm: "column", md: "column", lg: "row", xl: "row" }}>
                        <Box w={{ base: "", sm: "", md: "", lg: "40%", xl: "40%" }} p={"10px"} boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px;">
                           <Text>Deposit Funds</Text>
                           <Text>Select a payment method</Text>
                           <Box mt={"30px"}>
                              <Flex justifyContent={"space-between"}>
                                 <Box
                                    borderRadius={"15px"}
                                    p={"20px"}
                                    w={"45%"}
                                    bg={isButtonClicked("BTC") ? "rgb(80,80,80)" : "white"}
                                    color={isButtonClicked("BTC") ? "white" : "rgb(80,80,80)"}
                                    onClick={() => handleButtonClick("BTC")}
                                 >
                                    <Flex gap={{ base: "3px", sm: "4px", md: "8px", lg: "20px", xl: "20px" }}>
                                       <Image w={{ base: "30px", sm: "60px", md: "60px", lg: "70px", xl: "100px" }} src={btc}></Image>
                                       <Text>BTC</Text>
                                    </Flex>
                                 </Box>
                                 <Box
                                    borderRadius={"15px"}
                                    p={"20px"}
                                    w={"45%"}
                                    bg={isButtonClicked("ETH") ? "rgb(80,80,80)" : "white"}
                                    color={isButtonClicked("ETH") ? "white" : "rgb(80,80,80)"}
                                    onClick={() => handleButtonClick("ETH")}
                                 >
                                    <Flex gap={{ base: "3px", sm: "4px", md: "8px", lg: "20px", xl: "20px" }}>
                                       <Image w={{ base: "30px", sm: "60px", md: "60px", lg: "70px", xl: "100px" }} src={eth}></Image>
                                       <Text>ETH</Text>
                                    </Flex>
                                 </Box>
                              </Flex>
                           </Box>
                           <Box mt={"30px"}>
                              <Flex justifyContent={"space-between"}>
                                 <Box
                                    borderRadius={"15px"}
                                    p={"20px"}
                                    w={"45%"}
                                    bg={isButtonClicked("BNB") ? "rgb(80,80,80)" : "white"}
                                    color={isButtonClicked("BNB") ? "white" : "rgb(80,80,80)"}
                                    onClick={() => handleButtonClick("BNB")}
                                 >
                                    <Flex gap={{ base: "3px", sm: "4px", md: "8px", lg: "20px", xl: "20px" }}>
                                       <Image w={{ base: "30px", sm: "60px", md: "60px", lg: "70px", xl: "100px" }} src={bnb}></Image>
                                       <Text>BNB</Text>
                                    </Flex>
                                 </Box>
                                 <Box
                                    borderRadius={"15px"}
                                    p={"20px"}
                                    w={"45%"}
                                    bg={isButtonClicked("LTC") ? "rgb(80,80,80)" : "white"}
                                    color={isButtonClicked("LTC") ? "white" : "rgb(80,80,80)"}
                                    onClick={() => handleButtonClick("LTC")}
                                 >
                                    <Flex gap={{ base: "3px", sm: "4px", md: "8px", lg: "20px", xl: "20px" }}>
                                       <Image w={{ base: "30px", sm: "60px", md: "60px", lg: "70px", xl: "100px" }} src={ltc}></Image>
                                       <Text>LTC</Text>
                                    </Flex>
                                 </Box>
                              </Flex>
                           </Box>
                           <Box mt={"30px"}>
                              <Flex justifyContent={"space-between"}>
                                 <Box
                                    borderRadius={"15px"}
                                    p={"20px"}
                                    w={"45%"}
                                    bg={isButtonClicked("USDT-ERC20") ? "rgb(80,80,80)" : "white"}
                                    color={isButtonClicked("USDT-ERC20") ? "white" : "rgb(80,80,80)"}
                                    onClick={() => handleButtonClick("USDT-ERC20")}
                                 >
                                    <Flex gap={{ base: "3px", sm: "4px", md: "8px", lg: "20px", xl: "20px" }}>
                                       <Image w={{ base: "30px", sm: "60px", md: "40px", lg: "50px", xl: "100px" }} display={{ base: "none", sm: "none", md: "block", lg: "block", xl: "block" }} src={usdt}></Image>
                                       <Box>
                                          <Text>Tether USD</Text>
                                          <Text>ERC20</Text>
                                       </Box>
                                    </Flex>
                                 </Box>
                                 <Box
                                    borderRadius={"15px"}
                                    p={"20px"}
                                    w={"45%"}
                                    bg={isButtonClicked("USDT-TRC20") ? "rgb(80,80,80)" : "white"}
                                    color={isButtonClicked("USDT-TRC20") ? "white" : "rgb(80,80,80)"}
                                    onClick={() => handleButtonClick("USDT-TRC20")}
                                 >
                                    <Flex gap={{ base: "3px", sm: "4px", md: "8px", lg: "20px", xl: "20px" }}>
                                       <Image w={{ base: "20px", sm: "60px", md: "40px", lg: "50px", xl: "100px" }} display={{ base: "none", sm: "none", md: "block", lg: "block", xl: "block" }} src={usdt}></Image>
                                       <Box>
                                          <Text>Tether USD</Text>
                                          <Text>TRC20</Text>
                                       </Box>
                                    </Flex>
                                 </Box>
                              </Flex>
                           </Box>
                        </Box>
                        <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "50%", xl: "59%" }} boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px;" p={"20px"}>
                           <form onSubmit={uploadImage} enctype="multipart/form-data">
                              <Box p={"30px"}>
                                 <Flex justifyContent={"space-between"} gap={"20px"} flexDirection={{ base: "column", sm: "column", md: "column", lg: "row", xl: "row" }}>
                                    <Box bg={"white"} color={"black"} w={{ base: "100%", sm: "100%", md: "100%", lg: "40%", xl: "40%" }} borderRadius={"15px"} p={"20px"}>
                                       <Text>
                                          {text}
                                       </Text>
                                    </Box>
                                    <Box >
                                       <Center> <Image src={scanner}></Image> </Center>
                                    </Box>
                                 </Flex>
                              </Box>
                              <Box>
                                 <Flex>
                                    <Input color={"white"} type="text" name="code" value={code} onChange={(e) => { setCode(e.target.value) }} isDisabled />
                                    <Button onClick={onCopy} ml={2}>
                                       {hasCopied ? 'Copied!' : 'Copy'}
                                    </Button>
                                 </Flex>
                              </Box>
                              <Box mt={"30px"}>
                                 <Text>Enter amount sent</Text>
                                 <Input mt={"5px"} type="number" value={amount} name="amount" placeholder="Eg. 1000" onChange={(e) => setAmount(e.target.value)} />
                              </Box>
                              <Box mt={"30px"}>
                                 <Text mb={"10px"}>Upload payment proof</Text>
                                 <input type="file" name="Image" onChange={(e) => handleImg(e)} />
                              </Box>
                              <Box mt={"30px"}>
                                 <Text >Note: only click paid after making payment to the above address</Text>
                                 {
                                    load ?<Button type="submit" cursor={"pointer"} mt={"5px"} bg={"rgb(255,218,69)"} color={"black"} w={"100%"} >Paid</Button>:<Button type="submit" mt={"5px"} bg={"rgb(255,218,69)"} color={"black"} cursor={"pointer"} w={"100%"} ><Spinner/></Button>
                                 }
                              </Box>
                           </form>
                        </Box>
                     </Flex>
                  </Box>
               </Box>
            </Box>
         </Box>
      </>
   );
}
export default AddDeposit;
