import { Box, Button, Flex, Input, Text, Textarea } from "@chakra-ui/react";
import Navbar from "../../Navbar/Navbar";
import NavbarNew from "../../Navbar/NavbarNew";
import NavbarHome from "../../Navbar/NavbarHome";
import Footer from "../../Navbar/Footer";



function OpenLive() {
    return (
        <>
            <Box>
                <NavbarHome />
            </Box>
            <Box padding={{base:'10px',sm:"10px",md:"10px",xl:"30px 45px",lg:"30px 45px"}} >
                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"50px"}>Open Live Account</Text>
            </Box>
            <Box paddingLeft={{base:'10px',sm:"10px",md:"10px",xl:"45px",lg:"45px"}}>
                <Flex gap={"20px"} flexDirection={{base:"column",sm:"column",md:"column",lg:"row",xl:"row"}}>
                    <Box w={{base:'100%',sm:"100%",md:"100%",xl:"70%",lg:"70%"}}>
                        <Box>
                            <Text fontSize={"15px"}>
                            Do not mix the Demo Account with the Live Account, though. There is no chance of losing money when trading using a demo account, but there is also no chance of making money either. It merely serves as a training field where you may pick up the fundamentals, recommendations, and techniques. It is designed to make it simpler for you to access the crucial FOREX trading tools, features, and information. Since there are no risks involved, you are free to take unnecessary risks or make mistakes.
                            </Text>
                            <Text fontSize={"15px"} mt={"30px"}>
                            The real account is called a live account or an online account. It can be opened using GloabFx. Yes, you are just a step away from the live trading account. Our straightforward method makes it child’s play. It does not matter if you are looking for an individual account or a corporate account. Forget about your knowledge of strategy, skill levels, and kitty size. For you, we offer the best possible answer. It is quick, safe, and easy. We do not believe in hidden fees or terms and conditions with asterixis. Our strategy is honest and fair.
                            </Text>
                            
                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>The results are outstanding, subject to market risks!</Text>
                            </Box>
                            <Box>
                            <Text fontSize={"15px"} mt={"30px"}>
                            We promise that if you register an online account with us, you will make good money. However, keep in mind that the results in the demo account could not reflect exactly the same in the live account. The live environment is not the same as the conditions in the demo accounts. Certain factors, like market volatility, execution and pricing, leverage and margin setting, and performance and pricing, make a big difference.
                            </Text>
                            <Text fontSize={"15px"} mt={"30px"}>
                            Having said that, the sample account establishes a strong foundation for anyone looking to master FOREX Trading techniques. Our professionals at GloabFx are always glad to assist with their in-depth knowledge of the industry. We are happy to offer our assistance in order for you to succeed financially. Join GloabFx as a proud Live Account Holder today and explore the world of FOREX Trading!
                            </Text>
                            
                            </Box>

                        </Box>
                        
                    </Box>

                    <Box w={{base:"100%",sm:"100%",md:"100%",lg:"30%",xl:"30%"}} p={{base:'5px',sm:"5px",md:"5px",lg:"30px",xl:"30px"}}>
                        <Input placeholder="Your Name" />
                        <Input mt={"20px"} placeholder="Your Email" />
                        <Input mt={"20px"} placeholder="Phone Number" />
                        <Textarea mt={"20px"} placeholder="Your Messages"/>
                        <Button mt={"20px"}  bg={"rgb(255,218,69)"} w={"100%"}>SEND MAIL</Button>
                    </Box>
                </Flex>
            </Box>
            <Box>
                <Footer/>
            </Box>
        </>
    )
}
export default OpenLive;