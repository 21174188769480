import { Box, Button, Divider, Flex, Input, Spinner, Text, useToast } from "@chakra-ui/react";
import NavbarNew from "./NavbarNew";
import { useContext, useEffect, useRef, useState } from "react";
import { myContext } from "../context/Context";
import { useNavigate } from "react-router-dom";



function Profile() {
    let result = JSON.parse(sessionStorage.getItem('key'));
    let navigate = useNavigate()
    const btnRef = useRef()
    let [data, setData] = useState({})
    let [load, setLoad] = useState(true);
    let toast=useToast();



    let [neteq, setNetEquity] = useState(0)
    let pl = JSON.parse(localStorage.getItem("totalpl"))
    console.log(pl)

    let saveDetails = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }
    let { totalbalance, fetchBalance } = useContext(myContext)

    let margin = JSON.parse(localStorage.getItem("margin"))
    let res = JSON.parse(localStorage.getItem("account_type"))

    let fetchDataprofile = async () => {
        try {
            let response = await fetch(`https://trading-jz57.onrender.com/register/${result.type.AcNumber}`)
            let res = await response.json();
            console.log(res);
            setData(res);

        } catch (error) {
            console.log(error)
        }
    }
    console.log(data)

    let EditDataprofile = async () => {
        setLoad(false);
        try {
            let response = await fetch(`https://trading-jz57.onrender.com/register/${result.type.AcNumber}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });
    
            if (response.ok) {
                let res = await response.json();
                console.log(res);
                setData(res);
                toast({
                    description: `Updated successfully`,
                    status: `success`,
                    duration: 5000,
                    isClosable: true,
                    position: "top-center",
                });
                setLoad(true);
            } else {
                // Handle non-200 HTTP status codes
                console.error('Failed to fetch:', response.status, response.statusText);
                setLoad(true);
            }
        } catch (error) {
            console.error('Error during fetch:', error);
            setLoad(true);
        }
    };
    

    useEffect(() => {
        fetchDataprofile()
    }, [])



    useEffect(() => {
        fetchBalance();
        setNetEquity((totalbalance) + parseFloat(pl))

    }, [totalbalance])
    return (
        <>
            <Box>
                <NavbarNew />
            </Box>
            <Box>
                <Flex>
                    <Box display={{ base: "none", sm: "none", md: "none", lg: "none", xl: "block" }} w={"20%"} p={"20px"} color={"white"} bg={"black"} >
                        <Box mt={"30px"}>
                            <Text textAlign={"center"} fontSize='2xl'>Account Summary</Text>
                        </Box>
                        <Box mt={"30px"}>
                            <Flex direction={"column"} gap={"20px"}>
                                <Box>
                                    <Flex justifyContent={"space-between"}>
                                        <Text fontWeight={"bold"}> Package</Text>
                                        <Text fontWeight={"500"}>{res.account_type} </Text>
                                    </Flex>
                                </Box>
                                <Box>
                                    <Flex justifyContent={"space-between"}>
                                        <Text fontWeight={"bold"}> Balance</Text>
                                        <Text fontWeight={"500"}> $ {totalbalance !== undefined ? totalbalance.toFixed(2) : "0.00"}</Text>
                                    </Flex>
                                </Box>
                                <Box>
                                    <Flex justifyContent={"space-between"}>
                                        <Text fontWeight={"bold"}> Open P/L</Text>
                                        <Text fontWeight={"500"}> $ {pl !== null ? pl.toFixed(2) : "0.00"}</Text>
                                    </Flex>
                                </Box>
                                <Box>
                                    <Flex justifyContent={"space-between"}>
                                        <Text fontWeight={"bold"}> Net Equity</Text>
                                        <Text fontWeight={"500"}> $ {neteq !== undefined ? neteq.toFixed(2) : "0.00"}</Text>
                                    </Flex>
                                </Box>
                                <Box>
                                    <Flex justifyContent={"space-between"}>
                                        <Text fontWeight={"bold"}> Margin</Text>
                                        <Text fontWeight={"500"}> $ {margin}</Text>
                                    </Flex>
                                </Box>
                            </Flex>
                        </Box>

                    </Box>
                    <Box p={"20px"} w={"100%"}>
                        <Box >
                            <Text fontSize={"2xl"}>My Profile</Text>
                        </Box>
                        <Divider></Divider>
                        <Box>


                            <Box >
                                <Box>
                                    <Flex justifyContent={"space-between"} flexDirection={{ base: "column", sm: "column", md: "row", lg: "row", xl: "row" }}>
                                        <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "45%", xl: "45%" }} mt={"20px"}>
                                            <Text>Account Number</Text>
                                            <Input name="AcNumber" value={data.AcNumber} mt={"10px"} readOnly isDisabled color={"rgb(110,142,165)"} onChange={saveDetails} />
                                        </Box>
                                        <Box w={{ base: "100%", sm: "100%", md: "100%%", lg: "45%", xl: "45%" }} mt={{ base: "20px", sm: "20px", md: "20px", lg: "20px", xl: "20px" }}>
                                            <Text>Email Address</Text>
                                            <Input name="email" value={data.email} type="email" mt={"10px"} readOnly isDisabled color={"rgb(110,142,165)"} onChange={saveDetails} />
                                        </Box>
                                    </Flex>
                                </Box>

                                <Box>
                                    <Flex justifyContent={"space-between"} flexDirection={{ base: "column", sm: "column", md: "row", lg: "row", xl: "row" }}>
                                        <Box w={{ base: "100%", sm: "100%", md: "45%", lg: "45%", xl: "45%" }} mt={"20px"}>
                                            <Text>Package</Text>
                                            <Input name="account_type" type="text" mt={"10px"} value={data.account_type} readOnly isDisabled color={"rgb(110,142,165)"} onChange={saveDetails} />
                                        </Box>
                                        <Box w={{ base: "100%", sm: "100%", md: "45%", lg: "45%", xl: "45%" }} mt={{ base: "20px", sm: "20px", md: "20px", lg: "20px", xl: "20px" }}>
                                            <Text>Leverage Ratio
                                            </Text>
                                            <Input name="leverage" type="text" mt={"10px"} value={data.leverage} readOnly isDisabled color={"rgb(110,142,165)"} onChange={saveDetails} />
                                        </Box>
                                    </Flex>
                                </Box>
                                <Box>
                                    <Flex justifyContent={"space-between"} flexDirection={{ base: "column", sm: "column", md: "row", lg: "row", xl: "row" }}>
                                        <Box w={{ base: "100%", sm: "100%", md: "45%", lg: "45%", xl: "45%" }} mt={"20px"}>
                                            <Text>First Name</Text>
                                            <Input name="first_name" type="text" mt={"10px"} value={data.first_name} onChange={saveDetails} />
                                        </Box>
                                        <Box w={{ base: "100%", sm: "100%", md: "45%", lg: "45%", xl: "45%" }} mt={{ base: "20px", sm: "20px", md: "20px", lg: "20px", xl: "20px" }}>
                                            <Text>Last Name</Text>
                                            <Input name="last_name" type="text" mt={"10px"} value={data.last_name} onChange={saveDetails} />
                                        </Box>
                                    </Flex>
                                </Box>
                                <Box>
                                    <Flex justifyContent={"space-between"} flexDirection={{ base: "column", sm: "column", md: "row", lg: "row", xl: "row" }}>
                                        <Box w={{ base: "100%", sm: "100%", md: "45%", lg: "45%", xl: "45%" }} mt={"20px"}>
                                            <Text>Mobile Number</Text>
                                            <Input name="mobile" type="number" mt={"10px"} value={data.phone} onChange={saveDetails} />
                                        </Box>
                                        <Box w={{ base: "100%", sm: "100%", md: "45%", lg: "45%", xl: "45%" }} mt={{ base: "20px", sm: "20px", md: "20px", lg: "20px", xl: "20px" }}>
                                            <Text>Date Of Birth</Text>
                                            <Input name="dob" type="text" mt={"10px"} value={data.dob} onChange={saveDetails} />
                                        </Box>
                                    </Flex>
                                </Box>
                                <Box>
                                    {/* <Flex justifyContent={"space-between"} flexDirection={{ base: "column", sm: "column", md: "row", lg: "row", xl: "row" }}> */}
                                    <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "100%", xl: "100%" }} mt={"20px"}>
                                        <Text>Street Address</Text>
                                        <Input name="street_add" type="text" mt={"10px"} value={data.street_add} onChange={saveDetails} />
                                    </Box>

                                    {/* </Flex> */}
                                </Box>
                                <Box>
                                    <Flex justifyContent={"space-between"} flexDirection={{ base: "column", sm: "column", md: "row", lg: "row", xl: "row" }}>
                                        <Box w={{ base: "100%", sm: "100%", md: "45%", lg: "45%", xl: "45%" }} mt={"20px"}>
                                            <Text>City</Text>
                                            <Input name="city" type="text" mt={"10px"} value={data.city} onChange={saveDetails} />
                                        </Box>
                                        <Box w={{ base: "100%", sm: "100%", md: "45%", lg: "45%", xl: "45%" }} mt={{ base: "20px", sm: "20px", md: "20px", lg: "20px", xl: "20px" }}>
                                            <Text>State</Text>
                                            <Input name="state" type="text" mt={"10px"} value={data.state} onChange={saveDetails} />
                                        </Box>
                                    </Flex>
                                </Box>
                                <Box>
                                    <Flex justifyContent={"space-between"} flexDirection={{ base: "column", sm: "column", md: "row", lg: "row", xl: "row" }}>
                                        <Box w={{ base: "100%", sm: "100%", md: "45%", lg: "45%", xl: "45%" }} mt={"20px"}>
                                            <Text>Country</Text>
                                            <Input name="country" mt={"10px"} type="text" value={data.country} onChange={saveDetails} />
                                        </Box>
                                        <Box w={{ base: "100%", sm: "100%", md: "45%", lg: "45%", xl: "45%" }} mt={{ base: "20px", sm: "20px", md: "20px", lg: "20px", xl: "20px" }}>
                                            <Text>
                                                Postal Code</Text>
                                            <Input name="zip_code" type="number" mt={"10px"} value={data.zip_code} onChange={saveDetails} />
                                        </Box>
                                    </Flex>
                                </Box>
                                <Box mt={"20px"}>
                                    {
                                        load?<Button bg="rgb(255,218,69)" onClick={EditDataprofile} color={"black"}>UPDATE PROFILE</Button>:
                                        <Button bg="rgb(255,218,69)"  color={"black"}><Spinner/></Button>
                                    }
                                    
                                </Box>
                            </Box>

                        </Box>
                    </Box>
                </Flex>
            </Box>
        </>
    )
}

export default Profile;