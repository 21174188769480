import { Box, Button, Flex, Input, Text, Textarea } from "@chakra-ui/react";
import Navbar from "../../Navbar/Navbar";
import NavbarNew from "../../Navbar/NavbarNew";
import NavbarHome from "../../Navbar/NavbarHome";
import Footer from "../../Navbar/Footer";



function CryptoCurr() {
    return (
        <>
            <Box>
                <NavbarHome />
            </Box>
            <Box padding={{base:'10px',sm:"10px",md:"10px",xl:"30px 45px",lg:"30px 45px"}} >
                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"50px"}>Crypto Currency</Text>
            </Box>
            <Box paddingLeft={{base:'10px',sm:"10px",md:"10px",xl:"45px",lg:"45px"}}>
                <Flex gap={"20px"} flexDirection={{base:"column",sm:"column",md:"column",lg:"row",xl:"row"}}>
                    <Box w={{base:'100%',sm:"100%",md:"100%",xl:"70%",lg:"70%"}}>
                        <Box>
                            <Text fontSize={"15px"}>
                                Cryptocurrencies are digital assets spreading globally for various purposes using an encrypted mechanism. Because it operates on a blockchain, each transaction’s integrity is guaranteed. Let us talk about a few benefits of cryptocurrencies before delving deeper into them.
                            </Text>
                            <Text fontSize={"15px"} mt={"30px"}>
                                Cryptographic systems enable cryptocurrencies, which are essentially virtual currencies. This is how transactions are finished without the involvement of a third party. Combinations of different algorithms and cryptographic approaches are referred to as “cryptos.” Cryptocurrency exchanges allow users to purchase cryptocurrency, and mining is the process of doing so. Actually, as more and more people are using cryptocurrencies to buy and sell goods, they are employed to make transactions simple and risk-free. However, not all businesses accept this kind of payment because the market will need some time to adjust to the proper adoption of cryptocurrencies.
                            </Text>
                            <Text fontSize={"15px"} mt={"30px"}>
                                To put it simply, it is a mechanism for making safe payments using virtual tokens. To protect your transactions, a variety of encrypted algorithms are used, including hashing techniques, private keys, and public keys.
                            </Text>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>Know a few facts about it?</Text>
                            </Box>
                            <Box>
                                <Text fontSize={"15px"} mt={"30px"}>
                                    The organisation known as “Satoshi Nakamoto” introduced “Bitcoin,” the original cryptocurrency, in 2009. The current estimated worth of bitcoin is $1.3 trillion. There are a tonne of bitcoins on the market right now, and some of the competitors include Ethereum, Litecoin, Cardano, and others.
                                </Text>


                            </Box>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>Types of digital money</Text>
                            </Box>
                            <Box>
                                <Text fontSize={"15px"} mt={"30px"}>
                                    There isn’t actually a single type of cryptocurrency because there are a tonne of coins on the market that are used by some anonymous users. For instance, several anonymous people created “Bitcoin” in 2009 and quickly saw positive feedback, which enabled them to grow their assets and worth. Upon closely examining the statistics, we can discover that over 18 million bitcoins are in use, with a market value of nearly $1.3 trillion.
                                </Text>
                                <Text fontSize={"15px"} mt={"30px"}>
                                    Following the popularity of bitcoin, a plethora of other coins dubbed “altcoins” have entered the market. There are a plethora of coins available on the market that were created from scratch, like bitcoin. Solana, Bitcoin, Litecoin, etc.
                                </Text>


                            </Box>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>Is trading in cryptocurrency legal?</Text>
                            </Box>
                            <Box>
                                <Text fontSize={"15px"} mt={"30px"}>
                                    All government-supported fiat currencies, including the US dollar, are backed by the Federal Reserve.
                                </Text>
                                <Text fontSize={"15px"} mt={"30px"}>
                                    Cryptocurrency backing is non-existent, i.e., neither public nor private authorities are supporting them. Whether it is legal or not is a topic of constant press coverage. El Salvador became the first nation to legalise cryptocurrencies in 2021.
                                </Text>


                            </Box>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>First, let us talk about a few benefits</Text>
                            </Box>
                            <Box p={"20px"}>
                                <Text fontSize={"15px"} mt={"30px"} >
                                    <li>With the start of cryptocurrencies, it will no longer be required to establish centralised middlemen like banks and financial institutions in order to finalise a transaction between two parties.</li>
                                </Text>
                                <Text fontSize={"15px"} >
                                    <li>It includes simple money transfers and, most crucially, goes straight to the recipient without the intervention of banks, financial institutions, or other third parties.</li>
                                </Text>
                                <Text fontSize={"15px"} >
                                    <li>Two types of keys will be used to secure these transactions: public and private. Due to the lack of third-party intervention, this has emerged as the quickest way to send money.</li>
                                </Text>
                                <Text fontSize={"15px"} >
                                    <li>Furthermore, the Japan Payment Service Act was specified in Japan and states that Bitcoin is a legitimate asset. Conversely, China outlawed the usage of cryptocurrencies completely, and news recently surfaced that India is developing a regulatory framework for cryptocurrencies.</li>
                                </Text>


                            </Box>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>There are a few drawbacks to this market.</Text>
                            </Box>
                            <Box p={"20px"}>
                                <Text fontSize={"15px"} mt={"10px"} >
                                    <li>Because it is still seen as an anonymous transaction, it is pseudonymous. The Federal Bureau of Investigation (FBI) is able to interpret its digital trial, suggesting that the government may be monitoring the transactions.</li>
                                </Text>
                                <Text fontSize={"15px"} >
                                    <li>used for more evil purposes, such as money laundering and illegal transactions. Crypto gained notoriety among hackers for engaging in illicit activity.</li>
                                </Text>
                                <Text fontSize={"15px"} >
                                    <li>Essentially, it is intended to use blockchain technology to decentralise your finances across numerous parties.the lack of third-party intervention, this has emerged as the quickest way to send money.</li>
                                </Text>
                                


                            </Box>
                            <Text>GloabFx is a new cryptocurrency, and the reason for our delayed launch is that we are still conducting research. Sure! Before we started it properly, our team conducted research on it and determined its market. We have amazing outcomes, so if you want to start trading cryptocurrency, all you have to do is open an account with us and receive our team’s complete help.</Text>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>GloabFX Conditions</Text>
                            </Box>
                            <Box>
                                <Text fontSize={"15px"} mt={"30px"}>
                                We are dedicated to giving traders access to all of the resources and opportunities needed to succeed in the financial industry. You may find full information about our trading conditions, tools, and services below
                                </Text>
                               


                            </Box>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>Trading Hour</Text>
                            </Box>
                            <Box>
                                <Text fontSize={"15px"} mt={"30px"}>
                                The market opens at 22:00 GMT on Sunday and closes at 21:00 GMT on Friday. Almost all goods are traded nonstop during this 24-hour period (trading hours can vary owing to seasonal time adjustments, public holidays, and unusual liquidity that results from exceptional world events). Nonetheless, as the following table details, several products have designated trade hours
                                </Text>
                               


                            </Box>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>Margin Requirements</Text>
                            </Box>
                            <Box>
                                <Text fontSize={"15px"} mt={"30px"}>
                                For a range of financial assets, including Forex, precious metals, commodities, and index-based CFDs, GloabFx provides leveraged (margin) trading services. This allows you to trade large positions using a relatively small amount of money in your account. 
                                </Text>
                                <Text fontSize={"15px"} mt={"30px"}>
                                “Margin” enables you to keep a position that is far larger than the real value of your account while also serving as collateral to cover any losses you may suffer. This implies that you have the chance to make sizable (in relation to the amount invested) profits.
                                </Text>
                                <Text fontSize={"15px"} mt={"30px"}>
                                Leverage has two drawbacks. It can quickly magnify your losses as well as significantly improve your winnings. Overleveraging can make the profits from numerous winning trades offset by a few poor ones. Because of the high level of risk involved, leveraged trading may not be appropriate for all investors.
                                </Text>
                                <Text fontSize={"15px"} mt={"30px"}>
                                You can never lose more money than you have deposited with GloabFx because of our Negative Balance Protection programme, but even a slight shift in the market might cause a significant loss of money. Our trading platform automatically determines your required margin before placing any orders and verifies the amount of money in your account before approving any withdrawal requests.
                                </Text>
                                <Text fontSize={"15px"} mt={"30px"}>
                                When you want to expand your exposure in the account—for example, by starting a new trade or asking to withdraw money while you still have open positions in the account—the margin requirements come into play. Particular margin needs to consider the possible risk of open positions according to the asset’s volatility, liquidity, and pricing availability. The increased margin needed during trading breaks stems from this.
                                </Text>
                               


                            </Box>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>CFD Expiration Dates</Text>
                            </Box>
                            <Box>
                                <Text fontSize={"15px"} mt={"30px"}>
                                Now, in addition to our other trading choices, traders may benefit from CFDs. Each CFD contract has an expiration date and a limited period of time during which it may be exchanged. The contract cannot be traded after the expiration date, and all positions must be closed.
                                </Text>
                               


                            </Box>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"50px"}>Trading Rules</Text>
                            </Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>Policy for Order Execution</Text>
                            </Box>
                            <Box>
                                <Text fontSize={"15px"} mt={"30px"}>
                                An order, such as a buy limit, buy stop, sell limit, sell stop, stop loss order, take profit, or similar order, is instantaneously executed at the price specified in the order if the market price hits that price. Under certain market situations, it might not be able to execute orders at the price requested by the client. If this occurs, FIH is entitled to execute the order at the first accessible price. This could occur, for instance, during periods of volatile price movements if the price decreases or increases during a single trading session to the point where, in accordance with the applicable exchange’s rules, trade is stopped or closed. It could also happen at the start of trading sessions.
                                </Text>
                               

                            </Box>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>Completing Foreign Exchange Deals</Text>
                            </Box>
                            <Box>
                                <Text fontSize={"15px"} mt={"30px"}>
                                Open forward and spot transactions will be carried over by FIH for an extra two (2) business days if the client does not complete them by the time their value date arrives. As a result, until FIH closes each open transaction in accordance with the provisions of this agreement, the open transactions will roll over for a further two (2) working days each.
                                </Text>
                                

                            </Box>

                        </Box>
                        
                        <Box>
                            
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>Hedging</Text>
                            </Box>
                            <Box>
                                <Text fontSize={"15px"} mt={"30px"}>
                                When two transactions are opened with the same underlying asset or currency pair but different directions (one “buy” and the other “sell”), whether or not they are opened for the same quantity and whether or not they are opened at the same time, this is known as hedging. In order to determine the minimum margin, FIH may consider hedging transactions on either a cumulative or “net” basis, as it could choose at its sole discretion. Furthermore, FIH might interpret the termination of a hedging transaction as the start of a new transaction that is equivalent to the hedging transaction that results.
                                </Text>
                                

                            </Box>


                        </Box>
                        
                    </Box>

                    <Box w={{base:"100%",sm:"100%",md:"100%",lg:"30%",xl:"30%"}} p={{base:'5px',sm:"5px",md:"5px",lg:"30px",xl:"30px"}}>
                        <Input placeholder="Your Name" />
                        <Input mt={"20px"} placeholder="Your Email" />
                        <Input mt={"20px"} placeholder="Phone Number" />
                        <Textarea mt={"20px"} placeholder="Your Messages" />
                        <Button mt={"20px"} bg={"rgb(255,218,69)"} w={"100%"}>SEND MAIL</Button>
                    </Box>
                </Flex>
            </Box>
            <Box>
                <Footer/>
            </Box>
        </>
    )
}
export default CryptoCurr;