import { Box, Button, Divider, Flex, Image, Table, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr } from "@chakra-ui/react";
import LeftNav from "./LeftNav";
import { useEffect, useState } from "react";


function Verify() {


    let [data, setData] = useState([])
    let fetchBankWithdraw = async () => {
        try {
            let response = await fetch(`https://trading-jz57.onrender.com/verify/admin`)
            let res = await response.json();
            console.log(res);
            setData(res.reverse());
        } catch (error) {
            console.log(error)
        }
    }

    let ApproveRequest = async (withdraw_id, ac, approve, verification_status) => {
        console.log(approve);
        console.log(verification_status);
        try {
            let response = await fetch(`https://trading-jz57.onrender.com/verify/putfile/${withdraw_id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ "type_at": approve })
            });
    
            if (response.ok) {
                approveuserData(ac, verification_status); 
                let res = await response.json();
                console.log(res);
            } else {
                console.log(`Error: ${response.status}`);
            }
        } catch (error) {
            console.log(error);
        }
    }
    
    let approveuserData = async (id, verification_status) => {
        console.log(id, verification_status);
        try {
            let response = await fetch(`https://trading-jz57.onrender.com/register/${id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ "verification_status":verification_status })
            });
            if(response.ok){
            let res = await response.json();
            window.location.reload();
            }
           
        } catch (error) {
            console.log(error)
        }
    }
    
    useEffect(() => {
        fetchBankWithdraw();
    }, [])
    

    return (
        <>
            <Box>
            <Flex gap={"20px"} flexDirection={{base:"column",sm:"column",md:"column",lg:"row",xl:"row"}}>
                    <Box w={{base:"100%",sm:"100%",md:"100%",lg:"20%",xl:'20%'}}>
                        <LeftNav />
                    </Box>
                    <Box w={{base:"100%",sm:"100%",md:"100%",lg:"80%",xl:'80%'}} p={"20px"}>

                        <Text fontSize={"2xl"}>All Withdraw</Text>
                        <Divider></Divider>
                        <TableContainer>
                            <Table variant='simple'>

                                <Thead>
                                    <Tr>

                                        <Th color={"white"}> User Account </Th>
                                        <Th color={"white"}>Image</Th>
                                        <Th color={"white"}>Date of Request</Th>
                                        <Th color={"white"}>Document Id</Th>
                                        <Th color={"white"}>Status</Th>
                                        <Th color={"white"}>Action</Th>
                                        <Th  color={"white"}>Action</Th>


                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {
                                        data.length > 0 ? data.map((ele, idx) => {
                                            return <Tr>
                                                <Td>{ele.AcNumber}</Td>
                                                

                                                <Td>
                                                <a href={ele.Image} target="_blank" rel="noopener noreferrer">
                                                    <Image w={"120px"} h={"30px"} src={ele.Image}></Image>
                                                    </a>
                                                    </Td>
                                                <Td>{new Date(ele.created_at).toLocaleString('en-GB', {
                                                        day: 'numeric',
                                                        month: 'short',
                                                        year:"2-digit",
                                                        hour: 'numeric',
                                                        minute: '2-digit',
                                                        second: '2-digit',
                                                        hour12: true,
                                                    })
                                                    .replace(/ /, '-')
                                                    .replace(/,/g, '')
                                                    .replace(/(\w+) (\d+)-(\d+),/, '$1-$2-$3')}</Td>
                                                <Td>{ele.document}</Td>
                                                <Td>{ele.type_at}</Td>



                                                <Td>
                                                    <Button
                                                        color={"white"}
                                                        bg={"rgb(10,179,156)"}
                                                    onClick={() => ApproveRequest(ele._id,ele.AcNumber, "approved","true")}
                                                    >
                                                        Approve
                                                    </Button>
                                                </Td>
                                                <Td>
                                                    <Button
                                                        color={"white"}
                                                        bg={"red"}
                                                    onClick={() => ApproveRequest(ele._id,ele.AcNumber, "rejected","false")}
                                                    >
                                                        Reject
                                                    </Button>
                                                </Td>

                                            </Tr>
                                        }) : <Tr>
                                            <Td colSpan={7} textAlign={"center"}>No Verify Request</Td>

                                        </Tr>
                                    }


                                </Tbody>
                                <Tfoot>

                                </Tfoot>
                            </Table>
                        </TableContainer>

                    </Box>
                </Flex>
            </Box>
        </>
    )
}

export default Verify;