import { AspectRatio, Box, Center, Text } from "@chakra-ui/react";
import NavbarNew from "../../Navbar/NavbarNew";



function LiveChart() {
    return (
        <>
            <Box>
                <NavbarNew />
            </Box>

            <Box w={"100%"} overflow="hidden"
                // border="1px solid blue" 
                boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                h={"100vh"}
            >
                <AspectRatio  >
                    <iframe
                        style={{height:"100vh"}}
                        title='Trading'
                        src='https://s.tradingview.com/financemagnates/widgetembed/?hideideas=1&overrides=%7B%7D&enabled_features=%5B%5D&disabled_features=%5B%5D&locale=en#%7B%22symbol%22%3A%22FX%3AEURUSD%22%2C%22frameElementId%22%3A%22tradingview_4b970%22%2C%22interval%22%3A%221D%22%2C%22range%22%3A%2212M%22%2C%22hide_side_toolbar%22%3A%220%22%2C%22allow_symbol_change%22%3A%221%22%2C%22save_image%22%3A%221%22%2C%22studies%22%3A%22%5B%5D%22%2C%22theme%22%3A%22dark%22%2C%22style%22%3A%221%22%2C%22timezone%22%3A%22Etc%2FUTC%22%2C%22withdateranges%22%3A%221%22%2C%22studies_overrides%22%3A%22%7B%7D%22%2C%22utm_source%22%3A%22www.forexlive.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22chart%22%2C%22utm_term%22%3A%22FX%3AEURUSD%22%2C%22page-uri%22%3A%22www.forexlive.com%2FLiveCharts%22%7D'
                    // allowFullScreen
                    />
                    {/* <iframe id="tradingview_83bf5" name="tradingview_83bf5" src="blob:https://my.exness.com/8fc2be8e-e99e-47f4-9e18-e580fb5335c2" 
                                data-widget-options="symbol=USD%2FJPY&amp;interval=1&amp;widgetbar=%7B%22details%22%3Afalse%2C%22watchlist%22%3Afalse%2C%22news%22%3Afalse%2C%22datawindow%22%3Afalse%2C%22watchlist_settings%22%3A%7B%22default_symbols%22%3A%5B%5D%7D%7D&amp;timeFrames=%5B%7B%22text%22%3A%225y%22%2C%22resolution%22%3A%221W%22%7D%2C%7B%22text%22%3A%221y%22%2C%22resolution%22%3A%221W%22%7D%2C%7B%22text%22%3A%226m%22%2C%22resolution%22%3A%22120%22%7D%2C%7B%22text%22%3A%223m%22%2C%22resolution%22%3A%2260%22%7D%2C%7B%22text%22%3A%221m%22%2C%22resolution%22%3A%2230%22%7D%2C%7B%22text%22%3A%225d%22%2C%22resolution%22%3A%225%22%7D%2C%7B%22text%22%3A%221d%22%2C%22resolution%22%3A%221%22%7D%5D&amp;locale=en&amp;uid=tradingview_83bf5&amp;clientId=0&amp;userId=0&amp;chartsStorageVer=1.0&amp;customCSS=%2Fwebtrading%2Ftrading_view%2Fcustom.css%3Fv2&amp;debug=false&amp;timezone=Etc%2FUTC&amp;theme=dark"
                                 title="Financial Chart" frameborder="0" allowtransparency="true" scrolling="no" 
                                allowfullscreen="" >
                                </iframe> */}
                </AspectRatio>
            </Box>
        </>
    )
}

export default LiveChart;