import { Box, Button, Divider, Flex ,Image,Text} from "@chakra-ui/react";
import logo from "../images/logo.png";
import { useNavigate } from "react-router-dom";


function Footer(){
    let navigate=useNavigate()
    return(
        <>
        <Box mt={"50px"}  bg={"#37424C"} boxShadow="rgba(0, 0, 0, 0.15) 0px 2px 8px" pt={"35px"}>
            <Flex gap={"20px"} flexDirection={{base:"column",sm:"column",md:"column",lg:"row",xl:"row"}}>
            <Box w={{base:"100%",sm:"100%",md:"100%",lg:"40%",xl:"40%"}}  pl={{base:"20px",sm:"20px",md:"20px",lg:"50px",xl:"50px"}}  >
                        <Box p={"20px"} w={{ base: "100%", sm: "100%", md: "100%", lg: "80%", xl: "80%" }} >
                            <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "70%%", xl: "70%%" }}>
                                <Image cursor={"pointer"} src={logo}></Image>
                            </Box>
                        </Box>
                        <Text>GloabFx is amongst the most favored and trusted companies offering stocks, commodities and cfd trading</Text>
                        <Box mt={"20px"}>
                            <Flex gap={"30px"}>
                            <i class="fa-brands fa-facebook"></i>
                            <i class="fa-brands fa-twitter"></i>
                            <i class="fa-brands fa-instagram"></i>
                            <i class="fa-brands fa-youtube"></i>
                            <i class="fa-brands fa-linkedin"></i>
                            </Flex>
                        </Box>
                        <Button bg={"rgb(255,218,69)"} size={"lg"} mt={"20px"}>DOWNLOAD APP</Button>
                    </Box>
               
                <Box w={{base:"100%",sm:"100%",md:"100%",lg:"60%",xl:"60%"}} m={"auto"} p={{base:"20px",sm:"20px",md:"20px",lg:"0px",xl:"0px"}} >
                    <Flex justifyContent={"space-between"} flexDirection={{base:"column",sm:"column",md:"column",lg:"row",xl:"row"}}>
                        <Box w={{base:"100%",sm:"100%",md:"100%",lg:"33.5%",xl:"33.5%"}} >
                         <Text fontSize={"20px"} fontWeight={"bold"}>Learn to Trade</Text>
                         <Box mt={"20px"}>
                            <Text fontSize={"15px"} cursor={"pointer"} onClick={() => navigate("/forex")}>Forex</Text>
                            <Text mt={"10px"} cursor={"pointer"} fontSize={"15px"} onClick={() => navigate("/commodities")}>Commodities</Text>
                            <Text  mt={"10px"} cursor={"pointer"} fontSize={"15px"} onClick={() => navigate("/indices")}>Indices</Text>
                            <Text   mt={"10px"} cursor={"pointer"} fontSize={"15px"} onClick={() => {
                                                        navigate("/stock")
                                                    }}>Stocks</Text>
                            <Text   mt={"10px"} cursor={"pointer"} fontSize={"15px"} onClick={() => navigate("/currencytrad")}>Currency Trading</Text>
                            <Text  mt={"10px"} cursor={"pointer"} fontSize={"15px"} onClick={() => navigate("/cryptocurr")}>Crypto Currency</Text>
                            <Text  mt={"10px"} cursor={"pointer"} fontSize={"15px"} onClick={() => navigate("/faq")}>Trading FAQs</Text>
                         </Box>
                        </Box>
                        <Box w={{base:"100%",sm:"100%",md:"100%",lg:"33.5%",xl:"33.5%"}} mt={{base:"20px",sm:"20px",md:"20px",lg:"0px",xl:"0px"}}>
                        <Text fontSize={"20px"} fontWeight={"bold"}>Tools for Trading</Text>
                        <Box  mt={"20px"}>
                            <Text  mt={"10px"} cursor={"pointer"} fontSize={"15px"} onClick={() => navigate("/dailym")}>Daily Market Review</Text>
                            <Text  mt={"10px"} cursor={"pointer"} fontSize={"15px"} onClick={() => navigate("/fincal")}>Financial Calender</Text>
                            <Text  mt={"10px"} cursor={"pointer"} fontSize={"15px"} onClick={() => navigate("/realchart")}>Real Time Charts</Text>
                            <Text  mt={"10px"} cursor={"pointer"} fontSize={"15px"} onClick={() => navigate("/signal")}>Signals</Text>
                            <Text   mt={"10px"} cursor={"pointer"} fontSize={"15px"} onClick={() => navigate("/trading")}>Full List of  Trading Signals</Text>
                            <Text   mt={"10px"} cursor={"pointer"} fontSize={"15px"} onClick={() => navigate("/platform")}>Platform</Text>
                            <Text   mt={"10px"} cursor={"pointer"} fontSize={"15px"} onClick={() => navigate("/education")}>Education Package</Text>
                         </Box>
                        </Box>
                        <Box w={{base:"100%",sm:"100%",md:"100%",lg:"33.5%",xl:"33.5%"}}mt={{base:"20px",sm:"20px",md:"20px",lg:"0px",xl:"0px"}} >
                        <Text fontSize={"20px"} fontWeight={"bold"}>About GloabFx</Text>
                        <Box  mt={"20px"} >
                            <Text  mt={"10px"} cursor={"pointer"} onClick={() => navigate("/goal")} fontSize={"15px"}>Our Goal</Text>
                            <Text  mt={"10px"} cursor={"pointer"} onClick={() => navigate("/benfit")} fontSize={"15px"}>Benefits of Trading</Text>
                            <Text  mt={"10px"}  cursor={"pointer"} onClick={() => navigate("/costtrading")} fontSize={"15px"}>Costs and Trading</Text>
                            <Text  mt={"10px"} cursor={"pointer"} onClick={() => navigate("/help")} fontSize={"15px"}>GloabFx Help</Text>
                            <Text  mt={"10px"} cursor={"pointer"} onClick={() => navigate("/policy")} fontSize={"15px"}>Our Trading Policy</Text>
                            <Text  mt={"10px"} cursor={"pointer"} onClick={() => navigate("/terms")} fontSize={"15px"}>Trading Terms</Text>
                            <Text  mt={"10px"} cursor={"pointer"} onClick={() => navigate("/risk")} fontSize={"15px"}>Risk Caution for Forex</Text>
                         </Box>
                        </Box>
                    </Flex>
                </Box>
            </Flex>
            <Divider mt={"20px"}/>
            <Box pl={"50px"}>
                <Text p={"10px"} >Copyright 2023 GloabFx, All Right Reserved |</Text>
            </Box>
        </Box>
        </>
    )
}

export default Footer;