import { Box, Button, Flex, Input, Text, Textarea } from "@chakra-ui/react";
import Navbar from "../../Navbar/Navbar";
import NavbarNew from "../../Navbar/NavbarNew";
import NavbarHome from "../../Navbar/NavbarHome";
import Footer from "../../Navbar/Footer";



function DailyM() {
    return (
        <>
            <Box>
                <NavbarHome />
            </Box>
            <Box padding={{ base: '10px', sm: "10px", md: "10px", xl: "30px 45px", lg: "30px 45px" }} >
                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"50px"}>Daily Market Review</Text>
            </Box>
            <Box paddingLeft={{ base: '10px', sm: "10px", md: "10px", xl: "45px", lg: "45px" }}>
                <Flex gap={"20px"} flexDirection={{ base: "column", sm: "column", md: "column", lg: "row", xl: "row" }}>
                    <Box w={{ base: '100%', sm: "100%", md: "100%", xl: "70%", lg: "70%" }}>
                        <Box>
                            <Text fontSize={"15px"}>
                                The most reliable and competent FOREX Trading Services are provided by GloabFx. Since we are licenced brokers, our goal is to assist each of our clients in achieving the highest possible degree of profit. We enable you to participate with assurance in the cutting-edge margin FX market.
                            </Text>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>Maintain a strong Understanding</Text>
                            </Box>
                            <Box>
                                <Text fontSize={"15px"} mt={"30px"}>
                                    With the help of our Daily Market Review, have a solid understanding of the FOREX market.
                                </Text>
                                <Text fontSize={"15px"} mt={"30px"}>
                                    Are you curious about the most recent professional thoughts on the euro, dollar, or pound? Or do you wish to track changes in a specific currency’s price? Or are the reviews and analyses of Gold and Silver making you more enthusiastic?
                                </Text>
                                <Text fontSize={"15px"} mt={"30px"}>
                                    GloabFx is your one-stop shop, regardless of your preferences or area of expertise. Our in-depth, coherent analysis will help you comprehend patterns and market ups and downs more clearly.
                                </Text>
                            </Box>
                            <Text fontSize={"15px"} mt={"30px"}>
                                To help you frequently examine the details of the FOREX market, we provide basic daily analysis. The profit margins increase with your level of knowledge. As a result, we provide authoritative, in-depth, and thorough studies on market trends, fluctuations, and forecasts. The reports are current with regard to financial and economic developments. We analyse and analyse every factor influencing FOREX markets and trends. Along with providing you with the “bigger picture,” we also provide you with all the necessary trading signals to help you stay on top of market volatility.
                            </Text>

                        </Box>
                        <Box>
                            <Box mt={"20px"}>
                                <Text color={"rgb(255,218,69)"} fontWeight={"bold"} fontSize={"35px"}>Be a knowledgeable broker</Text>
                            </Box>
                            <Box>
                                <Text fontSize={"15px"} mt={"30px"}>
                                The phrase “Knowledge is power” is true when it comes to FOREX trading. So that you can make an informed choice, we provide thorough information on currency pairs, indexes, and commodities. Our reports are supported by the solid expertise and experience of FOREX Trading veterans who have a wealth of experience in the industry.
                                </Text>
                                <Text fontSize={"15px"} mt={"30px"}>
                                Whether you are a beginner or a seasoned trader, GloabFx offers you unmatched trading security and confidence!
                                </Text>
                                

                            </Box>

                        </Box>
                    </Box>

                    <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "30%", xl: "30%" }} p={{ base: '5px', sm: "5px", md: "5px", lg: "30px", xl: "30px" }}>
                        <Input placeholder="Your Name" />
                        <Input mt={"20px"} placeholder="Your Email" />
                        <Input mt={"20px"} placeholder="Phone Number" />
                        <Textarea mt={"20px"} placeholder="Your Messages" />
                        <Button mt={"20px"} bg={"rgb(255,218,69)"} w={"100%"}>SEND MAIL</Button>
                    </Box>
                </Flex>
            </Box>
            <Box>
                <Footer/>
            </Box>
        </>
    )
}
export default DailyM;