import { Box, Spinner,Text } from "@chakra-ui/react";

function Loading() {
    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap={"10px"}
            h="100vh"
            w="100vw"
            position="fixed"
            top="0"
            left="0"
            backdropFilter="blur(5px)"  // Apply background blur
            backgroundColor="rgba(255, 255, 255, 0.8)"  // Adjust background color and opacity
        >
            <Spinner size="xl" thickness="4px" speed="0.65s" color="rgb(55,67,78)" /> {/* Adjust the size and color as needed */}
            <Text color={"rgb(55,67,78)"}>Loading...</Text>
        </Box>
    );
}

export default Loading;
